<template>
  <v-container v-if="getExaminationData" grid-list-md class="py-3">
    <v-layout class="fill-height" wrap>
      <v-flex xs12 v-if="!isGastro">
        <div class="bg-white px-3 py-3">
          <v-radio-group
            v-model="examViewMode"
            name="examView"
            row
            hide-details
            class="pt-0 mt-0 justify-end"
          >
            <v-radio :label="$t('report.simple')" value="simple" />
            <v-radio :label="$t('report.normal')" value="full" />
          </v-radio-group>
        </div>
      </v-flex>
      <v-flex xs12 v-show="examViewMode == 'full'" v-if="!isGastro">
        <div class="bg-white pa-3 pt-1">
          <ExaminationTimeField
            :data="getExaminationData.examination"
            hide-details
            @change="change($event.field, $event.event)"
          />
        </div>
      </v-flex>
      <v-flex xs6>
        <form-select
          v-if="!isGastro"
          :selected="getExaminationData.examination.indication"
          :items="getIndicationList"
          :label="$t('common.indication')"
          column="indication"
          other-column="indicationOther"
          :other-value="getExaminationData.examination.indicationOther"
          @change="change"
        />
        <form-select
          v-if="!isGastro"
          :selected="getExaminationData.examination.clean_medID"
          :items="
            addOtherItem(
              formSelectDataFormat(getCleanMedList, 'id', 'clean_med')
            )
          "
          :label="$t('common.preparation_agent')"
          column="clean_medID"
          other-column="clean_medOther"
          :other-value="getExaminationData.examination.clean_medOther"
          @change="change"
        />
        <form-select
          v-if="!isGastro"
          :selected="getExaminationData.examination.preparationTime"
          :items="getPreparationTimeList.map((l) => ({ text: l, value: l }))"
          :label="$t('common.preparation_time')"
          column="preparationTime"
          :multiple="false"
          @change="change"
        />
        <form-select
          v-if="!isGastro"
          v-show="examViewMode === 'full'"
          :selected="getExaminationData.examination.antiplateletID"
          :items="
            addOtherItem(
              formSelectDataFormat(getAntiplateletList, 'id', 'antiplatelet')
            )
          "
          :label="$t('common.anti_platelet')"
          column="antiplateletID"
          other-column="antiplateletOther"
          :other-value="getExaminationData.examination.antiplateletOther"
          @change="change"
        >
          <template #extra>
            <v-text-field
              :value="getExaminationData.examination.antiplateletStopDays"
              type="number"
              :label="$t('common.anti_platelet_stop')"
              :suffix="$t('common.day')"
              outline
              hide-details
              @change="change('antiplateletStopDays', $event)"
            />
          </template>
        </form-select>

        <form-select
          v-if="!isGastro"
          :selected="getExaminationData.examination.cleanScale"
          :items="getCleanScaleList"
          :label="$t('common.cleanse_level.before')"
          column="cleanScale"
          :multiple="false"
          @change="change"
        />

        <form-select
          v-if="!isGastro"
          v-show="examViewMode === 'full'"
          :selected="getExaminationData.examination.cleanScaleAfter"
          :items="getCleanScaleList"
          :label="$t('common.cleanse_level.after')"
          column="cleanScaleAfter"
          :multiple="false"
          @change="change"
        />

        <form-select
          :selected="getExaminationData.examination.incompleteDue"
          :items="
            getIncompleteReasonList(getExaminationData.examination.examination_typeID)
          "
          :label="$t('common.incomplete.title')"
          column="incompleteDue"
          :multiple="false"
          @change="change"
        />
        <form-select
          v-show="examViewMode === 'full' && isGastro"
          :selected="getExaminationData.examination.wash_instrumentID"
          :items="
            formSelectDataFormat(getWashInstrumentList, 'id', 'wash_instrument')
          "
          :label="$t('common.washing_machine')"
          column="wash_instrumentID"
          :multiple="false"
          @change="change"
        />
        <form-select
          v-show="examViewMode === 'full' && isGastro"
          :selected="getExaminationData.examination.cloTestLocations"
          :items="getCloTestLocations"
          label="CLO Test"
          column="cloTestLocations"
          @change="change"
        />
      </v-flex>
      <v-flex xs6>
        <form-select
          v-show="examViewMode === 'full'"
          :selected="getExaminationData.examination.inspection_instrumentID"
          :items="
            formSelectDataFormat(
              getInspectionInstrumentList,
              'id',
              'inspection_instrument'
            )
          "
          :label="$t('common.scope_id')"
          column="inspection_instrumentID"
          :multiple="false"
          @change="change"
        />
        <form-select
          v-show="examViewMode === 'full' && !isGastro"
          :selected="getExaminationData.examination.wash_instrumentID"
          :items="
            formSelectDataFormat(getWashInstrumentList, 'id', 'wash_instrument')
          "
          :label="$t('common.washing_machine')"
          column="wash_instrumentID"
          :multiple="false"
          @change="change"
        />
        <!-- **Premedication** -->
        <antispasmodic-input
          v-if="!isGastro"
          v-show="examViewMode === 'full'"
          class="my-3"
          :data="getAntispasmodicValues"
          @change="($event) => change($event.field, $event.event)"
          hide-details
        />

        <div class="my-3" v-show="examViewMode === 'full'">
          <sedative-input-group
            :examinationID="getExaminationData.examination.id"
            :sedatives="getExaminationData.examination.sedatives"
            :sedativesList="getSedatives"
            :sedativeOther="getExaminationData.examination.sedativeOther"
            @change="change('sedativeOther', $event)"
            hide-details
          />
        </div>
        <form-select
          v-if="!isGastro"
          :selected="getExaminationData.examination.insertionLevel"
          :items="getInsertionLevelList"
          label="Insertion Level"
          column="insertionLevel"
          :multiple="false"
          @change="change"
        />
        <v-card
          v-if="!isGastro"
          class="my-3"
          v-show="examViewMode === 'full'"
          flat
        >
          <v-card-title
            class="py-3 px-2 grey--text text--darken-1 h3 font-weight-bold border-b border-formHighlight"
          >
            <h3>Insertion Length</h3>
          </v-card-title>
          <v-card-text>
            <v-text-field
              hide-details
              suffix="cm"
              :value="getExaminationData.examination.insertionLength"
              @change="change('insertionLength', $event)"
              class="py-0"
            />
          </v-card-text>
        </v-card>

        <form-select
          v-if="!isGastro"
          class="my-3"
          :selected="getExaminationData.examination.complication"
          :items="addOtherItem(filterNilSelect(getComplicationList))"
          :label="$t('common.complication')"
          column="complication"
          other-column="complicationOther"
          :other-value="getExaminationData.examination.complicationOther"
          @change="change"
        >
        </form-select>

        <v-card class="mt-3" v-show="examViewMode === 'full'" flat>
          <v-card-title
            class="py-3 px-2 grey--text text--darken-1 h3 font-weight-bold border-b border-formHighlight"
          >
            <h3>{{ $t('common.additional_remarks') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-textarea
              no-resize
              outline
              height="133px"
              row-height="20px"
              :value="getExaminationData.examination.additionalRemarks"
              class="additional-remarks"
              @change="change('additionalRemarks', $event)"
              hide-details
            ></v-textarea>
          </v-card-text>
        </v-card>

        <v-card class="my-3" v-show="examViewMode === 'simple'">
          <v-card-title
            class="py-3 px-2 grey--text text--darken-1 h3 font-weight-bold border-b border-formHighlight"
          >
            WithdrawTime
          </v-card-title>
          <v-card-text>
            <v-text-field
              hide-details
              :value="getExaminationData.examination.withdrawTime"
              @change="change('withdrawTime', $event)"
              class="py-0"
            />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EXAMINATION_TYPE_OF_GASTROSCOPY } from '@/utils/examinations.config.js'
import FormSelect from './form-select'
import SedativeInputGroup from './SedativeInputGroup.vue'
import AntispasmodicInput from './AntispasmodicInput.vue'
import ExaminationTimeField from './ExaminationTimeField.vue'

export default {
  name: 'ExaminationInfo',
  components: {
    FormSelect,
    SedativeInputGroup,
    AntispasmodicInput,
    ExaminationTimeField,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      examViewMode: 'full',
    }
  },
  computed: {
    ...mapGetters('examinations', [
      'getExaminationData',
      'getIndicationList',
      'getCleanMedList',
      'getAntiplateletList',
      'getCleanScaleList',
      'getIncompleteReasonList',
      'getInspectionInstrumentList',
      'getWashInstrumentList',
      'getInsertionLevelList',
      'getComplicationList',
      'getSedatives',
      'getPreparationTimeList',
      'getCloTestLocations',
    ]),
    getAntispasmodicValues() {
      const {
        antispasmodicHave,
        antispasmodicID,
        antispasmodicOther,
        antispasmodicReason,
      } = this.getExaminationData.examination
      return {
        antispasmodicHave: Number(antispasmodicHave) || null,
        antispasmodicIDs: antispasmodicID,
        antispasmodicOther,
        antispasmodicReason,
      }
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.name === 'xs' ||
        this.$vuetify.breakpoint.name === 'sm'
      )
    },
    isGastro() {
      return (
        this.getExaminationData.examination.examination_typeID ===
        EXAMINATION_TYPE_OF_GASTROSCOPY
      )
    },
  },
  methods: {
    ...mapActions('examinations', ['updateExaminationColumn']),
    formSelectDataFormat(arr, valueKey, textKey) {
      return arr.map((item) => ({ text: item[textKey], value: item[valueKey] }))
    },
    addOtherItem(arr) {
      return arr.concat({ text: this.$t('common.other'), value: arr.length + 1 })
    },
    filterNilSelect(arr) {
      // filter text = nil / aka value = 0
      return arr.filter((i) => i.value !== 0)
    },
    change(column, value) {
      this.updateExaminationColumn({ id: this.id, column, value })
    },
  },
}
</script>
