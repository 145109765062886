<template>
  <div
    class="findingLetter break-inside break-inside-page"
    :class="[`findingLetter--${$i18n.locale}`]"
  >
    <div v-show="type !== 'suggestion'" class="break-inside py-3">
      <v-layout row wrap>
        <v-flex xs12 mb-2>
          <h3 class="print-color subtitle-border">{{ $t('common.summary') }} :</h3>
        </v-flex>
        <v-flex xs12>
          <ol v-if="getFindings && getFindings.length > 0">
            <li
              v-for="(finding, findingIndex) in getFindings"
              :key="findingIndex"
              class="mb-2 findingLetter__li"
            >
              {{ finding.summary }}
            </li>
          </ol>
          <div v-else class="ml-3">
            <h3>
              {{ negativeFinding }} :
              <p>{{ getNegaSuggestion || $t('common.nil') }}</p>
            </h3>
          </div>
        </v-flex>
      </v-layout>
    </div>

    <div
      class="break-inside border-t border-t--dashed py-3"
      v-show="type !== 'summary'"
    >
      <v-layout row wrap>
        <v-flex xs12 mb-2>
          <h3 class="print-color subtitle-border">{{ $t('report.suggestion') }} :</h3>
        </v-flex>
        <v-flex xs12>
          <div class="ml-3">
            <p v-if="getExaminationData?.examination?.suggestion">
              {{ getExaminationData.examination?.suggestion }}
            </p>
            <h3 v-else>
              {{ negativeFinding }} :
              <p>{{ getNegaSuggestion || $t('common.nil') }}</p>
            </h3>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SummaryInfoGroup',
  props: {
    reportTitle: String,
    type: {
      type: String,
      validator: (val) => ['summary', 'suggestion', 'all'].indexOf(val) !== -1,
      default: 'all',
    },
    findings: Array,
  },
  watch: {
    getExaminationData() {
      this.findOrCreateSummary()
    },
  },
  computed: {
    ...mapGetters('examinations', ['getExaminationData', 'getLandmarkList']),
    getFindings() {
      return this.findings
    },
    getNegaSuggestion() {
      return this.getExaminationData
        ? this.getExaminationData.examination.negaSuggestion
        : null
    },
    negativeFinding() {
      var findingName = 'Negative finding'
      var insertionLevel = '1,4'

      if (this.getExaminationData) {
        if (
          this.getExaminationData.examination.examination_typeID != 1 &&
          insertionLevel.indexOf(
            this.getExaminationData.examination.insertionLevel,
          ) != -1
        ) {
          findingName = 'Negative finding in the observable segments'
        }
      }

      return findingName
    },
  },
  methods: {
    ...mapActions('examinations', ['writeRecordDiagnosis']),
    async findOrCreateSummary() {
      const findings = await this.getFindings
      for (
        let findingIndex = 0;
        findingIndex < findings.length;
        findingIndex++
      ) {
        // finding don't have value, skip the summary
        if (findings[findingIndex].questions.finding.value == 0) {
          return
        }

        const records = findings[findingIndex].records
        for (let recordIndex = 0; recordIndex < records.length; recordIndex++) {
          const recordId = records[recordIndex].id
          const payload = {
            findingIndex: findingIndex,
            recordIndex: recordIndex,
            recordId: recordId,
          }
          await this.writeRecordDiagnosis(payload)
        }
      }
    },
  },
}
</script>
<style scoped>
.article {
  font-style: normal;
  font-weight: normal;
}
</style>
