<template>
  <v-text-field
    hide-details
    label="Insertion Length"
    suffix="cm"
    :value="data"
    @change="$emit('change', $event)"
   ></v-text-field>
</template>

<script>
export default {
    name: 'InsertionLengthInput',

    props: {
        data: {
            type: [String, Number],
            required: false
        },
    },

    data() {
        return {
            field: 'insertionLength',
            label: 'Insertion Length',
        }
    },

}
</script>