<template>
  <ul class="px-3">
    <v-layout
      wrap
      tag="li"
      class="list-style-none mb-3 border-b border-2 border-light"
      v-for="(value, type) in gastroFindings"
      :key="value.id"
    >
      <v-flex wrap xs2 tag="h4" class="py-2 text-uppercase">
        {{ $t(`common.${type}`) }} :
      </v-flex>
      <v-flex xs10>
        <FindingsItems
          :findings="value"
          :hasFindingLetter="false"
          @delete="$emit('delete', $event)"
          @edit="$emit('edit', $event)"
        />
      </v-flex>
    </v-layout>
  </ul>
</template>

<script>
import FindingsItems from './FindingsItems.vue'
import GastroMixins from '@/components/commons/GastroMixins.js'

export default {
  name: 'FindingsItemsByOrgan',
  components: {
    FindingsItems,
  },
  mixins: [GastroMixins],
}
</script>
