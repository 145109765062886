<style lang="scss">
.screenshot-zoom {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50.5%;
  height: 100%;
  background-color: rgba(#000, 0.5);

  &__view {
    img {
      outline: 15px solid #eceff1;
    }
  }
}
</style>

<template>
  <v-fade-transition>
    <v-layout
      v-show="value"
      align-center
      justify-center
      class="screenshot-zoom"
      @click="click"
    >
      <v-flex xs11 sm10 md9 class="text-xs-center">
        <photo-zoom :url="src" :scale="scale" class="screenshot-zoom__view" />
      </v-flex>
    </v-layout>
  </v-fade-transition>
</template>

<script>
import PhotoZoom from 'vue-photo-zoom-pro'
import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'

export default {
  name: 'ScreenshotZoom',
  inheritAttrs: false,
  components: {
    PhotoZoom,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      scale: 1.3,
    }
  },
  watch: {
    value(v) {
      if (v) {
        addEventListener('wheel', this.wheel)
      } else {
        removeEventListener('wheel', this.wheel)
      }
    },
  },
  beforeDestroy() {
    removeEventListener('wheel', this.wheel)
  },
  methods: {
    click() {
      this.scale = 1.3
      this.$emit('close')
    },
    wheel(evt) {
      if (evt.wheelDeltaY / 120 > 0) {
        if (this.scale + 0.3 < 5.3) {
          this.scale += 0.3
        }
      } else {
        if (this.scale - 0.3 >= 1.3) {
          this.scale -= 0.3
        }
      }
    },
  },
}
</script>
