<template>
    <h1>Usage</h1>
</template>
<script>
export default {
    name: 'AnalyticsUsage',
    component: {},
    data() {
        return {}
    },
    created() {},
    mounted() {},
    methods: {},
}
</script>
