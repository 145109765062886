<template>
  <apexchart type="bar" height="600" :options="chartOptions" :series="series" />
</template>

<script>
import { zipWith } from 'lodash'
import common from '@/utils/common'

export default {
  name: 'BarWithNegativeChart',
  props: {
    title: {
      type: String,
      default: '',
    },
    compares: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentIndex: undefined,
    }
  },
  computed: {
    series() {
      return this.data
    },
    labels() {
      return this.compares.map(e => e.doctorName)
    },
    dates() {
      return this.compares.map(
        e =>
          `${common.dateFormat(e.date.startDate)} - ${common.dateFormat(
            e.date.endDate,
          )}`,
      )
    },
    chartOptions() {
      return {
        chart: {
          stacked: true,
          toolbar: { show: false },
        },
        colors: ['#008ffb', '#bdbdbd'],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%',
          },
        },
        legend: {
          fontSize: '18px',
          itemMargin: {
            vertical: 20,
          },
          markers: {
            width: 18,
            height: 18,
            radius: 18,
          },
        },
        dataLabels: {
          enabled: true,
          formatter(v) {
            return Math.abs(Math.round(v)) + '%'
          },
          style: {
            fontSize: '24px',
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: this.labels,
          min: -100,
          max: 100,
          labels: {
            formatter(v) {
              return Math.abs(Math.round(v)) + '%'
            },
            style: {
              fontSize: '24px',
            },
            offsetX: -10,
            offsetY: 10,
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '24px',
            },
          },
        },
        tooltip: {
          shared: false,
          theme: 'dark',
          custom: this.genTooltipContent,
        },
        title: {
          text: this.title,
          align: 'center',
          style: {
            fontSize: '34px',
            fontWeight: 'bold',
            color: '#757575',
          },
        },
      }
    },
    sums() {
      return zipWith(...this.data.map(e => e.count), (a, b) => a + b)
    },
  },
  methods: {
    sum(nums) {
      return nums.reduce((x, y) => x + y, 0)
    },
    genTooltipContent({ seriesIndex, dataPointIndex }) {
      const { count, name } = this.data[seriesIndex]

      return `
        <div class="chart__tips">
          <h2 class="my-2 headline">${this.labels[dataPointIndex]}</h2>
          <h2 class="my-2 headline">${this.dates[dataPointIndex]}</h2>
          <h2 class="my-2 headline">
            Examination Without Intervention:
            <span class="chart__percent">
              ${this.sums[dataPointIndex]}
            </span>
            records
          </h2>
          <h2 class="my-2 headline">
            ${name}:
            <span class="chart__percent">
              ${count[dataPointIndex]}
            </span>
            records
          </h2>
        </div>
      `
    },
  },
}
</script>
