<template>
  <v-card class="sedative-input-group" flat>
    <v-card-title
      class="pa-2 grey--text text--darken-1 h3 font-weight-bol border-b border-formHighlight"
    >
      <h3 class="mb-0">{{ $t('common.sedatives') }}</h3>
      <v-btn
        id="add-sedatives"
        small
        icon　@click="handleSedativeAction('add', $event)"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pa-2">
      <div v-if="sedativesList.length" class="mb-2 px-1">
        <div v-for="(item, index) in sedativesList" :key="index">
          <InputSelectField
            :selectedId="item.sedativeID"
            :selectedList="sedativesSelectItems"
            :inputText="item.value"
            hideDetails
            align="end"
            @deleteItem="handleSedativeAction('delete', item)"
            @changeSelect="
              handleSedativeChange({
                column: 'sedativeID',
                value: $event,
                id: item.id,
              })
            "
            @changeFillInput="
              handleSedativeChange({
                column: 'value',
                value: $event,
                id: item.id,
              })
            "
          />
        </div>
      </div>
      <template v-if="!sedativesList.length && !sedativeOtherText">
        <p class="mb-2 px-1 text-defaultGreyText">{{ $t('common.nil') }}</p>
      </template>
      <v-layout class="others">
        <v-text-field
          flex
          xs4
          :label="$t('common.other')"
          v-model="sedativeOtherText"
          @change="selectOther($event)"
          hide-details
          class="pt-3 pa-2"
        ></v-text-field>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import forms from '@/utils/forms'
import InputSelectField from './InputSelectField.vue'

export default {
  name: 'SedativeInputGroup',
  components: {
    InputSelectField,
  },

  props: {
    sedativesList: {
      type: Array,
      required: true,
    },
    examinationID: {
      type: Number,
    },
    sedativeOther: {
      type: String,
    },
    // all sedative info
    sedatives: {
      type: Array,
    },
  },
  data() {
    return {
      checkOthers: false,
      sedativeOtherText: this.sedativeOther,
    }
  },
  computed: {
    ...mapGetters('examinations', ['getSedativeList']),
    sedativesSelectOptions() {
      return this.sedatives
    },
    sedativesSelectItems() {
      return forms.mapSedativesSelectItems(this.getSedativeList)
    },
  },
  methods: {
    ...mapActions('examinations', [
      'updateExaminationSedativeColumn',
      'addExaminationSedative',
      'deleteExaminationSedative',
    ]),
    handleSedativeChange({ column, value, id }) {
      this.updateExaminationSedativeColumn({
        column,
        value: Number(value),
        id,
      })
    },
    handleSedativeAction(action, event) {
      const examinationSedativeID = event ? event.id : null
      this[`${action}ExaminationSedative`]({
        examinationID: this.examinationID,
        examinationSedativeID,
      })
    },
    selectOther(value) {
      this.$emit('change', value)
    },
  },
}
</script>
