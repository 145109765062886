export const FindingList = {
    "1": [
        "GERD",
        "Barrett's esophagus",
        "hiatal hernia",
        "esophageal CA",
        "esophageal varices",
        "gastric varices",
        "GU","gastritis",
        "polyp",
        "subepithelial tumor",
        "gastric CA",
        "DU",
        "bulbitis",
        "brunner's gland hyperplasia",
        "esophageal dysplasia",
        "gastric dysplasia",
        "lipoma",
        "carcinoid tumor",
        "GIST",
        "metastatic tumor",
        "extraluminal impression",
        "NET",
        "lymphoma",
        "hemangioma",
        "hyperplastic polyp",
        "operation scar",
        "reflux esophagitis",
        "acute gastritis",
        "hemorrhagic gastritis",
        "chronic gastritis",
        "superficial gastritis",
        "atrophic gastritis",
        "portal hypertensive gastropathy",
        "fundic gland polyp",
        "adenomatous polyp",
        "FAP",
        "scar",
        "anastomosis",
        "total gastrectomy",
        "subtotal gastrectomy",
        "stich",
        "stitches",
        "erosion",
        "foreign body",
        "ulcer",
        "intestinal metaplasia",
        "esophageal stenosis",
    ],
    "2": [
        "polyp", 
        "subepithelial tumor", 
        "tumor", 
        "lateral spreading tumor", 
        "flat tumor", 
        "cancer",
        "ulcer", "erosion",
        "colitis",
        "IBD",
        "ulcerative colitis", 
        "Crohn's disease",
        "pseudomembranous colitis",
        "ischemic colitis", 
        "infectious colitis",
        "TB colitis",
        "amoebic colitis",
        "radiation colitis",
        "proctitis",
        "hemorrhoid",
        "diverticulum",
        "diverticula",
        "diverticulosis",
        "xanthoma",
        "lipoma",
        "carcinoid tumor",
        "GIST",
        "metastatic tumor",
        "extraluminal impression",
        "appendiceal tumor",
        "NET",
        "lymphoma",
        "hemangioma",
        "GI bleeding",
        "melanosis coli",
        "ileitis",
        "foreign body",
        "lymphoid hyperplasia", 
        "polypoid tumor",
        "hyperplastic polyp",
        "Peutz-Jeghers syndrome",
        "Juvenile polyp",
        "tubular adenoma",
        "tubulovillous adenoma",
        "villous adenoma",
        "sessile serrated adenoma",
        "traditional serrated adenoma",
        "inflammatory adenoma",
        "early colorectal cancer",
        "advanced colorectal cancer",
        "TB related lesion",
        "non-specific colitis",
        "lymphangioma",
        "bechet's disease",
        "s/p partial colectomy",
        "situs inversus",
        "colonic wall cyst",
        "lymphoid follicles",
        "operation scar",
        "suture granuloma",
        "petechia",
        "diverticulitis",
        "internal hemorrhoid",
        "external hemorrhoid",
        "mixed hemorrhoid",
        "diverticular bleeding",
        "upper GI bleeding",
        "lower GI bleeding",
        "occult bleeding",
        "unknown bleeder",
        "hematin",
        "tarry stool",
        "fresh blood",
        "active bleeding",
        "scar",
        "anastomosis",
        "stich",
        "right hemicolectomy",
        "left hemicolectomy",
        "stitches"
    ]
};
