<template>
  <div class="pt-2 pb-2">
    {{ label }}
    <v-select
      :label="'condition'"
      :items="items[data.examination.examination_typeID]"
      :value="data.examination.preparation"
      @change="$emit('change', { column: 'preparation', event: $event })"
    ></v-select>
    <v-select
      multiple
      :label="'Location'"
      :items="getList"
      :value="data.examination.preparationLoca"
      @change="$emit('change', { column: 'preparationLoca', event: $event })"
    ></v-select>
    <template v-if="examinationData && examinationData.examination_typeID !== 1">
      <v-select
        :label="'Cleansing Level (before)'"
        :items="cleanScaleList"
        :value="data.examination.cleanScale"
        @change="$emit('change', { column: 'cleanScale', event: $event })"
      ></v-select>
      <v-select
        :label="'Cleansing Level (after)'"
        :items="cleanScaleList"
        :value="data.examination.cleanScaleAfter"
        @change="$emit('change', { column: 'cleanScaleAfter', event: $event })"
      ></v-select>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PreparationInput",

  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      field: "preparation",
      label: "Perioperative Condition",
      examinationData: this.$store.state.examinations.examinations.find(
        d => d.id == this.$route.params.examinationID
      )
    };
  },
  computed: {
    ...mapGetters("examinations", ["getLandmarkList"]),
    items() {
      return this.$store.getters["examinations/getPreparationList"];
    },
    getList() {
      return this.getLandmarkList.map(item => {
        return {
          text: item.landmark,
          value: item.id
        };
      });
    },
    cleanScaleList() {
      return this.$store.getters["examinations/getCleanScaleList"];
    },
    getPreparationLocaArray() {
      return this.data.examination.preparationLoca.split(",");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/components/textInputsTextfield.scss";
</style>