<template>
  <section class="word-break">
    <!-- 國健署資料 --start -->
    <colon-preview-bowel-cleanse-forms
      id="colonPreviewBowelCleanseForms"
      v-if="isColonReport"
      :examination="examination"
      :sedatives="sedatives"
    />
    <div class="border-b">
      <gastro-preview-bowel-cleanse-forms
        v-if="!isColonReport"
        :examination="examination"
        :sedatives="sedatives"
      />
    </div>
    <!-- 國健署資料 --end -->
    <!-- procedureNote -->
    <div class="border-b border-b--dashed py-3" v-if="isColonReport">
      <h3 class="print-color subtitle-border mb-2">
        {{ $t('common.procedure_note') }}：
      </h3>
      <p class="ml-2">{{ examination.examination.procedureNote }}</p>
    </div>

    <!-- Findings 文字 -->
    <div class="border-b border-b--dashed pt-3">
      <template v-if="isColonReport">
        <FindingInfoByOrder :findings="findings" />
        <div v-if="getColonDescriptiveParagraph" class="pa-3 border-defaultGreyText border-t border-t--dashed">
          <div v-for="(text, idx) in getColonDescriptiveParagraph" :key="`detail-colon-des-${idx}`">
            <div>{{ text }}</div>
          </div>
        </div>
      </template>
      <template v-if="!isColonReport">
        <FindingInfoByOrgan :findings="findings" />
        <div v-if="getGastroDescriptiveParagraph" class="border-t border-t--dashed pa-3">
          <div v-for="(text, pos) in getGastroDescriptiveParagraph" :key="pos" class="mb-3">
            <p class="font-weight-bold mb-0">{{ pos }}:</p>
            <div>{{ text || '-'}}</div>
          </div>
        </div>
      </template>
    </div>

    <div :class="{ 'border-b--dashed border-b': isFullMode }">
      <SummaryInfoGroup :report-title="reportTitle" :findings="findings" />
    </div>

    <!-- Findings - Procedure Images -->
    <FindingItemImagesGroup
      class="py-3"
      v-if="isFullMode"
      :findings="getFindings"
      :additionImages="additionImages"
      :imgArrange="imgArrange"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import SummaryInfoGroup from '@/components/SummaryInfoGroup.vue'
import GastroPreviewBowelCleanseForms from '@/components/preview/GastroPreviewBowelCleanseForms.vue'
import ColonPreviewBowelCleanseForms from '@/components/preview/ColonPreviewBowelCleanseForms.vue'
import FindingInfoByOrgan from './FindingInfoByOrgan.vue'
import FindingInfoByOrder from './FindingInfoByOrder.vue'
import FindingItemImagesGroup from '@/components/preview/FindingItemImagesGroup.vue'

import { PRINT_STATUS_YES } from '@/utils/examinations.config.js'

export default {
  name: 'SummaryEnReport',
  components: {
    ColonPreviewBowelCleanseForms,
    GastroPreviewBowelCleanseForms,
    SummaryInfoGroup,
    FindingItemImagesGroup,
    FindingInfoByOrder,
    FindingInfoByOrgan,
  },
  props: {
    examination: Object,
    sedatives: Array,
    isColonReport: {
      type: Boolean,
      default: true,
    },
    isFullMode: {
      type: Boolean,
      default: true,
    },
    reportTitle: String,
    imgArrange: Number,
    findings: Array,
  },
  computed: {
    ...mapGetters('examinations', [
      'getColonDescriptiveParagraph',
      'getGastroDescriptiveParagraph',
    ]),
    getFindings() {
      return this.examination.findings || null
    },
    additionImages() {
      return this.examination.all.filter(
        (item) => item.findingID == 0 && item.status === PRINT_STATUS_YES,
      )
    },
  }
}
</script>
