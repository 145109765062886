export const MapUIforFinding = {
    "Colonoscopy": {
        "polyp": "9",
        "tumor": "17",
        "lateral spreading tumor": "18",
        "flat tumor": "19",
        "cancer": "20",
        "polypoid tumor": "57",
        "ulcer": "21",
        "erosion": "22",
        "colitis": "23",
        "ibd": "24",
        "ulcerative colitis": "25",
        "crohn's disease": "26",
        "pseudomembranous colitis": "27",
        "ischemic colitis": "28",
        "infectious colitis": "29",
        "tb colitis": "30",
        "amoebic colitis": "31",
        "radiation colitis": "32",
        "proctitis": "33",
        "hemorrhoid": "34",
        "diverticulum": "35",
        "diverticula": "36",
        "diverticulosis": "37",
        "xanthoma": "38",
        "subepithelial tumor": "10",
        "lipoma": "39",
        "carcinoid tumor": "40",
        "gist": "41",
        "metastatic tumor": "42",
        "extraluminal impression": "43",
        "appendiceal tumor": "44",
        "net": "45",
        "lymphoma": "46",
        "hemangioma": "47",
        "gi bleeding": "48",
        "melanosis coli": "49",
        "ileitis": "50",
        "foreign body": "51",
        "lymphoid hyperplasia": "52",
        "hyperplastic polyp": "540",
        "peutz-jeghers syndrome": "541",
        "juvenile polyp": "542",
        "tubular adenoma": "543",
        "tubulovillous adenoma": "544",
        "villous adenoma": "545",
        "sessile serrated adenoma": "546",
        "traditional serrated adenoma": "547",
        "inflammatory adenoma": "548",
        "early colorectal cancer": "549",
        "advanced colorectal cancer": "550",
        "tb related lesion": "551",
        "non-specific colitis": "552",
        "lymphangioma": "553",
        "bechet's disease": "554",
        "s/p partial colectomy": "555",
        "situs inversus": "556",
        "colonic wall cyst": "557",
        "lymphoid follicles": "558",
        "operation scar": "559",
        "suture granuloma": "560",
        "petechia": "561",
        "diverticulitis": "564",
        "internal hemorrhoid": "575",
        "external hemorrhoid": "576",
        "mixed hemorrhoid": "577",
        "diverticular bleeding": "578",
        "upper gi bleeding": "579",
        "lower gi bleeding": "580",
        "occult bleeding": "581",
        "unknown bleeder": "582",
        "hematin": "583",
        "tarry stool": "584",
        "fresh blood": "585",
        "active bleeding": "586",
        "scar": "587",
        "anastomosis": "588",
        "right hemicolectomy": "595",
        "left hemicolectomy": "596",
        "stich": "594",
        "stitches": "597",
        "gvhd related colitis": "638",
        "drug induce colitis": "639",
        "angiodysplasia": "640",
        "cytomegalovirus colitis": "641",
        "colon polyposis": "642",
        "colon maltoma": "643",
        "hemorrhagic colitis": "644",
        "colitis aphthosa": "645",
        "amyloidosis": "646",
        "mega colon": "647",
        "rectal varices": "648",
        "mucosa prolapse": "649",
        "intussusception": "650",
        "colon polyposis, familiar": "651",
        "inflammatory polyp": "661",
        "colonic tuberculosis": "662",
        "colon fistula": "663",
        "post-treatment residual neoplasm": "664",
        "post endoscopy treatment scar": "665",
        "colonic stricture": "666"
    },
    "Gastroscopy": {
        "gerd": "1",
        "barrett's esophagus": "2",
        "hiatal hernia": "3",
        "esophageal ca": "4",
        "esophageal varices": "5",
        "gastric varices": "6",
        "gu": "7",
        "gastritis": "8",
        "polyp": "9",
        "subepithelial tumor": "10",
        "gastric ca": "11",
        "du": "12",
        "bulbitis": "13",
        "brunner's gland hyperplasia": "14",
        "esophageal dysplasia": "15",
        "gastric dysplasia": "16",
        "ulcer": "21",
        "erosion": "22",
        "lipoma": "39",
        "carcinoid tumor": "40",
        "gist": "41",
        "metastatic tumor": "42",
        "extraluminal impression": "43",
        "net": "45",
        "lymphoma": "46",
        "hemangioma": "47",
        "foreign body": "51",
        "reflux esophagitis": "565",
        "acute gastritis": "566",
        "hemorrhagic gastritis": "567",
        "chronic gastritis": "568",
        "superficial gastritis": "569",
        "atrophic gastritis": "570",
        "portal hypertensive gastropathy": "571",
        "fundic gland polyp": "572",
        "adenomatous polyp": "573",
        "hyperplastic polyp": "540",
        "fap": "574",
        "operation scar": "559",
        "scar": "587",
        "anastomosis": "588",
        "total gastrectomy": "592",
        "subtotal gastrectomy": "593",
        "stich": "594",
        "stitches": "597",
        "peptic ulcer": "7",
        "intestinal metaplasia": "667",
        "esophageal stenosis": "668",
    },
    "Sigmoidoscopy": {
        "polyp": "9",
        "tumor": "17",
        "lateral spreading tumor": "18",
        "flat tumor": "19",
        "cancer": "20",
        "polypoid tumor": "57",
        "ulcer": "21",
        "erosion": "22",
        "colitis": "23",
        "ibd": "24",
        "ulcerative colitis": "25",
        "crohn's disease": "26",
        "pseudomembranous colitis": "27",
        "ischemic colitis": "28",
        "infectious colitis": "29",
        "tb colitis": "30",
        "amoebic colitis": "31",
        "radiation colitis": "32",
        "proctitis": "33",
        "hemorrhoid": "34",
        "diverticulum": "35",
        "diverticula": "36",
        "diverticulosis": "37",
        "xanthoma": "38",
        "subepithelial tumor": "10",
        "lipoma": "39",
        "carcinoid tumor": "40",
        "gist": "41",
        "metastatic tumor": "42",
        "extraluminal impression": "43",
        "appendiceal tumor": "44",
        "net": "45",
        "lymphoma": "46",
        "hemangioma": "47",
        "gi bleeding": "48",
        "melanosis coli": "49",
        "ileitis": "50",
        "foreign body": "51",
        "lymphoid hyperplasia": "52",
        "hyperplastic polyp": "540",
        "peutz-jeghers syndrome": "541",
        "juvenile polyp": "542",
        "tubular adenoma": "543",
        "tubulovillous adenoma": "544",
        "villous adenoma": "545",
        "sessile serrated adenoma": "546",
        "traditional serrated adenoma": "547",
        "inflammatory adenoma": "548",
        "early colorectal cancer": "549",
        "advanced colorectal cancer": "550",
        "tb related lesion": "551",
        "non-specific colitis": "552",
        "lymphangioma": "553",
        "bechet's disease": "554",
        "s/p partial colectomy": "555",
        "situs inversus": "556",
        "colonic wall cyst": "557",
        "lymphoid follicles": "558",
        "operation scar": "559",
        "suture granuloma": "560",
        "petechia": "561",
        "diverticulitis": "564",
        "internal hemorrhoid": "575",
        "external hemorrhoid": "576",
        "mixed hemorrhoid": "577",
        "diverticular bleeding": "578",
        "upper gi bleeding": "579",
        "lower gi bleeding": "580",
        "occult bleeding": "581",
        "unknown bleeder": "582",
        "hematin": "583",
        "tarry stool": "584",
        "fresh blood": "585",
        "active bleeding": "586",
        "scar": "587",
        "anastomosis": "588",
        "right hemicolectomy": "595",
        "left hemicolectomy": "596",
        "stich": "594",
        "stitches": "597"
    },

}

export const MailMapUiforFinding = {
  "Colonoscopy": {
    "polyp": "9",
    "hemorrhoid": "34",
    "internal hemorrhoid": "575",
    "external hemorrhoid": "576",
    "mixed hemorrhoid": "577",
  },
  "Gastroscopy": {
    "gerd": "1",
    "gu": "7",
    "gastritis": "8",
  },
}

export const COLON = MapUIforFinding["Colonoscopy"];
export const GASTRO = MapUIforFinding["Gastroscopy"];
export const SIGMOID = MapUIforFinding["Sigmoidoscopy"];



export const generateFindingID = (name, examTypeID) => {
    const UI_LIST = (typeId) => {
        switch (typeId) {
          case 1:
            return MapUIforFinding["Gastroscopy"];
          case 2:
            return MapUIforFinding["Colonoscopy"];
          case 3:
            return MapUIforFinding["Sigmoidoscopy"];
          default:
            return {};
        }
    };
    return UI_LIST(examTypeID)[name];
}