import { i18n } from '../../locale'

export const complicationList = [
    { value: 0, text: 'Nil' },
    { value: 1, text:'Perforation'},
    { value: 2, text:'Significant Bleeding' },
    { value: 3, text:'Cardiopulmonary Complication'}
]

export const indicationList = [
    { group: '0', value: '1', text: '國健局糞便潛血檢查陽性' },
    { group: '0', value: '2', text: '自費健康檢查'},
    { group: '9', value: 'a', text: '大腸癌家族史' },
    { group: '9', value: 'b', text: '瘜肉切除後追蹤'},
    { group: '9', value: 'c', text: '大腸癌術後追蹤' },
    { group: '9', value: 'd', text: '大腸鏡併發症相關處理'},
    { group: '9', value: 'e', text: '瘜肉或腫瘤切除' },
    { group: '9', value: 'f', text: '發炎性腸道疾病追蹤' },
    { group: '9', value: 'g', text: '術前評估' }
]
export const preparationTimeList = [
    '',
    'Morning single dose',
    'Evening single dose',
    'Split dose'
]

export const cloTestLocations = [
    { value: 153, text: 'pylorus' },
    { value: 154, text: 'antrum' },
    { value: 155, text: 'low-body'}
]

export const noUseAntipasmodicReasonList = [
    { value: 1, text: 'common.premedication.1' },
    { value: 2, text: 'common.premedication.2' },
    { value: 3, text: 'common.premedication.3' },
    { value: 4, text: 'common.premedication.4' },
]

export const cleanScaleList = [
    { value: '1', text: 'Excellent' },
    { value: '2', text: 'Good' }, 
    { value: '3', text: 'Fair' }, 
    { value: '4', text: 'Poor' }, 
]

export const gastroPreparationList = [
    { value: '0', text: '' }, 
    { value: '1', text: 'food debris' }, 
    { value: '2', text: 'food stasis' }, 
    { value: '3', text: 'food' },
    { value: '4', text: 'redundant' }, 
    { value: '5', text: 'adhesion' },
    { value: '6', text: 'much gas' },
    { value: '7', text: 'gastric acid' },
]

export const colonPreparationList = [
    { value: '0', text: '' }, 
    { value: '1', text: 'stool' }, 
    { value: '2', text: 'stool impaction' }, 
    { value: '3', text: 'stool stasis' },
    { value: '4', text: 'redundant' }, 
    { value: '5', text: 'adhesion' }
]
export const insertionLevelList = [
    { value: '00', text: 'Terminal ileum' },
    { value: '01', text: 'Cecum' },
    { value: '02', text: 'Ascending colon' },
    { value: '03', text: 'Hepatic flexure' },
    { value: '04', text: 'Transverse colon' },
    { value: '05', text: 'Splenic flexure' },
    { value: '06', text: 'Descending colon' },
    { value: '07', text: 'Sigmoid colon' },
    { value: '08', text: 'Rectum' },
    { value: '09', text: 'Anastomosis' },
    { value: '10', text: 'Anus' },
    { value: '11', text: 'Terminal ileum' },
    { value: '12', text: 'Ileocecal valve' },
    { value: '13', text: 'Appendix orifice' },
    // { value: '4', text: 'Cecum' },
    // { value: '5', text: 'Ascending colon' },
    { value: '14', text: 'Proximal Ascending Colon' },
    { value: '15', text: 'Middle Ascending Colon' },
    { value: '16', text: 'Distal Ascending Colon' },
    // { value: '9', text: 'Hepatic flexure' },
    // { value: '10', text: 'Transverse colon' },
    { value: '17', text: 'Proximal Transverse colon' },
    { value: '18', text: 'Middle Transverse colon' },
    { value: '19', text: 'Distal Transverse colon' },
    // { value: '14', text: 'Splenic flexure' },
    // { value: '15', text: 'Descending colon' },
    { value: '20', text: 'Proximal Descending Colon' },
    { value: '21', text: 'Middle Descending Colon' },
    { value: '22', text: 'Distal Descending Colon' },
    // { value: '19', text: 'Sigmoid colon' },
    { value: '23', text: 'Proximal Sigmoid Colon' },
    { value: '24', text: 'Middle Sigmoid Colon' },
    { value: '25', text: 'Distal Sigmoid Colon' },
    { value: '26', text: 'RS junction' },
    // { value: '24', text: 'Rectum' },
    { value: '27', text: 'Upper Rectum' },
    { value: '28', text: 'Middle Rectum' },
    { value: '29', text: 'Lower Rectum' },
    // { value: '28', text: 'Anus' },
    { value: '30', text: 'All segments of colon' },
    { value: '31', text: 'Anal retroflex' },
    // { value: '30', text: 'Anastomosis' }
]

export const colonIncompleteReasonList = [
    { value: '0', text: 'common.incomplete.colon.0' }, 
    { value: '1', text: 'common.incomplete.colon.1' }, 
    { value: '2', text: 'common.incomplete.colon.2' }, 
    { value: '3', text: 'common.incomplete.colon.3' }, 
    { value: '4', text: 'common.incomplete.colon.4' }, 
    { value: '5', text: 'common.incomplete.colon.5' },
    { value: '6', text: 'common.incomplete.colon.6' },
    { value: '7', text: 'common.incomplete.colon.7' },
]

export const gastroIncompleteReasonList = [
    { value: '0', text: 'common.incomplete.gastro.0' },
    { value: '1', text: 'common.incomplete.gastro.1' },
    { value: '2', text: 'common.incomplete.gastro.2' },
    { value: '3', text: 'common.incomplete.gastro.3' },
    { value: '4', text: 'common.incomplete.gastro.4' },
]

export const complicationsList = [
    { value: '1', text:'perforation' },
    { value: '2', text:'significant bleeding'},
    { value: '3', text: 'cardiopulmonary complication' },

]