import moment from 'moment'

const common = {
	isEmptyObject (obj) {
    if (obj == null) {
      return true
    }
		return Object.keys(obj).length === 0 && obj.constructor === Object
	},

  flattenArray (arr) {
    if (!arr || !Array.isArray(arr)) {
      return
    }

    let result = []

    arr.map(item => {
      if (Array.isArray(item)) {
        result = result.concat(common.flattenArray(item))
      } else {
        result.push(item)
      }
    })
    return result
  },

  arrayToObject: (array, keyField) =>
   array.reduce((obj, item) => {
     obj[item[keyField]] = item
     return obj
   }, {}),

	calculageAge (birth, format) {
    const birthObj = moment(birth, format)
    const now = moment()
    return now.diff(birthObj, 'years')
  },

  compose: (...fns) => x => fns.reduceRight( (accu, f) => f(accu), x),

  getGender (gender) {
  	if (!gender) {
  		return
  	}
  	
    switch (Number(gender)) {
      case 0:
        return 'Female';
        break; // eslint-disable-line no-unreachable
      case 1:
        return 'Male';
        break; // eslint-disable-line no-unreachable
    }
  },
  getDateCompareToToday (daysCal) {
    // -14 or 14
    const currentTime = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000)
    const d = currentTime.setDate(currentTime.getDate() + daysCal);
    return new Date(d).toISOString().slice(0,10);
  },
  fileRule(getExaminationData, getExaminationTypeList, getPatient) {
    // 檢查日期
    const examinationDate = getExaminationData.examination.date;
    // 個人識別號碼
    const patientId = getPatient.no;
    // 病患姓名
    const patinetName = getPatient.name;
    // 檢查報告類別
    const examinationType = getExaminationTypeList.filter(type => type.id === getExaminationData.examination.examination_typeID)[0].examination_type;
    
    //filename rule = 檢查報告類別_病患姓名_個人識別號_檢查日期
    return `${examinationType}_${patinetName}_${patientId}_${examinationDate}`;
  },
  sid() {
    return Math.random().toString(36).substring(2)
  },
  dateFormat(date) {
    // const FIRST_YEAR = 1911
    return date
      ? moment(date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
  },
  toggleInputValue (newVal, oldVal) {
    if (newVal !== oldVal) return newVal
    return ''
  },
  toggleItemInArr(arr, item) {
    const idx = arr.findIndex((i) => i === item)
    return idx === -1
      ? [...arr, item]
      : [...arr.slice(0, idx), ...arr.slice(idx + 1)]
  },
  calBetweenTimes (a, b) {
    var a = a.split(":");
      var b = b.split(":");
      a = Number(a[0]) * 3600 + Number(a[1]) * 60 + Number(a[2]);
      b = Number(b[0]) * 3600 + Number(b[1]) * 60 + Number(b[2]);
      var c = a - b;
      var HHiiss = "00:00:00";
      if (c > 0) {
        var h = parseInt(c / 3600);
        var i = parseInt((c - h * 3600) / 60);
        var s = c - h * 3600 - i * 60;
        String(h).length == 1 && (h = "0" + String(h));
        String(i).length == 1 && (i = "0" + String(i));
        String(s).length == 1 && (s = "0" + String(s));
        HHiiss = h + ":" + i + ":" + s;
      }
      return HHiiss;
  },
  _toLowerCase (str) {
    return typeof str === 'string' ? str.toLowerCase() : str;
  },
  getASRUrl (domain, ws_port, ws_path) {
    const isHTPPS = location.protocol === 'https:'
    const prefix = isHTPPS ? 'wss://' : 'ws://'
    const port = /\d{4}/.test(ws_port) ? `:${ws_port}` : ws_port
    return `${prefix}${domain}${port}${ws_path}`
  },
  get_tts_url (domain, tts_port, tts_path) {
    const port = /\d{4}/.test(tts_port) ? `:${tts_port}` : tts_port
    return `${location.protocol}//${domain}${port}${tts_path}?${Date.now()}`
  },
}

export default common