<style lang="scss">
#sign-in {
  width: 100%;
  height: 100%;
  background: #112041 url('/public/evas-bg.png') center / 100% 100% no-repeat;

  .--fill-width {
    width: 100px;
  }

  .version {
    position: absolute;
    top: 190px;
    left: 225px;
    color: #7eccd7;
    font-size: 24px;
    letter-spacing: 1px;
  }

  .form-wrap {
    width: 570px;
  }

  .theme--light.v-label {
    font-size: 26px;
    color: #7eccd7;
  }

  .theme--light.v-text-field {
    > .v-input__control > .v-input__slot:before {
      border-color: #7eccd7;
    }

    &.error--text > .v-input__control > .v-input__slot:before {
      border-color: #ff5252 !important;
    }
  }

  .theme--light.v-input:not(.v-input--is-disabled) input {
    font-size: 20px;
    color: #fff;
  }

  .btn.btn--submit {
    width: 192px;
    height: 48px;
    color: #171c61;
    font-size: 24px;
    font-weight: 500;
    border-radius: 24px;
    background: linear-gradient(to right, #5abfc3, #2c92a4);
    outline: 0;
    transition: background 0.3s;

    &:active {
      background: linear-gradient(to right, #2c92a4, #5abfc3);
    }

    &:disabled {
      color: #fff;
      background: linear-gradient(to right, #aaa, #777);
    }
  }

  // .test {
  //   width: 200px;
  //   height: 200px;
  //   background-color: red;
  //   display: none;
  // }

  @media screen and (max-width: 1920px) {
    .test {
      display: block;
    }
  }
}
</style>

<template>
  <div id="sign-in">
    <div class="version">
      <p class="mb-0">EVAS-01</p>
      <p>v.{{version}}</p>
    </div>
    <v-layout fill-height>
      <v-flex xs6 class="my-auto">
        <div class="form-wrap mx-auto">
          <v-form
            v-model="isSignInFormValid"
            class="pt-5 mt-5"
            @submit.prevent="login({ username, password })"
          >
            <v-text-field
              v-model="username"
              :label="$t('login.username')"
              :rules="[v => !!v || 'Username cannot be empty']"
              class="my-3"
              color="#7eccd7"
            />
            <v-text-field
              v-model="password"
              :label="$t('login.password')"
              type="password"
              :rules="[v => !!v || 'Password cannot be empty']"
              class="my-3"
              color="#7eccd7"
            />
            <v-text-field
              :label="$t('login.verificationcode')"
              :rules="[
                v =>
                  v == identifyCode ||
                  'Verification code error, please re-enter',
              ]"
              color="#7eccd7"
            />

            <div class="mt-2" @clcik="refreshCode()">
              <s-identify :identifyCode="identifyCode"></s-identify>
            </div>
            <v-alert :value="showErrorAlert" type="error" outline>
              Username / Password don't match. Please try again.
            </v-alert>

            <button
              type="submit"
              :disabled="!isSignInFormValid"
              class="mt-4 btn btn--submit"
            >
              {{ $t('login.signin') }}
            </button>
          </v-form>
        </div>
      </v-flex>
      <v-flex xs6> </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SIdentify from '../components/Identify.vue'
import packageInfo from '../../package.json'

export default {
  components: {
    SIdentify,
  },

  name: 'SignIn',
  created() {
    window.onkeydown = function(e) {}
    window.onkeyup = function(e) {}
  },

  data() {
    return {
      username: null,
      password: null,
      isSignInFormValid: true,
      identifyCode: '',
      identifyCodes: '0123456789',
      version: packageInfo.version,
    }
  },

  computed: {
    ...mapGetters(['getError']),

    showErrorAlert() {
      return this.getError === 'auth'
    },
  },

  mounted() {
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)
  },

  methods: {
    ...mapActions(['login']),
    refreshCode() {
      //
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 隨機生成驗證碼字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    },
  },
}
</script>
