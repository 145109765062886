<template>
  <v-layout
    tag="li"
    column
    pa-2
    pb-4
    data-test="field-card"
    class="list-style-none basic-field-item"
    :class="[
      !disabled ? bgColor : 'bg-light',
      { 'justify-space-between': inputType !== 'selectInput' },
    ]"
  >
    <v-flex
      tag="h2"
      data-test="field-title"
      class="fsz-18 fsz-md-28 flex-grow-0 mb-2"
      :class="{ 'text-defaultGreyBG': disabled }"
      >{{ title }}</v-flex
    >
    <v-flex class="flex-grow-0">
      <v-select
        v-if="inputType === 'select'"
        data-test="field-type-select"
        hide-details
        :value="value"
        :items="options"
        :disabled="disabled"
        class="fsz-24 l-space-1 text-primary"
        @change="$emit('change', $event)"
        color="#9dc3e6"
      />

      <ul
        v-if="inputType === 'selectInput'"
        class="pl-0"
        data-test="field-type-selectInput"
      >
        <li
          v-for="option in options"
          :key="option.id"
          class="list-style-none --cursor l-space-1"
          @click="$emit('change', option.value)"
        >
          <v-chip
            :selected="option.value === value"
            label
            style="height: auto"
            class="fsz-16 fsz-lg-24 font-weight-medium"
            :class="{ 'text-white': option.value === value }"
            :color="option.value === value ? '#3e4c60' : '#ededed'"
            >{{ option.text }}</v-chip
          >
        </li>
      </ul>

      <v-text-field
        v-if="inputType === 'text'"
        data-test="field-type-text"
        :value="value"
        @change="$emit('change', $event)"
        :disabled="disabled"
        filled
        clearable
        hide-details
        :type="textType"
        :suffix="suffix"
        class="fsz-24 l-space-1 text-primary"
        color="#1f4e79"
      />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "BasicRecordFieldItem",
  props: {
    title: String,
    value: String,
    disabled: Boolean,
    inputType: {
      type: String,
      validator: (val) => ["text", "select", "selectInput"].indexOf(val) !== -1,
    },
    options: {
      type: Array,
      default: () => [],
    },
    tag: {
      type: String,
      default: "div",
    },
    bgColor: {
      type: String,
      default: "bg-success-1",
    },
    textType: {
      type: String,
      default: "text",
    },
    suffix: String,
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.basic-field-item {
  .v-select__selections {
    justify-content: center;
    padding-bottom: 1rem;
  }
  .v-select__selection--comma {
    width: 100%;
    justify-content: center;
    font-weight: 500;
    margin-left: 0.5rem;
    color: $primary;
  }
  input {
    text-align: center;
    color: $primary !important;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .v-text-field__suffix {
    font-weight: 500;
    margin-bottom: 1rem;
  }
}
</style>
