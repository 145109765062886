<template>
  <v-container
    v-if="examination && patient && dataKeysLen > 0"
    fluid
    grid-list-md
  >
    <v-layout wrap pa-3 align-items-center>
      <v-flex sm6 align-self-center>
        <v-menu offset-y v-if="showStatusButton">
          <v-chip
            slot="activator"
            label
            outline
            :color="
              reportStatusColors[getReportStatus(examination.reportStatus)]
            "
            class="ml-0"
          >
            {{ $t(`report.${getReportStatus(examination.reportStatus)}`) }}
          </v-chip>
          <v-list>
            <v-list-tile
              v-for="item in reportStatus"
              :key="item"
              @click="$emit('reportStatusChange', item)"
            >
              <v-list-tile-title>{{ $t(`report.${item}`) }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-flex>
      <v-flex sm6 class="text-xs-right">
        <slot name="right" />
      </v-flex>
      <v-flex sm12></v-flex>
      <v-flex
        v-for="item in infoList"
        :key="item.text"
        class="white shades py-3"
      >
        <text-with-label :text="item.text" :label="item.label" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import common from '@/utils/common'
import forms from '@/utils/forms'
import TextWithLabel from './TextWithLabel.vue'

export default {
  name: 'ReportHeader',

  components: {
    TextWithLabel,
  },

  props: {
    examination: {
      type: Object,
      required: true,
    },

    patient: {
      type: Object,
      required: true,
    },

    examinationTypeList: {
      type: Array,
      required: true,
    },

    showStatusButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      reportStatus: ['Unedited', 'Editing', 'Finalized'],

      reportStatusColors: {
        Unedited: 'primary',
        Editing: 'orange',
        Finalized: 'green',
      },
    }
  },

  computed: {
    listOfKeys() {
      return [
        {
          key: 'date',
          source: 'examination',
          label: this.$t('examination.date'),
        },
        {
          key: 'examination_typeID',
          source: 'examination',
          label: this.$t('examination.type'),
        },
        {
          key: 'no',
          source: 'patient',
          label: this.$t('patient.id'),
        },
        {
          key: 'accNo',
          source: 'examination',
          label: this.$t('examination.number'),
        },
        {
          key: 'name',
          source: 'patient',
          label: this.$t('patient.name'),
        },
        {
          key: 'gender',
          source: 'patient',
          label: this.$t('patient.sex'),
        },
        {
          key: 'birthday',
          source: 'patient',
          label: this.$t('patient.age'),
        },
      ]
    },
    dataKeysLen() {
      return Object.keys(this.examination).length
    },

    getExaminationType() {
      return this.examinationTypeList.filter(
        (type) => type.id === this.examination.examination_typeID,
      )
    },

    infoList() {
      return this.listOfKeys.map((item) => {
        const key = item.key

        let obj = {
          label: item.label,
        }

        if (key === 'examination_typeID') {
          obj.text = this.getExaminationType[0].examination_type
        } else if (key === 'birthday') {
          const age = common.calculageAge(this[item.source][key], 'YYYY-MM-DD')
          obj.text = age.toString()
        } else if (key === 'gender') {
          obj.text = common.getGender(this[item.source][key])
        } else {
          obj.text = this[item.source][key]
        }

        return obj
      })
    },
  },

  methods: {
    getReportStatus: (status) => forms.getReportStatus(status),
  },
}
</script>

<style lang="scss">
@import '@/styles/components/examinationInfo.scss';
</style>
