<style lang="scss">
@import '@/styles/components/examinationInfo.scss';
.delete-dialog {
  color: red;
}
</style>

<template>
  <v-dialog
    v-model="showDialog"
    content-class="delete-dialog-overlay"
    persistent
  >
    <v-card class="delete-dialog" width="40%">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-btn outline color="info" @click="onCancel()">{{ $t('common.cancel') }}</v-btn>

        <v-btn color="info" @click="onConfirm()">{{ $t('common.yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',

  props: {
    showDialog: {
      required: true,
      type: Boolean,
      default: false,
    },
    title: {
      required: true,
      type: String,
    },
    message: {
      required: true,
      type: String,
    },
    onConfirm: {
      required: true,
      type: Function,
    },
    onCancel: {
      required: true,
      type: Function,
    },
  },
}
</script>
