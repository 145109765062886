<style lang="scss">
.analytics-search-select {
  &__item {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    .--cursor {
      cursor: pointer;
    }

    .v-list__tile {
      height: auto;
      min-height: 48px;
      overflow: hidden;

      .v-input--selection-controls.v-input--checkbox {
        flex: 0 0 auto;
        margin-top: 0;
        padding-top: 0;
      }

      .v-list__tile__action {
        min-width: auto;
      }
    }
  }

  &__item-content {
    max-width: 100%;
  }

  &__label-wrap {
    height: 48px;
  }

  &__label {
    max-width: 90%;
  }
}
</style>

<template>
  <v-menu
    offset-y
    left
    max-width="250"
    min-width="250"
    max-height="350"
    :close-on-content-click="false"
  >
    <template slot="activator" slot-scope="props">
      <div v-on="!noData && props.on">
        <v-text-field
          :value="names"
          :disabled="noData"
          label="Value"
          readonly
          hide-details
          outline
          :error="error"
          class="search-filter__input"
        />
      </div>
    </template>
    <v-list>
      <v-list-tile
        v-for="(item, key) in data"
        :key="key"
        :title="item.value"
        class="analytics-search-select__item"
      >
        <div class="analytics-search-select__item-content">
          <v-layout align-center class="analytics-search-select__label-wrap">
            <v-checkbox
              :input-value="value"
              :value="item.value"
              hide-details
              @change="handleChange(key, $event)"
            />
            <div
              :class="[
                'analytics-search-select__label',
                'd-flex',
                'align-center',
                { '--cursor': !!item.subItems },
              ]"
              @click.stop="toggle(key)"
            >
              <v-icon
                v-if="!!item.subItems"
                :class="{ '--rotate': item.expand }"
              >
                keyboard_arrow_right
              </v-icon>
              <p class="mb-0 text-truncate">
                {{ item.name }}
              </p>
            </div>
          </v-layout>
          <search-select-children
            v-model="item.checked"
            :expand="item.expand"
            :items="item.subItems"
            @input="handleChildrenChecked(key)"
            @checked="handleChildrenChecked(key)"
            @unchecked="handleChildrenChecked(key)"
          />
        </div>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import SearchSelectChildren from './SearchSelectChildren'

import checkedMixin from './checked'

export default {
  name: 'SearchSelect',
  components: {
    SearchSelectChildren,
  },
  mixins: [checkedMixin],
  data() {
    return {
      data: {},
    }
  },
  computed: {
    noData() {
      return Array.isArray(this.items) && !this.items.length
    },
    error() {
      return !this.noData && !this.value.length
    },
    nameMap() {
      return this.items.reduce(
        (acc, cur) =>
          cur.subItems
            ? Object.assign(
                acc,
                { [cur.value]: cur.name },
                cur.subItems.reduce(
                  (o, e) => ({ ...o, [e.value]: e.name }),
                  {},
                ),
              )
            : Object.assign(acc, { [cur.value]: cur.name }),
        {},
      )
    },
    names() {
      return this.value.map(v => this.nameMap[v])
    },
  },
  watch: {
    items(nv, ov) {
      if (nv.length !== ov.length) {
        this.init()
      }
    },
  },
  methods: {
    init() {
      if (this.items.length) {
        this.data = this.items.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.name]: cur.subItems
              ? { ...cur, expand: false, checked: [] }
              : cur,
          }),
          {},
        )
      }
    },
    toggleItemField(key, field) {
      const item = this.data[key]
      this.data = {
        ...this.data,
        [key]: { ...item, [field]: !item[field] },
      }
    },
    toggle(key) {
      this.toggleItemField(key, 'expand')
    },
    handleChange(key, value) {
      if (key === 'ALL') {
        if (this.value.includes('ALL')) {
          this.$emit('input', [])
          this.data = Object.entries(this.data).reduce(
            (acc, [k, v]) => ({
              ...acc,
              [k]: v.subItems ? { ...v, checked: [] } : v,
            }),
            {},
          )
        } else {
          this.$emit('input', this.items.map(({ value }) => value))
          this.data = Object.entries(this.data).reduce(
            (acc, [k, v]) => ({
              ...acc,
              [k]: v.subItems
                ? { ...v, checked: v.subItems.map(({ value }) => value) }
                : v,
            }),
            {},
          )
        }
      } else {
        const item = this.data[key]
        if (this.value.includes(item.value)) {
          this.$emit('input', this.value.filter(v => v !== item.value))

          this.data = {
            ...this.data,
            [key]: item.subItems ? { ...item, checked: [] } : item,
          }
        } else {
          if (item.subItems) {
            this.$emit(
              'input',
              value.filter(v => this.valuesWithoutAll.includes(v)),
            )
            this.data = {
              ...this.data,
              [key]: item.subItems
                ? { ...item, checked: item.subItems.map(({ value }) => value) }
                : item,
            }
          } else {
            this.$emit('input', value)
          }
        }
      }
    },
    handleChildrenChecked(key) {
      const item = this.data[key]
      if (
        item.checked.length === item.subItems.length &&
        !this.value.includes(item.value)
      ) {
        this.$emit(
          'input',
          this.value.filter(v => !item.checked.includes(v)).concat(item.value),
        )
      } else if (item.checked.length < item.subItems.length) {
        this.$emit(
          'input',
          this.value
            .filter(v => v !== item.value && this.valuesWithoutAll.includes(v))
            .concat(item.checked),
        )
      }
    },
  },
}
</script>
