<template>
  <!-- TODO: FOCUS CLASS -->
  <v-layout row wrap fill-height class="basicFindingForm__finding">
    <v-flex xs8 v-if="findingQ.finding">
      <v-layout
        fill-height
        pb-3
        pa-2
        mr-2
        class="border border-2 border-primary"
      >
        <v-flex align-self-end>
          <v-select
            data-test="form-select"
            color="#9dc3e6"
            class="fsz-36 fsz-lg-50"
            hide-details
            :value="findingQ.finding.value"
            :items="findingsOptions"
            @change="$emit('change', { field: 'finding', value: $event })"
          >
          </v-select>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex
      xs4
      class="basicFindingForm__number"
      :class="isCountableFinding ? 'bg-dark-blue' : 'bg-light'"
    >
      <v-layout class="pl-0" align-items-end pa-2 pb-4 fill-height>
        <v-flex
          xs7
          tag="h3"
          class="fsz-36 fsz-md-50 text-white"
          align-self-end
          v-if="hasNumberType"
          >Number
        </v-flex>
        <v-flex :xs5="hasNumberType" :xs12="!hasNumberType" align-self-end>
          <v-text-field
            data-test="form-number"
            type="text"
            class="fsz-40 fsz-md-60"
            hide-details
            :value="findingNumber"
            :disabled="!isCountableFinding"
            @change="handleFindingNumber"
          >
          </v-text-field>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "BasicExamFindingForm",
  props: {
    finding: {
      type: Object,
      default: () => ({}),
    },
    findingsOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      findingNumber: 1,
      maxNumber: 20,
    };
  },
  computed: {
    findingQ() {
      return this.finding.questions || {};
    },
    isCountableFinding() {
      return Boolean(
        this.findingQ && this.findingQ.finding && this.findingQ.number
      );
    },
    findingNumberValue() {
      return this.findingQ.number && this.findingQ.number.value;
    },
    hasNumberType() {
      if (this.findingNumber === "") return true;
      return this.isCountableFinding && !isNaN(this.findingNumber);
    },
  },
  methods: {
    handleFindingNumber(value) {
      this.findingNumber = this.getFindingNumber({ isCountable: true, value });

      if (value > 1) {
        const takeNoteOverAllVal = 501;
        this.$emit("change", { field: "takeNote", value: takeNoteOverAllVal });
      }
      this.$emit("change", { field: "number", value: this.findingNumber });
    },
    getFindingNumber({ isCountable, value }) {
      if (!isCountable) return "";

      return isNaN(value) && value !== "several" && value !== "multiple"
        ? 1
        : value > this.maxNumber
        ? this.maxNumber
        : value;
    },
  },
  watch: {
    isCountableFinding(val) {
      this.findingNumber = this.getFindingNumber({
        isCountable: val,
        value: val ? 1 : "",
      });
    },
    findingNumberValue(val) {
      this.findingNumber = this.getFindingNumber({
        isCountable: val,
        value: val,
      });
    },
  },
  mounted() {
    const findingHasNumber = Boolean(this.findingQ.number);
    this.findingNumber = this.getFindingNumber({
      isCountable: findingHasNumber,
      value: !findingHasNumber ? "" : this.findingQ.number.value,
    });
  },
};
</script>

<style lang="scss">
.basicFindingForm__finding {
  .v-select__selections {
    min-height: 70px;
    color: #1f4e79 !important;
    line-height: 1.2;
    font-weight: bold;
  }
  .v-text-field .v-input__append-inner {
    align-self: center;
  }
}
.basicFindingForm__number .v-input {
  input {
    color: white !important;
    height: 100%;
    max-height: 80px;
    text-align: center;
  }
}
</style>
