<template>
  <div>
    <h3 class="print-color subtitle-border mb-2">{{ $t('report_preview.findings') }}：</h3>
    <ol v-if="findings.length">
      <li v-for="finding in findings" :key="finding.id" class="mb-2">
        <FindingItemContent :finding="finding" />
      </li>
    </ol>
    <p v-else class="pl-3">{{ $t('common.nil') }}</p>
  </div>
</template>

<script>
import FindingItemContent from '@/components/commons/FindingItemContent.vue'

export default {
  name: 'FindingInfoByOrder',
  props: {
    findings: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    FindingItemContent,
  },
}
</script>
