<template>
  <v-layout
    row
    wrap
    fill-height
    align-content-start
    class="bg-white basic-examinations"
  >
    <v-flex xs12 class="pos-relative gradient-gray-bg d-grid">
      <VoiceActionControl
        ref="webrtc"
        :class="{ hidden: !getWebrtcStatus }"
        class="pos-absolute"
        style="z-index: -1"
      />

      <div
        class="pos-absolute"
        style="z-index: 2; top: 0; height: 100%; width: 100%"
      >
        <BasicExamVoiceCommandDisplay
          ref="voiceCommand"
          class="h-100 width-full full-width"
        />
      </div>

      <div class="pos-relative">
        <div
          class="pos-absolute"
          style="right: 0%; z-index: 3"
          @click="handleToggleCommand"
        >
          <v-btn slot="activator" small icon test-data="history-voice-record">
            <v-icon class="text-defaultGreyText">more_vert</v-icon>
          </v-btn>
        </div>
      </div>

      <BasicTimeGroup
        :data="getExaminationData && getExaminationData.examination"
        :showTimeSquare="isColon"
      />
    </v-flex>
    <v-flex xs12>
      <BasicExamFinding />
    </v-flex>
    <TextAnimation
      v-if="getStoreHiddenParameter.isTimeFinish"
      title="Finish!"
      @close="setTimeFinish(false)"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EXAMINATION_TYPE_OF_COLONOSCOPY } from "@/utils/examinations.config";
import examMixins from "@/mixins/examMixins";
import BasicExamFinding from "@/components/examinationsBasic/BasicExamFinding.vue";
import BasicTimeGroup from "@/components/examinationsBasic/BasicTimeGroup.vue";
import Webrtc from "@/components/Webrtc.vue";
import VoiceActionControl from "@/components/VoiceActionControl.vue";
import TextAnimation from "@/components/commons/TextAnimation.vue";
import BasicExamVoiceCommandDisplay from "../components/examinationsBasic/BasicExamVoiceCommandDisplay.vue";

export default {
  name: "ExaminationBasicDetailPage",
  components: {
    BasicExamFinding,
    BasicTimeGroup,
    VoiceActionControl,
    TextAnimation,
    BasicExamVoiceCommandDisplay,
  },
  mixins: [examMixins],
  data() {
    return {
      showCommend: true,
      timeoutID: "",
    };
  },
  computed: {
    ...mapGetters("examinations", [
      "getCommandQueue",
      "getWebrtcStatus",
      "getExaminationData",
      "getStoreHiddenParameter",
    ]),
    isColon() {
      return (
        this.getExaminationData &&
        this.getExaminationData.examination &&
        this.getExaminationData.examination.examination_typeID ===
          EXAMINATION_TYPE_OF_COLONOSCOPY
      );
    },
    getFindings() {
      return this.getExaminationData ? this.getExaminationData.findings : null;
    },
    getCommandQueueText() {
      return this.getCommandQueue.join(" ");
    },
    isRecording() {
      return this.getStoreHiddenParameter.isRecording;
    },
  },
  methods: {
    ...mapActions("examinations", [
      "updateExaminationColumn",
      "setTimeFinish",
      "setExaminationFinishDialog",
    ]),
    handleToggleCommand() {
      this.showCommend = !this.showCommend;
      this.toggleCommand(this.showCommend);
    },
    toggleCommand(status) {
      this.$refs.voiceCommand.toggleQueueText(status);
    },
    init() {
      this.$store.dispatch("examinations/fetchExaminationDetailAndOtherList", {
        examinationID: this.exID_examMixins,
        accNo: this.accNo_examMixins,
      });
      this.$store.dispatch("examinations/resetStoreHiddenParameterExamination");
      this.$store.dispatch("examinations/resetQuestionsList");
      this.$store.dispatch("examinations/setExaminationFinishDialog", false);
    },
  },
  watch: {
    isRecording(val) {
      if (val) {
        clearTimeout(this.timeoutID);
      }
    },
    getCommandQueueText(val) {
      if (val) {
        this.timeoutID = setTimeout(() => {
          this.toggleCommand(false);
        }, 3000);
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "@/styles/mixins";
.basic-examinations {
  display: grid;
  grid-template-rows: 15% 85%;
  height: 100%;
  max-height: calc(100vh - 60px);
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 0px;
  }
}
.gradient-gray-bg {
  background: linear-gradient(#fff, #ededed 60%, #eaeaea 70%, #ededed 75%);
}
</style>
