<style lang="scss">
.report-time {
  color: #0beff7;
  font-size: 36px;
  background: #333;
  border-radius: 5px;
  user-select: none;

  &.--paused {
    animation-play-state: paused;
  }

  &.--fixed {
    position: fixed;
    top: 20px;
    left: calc(50% - 77px);
    z-index: 10;
    cursor: pointer;
  }

  &.--static {
    position: static;
    cursor: default;
  }

  &.--small {
    font-size: 16px;
  }
}
</style>

<template>
  <div
    v-show="hasReportTime"
    :class="[
      'd-inline-block',
      'px-2',
      'report-time',
      `--${position}`,
      { '--paused': !hasReportTime },
      { '--small': small },
    ]"
    @click="reset"
    @mouseover="handleMouseover"
    @mouseleave="handleMouseleave"
  >
    {{ display }}
  </div>
</template>

<script>
import moment from 'moment'

import { reportServices } from '@/services/db'

const FORMAT = 'HH:mm:ss'

export default {
  name: 'ReportTime',
  props: {
    eid: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: 'fixed',
      validator(v) {
        return ['static', 'fixed'].includes(v)
      },
    },
    constant: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reportTime: 0,
      startTime: '',
      mouseover: false,
      mouseleave: false,
    }
  },
  computed: {
    hasReportTime() {
      return this.reportTime > 0
    },
    time() {
      if (this.hasReportTime) {
        return this.calc(this.reportTime)
      }
      /* if (typeof this.end === 'object' && moment.isMoment(this.end)) {
        return this.calc(this.end)
      }
      if (moment.isMoment(this.self)) {
        return this.calc(this.self)
      } */
      return ''
    },
    display() {
      return this.mouseover ? this.$t('report.reset_timer') : this.time
    },
  },
  watch: {
    eid(nv, ov) {
      if (nv !== ov) {
        this.fetchReportTime()
      }
    },
  },
  created() {
    this.fetchReportTime()
    addEventListener('beforeunload', this.beforeunload)
  },
  beforeDestroy() {
    if (!this.hasReportTime) {
      this.updateReportTime()
    }
    removeEventListener('beforeunload', this.beforeunload)
  },
  methods: {
    beforeunload() {
      if (!this.hasReportTime) {
        this.updateReportTime()
      }
    },
    calc(momentOrSecs) {
      if (moment.isMoment(momentOrSecs)) {
        return moment
          .utc(momentOrSecs.diff(this.startTime, 's') * 1000)
          .format(FORMAT)
      }
      return moment.utc(momentOrSecs * 1000).format(FORMAT)
    },
    /* counttime() {
      this.timeout = setTimeout(() => {
        this.self = this.self.add(1, 's')
      }, 1000);
    }, */
    fetchReportTime() {
      reportServices.fetchReportTime(this.eid).then(res => {
        this.reportTime = 0

        if (res.reporting_times) {
          this.reportTime = res.reporting_times
        } else {
          this.startTime = moment()
        }
      })
    },
    updateReportTime() {
      if (!this.constant) {
        reportServices.updateReportTime(
          this.eid,
          moment().diff(this.startTime, 's'),
        )
      }
    },
    handleMouseover() {
      if (!this.constant) {
        this.mouseover = true
        this.mouseleave = false
      }
    },
    handleMouseleave() {
      if (!this.constant) {
        this.mouseover = false
        this.mouseleave = true
      }
    },
    reset() {
      if (!this.constant) {
        this.reportTime = 0
        this.startTime = moment()
        this.mouseover = false
        this.mouseleave = false
      }
    },
  },
}
</script>
