import { render, staticRenderFns } from "./CleanMedList.vue?vue&type=template&id=144c66f4&"
import script from "./CleanMedList.vue?vue&type=script&lang=js&"
export * from "./CleanMedList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports