<template>
  <v-menu offset-y left max-width="200" min-width="200" max-height="350">
    <template slot="activator" slot-scope="props">
      <slot :on="props.on" />
    </template>
    <v-list>
      <v-list-tile
        v-for="item in items"
        :key="item.value"
        :title="item.value"
        @click="handleClick(item.value)"
      >
        <v-list-tile-content>
          <v-list-tile-title :class="{ 'primary--text': item.value === value }">
            {{ item.text | noColon }}
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'SelectIcon',
  filters: {
    noColon(text) {
      return text.replace(/(\Wof\Wcolon|\Wcolon)$/ig, '')
    },
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClick(v) {
      this.$emit('change', v)
    },
  },
}
</script>
