<template>
  <section class="print">
    <h3 class="print-color subtitle-border mb-2">{{ $t('report_preview.findings') }}:</h3>
    <ul class="pl-3">
      <FindingsTypesLayout
        tag="li"
        :name="type"
        v-for="(value, type) in gastroFindings"
        :key="value.id"
      >
        <template #content>
          <template v-if="value.length">
            <li v-for="v in value" :key="v.id" class="mb-1">
              <FindingItemContent :finding="v" />
            </li>
          </template>
          <p v-else>-</p>
        </template>
      </FindingsTypesLayout>
    </ul>
  </section>
</template>

<script>
import FindingsTypesLayout from '@/components/commons/FindingsTypesLayout.vue'
import FindingItemContent from '@/components/commons/FindingItemContent.vue'
import GastroMixins from '@/components/commons/GastroMixins.js'

export default {
  name: 'FindingInfoByOrgan',
  components: {
    FindingItemContent,
    FindingsTypesLayout,
  },
  props: {
    findings: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [GastroMixins],
}
</script>
