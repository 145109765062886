<template>
  <v-layout>
    <v-flex xs6 sm7 md8>
      <apexchart
        type="bar"
        height="600"
        :options="chartOptions"
        :series="series"
      />
    </v-flex>
    <v-flex xs6 sm5 md4 align-self-center>
      <v-slide-x-reverse-transition>
        <v-card v-if="current" dark>
          <div class="chart__tips">
            <h2 class="mb-1 headline">{{ current.name }}</h2>
            <h2 class="mb-3 headline">{{ date }}</h2>
            <h2 class="mb-1 headline">
              Total examination:
              <span class="chart__percent">
                {{ current.total }}
              </span>
              records
            </h2>
            <h2 class="mb-3 headline">
              Positive with Polyp:
              <span class="chart__percent">
                {{ current.count }}
              </span>
              records
            </h2>
            <ul class="pl-0" style="list-style-type: none;">
              <li
                v-for="item in current.subItems"
                :key="`${current.name}-${item.name}`"
                class="chart__tip"
              >
                {{ item.name }}: {{ item.definedPolypCount }}
                <span class="chart__percent"> ({{ item.percent }}) </span>
              </li>
            </ul>
          </div>
        </v-card>
      </v-slide-x-reverse-transition>
    </v-flex>
  </v-layout>
</template>

<script>
import common from '@/utils/common'

export default {
  name: 'BarChart',
  props: {
    title: {
      type: String,
      default: '',
    },
    compares: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentIndex: undefined,
    }
  },
  computed: {
    series() {
      return [
        {
          data: this.data.map(e => e.percent),
        },
      ]
    },
    labels() {
      return this.compares.map(e => e.doctorName)
    },
    dates() {
      return this.compares.map(
        e =>
          `${common.dateFormat(e.date.startDate)} - ${common.dateFormat(
            e.date.endDate,
          )}`,
      )
    },
    date() {
      return typeof this.currentIndex === 'number'
        ? this.dates[this.currentIndex]
        : ''
    },
    chartOptions() {
      return {
        chart: {
          events: {
            // dataPointSelection: this.handleSelection,
            dataPointMouseEnter: this.dataPointMouseEnter,
            dataPointMouseLeave: this.dataPointMouseLeave,
          },
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter(v) {
            return v + '%'
          },
          offsetY: -40,
          style: {
            fontSize: '24px',
            colors: ['#333'],
          },
        },
        xaxis: {
          categories: this.labels,
          labels: {
            offsetY: 5,
            style: {
              fontSize: '24px',
            },
          },
        },
        yaxis: {
          labels: {
            formatter(v) {
              return v + '%'
            },
          },
        },
        title: {
          text: this.title,
          align: 'center',
          style: {
            fontSize: '34px',
            fontWeight: 'bold',
            color: '#757575',
          },
        },
        tooltip: { enabled: false },
        states: {
          active: {
            filter: {
              type: 'lighten',
              value: 0.15,
            },
          },
        },
      }
    },
    current() {
      if (typeof this.currentIndex === 'number') {
        return this.data[this.currentIndex]
      }
      return null
    },
  },
  methods: {
    /* handleSelection(event, chartContext, config) {
      if (typeof this.currentIndex === 'number') {
        this.currentIndex = undefined
      } else {
        this.currentIndex = config.dataPointIndex
      }
    }, */
    dataPointMouseEnter(event, chartContext, config) {
      this.currentIndex = config.dataPointIndex
    },
    dataPointMouseLeave() {
      this.currentIndex = undefined
    },
  },
}
</script>
