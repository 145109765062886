import { antispasmodicServices } from '@/services/db'

const state = {
  antispasmodics: [],
  antispasmodicsListFormat: []
}

// getters
const getters = {
  getAntispasmodics: state => state.antispasmodics,
  getAntispasmodicsListFormat: state => state.antispasmodicsListFormat,
}

// actions
const actions = {

  async fetchAntispasmodics ({commit}, payload) {
    try {
      commit('setLoading', true, { root: true })

      const res = await antispasmodicServices.fetchAntispasmodics(payload)
      const list = []
      res.forEach((v,i) => {
        list.push({text:v.antispasmodic,value:v.id})  
      })
    
      commit('setAntispasmodicsListFormat', list)
      commit('setAntispasmodics', res)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit}, payload) {
    /*************************************
    * ID: antispasmodic Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    
    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      const res = await antispasmodicServices.updateAntispasmodicColumn(payload)

      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addAntispasmodic ({commit}) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new antispasmodic */
      /* code here */

      let obj = {
        name: 'New Preparation Agent'
      }

      const res = await antispasmodicServices.addAntispasmodic(obj)
      const data = await Promise.resolve([
        ...state.antispasmodics,
        res.data
      ])
      commit('setAntispasmodics', data)
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortAntispasmodic({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.antispasmodics.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.antispasmodics.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setAntispasmodics', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetAntispasmodics (state) {
    state.antispasmodics = []
  },

  setAntispasmodics (state, payload) {
    state.antispasmodics = payload
  },

  setAntispasmodicsListFormat (state, payload) {
    state.antispasmodicsListFormat = payload
  }

  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}