<template>
  <section class="pos-relative">
    <div
      class="--shadow px-2 text-defaultGreyText d-flex align-items-center items-center"
    >
      <div
        v-if="currentDevice.audio && showDeviceHint"
        class="d-flex align-items-center items-center"
      >
        <div class="d-flex align-center">
          <p class="list-style-none flex-grow-0 mr-2 mb-0 fsz-10 fsz-md-14">
            <span>麥克風: </span>{{ currentDevice.audio }}
          </p>
          <p class="list-style-none flex-grow-0 mb-0 fsz-10 fsz-md-14" v-if="hasCaptureFeature">
            <span>| 影像: </span>{{ currentDevice.video }}
          </p>
        </div>
        <div class="text-xs-right">
          <IconDropdown
            icon="keyboard_voice"
            :value="audioId"
            :items="
              audioDevices.map((d) => ({ label: d.label, id: d.deviceId }))
            "
            @change="(deviceId) => changeDeviceType('audio', deviceId)"
          />
          <IconDropdown
            v-if="hasCaptureFeature"
            icon="tv"
            :value="videoId"
            :items="
              videoDevices.map((d) => ({ label: d.label, id: d.deviceId }))
            "
            @change="(deviceId) => changeDeviceType('video', deviceId)"
          />
        </div>
      </div>
      <div v-else><p class="mb-0 py-2">無啟用裝置</p></div>
    </div>
    <v-card class="pos-absolute" style="z-index: -1">
      <div
        :style="{
          display: showDeviceList,
          padding: '3px',
          backgroundColor: '#fff',
        }"
      >
        <audio
          id="speak"
          :src="require(`@/assets/speak.wav`)"
          crossorigin="anonymous"
        />
        <audio
          id="photo"
          :src="require(`@/assets/photo.mp3`)"
          crossorigin="anonymous"
        />
      </div>
      <div
        id="monitor"
        class="monitor"
        :class="{ 'pos-absolute': !getWebrtcStatus }"
        style="z-index: -1"
      >
        <canvas ref="canvas" class="d-none" />
        <video ref="video" autoplay playsinline />
      </div>
    </v-card>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import common from '../../utils/common'
import IconDropdown from '../commons/IconDropdown.vue'

const JUDGING_TIMOUT = 3500

export default {
  name: 'Video',
  props: {
    speechRecognition: {
      type: Function,
      required: true,
    },
    showDeviceHint: {
      type: Boolean,
      default: true,
    },
    isRealTime: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IconDropdown,
  },
  computed: {
    ...mapGetters(['getUser', 'getSetting']),
    ...mapGetters('examinations', [
      'getExaminationData',
      'getVoiceCommandTestKeyword',
      'getStoreHiddenParameter',
      'getWebrtcStatus',
    ]),
    hasCaptureFeature () {
      return this.getSetting.screenshot
    },
    examination_typeID() {
      return this.getExaminationData.examination.examination_typeID
    },

    examinationID() {
      return this.getExaminationData.examination.id
    },

    accNo() {
      return this.getExaminationData.examination.accNo
    },
    video() {
      return this.$refs.video
    },
    canvas() {
      return this.$refs.canvas
    },
    showDeviceList() {
      return this.getUser != null && this.getUser.level == 9 ? 'block' : 'none'
    },
    wsRecordUrl () {
      return common.getASRUrl(
        this.getSetting.asr_domain,
        this.getSetting.asr_ws_port,
        this.getSetting.asr_ws_path,
      )
    },
    wsRealTimeUrl () {
      return common.getASRUrl(
        this.getSetting.asr_domain,
        this.getSetting.asr_ws_realtime_port,
        this.getSetting.asr_ws_realtime_path,
      )
    },
  },
  data() {
    return {
      audioContext: null,
      cameraStream: null,
      micStream: null,
      recorder: null,
      recording: false,
      startTimestamp: 0,
      endTimestamp: 0,
      keydownTime: 0,
      currentDevice: {
        audio: null,
        video: null,
      },
      audioId: '',
      videoId: '',
      audioDevices: [],
      videoDevices: [],
      intervalID: '',
      sendTime: 250
    }
  },
  methods: {
    ...mapActions('examinations', [
      'setRecording',
      'setJudging',
      'setTimeCounting',
      'toggleWebrtcStatus',
      'popCommandQueue',
      'setCommandQueue',
      'cleanCommands',
    ]),
    // stream start
    async getUserMediaStream(device, deviceId) {
      const stream = await navigator.mediaDevices.getUserMedia({
        [device]: { deviceId },
      })
      return { stream }
    },
    async handleAudio(id) {
      try {
        const { stream } = await this.getUserMediaStream('audio', id)
        await this.handleMic(stream)

        const info = stream.getAudioTracks() && stream.getAudioTracks()[0]
        this.currentDevice[info.kind] = info.label
        this.audioId = id
      } catch (e) {
        this.handleError(e)
      }
    },
    async handleVideo(id) {
      try {
        const { stream } = await this.getUserMediaStream('video', id)
        await this.handleCamera(stream)
        const info = stream.getVideoTracks() && stream.getVideoTracks()[0]
        this.currentDevice[info.kind] = info.label
        this.videoId = id
      } catch (e) {
        this.handleError(e)
      }
    },
    async getBrowserMediaDevices() {
      try {
        const __filter = (fn, arr) => arr.filter(fn)
  
        const devices = await navigator.mediaDevices.enumerateDevices()
        const audioArr = __filter((d) => d.kind === 'audioinput', devices)
        
        if (audioArr) {
          await this.handleAudio(audioArr[0].deviceId)
          this.audioDevices = audioArr
        }
        if (this.hasCaptureFeature) {
        const videoArr = __filter((d) => d.kind === 'videoinput', devices)
          if (videoArr) {
            await this.handleVideo(videoArr[0].deviceId)
            this.videoDevices = videoArr
          }
        }
      } catch(e) {
        // regardless
      }
    },
    handleCamera(stream) {
      this.cameraStream = stream
      this.$refs.video.srcObject = stream
      return Promise.resolve()
    },
    handleMic(stream) {
      this.micStream = stream
      this.audioContext = new AudioContext({
        sampleRate: 16000,
      }) //44000 -> 16000
      this.audioContext.destination.channelCount = 1
      let input = this.audioContext.createMediaStreamSource(stream)

      // Firefox loses the audio input stream every five seconds
      // To fix added the input to window.source
      window.source = input

      // make the analyser available in window context
      window.userSpeechAnalyser = this.audioContext.createAnalyser()
      input.connect(window.userSpeechAnalyser)
      // window.userSpeechAnalyser.connect(this.audioContext.destination);

      // window.userSpeechAnalyser.fftSize = 2048;

      this.recorder = new Recorder(input)
      return Promise.resolve()
    },
    stopStreamTrack(stream) {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop())
      }
    },
    cleanStream() {
      this.stopStreamTrack(this.cameraStream)
      this.stopStreamTrack(this.micStream)
    },
    async changeDeviceType(type, id) {
      if (type === 'audio') {
        this.handleAudio(id)
        this.stopStreamTrack(this.micStream)
      } else if (type === 'video') {
        this.handleVideo(id)
        this.stopStreamTrack(this.cameraStream)
      }
    },
    deviceNotify() {
      this.$notify({
        group: 'bottomCenter',
        text: '裝置切換',
      })
    },
    handleDeviceChange() {
      this.getBrowserMediaDevices()
    },
    handleError(error) {
      console.log('Error:' + error.name, error)
    },
    // steam end
    savePic() {
      console.log('savePic #1: ' + new Date().getTime().toString())

      const eID = this.examinationID
      const eAccNo = this.accNo
      var fID = this.getExaminationData.findings[
        this.getStoreHiddenParameter.nowFindingIndex
      ]
        ? this.getExaminationData.findings[
            this.getStoreHiddenParameter.nowFindingIndex
          ].id
        : 0
      if (this.getStoreHiddenParameter.over) {
        fID = 0
      }
      const timestamp = new Date().getTime().toString()

      if (this.video.videoWidth !== 0 && this.video.videoHeight !== 0) {
        // Small
        this.canvas.width = this.video.videoWidth / 4
        this.canvas.height = this.video.videoHeight / 4
        this.canvas
          .getContext('2d')
          .drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height)
        this.$store.dispatch('examinations/addScreenshot', {
          data: {
            id: timestamp,
            examinationID: eID,
            findingID: fID,
            file: null,
            landmarkID: 0,
            cm: null,
            status: '0',
            timestamp: timestamp,
            data: this.canvas.toDataURL('image/jpeg'),
          },
          timestamp: null,
        })

        // Original
        this.canvas.width = this.video.videoWidth
        this.canvas.height = this.video.videoHeight
        this.canvas
          .getContext('2d')
          .drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height)
        this.$http
          .post(
            `/api/image?t=${timestamp}`,
            {
              data: this.canvas.toDataURL('image/jpeg'),
              examinationID: eID,
              accNo: eAccNo,
              findingID: fID,
            },
            { headers: { 'Content-Type': 'application/json' } }
          )
          .then((response) => {
            this.$store.dispatch('examinations/addScreenshot', {
              data: response.body.screenshot,
              timestamp,
            })
          })
      }
    },
    loopAction() {
      this.stopRecording()
      clearInterval(this.intervalID);
      this.startRecording(this.sendTime);
    },
    startRecording(s) {
      if (!this.recorder) return
      if (!this.isRealTime) {
        this.$disconnect()
        this.setRecording(true)
        this.setJudging(true)
        this.$connect(this.wsRecordUrl)
        this.recorder.record()
        this.sendLog('Recording ...')
      } else {
        this.recorder.record();
        this.startTimestamp = Date.now();
        this.intervalID = setInterval(this.loopAction, Number(s));
      }
    },
    stopRecording(upload) {
      if (this.recorder) {
        this.setRecording(false)
        setTimeout(() => this.setJudging(false), JUDGING_TIMOUT)
        this.recorder.stop()
        // 儲存音檔
        if (upload && !this.isRealTime) {
          this.saveAudioToServer()
        }
        // 傳送音檔給辨識系統
        this.sendRecordAudioWS()        
      }
    },
    saveAudioToServer () {
      let that = this
      this.recorder.exportWAV(function (blob) {
        var reader = new FileReader()
        reader.onload = function () {
          var rawData = reader.result
          that.speechRecognition(
            rawData,
            'websocket2',
            that.startTimestamp,
            that.endTimestamp
          )
        }
        reader.readAsDataURL(blob)
      })
    },
    sendRecordAudioWS () {
      const that = this
      this.recorder.export16kMono(function (blob) {
        if (that.$socket?.readyState == 1 && blob.size) {
          const sendItems = that.isRealTime ? [blob] : [blob, new Uint8Array(0)]
          sendItems.forEach(item => {
            that.$socket.send(item);
          })
          that.recorder.clear();
          // that.recorder.record();
        }
      }, "audio/x-raw");
      this.recorder.clear()
    },
    musicPlay(voiceinput) {
      try {
        let music = document.getElementById(voiceinput)
        if (music) {
          music.play()
        }
      } catch (error) {
        console.log(error)
      }
    },
    sendLog(text) {
      const timestamp = Date.now()
      const frontEndLog = `${text}: ${timestamp}`
      console.info(frontEndLog)
      this.$http.post(
        `/api/frontEndLog?t=${timestamp}`,
        JSON.stringify({ frontEndLog }),
        { headers: { 'Content-Type': 'application/json' } }
      )
    },
    keydownZ() {
      this.sendLog('onkeydown [ Z ]')
      this.recording = true
      this.startRecording()
      this.musicPlay('speak')
      this.startTimestamp = Date.now()
    },
    keydown(evt) {
      if (evt.keyCode === 90) {
        this.keydownTime += 1
        if (!this.recording) {
          this.keydownZ()
        }
      }
      return Promise.resolve()
    },
    keyupZ() {
      if (this.recording) {
        setTimeout(() => {
          this.sendLog('onkeyup [ 90 ] Stop Recording')
          this.recording = false
          this.stopRecording(true)
          this.endTimestamp = Date.now()
        }, 300)
      }
      this.keydownTime = 0
    },
    keyup(evt) {
      if (evt.keyCode === 90) {
        if (this.keydownTime == 1) {
          // 觸發拍照
          this.stopRecording(true)
          this.savePic()
          this.musicPlay('photo')
          this.recording = false
          this.keydownTime = 0
        } else {
          this.keyupZ()
        }
      }
      return Promise.resolve()
    },
    setIsRealTime() {
      this.$connect(this.wsRealTimeUrl);
      this.startRecording(this.sendTime)
    },
    clearIsRealTimeInterVal () {
      if (this.recorder && this.intervalID) {
        this.stopRecording(true);
        clearInterval(this.intervalID);
      }
    },
    async init() {
      try {
        await this.getBrowserMediaDevices()

        navigator.mediaDevices.addEventListener(
          'devicechange',
          this.handleDeviceChange
        )
        if (this.isRealTime) {
          this.setIsRealTime()
        } else {
          window.addEventListener('keydown', this.keydown)
          window.addEventListener('keyup', this.keyup)
        }
      } catch (e) {
        this.handleError(e)
      }
    },
  },
  created() {
    const that = this
    this.$options.sockets.onmessage = function (msg) {
      var reader = new FileReader()
      reader.onload = function () {
        if (reader.result) {
          const body = JSON.parse(reader.result)
          if (body && Array.isArray(body.data) && body.data.length) {
            const [voice] = body.data
            that.setJudging(false)
            that.sendLog(`final # ${voice.text}`)
            that.popCommandQueue()
            that.setCommandQueue(voice.text)
            that.speechRecognition(
              voice.text,
              'websocket',
              that.startTimestamp,
              that.endTimestamp
            )
          }
        }
      }
      reader.readAsText(msg.data)
    }
  },
  async mounted() {
    await this.init()
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydown)
    window.removeEventListener('keyup', this.keyup)
    this.cleanCommands()
    this.cleanStream()
    this.$disconnect()

    navigator.mediaDevices.removeEventListener(
      'devicechange',
      this.handleDeviceChange
    )
  
    this.clearIsRealTimeInterVal() 
  },
  watch: {
    isRealTime(val) {
      if (val) {
        this.setIsRealTime()
        window.removeEventListener('keydown', this.keydown)
        window.removeEventListener('keyup', this.keyup)
      } else {
        this.clearIsRealTimeInterVal()
        this.$disconnect()
        window.addEventListener('keydown', this.keydown)
        window.addEventListener('keyup', this.keyup)
      }
    }
  }
}
</script>
