<template>
  <div class="examination-form-core" v-if="questions && (questions.length > 0)">
    <template v-for="(value, key) in questions">
      <div v-if="!isEmptyObj(value)" :key="value.id">
        <div v-if="value.inputType === 'c'">
          <component
            v-for="(v, k) in value.options"
            :key="`question${key}Options${k}`"
            v-bind:is="typeOfQuestion(value.inputType)"
            v-bind="prepareCheckboxQuestionProps({ key: k, value: v,  field: value.field })"
            @change="updateFormBinding({item: value, newValue: $event})"
          ></component>
        </div>

        <!-- other question types -->
        <component
          v-else
          :key="key"
          v-bind:is="typeOfQuestion(value.inputType)"
          v-bind="questionComponentProps(value)"
          @change="updateFormBinding({item: value, newValue: $event})"
        ></component>
      </div>
    </template>
  </div>
</template>

<script>
  import { VTextField, VCheckbox, VSelect} from 'vuetify/lib'
  import forms from '@/utils/forms'

  export default {
    name: 'ReportFindingsFormLevleOne',

    props: {
      data: {
        type: Object,
        required: true
      },

      questions: {
        type: Object,
        required: true
      },
    },

    components: {
      VTextField,
      VCheckbox,
      VSelect
    },

    data() {
      return {
      }
    },

    methods: {
      isEmptyObj (obj) {
        return Object.entries(obj).length === 0 && obj.constructor === Object
      },

      questionComponentProps (item) {
        if (item.inputType === 'c') {
          return
        }

        const type = item.inputType
        const propsObj = {
          label: item.question,
          value: this.data.questions[item.field].value
        }

        if (type === 's') {
          return Object.assign(propsObj, forms.prepareSelectQuestionProps(item))
        } else if (type === 't') {
          return propsObj
        }

        return null
      },

      prepareCheckboxQuestionProps (option) {
        const label = option.value
        const value = Number(option.key)

        return {
          label,
          value,
          'input-value': this.data.questions[option.field].value
        }
      },

      updateFormBinding (obj) {
        const item = this.data.questions[obj.item.field]
        const field = obj.item.field

        this.$emit('change', {
          item,
          field,
          newValue: obj.newValue
        })
      },

      prepareSelectQuestionProps: (item) => forms.prepareSelectQuestionProps(item),
      typeOfQuestion: (type) => forms.formTypeDirective(type),
      prepareSelectItems: (options) => forms.prepareSelectItems(options)
    }
  }
</script>

<style lang="scss" scoped>

</style>