export const en = {
  report: {
    Unedited: 'Unedited',
    Editing: 'Editing',
    Finalized: 'Finalized',
    exam_info: 'Exam. Info',
    findings: 'Findings',
    diagnosis: 'Diagnosis',
    suggestion: 'Suggestion',
    confirm: {
      title: 'delete',
      text: 'Are you sure you want to delete Finding {0}?',
      delete_image: 'Are you sure you want to delete this image?',
    },
    reset_timer: 'reset the timer',
    simple: 'Simple Ver.',
    normal: 'Normal Ver.',
  },
  report_preview: {
    title: 'REPORT PREVIEW',
    ai: 'AI-powered medical report',
    back_to: 'Go back to editing',
    Gastroscopy: 'Gastroscopy Report',
    Colonoscopy: 'Colonoscopy Report',
    name: '@:patient.name',
    gender: 'Gender',
    age: '@:patient.age',
    recordNo: 'Med.Record',
    examDate: '@:examination.date',
    examTime: 'Exam. Time',
    examItem: 'Exam. Item',
    GastroscopyCheckNo: '28016C',
    ColonoscopyCheckNo: '28017C',
    findings: '@:report.findings',
    report_date: 'Report Date',
    technician: 'Technician',
    print: 'Print',
    explanation: 'Doctor\'s explanation',
    personal_info: 'Personal information',
    diff: 'Before-and-after comparison',
    cleanse_off: 'Cleaned up, please rest assured!',
    deepest: 'Check the deepest location',
    timer: {
      title: 'Endoscope withdrawal time',
      withdrawal: 'Withdrawal Time',
      proximal: 'Proximal Colon Withdrawal Time',
    },
  },
  organization: {
    title: 'Organization',
    number: 'Organization No',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    connection: 'Connect to PACS Worklist',
    ae_title: 'Called_AE ',
    called_ip: 'Called_IP',
    called_port: 'Called_PORT',
    calling_ae: 'Calling_AE ',
    modality: 'Modality',
    record: '全時收音',
  },
  doctor: {
    title: 'Doctors',
  },
  examination: {
    title: 'Examinations',
    number: 'Examination Record',
    patient: '@:patient.name',
    date: 'Exam. Date',
    doctor: 'Order Doctor',
    type: 'Examination Type',
    add: 'Add Examination',
  },
  inspection_instrument: {
    title: 'Inspection Instruments',
  },
  wash_Instrument: {
    title: 'Wash Instruments',
  },
  patient: {
    title: 'Patients',
    name: 'Patient',
    id: 'Medical Record',
    dob: 'Birthdate',
    sex: 'Gender',
    age: 'Age',
  },
  nurse: {
    title: 'Nurse',
  },
  user: {
    title: 'Users',
  },
  analytics: {
    title: 'Analytics',
  },
  enviroment_test: {
    title: 'Enviroment Test',
  },
  common: {
    yes: 'Yes',
    no: "No",
    cancel: 'Cancel',
    nil: "Nil",
    summary: 'Summary',
    search: 'Search',
    reset: 'Reset',
    add: 'ADD',
    other: 'Other',
    day: 'day',
    ESOPHAGUS: 'ESOPHAGUS',
    STOMACH: 'STOMACH',
    DUODENUM: 'DUODENUM',
    NONSPECIFIC: 'NONSPECIFIC',
    indication: 'Indication',
    preparation_agent: 'Preparation Agent',
    preparation_time: 'Preparation Time',
    anti_platelet: 'Anti platelet',
    anti_platelet_stop: 'Antiplatelet medication discontinuation',
    cleanse_level: {
      before: 'Cleanse Level(before)',
      after: 'Cleanse Level(after)',
    },
    scope_id: 'Scope ID',
    sedatives: 'Sedatives',
    washing_machine: 'Washing machine',
    premedication: {
      title: 'Premedication',
      '1': 'Glaucoma',
      '2': 'BPH',
      '3': '心跳過速',
      '4': '過敏',
    },
    antispasmodics: {
      on: 'On antispasmodics',
      off: 'Off antispasmodics',
    },
    insertion_level: '伸入最深位置',
    insertion_length: '伸入最深長度',
    complication: "Complication",
    clo_test: 'CLO Test',
    additional_remarks: 'Additional Remarks',
    incomplete: {
      title: 'Incomplete study due to',
      gastro: {
        '0': '',
        '1': 'intolerance',
        '2': 'bleeding',
        '3': 'food stasis',
        '4': 'undigested medicine',
      },
      colon: {
        '0': '',
        '1': 'Poor Preparation',
        '2': 'Bleeding',
        '3': 'Bowel Perforation',
        '4': 'Patient Intolerance',
        '5': 'Other',
        '6': 'Food Stasis',
        '7': 'Undigested Medicine',
      }
    },
    procedure_note: 'Procedure Note',
  },
  dialog: {
    warn: 'Warn',
  },
  login:{
    username: "username",
    password: "password",
    verificationcode: "verification code",
    signin: "sign in"
  },
  homepage:{
    from: "From",
    to: "To",
    examination_records: "exammination records",
    medical_records: "Medical records",
    search: "search",
    today: "today",
    sync_worklist: "SYNC WORKLIST",
    examinations: "examinations",
    date: "date",
    examination_record: "examination record",
    examination_type: "examination type",
    patient_name: "Patient name",
    medical_record: "medical record",
    status: "status",
    no_data_available: "No data available",
    rows_per_page: "Rows per page",
    singout: "SING OUT",
    select_examination: "Select an examination from the left to see detail information.",
    finished: "Finished",
    unfinished: "Unfinished",
    colonoscopy: "Colonoscopy",
    gastoscopy: "Gastoscopy",
    gast:{
      birth: "DATE OF BIRTH",
      age: '@:patient.age',
      gender: "GENDER",
      male: "male",
      female: "female",
      scope_id: "Scope ID",
      washing_machine: "Washing Machine",
      docter: "Doctor",
      nurse: "Nurse",
      go_to_report: "GO TO REPORT",
      start_examination: "START EXAMINATION",
    },
    colon:{
      premedication: "@:common.premedication.title",
      preparation_agent: "Preparation Agent",
      preparationTime: "Preparation Time",
      antiplatelet: "Antiplatelet",
      complication: "@:common.complication",
      without_medication: "",
      please_select: "Please select",
    }
  },

  poweredByFirst: 'Powered By',
  poweredBySecond: 'KENKON EVAS',
  from: 'From',
  ward: 'Ward',
  applicationDate: 'Application Date',
  applicationTime: 'Application Time',
  applicationDr: 'Application DR',
  reportTime: 'Report Time',
  vs: 'VS.',
  examDr: 'EXAM. DR.',

  // ColonCloeanseForm
  informedConsent: 'Informed consent',
  insertionTime: 'Insertion Time',
  withdrawTime: 'Withdrawal Time',
  totalExamTime: 'Total Exam',
  proximalColonWithTime: 'Proximal Colon Withdrawal Time',

  // Colon finding
  finding: 'Finding',
  lesion: 'Lesion',
  procedureImages: 'Procedure Images',
  additionalImage: 'Additional Image',

  http: {
    error: {
      400: 'Bad Request',
      401: 'Unauthorized',
      403: 'Forbidden',
      404: 'Not Found',
      405: 'Method Not Allowed',
      406: 'Not Acceptable',
      408: 'Request Timeout',
      500: 'Internal Server Error',
      502: 'Bad Gateway',
      503: 'Service Unavailable',
      504: 'Gateway Timeout',
      505: 'HTTP Version Not Supported',
      999: 'JavaScript Error',
    },
  },
  i18n: {
    zh: '繁體中文',
    ja: '日本語',
    en: 'English',
  },
}
