var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"h-100 pos-relative px-2",class:{ 'finding-over-cover ': _vm.getStoreHiddenParameter.over }},_vm._l((_vm.getFindings),function(finding,findingIndex){return _c('section',{directives:[{name:"show",rawName:"v-show",value:(findingIndex === _vm.activeIdx),expression:"findingIndex === activeIdx"}],key:finding.id,staticClass:"pt-3 basicExamFindingInfo"},[_c('BasicExamFindingForm',{attrs:{"finding":finding,"findingsOptions":_vm.questionList},on:{"change":function($event){return _vm.saveQuestionInput({
          findingID: finding.id,
          findingIndex: findingIndex,
          ...$event,
        })}}}),_c('ul',{staticClass:"py-3 pl-0",attrs:{"data-test":"record-list"}},_vm._l((finding.records),function(record,recordIndex){return _c('li',{key:record.id,staticClass:"list-style-none",class:{ 'h-100': recordIndex === 0 },attrs:{"data-test":"record-list-item"}},[_c('BasicExamFindingRecord',{directives:[{name:"show",rawName:"v-show",value:(recordIndex === 0),expression:"recordIndex === 0"}],staticClass:"h-100",attrs:{"examType":_vm.examType,"record":record},on:{"locationChange":function($event){return _vm.updateExaminationLocationColumn({
              findingIndex,
              recordIndex,
              $event,
            })},"change":function($event){return _vm.saveFindingRecord({
              findingID: finding.id,
              recordID: record.id,
              recordIndex,
              findingIndex,
              event: $event,
            })}}})],1)}),0)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }