<style lang="scss">
.v-card--reveal {
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(#0097a7, 0.2);
}
.relative {
  position: relative;
}
.screenshot {
  &__order {
    border: 2px solid #fff;
    border-radius: 4px;
  }
  &__caption {
    position: absolute;
    right: 0.5rem;
    bottom: 0;
    z-index: 0;
    padding: 0 0.5rem;
    border-radius: 2px;
    color: rgb(197, 80, 80);
    background: rgba(#fff, 0.8);
  }

  &--print {
    background-color: #03a9f4;
  }

  &--print_disabled {
    background-color: #607d8b;
  }

  &__finding.v-btn.v-btn--outline {
    border-width: 2px;
  }
}
</style>

<template>
  <v-flex xs12 sm6 lg4 class="">
    <v-hover>
      <v-card slot-scope="{ hover }" dark color="grey lighten-4">
        <div class="relative">
          <v-img aspect-ratio="1.33333" :src="imgSrc">
            <v-expand-transition>
              <v-layout v-if="hover" class="v-card--reveal fill-height ma-0">
                <v-btn fab flat color="light-green" @click="download">
                  <v-icon large>cloud_download</v-icon>
                </v-btn>
                <v-btn fab flat @click="zoom">
                  <v-icon large>find_in_page</v-icon>
                </v-btn>
                <v-btn fab flat color="deep-orange" @click="notify('remove')">
                  <v-icon large>delete_outline</v-icon>
                </v-btn>
              </v-layout>
            </v-expand-transition>

            <template #placeholder>
              <v-layout fill-height align-center justify-center ma-0>
                <v-progress-circular indeterminate color="grey lighten-5" />
              </v-layout>
            </template>
          </v-img>
          <p
            class="screenshot__caption"
            v-if="printIcon.name === 'print_disabled'"
          >
            該圖片不會呈現於報告列印
          </p>
        </div>

        <v-card-actions :class="`screenshot--${printIcon.name}`">
          <h4 class="mr-1 py-1 px-2 title text-xs-center screenshot__order">
            P {{ order }}
          </h4>
          <h5>{{ cm }}</h5>
          <v-spacer />
          <v-btn icon @click="changePrintStatus">
            <v-icon large :color="printIcon.color">{{ printIcon.name }}</v-icon>
          </v-btn>
          <select-icon
            :value="info.findingID"
            :items="findings"
            @change="changeFinding"
          >
            <template #default="{ on }">
              <v-btn
                v-if="findingOrder"
                icon
                flat
                outline
                class="screenshot__finding"
                v-on="on"
              >
                <h4 class="title">{{ findingOrder }}</h4>
              </v-btn>
              <v-btn v-else icon flat v-on="on">
                <v-icon large>label</v-icon>
              </v-btn>
            </template>
          </select-icon>
        </v-card-actions>
        <v-card-actions :class="landmarkBarColor">
          <select-icon
            :value="info.landmarkID"
            :items="landmarks"
            @change="changeLandmark"
          >
            <template #default="{ on }">
              <v-btn icon flat :color="landmarkIconColor" v-on="on">
                <v-icon medium>location_on</v-icon>
              </v-btn>
              <font v-if="!landmarkText">Select Landmarks</font>
            </template>
          </select-icon>
          <div class="title grey--text text--darken-2">
            {{ landmarkText }}
          </div>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectIcon from '../screenshots/SelectIcon'
import common from '@/utils/common'

const NOTIFY = {
  REMOVE: 'remove',
}
const STATUS = {
  NO: '0',
  YES: '1',
}
const STATUS_CHANGE = [STATUS.YES, STATUS.NO]

export default {
  name: 'Screenshot',
  components: {
    SelectIcon,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    findings: {
      type: Array,
      default: () => [],
    },
    landmarks: {
      type: Array,
      default: () => [],
    },
    length: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('examinations', [
      'getExaminationData',
      'getExaminationTypeList',
      'getPatient',
    ]),
    imgSrc() {
      return this.info && this.info.file
        ? `/api/upload/image/${this.info.examinationID}/${this.info.file}`
        : ''
    },
    order() {
      return this.length - this.index
    },
    cm() {
      return this.info && this.info.cm ? `${this.info.cm} cm` : ''
    },
    printIcon() {
      return this.info && this.info.status === STATUS.YES
        ? { name: 'print', color: 'white' }
        : { name: 'print_disabled', color: '#ff5722' }
    },
    findingOrder() {
      if (this.info) {
        if (this.info.findingID) {
          return this.findings.findIndex((f) => f.value === this.info.findingID)
        }
      }
      return 0
    },
    landmarkText() {
      if (this.landmarks.length && this.info.landmarkID) {
        const found = this.landmarks.find(
          (l) => l.value === this.info.landmarkID,
        )
        if (found) {
          return found.text.replace(/(\Wof\Wcolon|\Wcolon)$/gi, '')
        }
      }
      return ''
    },
    landmarkBarColor() {
      return this.landmarkText ? 'yellow accent-4' : 'blue-grey'
    },
    landmarkIconColor() {
      return this.landmarkText ? 'deep-orange' : ''
    },
  },
  methods: {
    ...mapActions('screenshots', ['deleteScreenshot']),
    ...mapActions('examinations', [
      'imgLandmarkChange',
      'updateScreenshotStatusInFinding',
      'updateScreenshotStatusInAll',
      'moveImgFromAllToFinding',
    ]),
    toImgURL(url) {
      return fetch(url)
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
    },
    async download() {
      const a = document.createElement('a')
      a.href = await this.toImgURL(this.imgSrc)
      a.download = `${common.fileRule(
        this.getExaminationData,
        this.getExaminationTypeList,
        this.getPatient,
      )}_${this.imgSrc.replace(/^.*[\\\/]/, '')}`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    notify(action) {
      switch (action) {
        case NOTIFY.REMOVE:
          this.remove()
          break
        default:
          break
      }
    },
    remove() {
      this.$emit('notify', {
        value: true,
        msg: this.$t('report.confirm.delete_image'),
        onConfirm: () => this.deleteScreenshot(this.info.id),
      })
    },
    changeLandmark(newValue) {
      this.imgLandmarkChange({
        imgID: this.info.id,
        newValue,
        page: 'r',
      })
    },
    changePrintStatus() {
      const params = {
        newStatus: STATUS_CHANGE[this.info.status],
        item: this.info,
        imgID: this.info.id,
        findingID: this.info.findingID,
        landmarkID: this.info.landmarkID,
      }
      if (this.info.findingID !== 0) {
        this.updateScreenshotStatusInFinding(params)
      } else {
        this.updateScreenshotStatusInAll({ ...params, imgIndex: this.index })
      }
    },
    changeFinding(newValue) {
      this.moveImgFromAllToFinding({
        imgID: this.info.id,
        newValue,
      })
    },
    zoom() {
      this.$emit('zoom', this.imgSrc)
    },
  },
}
</script>
