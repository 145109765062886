<template>
  <div>
    <div class="pa-2 mb-3 bg-select">
      <div>
        <h3 class="mb-2">{{ $t('homepage.colon.premedication') }}</h3>
        <v-radio-group
          :value="examinationData.antispasmodicHave"
          name="examView"
          row
          hide-details
          class="mt-0"
          @change="handleAntispasmodicHave($event);"
        >
          <v-radio :label="$t('common.yes')" value="1" />
          <v-radio :label="$t('common.no')" value="0" />
        </v-radio-group>
      </div>
      <ExamSelect
        v-if="examinationData.antispasmodicHave === '1'"
        :multiple="true"
        :selected="examinationData.antispasmodicID"
        :items="getUseReasons" 
        column="antispasmodicID"
        other-column="antispasmodicOther"
        :other-value="examinationData.antispasmodicOther"
        @change="setExaminationColumn"
      />
      <ExamSelect
        v-else
        :multiple="true"
        :selected="examinationData.antispasmodicReason"
        :items="getNoUseReasons" 
        column="antispasmodicReason"
        other-column="antispasmodicOther"
        :other-value="examinationData.antispasmodicOther"
        @change="setExaminationColumn"
      />
    </div>
    <div class="pa-2 mb-3 bg-select">
      <SedativeContent
        :title="$t('common.sedatives')"
        :examinationID="examinationData.id"
        :sedatives="examinationData?.sedatives"
        :sedativesList="getSedatives"
        :sedativeOther="examinationData.sedativeOther"
        @change="setExaminationColumn('sedativeOther', $event)"
      />
    </div>
    <div class="pa-2 mb-3 bg-select">
      <ExamSelect
        :multiple="true"
        :title="$t('homepage.colon.preparation_agent')"
        :selected="examinationData.clean_medID"
        :items="
          addOtherItem(
            formSelectDataFormat(getCleanMedList, 'id', 'clean_med')
          )
        "
        column="clean_medID"
        other-column="clean_medOther"
        :other-value="examinationData.clean_medOther"
        @change="setExaminationColumn"
      />
    </div>
    <div class="pa-2 mb-3 bg-select">
      <ExamSelect
        :title="$t('homepage.colon.preparationTime')"
        :selected="examinationData.preparationTime"
        :items="getPreparationTimeList.map((l) => ({ text: l, value: l }))" 
        column="preparationTime"
        @change="setExaminationColumn"
      />
    </div>
    <div class="pa-2 mb-3 bg-select">
      <ExamSelect
        :multiple="true"
        :title="$t('homepage.colon.antiplatelet')"
        :selected="examinationData.antiplateletID"
        :items="
          addOtherItem(
            formSelectDataFormat(getAntiplateletList, 'id', 'antiplatelet')
          )
        "
        column="antiplateletID"
        other-column="antiplateletOther"
        :other-value="examinationData.antiplateletOther"
        @change="setExaminationColumn"
      >
        <template #extra>
          <v-text-field
            :value="examinationData.antiplateletStopDays"
            type="number"
            :label="$t('homepage.colon.antiplatelet')"
            :suffix="$t('common.day')"
            hide-details
            @change="($event) => setExaminationColumn('antiplateletStopDays', $event)"
          />
        </template>
      </ExamSelect>
    </div>
    <div class="pa-2 mb-3 bg-select">
      <ExamSelect
        :title="$t('homepage.colon.complication')"
        :selected="examinationData.complication"
        :items="filterNilSelect(addOtherItem(getComplicationList))"
        :multiple="true"
        :placeholder="$t('common.nil')"
        column="complication"
        other-column="complicationOther"
        :other-value="examinationData.complicationOther"
        @change="setExaminationColumn"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AntispasmodicInput from '@/components/report/AntispasmodicInput.vue'
import SedativeContent from '@/components/report/SedativeContent.vue'
import ExamSelect from '@/components/home/ExamSelect.vue'

export default {
  name: 'HpaInfo',
  props: {
    examinationData: {
      type: Object,
      required: true
    }
  },
  components: {
    AntispasmodicInput,
    SedativeContent,
    ExamSelect,
  },
  computed: {
    ...mapGetters('examinations', [
      'getSedatives',
      'getCleanMedList',
      'getComplicationList',
      'getAntispasmodicList',
      'getNoUseAntipasmodicReasonList',
      'getAntiplateletList',
      'getPreparationTimeList'
    ]),
    getUseReasons() {
      return this.getAntispasmodicList
        .map((item) => ({ text: item.antispasmodic, value: item.id }))
        .concat({ text: this.$t('common.other'), value: this.getAntispasmodicList.length + 1 })
    },
    getNoUseReasons() {
      return this.getNoUseAntipasmodicReasonList.concat({
        text: this.$t('common.other'),
        value: this.getNoUseAntipasmodicReasonList.length + 1,
      })
    },
  },
  methods: {
    setExaminationColumn(column, value) {
      this.$emit('change', column, value)
    },
    addOtherItem(arr) {
      return arr.concat({ text: this.$t('common.other'), value: arr.length + 1 })
    },
    formSelectDataFormat(data, value, text) {
      return data.map((d) => ({ text: d[text], value: d[value] }))
    },
    filterNilSelect(arr) {
      // filter text = nil / aka value = 0
      return arr.filter((i) => i.value !== 0)
    },
    handleAntispasmodicHave(val) {
      this.setExaminationColumn('antispasmodicHave', val)
      if (val === '1') {
        const defaultVal = this.getAntispasmodicList.find((item) => item.antispasmodic === 'Hyoscine butylbromide')
        if (!defaultVal) { return }
        this.setExaminationColumn('antispasmodicID', [defaultVal.id])
      }
    }
  }
}
</script>

<style lang="scss">
.bg-select {
  background: #f5f5f5;
}
</style>