import { dbServices } from '@/services/db'
import forms from '@/utils/forms'
import common from '@/utils/common'
import {
  complicationList,
  indicationList,
  cleanScaleList,
  gastroPreparationList,
  colonPreparationList,
  preparationTimeList,
  noUseAntipasmodicReasonList,
  insertionLevelList,
  colonIncompleteReasonList,
  gastroIncompleteReasonList,
  cloTestLocations
} from '../data/input-list'
import { i18n } from '../../locale'

let timeout = 0

const defaultStoreParams = {
  focus: '',
  nowFindingIndex: 0,
  nowRecordIndex: 0,
  nowImgIndex: 0,
  nowSedativesIndex: 0,
  nowLocationsIndex: 0,
  nowQuestionPart: '',
  isRecording: false,
  isJudging: false,
  isTimeCounting: false,
  nowTimeCountingStep: 0,
  prevTimeCountingStep: 0,
  isTimeFinish: false,
  over: false,
}

const state = {
  orderedGastricData: [],
  examinationOrderArticle: null,
  colonDescriptiveParagraph: '',
  gastroDescriptiveParagraph: '',
  examinations: [],
  sedatives: [],
  examinationData: null,
  doctorList: [],
  technicalStaffList: [],
  landmarkList: [],
  examinationTypeList: [],
  inspectionInstrumentList: [],
  washInstrumentList: [],
  cleanMedList: [],
  cleanScaleList: cleanScaleList,
  preparationList: {
    1: gastroPreparationList,
    2: colonPreparationList,
    3: colonPreparationList,
  },
  insertionLevelList: insertionLevelList,
  incompleteReasonList: {
    1: gastroIncompleteReasonList,
    2: colonIncompleteReasonList,
    3: colonIncompleteReasonList,
  },
  sedativeList: [],
  antiplateletList: [],
  antispasmodicList: [],
  cloTestLocations,
  complicationList: complicationList,
  indicationList: indicationList,
  preparationTimeList: preparationTimeList,
  noUseAntipasmodicReasonList,
  patient: null,
  questionsList: null,
  findingsFormQuestions: null,
  findingsRecordsQuestions: null,
  examinationInfos: [],
  examinationFinishDialog: false,
  storeHiddenParameter: {},
  voiceCommandTestKeyword: {},
  editArticleStatus: {},
  webrtcStatus: false,
  commandQueue: [],
  currentCommand: '',
  examinationSearchResult: [],
  examinationSearchInfo: {
    page: 1,
    pgsz: 100,
    total: 0, // may not need
    total_page: 0,
  },
}

// getters
const getters = {
  getOrderedGastricData: state => state.orderedGastricData,
  getExaminations: (state) => state.examinations,
  getSedatives: (state) => state.sedatives,
  getExaminationData: (state) => state.examinationData,
  getOrderArticle: (state) => state.examinationOrderArticle,
  getColonDescriptiveParagraph: (state) => state.colonDescriptiveParagraph,
  getGastroDescriptiveParagraph: (state) => state.gastroDescriptiveParagraph,
  getExaminationDatatFindings: (state, getters) => {
    if (getters.getExaminationData) {
      var result = this.getExaminationData.findings
      for (var i = 0; i < result.length; i++) {
        for (var i2 = 0; i2 < result[i].records.length; i2++) {
          result[i].records[i2].diagnosis =
            result[i].records[i2].diagnosis != null
              ? result[i].records[i2].diagnosis
                  .replace(
                    /<i id="([0-9a-zA-Z_]*)" data-focus="([a-zA-Z]*)">/gi,
                    '',
                  )
                  .replace(/<\/i>/gi, '')
                  .replace(/<font>.<\/font>/gi, '') // 選擇poly 不填寫任何東西時會有一個點
                  .replace(/<font>/gi, '')
                  .replace(/<\/font>/gi, '')
                  .replace(
                    /<br><br>/gi,
                    '<br><span style="color: #2894FF;">Intervention</span> : ',
                  )
              : result[i].records[i2].diagnosis
        }
      }
      return result
    } else {
      return null
    }
  },
  getDoctorList: (state) => state.doctorList,
  getTechnicalStaffList: (state) => state.technicalStaffList,
  getLandmarkList: (state) => state.landmarkList,
  getExaminationTypeList: (state) => state.examinationTypeList,
  getInspectionInstrumentList: (state) => state.inspectionInstrumentList,
  getWashInstrumentList: (state) => state.washInstrumentList,
  getCleanMedList: (state) => state.cleanMedList,
  getCleanScaleList: (state) => state.cleanScaleList,
  getSedativeList: (state) => state.sedativeList,
  getAntiplateletList: (state) => state.antiplateletList,
  getAntispasmodicList: (state) => state.antispasmodicList,
  getNoUseAntipasmodicReasonList: (state) => state.noUseAntipasmodicReasonList.map(v => ({...v, text: i18n.t(v.text) })),
  getPreparationList: (state) => state.preparationList,
  getInsertionLevelList: (state) => state.insertionLevelList,
  getIncompleteReasonList: (state) => id => state.incompleteReasonList[id].map(v => ({...v, text: i18n.t(v.text) })),
  getComplicationList: (state) => state.complicationList,
  getIndicationList: (state) => state.indicationList,
  getCloTestLocations: (state) => state.cloTestLocations,
  getPreparationTimeList: (state) => state.preparationTimeList,
  getPatient: (state) => state.patient,
  getQuestionsList: (state) => state.questionsList,
  getFindingsFormQuestions: (state) => state.findingsFormQuestions,
  getFindingsRecordsQuestions: (state) => state.findingsRecordsQuestions,
  getExaminationInfos: (state) => state.examinationInfos,
  getExaminationFinishDialog: (state) => state.examinationFinishDialog,
  getStoreHiddenParameter: (state) => state.storeHiddenParameter,
  getVoiceCommandTestKeyword: (state) => state.voiceCommandTestKeyword,
  getEditArticleStatus: (state) => state.editArticleStatus,
  getWebrtcStatus: (state) => state.webrtcStatus,
  getCommandQueue: (state) => state.commandQueue,
  getCurrentCommand: (state) => state.currentCommand,
  getExaminationSearchResult: (state) => state.examinationSearchResult,
  getExaminationSearchInfo: (state) => state.examinationSearchInfo,
}

// actions
const actions = {
  async uploadPDF({ commit }, payload) {
    try {
      const res = await dbServices.uploadPDF(payload)
    } catch (e) {
      throw new Error(e)
    }
  },

  async notificationUniGateUploadScreenshot({ commit }, payload) {
    try {
      const res = await dbServices.notificationUniGateUploadScreenshot(payload)
    } catch (e) {
      throw new Error(e)
    }
  },

  async fetchExaminationInfos({ commit }, payload) {
    commit('setLoading', true, { root: true })

    return dbServices.fetchExaminationInfos(payload).then((d) => {
      commit('setExaminationInfos', d)

      commit('setLoading', false, { root: true })
    })
  },

  async addExamination({ commit }, payload) {
    commit('setLoading', true, { root: true })
    try {
      const res = await dbServices.addExamination(payload)
      if (!res.success) {
        return res // provide error info for the ExaminationList
      }
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  searchExaminations({ commit }, condition) {
    try {
      return dbServices.searchExaminations(condition).then(({ data }) => {
        const { page, pgsz, total_page, total, list } = data.paginate
        commit('setExaminationSearchResult', list)
        commit('setExaminationSearchInfo', { page, pgsz, total_page, total })
      })
    } catch (e) {
      console.log(e)
    }
  },

  async fetchExaminationsList({ commit }, payload) {
    commit('setLoading', true, { root: true })

    try {
      dbServices.fetchExaminations(payload).then((d) => {
        //console.log('fetchExaminations', d)
        commit('setExaminations', d.examinationList)
        commit('setOrderedGastricData', d.setOrderedGastricData)
        commit('setExaminationTypeList', d.examinationTypeList)
        commit('setDoctorList', d.doctorList)
        commit('setTechnicalStaffList', d.technicalStaffList)
        commit('setInspectionInstrumentList', d.inspectionInstrumentList)
        commit('setWashInstrumentList', d.washInstrumentList)

        commit('setLoading', false, { root: true })
      })
    } catch (e) {
      console.log(e)
    }
  },

  async fetchNewExaminationsList({ commit }) {
    commit('setLoading', true, { root: true })

    try {
      await dbServices.fetchNewExaminations()
      return dbServices.fetchExaminations({}).then((d) => {
        commit('setExaminations', d.examinationList)
        commit('setLoading', false, { root: true })
      })
    } catch (e) {
      console.log(e)
      commit('setLoading', false, { root: true })
    }
  },

  async sendDICOMStructureReport({ commit }, exam_id) {
    try {
      dbServices.fetchNewExaminations().then((d) => {
        if (d.examinationList.length !== 0) {
          commit('setExaminations', d.examinationList)
          commit('setDoctorList', d.doctorList)
          commit('setTechnicalStaffList', d.technicalStaffList)
          commit('setInspectionInstrumentList', d.inspectionInstrumentList)
          commit('setWashInstrumentList', d.washInstrumentList)
        }

        commit('setLoading', false, { root: true })
      })
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById({ commit }, payload) {
    /*************************************
     * ID: landmark Id                      *
     * field: the field that changed      *
     * value: the new value of that field *
     *************************************/
    commit('setLoading', true, { root: true })

    try {
      const res = await dbServices.updateExaminationInfoColumn(payload) // eslint-disable-line
      if (!res.success) {
        return res // provide error info for the ExaminationList
      }
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async fetchExaminationDetailAndOtherList({ dispatch, commit }, payload) {
    try {
      commit('setLoading', true, { root: true })

      const d = await dbServices.fetchExaminationDetailAndOtherList(payload)
      if (d.error) {
        window.location.href = '/'
      }

      commit('setOrderedGastricData', d.orderedGastricData)

      commit('setExaminationData', d.data)
      commit('setSedatives', d.data.sedatives)
      commit('setPatient', d.patient)
      commit('setDoctorList', d.doctorList)
      commit('setTechnicalStaffList', d.technicalStaffList)
      commit('setLandmarkList', d.landmarkList)
      commit('setExaminationTypeList', d.examinationTypeList)
      commit('setInspectionInstrumentList', d.inspectionInstrumentList)
      commit('setWashInstrumentList', d.washInstrumentList)
      commit('setCleanMedList', d.cleanMedList)
      commit('setSedativeList', d.sedativeList)
      commit('setAntiplateletList', d.antiplateletList)
      commit('setAntispasmodicList', d.antispasmodicList)

      // construct the object that has all questions we need
      const uniqueQuestionsObj = forms.getUniqueValuesFromAllFindings(d.data.findings)
      const allUniqueQsArr = Object.values(uniqueQuestionsObj)

      try {
        let allQuestionsJSON
        if (!common.isEmptyObject(allUniqueQsArr)) {
          allQuestionsJSON = await forms.fetchQuestionsJSON(allUniqueQsArr)
          commit('addQuestionsToList', allQuestionsJSON)
        }
      } catch (e) {
        throw new Error(e)
      }

      commit('setLoading', false, { root: true })
      // dispatch('fetchFindingsQuestions', d.data.findings)
      // dispatch('fetchFindingsRecordsQuestions', d.data.findings)
      if (state.examinationData && state.examinationData.findings && Array.isArray(state.examinationData.findings)) {
        for (let i = 0; i < state.examinationData.findings.length; i++) {
          commit('updateArticleStatus', {
            findingIndex: i,
            recordIndex: -1,
            value: [],
          })
          if (
            state.examinationData &&
            state.examinationData.findings &&
            state.examinationData.findings[i] &&
            state.examinationData.findings[i].records &&
            Array.isArray(state.examinationData.findings[i].records)
          ) {
            for (let i2 = 0; i2 < state.examinationData.findings[i].records.length; i2++) {
              commit('updateArticleStatus', {
                findingIndex: i,
                recordIndex: i2,
                value: false,
              })
            }
          }
        }
      }

      return d
    } catch (e) {
      throw new Error(e)
    }
  },

  async fetchOtherList({ commit }, payload) {
    const { data } = await dbServices.fetchExaminationDetailAndOtherList(payload)
    commit('setExaminationData', data)
  },

  async fetchExamAndOther ({ commit }, payload) {
    const d = await dbServices.fetchExaminationDetailAndOtherList(payload)
    commit('setOrderedGastricData', d.orderedGastricData)

    commit('setExaminationData', d.data)
    commit('setSedatives', d.data.sedatives)
    commit('setPatient', d.patient)
    commit('setDoctorList', d.doctorList)
    commit('setTechnicalStaffList', d.technicalStaffList)
    commit('setLandmarkList', d.landmarkList)
    commit('setExaminationTypeList', d.examinationTypeList)
    commit('setInspectionInstrumentList', d.inspectionInstrumentList)
    commit('setWashInstrumentList', d.washInstrumentList)
    commit('setCleanMedList', d.cleanMedList)
    commit('setSedativeList', d.sedativeList)
    commit('setAntiplateletList', d.antiplateletList)
    commit('setAntispasmodicList', d.antispasmodicList)
  },

  async fetchFindings({ commit }, payload) {
    try {
      commit('setLoading', true, { root: true })

      const res = await dbServices.fetchFindings(payload)
      // console.log('findings:', res.result)

      commit('setFindings', res.result)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async updateExaminationStatus({ commit }, payload) {
    // eslint-disable-line
    const res = await dbServices.updateExaminationStatus(payload) // eslint-disable-line
  },

  async updateExaminationColumn({ commit }, payload) {
    try {
      let res

      if (payload.column === 'reportStatus') {
        res = await dbServices.updateReportStatus(payload)
      } else {
        res = await dbServices.updateExaminationColumn(payload)
      }
      // const res = await dbServices.updateExaminationColumn(payload)
      commit('updateExaminationColumn', payload)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async addFinding({ dispatch, commit }, payload) {
    try {
      const res = await dbServices.addFinding(payload)
      if (!res.data) {
        throw new Error('Cannot add finding')
      }

      await dispatch('fetchQuestionsForNewFinding', res.data)
      commit('addFinding', res.data)
      for (let i = 0; i < state.examinationData.findings.length; i++) {
        commit('updateArticleStatus', {
          findingIndex: i,
          recordIndex: -1,
          value: [],
        })
        for (let i2 = 0; i2 < state.examinationData.findings[i].records.length; i2++) {
          commit('updateArticleStatus', {
            findingIndex: i,
            recordIndex: i2,
            value: false,
          })
        }
      }
    } catch (e) {
      console.log(e)
      throw new Error(e)
    }
  },

  async deleteFinding({ commit }, { findingID, findingIndex }) {
    try {
      const res = await dbServices.deleteFinding(findingID)
      if (res.result !== 'success') {
        throw new Error('Cannot delete finding')
      }
      // commit('setStoreHiddenParameter', {
      //   target: 'over',
      //   value: true,
      // })
      commit('setStoreHiddenParameter', {
        target: 'focus',
        value: '',
      })
      commit('deleteFinding', findingIndex)
    } catch (e) {
      console.log(e)
      throw new Error(e)
    }
  },

  async addExaminationSedative({ dispatch, commit }, { examinationID }) {
    try {
      const res = await dbServices.addExaminationSedative(examinationID)
      commit('addExaminationSedative', res)
    } catch (e) {
      throw new Error(e)
    }
  },
  async deleteExaminationSedative({ commit }, { examinationSedativeID }) {
    try {
      const res = await dbServices.deleteExaminationSedative(examinationSedativeID)

      // console.log('[delete]',res)
      if (res.result !== 'success') {
        throw new Error('Cannot delete sedative')
      }

      commit('deleteExaminationSedative', examinationSedativeID)
    } catch (e) {
      console.log(e)
    }
  },
  async updateExaminationSedativeColumn({ commit }, payload) {
    try {
      const res = await dbServices.updateExaminationSedativeColumn(payload)
      commit('updateExaminationSedativeColumn', payload)
    } catch (e) {
      throw new Error(e)
    }
  },

  async addExaminationLocation({ dispatch, commit }, { recordID, findingIndex }) {
    try {
      const res = await dbServices.addExaminationLocation(recordID)
      commit('addExaminationLocation', {
        data: res,
        recordID,
        findingIndex,
      })
    } catch (e) {
      throw new Error(e)
    }
  },

  async updateEditArticleStatus({ dispatch, commit }, { recordIndex, findingIndex, value }) {
    try {
      commit('updateArticleStatus', {
        findingIndex,
        recordIndex,
        value,
      })
    } catch (e) {
      throw new Error(e)
    }
  },

  async deleteExaminationLocation({ commit }, { locationID, recordIndex, findingIndex }) {
    try {
      const res = await dbServices.deleteExaminationLocation(locationID)

      if (res.result !== 'success') {
        throw new Error('Cannot delete location')
      }

      commit('deleteExaminationLocation', {
        locationID,
        recordIndex,
        findingIndex,
      })
    } catch (e) {
      throw new Error(e)
    }
  },
  async updateExaminationLocationColumn({ commit }, { findingIndex, recordIndex, $event, obj }) {
    if (typeof obj != 'undefined') {
      try {
        const res = await dbServices.updateExaminationLocationColumn(obj)

        if (res.result !== 'success') {
          throw new Error('Cannot update location')
        }

        commit('updateExaminationLocationColumn', {
          findingIndex,
          recordIndex,
          data: obj,
        })
      } catch (e) {
        throw new Error(e)
      }
    } else {
      try {
        if ($event.value == 0) {
          $event.value = null
        }
        const res = await dbServices.updateExaminationLocationColumn($event)

        if (res.result !== 'success') {
          throw new Error('Cannot update location')
        }

        commit('updateExaminationLocationColumn', {
          findingIndex,
          recordIndex,
          data: $event,
        })
      } catch (e) {
        throw new Error(e)
      }
    }
  },

  async fetchFindingsQuestions({ commit }, payload) {
    let resultArr = []

    for (const thisFinding of payload) {
      let qPromiseArr = []

      for (const question in thisFinding.questions) {
        const qID = thisFinding.questions[question].questionID
        qPromiseArr.push(dbServices.fetchQuestionJSON(qID))
      }

      const qResult = await Promise.all(qPromiseArr)
      resultArr.push(qResult)
    }

    commit('addQuestionsToList', resultArr)
  },

  async fetchQuestionJSON({ commit }, payload) {
    return dbServices.fetchQuestionJSON(payload)
  },

  async fetchFindingsRecordsQuestions({ commit }, payload) {
    let all = []

    for (const finding of payload) {
      if (!finding.records) {
        all.push([])
        continue
      }

      const level2 = await forms.fetchRecordsQuestionsJSON(finding.records)

      all.push(level2)
    }

    commit('addQuestionsToList', all)
  },

  async updateFindingQuestions({ commit, state }, payload) {
    try {
      let newValue = payload.value
      if (typeof newValue == 'string') {
        newValue = payload.value.replace(/\\'/gi, "'")
      }
      const res = await dbServices.updateQuestionInput(payload)

      if (res.result !== 'success') {
        throw new Error('Unable to update question input.')
      }

      //console.log(res)
      //console.log('##############')
      /*
      if (res.data) {
        console.log(res.data.records[0].questions)
      }
      
      console.log('##############')
      console.log(res.data.records[0].questions.interven)
      console.log('##############')
      console.log('value: ' + res.data.records[0].questions.interven.value)
      console.log('questionID: ' + res.data.records[0].questions.interven.questionID)
      console.log('part: ' + res.data.records[0].questions.interven.part)
      */

      if (res.changeForm === true) {
        // we have multiple records to update
        if (Array.isArray(res.data) && res.range === 'records') {
          //const test = common.compose(Array.prototype.map(item => forms.filterOutExistingQuestions(item.questions, state.questionsList)), common.flattenArray, Array.prototype.reduce((acc, curr) => Object.assign(acc, curr), {}))(res.data)

          const newQuestionsArr = res.data.map((item) =>
            forms.filterOutExistingQuestions(item.questions, state.questionsList),
          )
          const flatNewQuestionsArr = common.flattenArray(newQuestionsArr)
          const newQuestionsObj = flatNewQuestionsArr.reduce((acc, curr) => Object.assign(acc, curr), {})

          let newQuestionsJSON
          if (!common.isEmptyObject(newQuestionsObj)) {
            newQuestionsJSON = await forms.fetchQuestionsJSON(newQuestionsObj)
            commit('addQuestionsToList', newQuestionsJSON)
          }
        } else {
          const data = res.data

          if (data.questions) {
            const newQuestions = forms.filterOutExistingQuestions(data.questions, state.questionsList)

            let newQuestionsJSON
            if (!common.isEmptyObject(newQuestions)) {
              newQuestionsJSON = await forms.fetchQuestionsJSON(newQuestions)
              commit('addQuestionsToList', newQuestionsJSON)
            }
          }

          if (data.records && data.records.length > 0) {
            const newQuestionsArr = data.records.map((record) =>
              forms.filterOutExistingQuestions(record.questions, state.questionsList),
            )
            const flatNewQuestionsArr = common.flattenArray(newQuestionsArr)
            const newQuestionsObj = flatNewQuestionsArr.reduce((acc, curr) => Object.assign(acc, curr), {})

            let newQuestionsJSON
            if (!common.isEmptyObject(newQuestionsObj)) {
              newQuestionsJSON = await forms.fetchQuestionsJSON(newQuestionsObj)
              commit('addQuestionsToList', newQuestionsJSON)
            }
          }

          /*
          const obj = {
            findingIndex: payload.findingIndex,
            recordIndex: payload.recordIndex,
            field: payload.field,
            item: payload.item,
            newValue,
            data: res.data,
            range: res.range,
            // questionsJSON
          }

          commit('changeFindingForm', obj)
          */
        }

        const obj = {
          findingIndex: payload.findingIndex,
          recordIndex: payload.recordIndex,
          field: payload.field,
          newValue,
          data: res.data,
          range: res.range,
        }

        commit('changeFindingFormTest', obj)
      } else {
        // capture both null and undefined
        if (payload.recordIndex != null) {
          commit('updateFindingRecordsQuestions', {
            findingIndex: payload.findingIndex,
            recordIndex: payload.recordIndex,
            field: payload.field,
            newValue,
          })
        } else {
          commit('updateFindingQuestions', {
            findingIndex: payload.findingIndex,
            field: payload.field,
            item: payload.item,
            newValue,
          })
        }
      }
    } catch (e) {
      throw new Error(e)
    }
  },

  /*
  async updateRecordQuestions ({commit}, payload) {
    try {
      const newValue = payload.value
      const res = await dbServices.updateRecordInput(payload)

      if (res.result !== 'success') {
        throw Error
      }

      console.log(res)

      commit('updateRecordQuestions', {
        findingIndex: payload.findingIndex,
        recordIndex: payload.recordIndex,
        field: payload.field,
        newValue
      })

    } catch (e) {
      console.log(e)
    }
  },
  */

  /*
  async updateFindingRecordsQuestions ({dispatch, commit}, payload) {
    try {
      const newValue = payload.newValue
      const res = await dispatch('updateQuestionInput', payload)

      console.log('======', res)

      commit('updateFindingRecordsQuestions', {
        findingIndex: payload.findingIndex,
        recordIndex: payload.recordIndex,
        item: payload.item,
        field: payload.field,
        newValue
      })

    } catch (e) {
      throw Error(e)
    }
  },
  */

  async fetchQuestionsForNewFinding({ commit, state }, payload) {
    if (payload.questions) {
      const newQuestions = forms.filterOutExistingQuestions(payload.questions, state.questionsList)

      let newQuestionsJSON
      if (!common.isEmptyObject(newQuestions)) {
        newQuestionsJSON = await forms.fetchQuestionsJSON(newQuestions)
        commit('addQuestionsToList', newQuestionsJSON)
      }
    }

    if (payload.records && payload.records.length > 0) {
      const newQuestionsArr = payload.records.map((record) =>
        forms.filterOutExistingQuestions(record.questions, state.questionsList),
      )
      const flatNewQuestionsArr = common.flattenArray(newQuestionsArr)
      const newQuestionsObj = flatNewQuestionsArr.reduce((acc, curr) => Object.assign(acc, curr), {})

      let newQuestionsJSON
      if (!common.isEmptyObject(newQuestionsObj)) {
        newQuestionsJSON = await forms.fetchQuestionsJSON(newQuestionsObj)
        console.log('newQuestionsJSON', newQuestionsJSON)
        commit('addQuestionsToList', newQuestionsJSON)
      }
    }
  },

  /*************************************************************
   * Screenshot status update for the examinationData object,   *
   * we dispatch action from screenshots module for the actual  *
   * API call to server.                                        *
   *************************************************************/
  async updateScreenshotStatusInAll({ dispatch, commit }, payload) {
    const item = payload.item
    //const newValue = payload.newValue
    const imgID = payload.imgID
    const findingID = payload.findingID

    try {
      // if this screenshot is in the finding, the call to server should already been done
      // see toggleImgStatus() in the report type view.
      if (findingID === 0) {
        await dispatch(
          'screenshots/setScreenshotGroup',
          { id: imgID, shallPrint: true },
          {
            root: true,
          },
        )
      }

      commit('updateScreenshotStatusInAll', {
        item,
        imgID,
        newStatus: payload.newStatus,
      })
    } catch (e) {
      console.log(e)
    }
  },

  async updateScreenshotStatusInFinding({ dispatch, commit }, payload) {
    //const item = payload.item
    //const newValue = payload.newValue
    const imgID = payload.imgID

    try {
      await dispatch('screenshots/setScreenshotGroup', { id: imgID, shallPrint: true }, { root: true })
      commit('updateScreenshotStatusInFinding', payload)
    } catch (e) {
      console.log(e)
    }
  },

  /*************************************************************
   * Moving screenshots between all and findings:               *
   * (1) Move from all to finding/landmark                      *
   * (2) Remove from finding/landmark                           *
   * (3) Move between fidndin/landmark                          *
   *************************************************************/
  async moveImgFromAllToFinding({ dispatch, commit }, { imgID, newValue }) {
    const dispatchObj = {
      id: imgID,
      findingID: newValue,
    }

    try {
      await dispatch('screenshots/setScreenshotGroup', dispatchObj, {
        root: true,
      })
      commit('moveImgFromAllToFinding', { imgID, newValue })
    } catch (e) {
      throw new Error(e)
    }
  },

  async removeImgFromFinding({ dispatch, commit }, payload) {
    const dispatchObj = {
      id: payload.imgID,
      type: 'finding',
      value: 0,
    }

    try {
      // await dispatch('screenshots/setScreenshotGroup', dispatchObj, { root: true })
      commit('removeImgFromFinding', payload)
    } catch (e) {
      console.log(e)
    }
  },

  async moveImgBetweenFindings({ dispatch, commit }, payload) {
    const dispatchObj = {
      id: payload.imgID,
      findingID: newValue,
    }

    try {
      await dispatch('screenshots/setScreenshotGroup', dispatchObj, {
        root: true,
      })
      commit('moveImgBetweenFindings', payload)
    } catch (e) {
      console.log(e)
    }
  },
  async sortExamination({ commit }, payload) {
    commit('setLoading', true, { root: true })
    let title = payload.title
    let order = payload.order

    let data = []
    if (order) {
      data = state.examinationInfos.sort((a, b) => a[title].toString().localeCompare(b[title].toString()))
    } else {
      data = state.examinationInfos.sort((b, a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setExaminationInfos', data)
    commit('setLoading', false, { root: true })
  },

  async sortExaminationSearchResult({ commit }, payload) {
    commit('setLoading', true, { root: true })
    let title = payload.title
    let order = payload.order

    let data = []
    if (order) {
      data = state.examinationSearchResult.sort((a, b) => a[title].toString().localeCompare(b[title].toString()))
    } else {
      data = state.examinationSearchResult.sort((b, a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setExaminationSearchResult', data)
    commit('setLoading', false, { root: true })
  },

  async setExaminationFinishDialog({ commit }, payload) {
    if (state.examinationFinishDialog == true && payload == true) {
      commit('setExaminationFinishDialog', false)
    } else {
      commit('setExaminationFinishDialog', payload)
    }
  },

  resetStoreHiddenParameter({ commit }) {
    commit('resetStoreHiddenParameter')
  },

  resetStoreHiddenParameterExamination({ commit }) {
    commit('resetStoreHiddenParameterExamination')
  },

  resetQuestionsList({ commit }) {
    commit('resetQuestionsList')
  },

  async setStoreHiddenParameter({ commit }, payload) {
    clearTimeout(timeout)

    commit('setStoreHiddenParameter', payload)

    timeout = setTimeout(() => {
      commit('setStoreHiddenParameter', { target: 'focus', value: '' })
    }, 1000)
  },

  setRecording({ commit }, payload) {
    commit('setRecording', payload)
  },

  setJudging({ commit }, payload) {
    commit('setJudging', payload)
  },

  setTimeCounting({ commit }, payload) {
    commit('setTimeCounting', payload)
  },

  setTimeCountingStep({ commit }, payload) {
    commit('setTimeCountingStep', payload)
  },

  setTimeFinish({ commit }, payload) {
    commit('setTimeFinish', payload)
  },

  async imgTagChange({ dispatch, commit }, { imgID, tag, findingID }) {
    const dispatchObj = {
      id: imgID,
      tag,
    }
    try {
      await dispatch('screenshots/setScreenshotGroup', dispatchObj, {
        root: true,
      })
      commit('imgTagChange', { imgID, tag, findingID })
    } catch (e) {
      throw new Error(e)
    }
  },

  async imgLandmarkChange({ dispatch, commit }, { imgID, newValue, page, insertionTo }) {
    const dispatchObj = {
      id: imgID,
      landmarkID: newValue,
    }

    try {
      await dispatch('screenshots/setScreenshotGroup', dispatchObj, {
        root: true,
      })
      commit('imgLandmarkChange', { imgID, newValue, page })
    } catch (e) {
      throw new Error(e)
    }
  },

  async imgCmChange({ dispatch, commit }, { imgID, newValue, page }) {
    const dispatchObj = {
      id: imgID,
      type: 'cm',
      value: newValue,
    }

    try {
      await dispatch('screenshots/setScreenshotGroup', dispatchObj, {
        root: true,
      })
      commit('imgCmChange', { imgID, newValue, page })
    } catch (e) {
      throw new Error(e)
    }
  },

  addScreenshot({ commit }, payload) {
    commit('addScreenshot', payload)
  },

  async writeRecordDiagnosis({ commit }, { findingIndex, recordIndex, recordId }) {
    try {
      const res = await dbServices.writeRecordDiagnosis(recordId)
      commit('updateFindingRecordsQuestions', {
        findingIndex,
        recordIndex,
        field: 'diagnosis',
        newValue: res.diagnosis,
      })
      commit('updateFindingRecordsQuestions', {
        findingIndex,
        recordIndex,
        field: 'summary',
        newValue: res.summary,
      })
    } catch (e) {
      console.log(e)
    }
  },

  async fetchOrderArticle ({ commit }, { examinationID }) {
    try {
      const res = await dbServices.getOrderArticle(examinationID)
      if (res.findings) {
        commit('setExaminationOrderArticle', res.findings)
      } else {
        commit('setExaminationOrderArticle', [])
      }
    } catch (e) {
      // regarless
    }
  },
  async fetchDescriptiveParagraph ({ commit }, { eid, etype }) {
    try {
      const res = await dbServices.fetchDescriptiveParagraph(eid, etype)
      if (res.paragraph && typeof res.paragraph === 'object') {
        if (Array.isArray(res.paragraph)) {
          commit('setColonDescriptiveParagraph', res.paragraph)
        } else {
          commit('setGastroDescriptiveParagraph', res.paragraph)
        }
      }
    } catch (e) {
      // ruby regarless
    }
  },
  async updateDescriptiveParagraph ({ commit }, payload) {
    try {
      const res = await dbServices.updateDescriptiveParagraph(payload)
      if (res.paragraph) {
        if (typeof res.paragraph === 'string') {
          commit('setColonDescriptiveParagraph', res.paragraph)
        } else if (typeof res.paragraph === 'object') {
          commit('setGastroDescriptiveParagraph', res.paragraph)
        }
      }
    } catch (e) {
      // regarless
    }
  },
  async sendEmail ({ commit }, paragraphObj) {
    try {
      const result = await dbServices.sendEmail(paragraphObj.eid, paragraphObj.etype, paragraphObj.email)
      if (!result.ok) {
        throw new Error(`HTTP error! status: ${result.status}`);
      }

      const data = await result.json();  
      console.log('Mail sent success:', data);

      commit('setEmailStatus', { success: true, message: 'Email sent successfully!' });
    } catch (e) {
      // ruby regarless
    }
  },
  toggleWebrtcStatus({ commit }) {
    commit('toggleWebrtcStatus')
  },

  setCommandQueue({ commit }, command) {
    let commands = state.commandQueue.concat(command)
    if (state.commandQueue.length >= 3) {
      commands = commands.slice(1)
    }
    commit('setCommandQueue', commands)
  },

  cleanCommands({ commit }) {
    commit('setCommandQueue', [])
  },

  setCurrentCommand({ commit }, command) {
    commit('setCurrentCommand', command) // may not need anymore
  },

  popCommandQueue({ commit }) {
    // if (state.commandQueue.length === 0) return;
    commit('popCommandQueue')
  },
}

// mutations
const mutations = {
  resetExaminations(state) {
    state.examinations = []
  },
  resetOrderedGastricData (state) {
    state.orderedGastricData = []
  },
  setVoiceCommandTestKeyword(state, payload) {
    state.voiceCommandTestKeyword = payload
  },
  setExaminations(state, payload) {
    state.examinations = payload
  },
  setOrderedGastricData(state, payload) {
    state.orderedGastricData = payload
  },
  setExaminationOrderArticle (state, payload) {
    state.examinationOrderArticle = payload
  },
  setColonDescriptiveParagraph (state, payload) {
    state.colonDescriptiveParagraph = payload
  },
  setGastroDescriptiveParagraph (state, payload) {
    state.gastroDescriptiveParagraph = payload
  },
  setExaminationData(state, payload) {
    state.examinationData = payload
  },
  setSedatives(state, payload) {
    state.sedatives = payload
  },
  setDoctorList(state, payload) {
    state.doctorList = payload
  },
  setTechnicalStaffList(state, payload) {
    state.technicalStaffList = payload
  },
  setLandmarkList(state, payload) {
    state.landmarkList = payload
  },
  setExaminationTypeList(state, payload) {
    state.examinationTypeList = payload
  },
  setInspectionInstrumentList(state, payload) {
    state.inspectionInstrumentList = payload
  },
  setWashInstrumentList(state, payload) {
    state.washInstrumentList = payload
  },
  setCleanMedList(state, payload) {
    state.cleanMedList = payload
  },
  setSedativeList(state, payload) {
    state.sedativeList = payload
  },
  setAntiplateletList(state, payload) {
    state.antiplateletList = payload
  },
  setAntispasmodicList(state, payload) {
    state.antispasmodicList = payload
  },
  setPatient(state, payload) {
    state.patient = payload
  },
  updateArticleStatus(state, payload) {
    const findingIndex = payload.findingIndex,
      recordIndex = payload.recordIndex
    if (state.examinationData.findings.length > 0) {
      if (recordIndex == -1) {
        state.editArticleStatus[findingIndex] = []
      }
    }

    if (state.examinationData.findings[findingIndex].records.length > 0) {
      if (recordIndex >= 0 && payload.value != null) {
        state.editArticleStatus[findingIndex][recordIndex] = payload.value
      }
    }
  },
  updateExaminationData(state, payload) {
    let idx = state.examinations.findIndex((d) => d.id == payload.result[0].id)
    if (idx !== -1) {
      state.examinations[idx] = payload.result[0]
    }
  },

  updateExaminationColumn(state, { column, value }) {
    let update = {}
    update[column] = value
    // if (column == 'preparationLoca') {
    //     update[column] = [];
    //     let temp = value.split(',');
    //     for (let i = 0; i < temp.length; i++) {
    //         update[column].push(Number(temp[i]));
    //     }
    // }
    if (state.examinationData != null) {
      state.examinationData.examination = Object.assign(state.examinationData.examination, update)
    }
  },

  addFinding(state, payload) {
    const len = state.examinationData.findings.length
    state.examinationData.findings.splice(len, 1, payload)
  },

  deleteFinding(state, idx) {
    var findingID = state.examinationData.findings[idx].id
    state.examinationData.findings.splice(idx, 1)
    for (var i = 0; i < state.examinationData.all.length; i++) {
      if (state.examinationData.all[i].findingID == findingID) {
        state.examinationData.all[i].findingID = 0
      }
    }
  },
  // use questionsList as an object to keep the questions JSON we have been fetching
  addQuestionsToList(state, payload) {
    const flattened = common.flattenArray(payload)

    if (!state.questionsList) {
      state.questionsList = {}
    }

    flattened.map((question) => {
      const qID = question.questionID

      let obj = {}
      obj[qID] = question

      if (!state.questionsList[qID]) {
        state.questionsList = Object.assign(state.questionsList, obj)
      }
    })
  },

  /* update questions for whole finding section */
  changeFindingForm(state, payload) {
    const findingIndex = payload.findingIndex
    const recordIndex = payload.recordIndex
    const data = payload.data
    const range = payload.range
    const findingQuestionsJSON = payload.questionsJSON.finding
    const recordsQuestionsJSON = payload.questionsJSON.records

    if (range === 'finding') {
      // update findingsFormQuestions for this finding
      state.findingsFormQuestions.splice(findingIndex, 1, findingQuestionsJSON)
      // update findingsRecordsQuestions for this finding
      state.findingsRecordsQuestions.splice(findingIndex, 1, recordsQuestionsJSON)

      // update record questions
      for (const i in data.records) {
        state.examinationData.findings[findingIndex].records.splice(i, 1, data.records[i])
      }
      // update finding questions
      state.examinationData.findings[findingIndex].questions = Object.assign({}, data.questions)
    } else if (range === 'records') {
      // update findingsRecordsQuestions for this finding
      state.findingsRecordsQuestions.splice(findingIndex, 1, recordsQuestionsJSON)
      // update records for this finding
      state.examinationData.findings[findingIndex].records = []
      state.examinationData.findings[findingIndex].records = data

      // update field data
      const levelOne = ['finidng', 'number', 'takeNote']
      if (levelOne.indexOf(payload.field) >= 0) {
        let findingObj = state.examinationData.findings[findingIndex]
        findingObj.questions[payload.field].value = payload.newValue
        state.examinationData.findings.splice(findingIndex, 1, findingObj)
      }
    } else if (range === 'record') {
      // update record questions
      state.findingsRecordsQuestions[findingIndex][recordIndex] = []
      state.findingsRecordsQuestions[findingIndex][recordIndex] = recordsQuestionsJSON

      // update records for this finding
      state.examinationData.findings[findingIndex].records.splice(recordIndex, 1, data)
    }
  },

  changeFindingFormTest(state, { findingIndex, recordIndex, field, newValue, data, range }) {
    if (range === 'finding') {
      // update record questions
      state.examinationData.findings[findingIndex].records = []
      for (const record of data.records) {
        state.examinationData.findings[findingIndex].records.push(record)
      }
      // update finding questions
      state.examinationData.findings[findingIndex].questions = Object.assign({}, data.questions)
    } else if (range === 'records') {
      state.examinationData.findings[findingIndex].records = []
      if (Array.isArray(data)) {
        data.forEach((item) => state.examinationData.findings[findingIndex].records.push(item))
      } else {
        state.examinationData.findings[findingIndex].records.push(data)
      }
      state.examinationData.findings[findingIndex].questions[field].value = newValue
    } else if (range === 'record') {
      state.examinationData.findings[findingIndex].records.splice(recordIndex, 1, data)
    }
  },

  updateFindingQuestions(state, { findingIndex, field, newValue }) {
    let findingObj = state.examinationData.findings[findingIndex]
    if (findingObj.questions[field]) {
      findingObj.questions[field].value = newValue
    } else {
      findingObj[field] = newValue
    }
    state.examinationData.findings.splice(findingIndex, 1, findingObj)
  },

  updateFindingRecordsQuestions(state, { findingIndex, recordIndex, field, newValue }) {
    // diagnosis and summary lives one level up compared to other questions
    let recordObj = state.examinationData.findings[findingIndex].records[recordIndex]
    if (field === 'diagnosis' || field === 'summary') {
      recordObj[field] = newValue
    } else {
      recordObj.questions[field].value = newValue
    }

    state.examinationData.findings[findingIndex].records.splice(recordIndex, 1, recordObj)
  },

  /*
  updateRecordQuestions (state, { findingIndex, recordIndex, field, newValue }) {
    let recordObj = state.examinationData.findings[findingIndex].records[recordIndex]
    recordObj[field].value = newValue

    state.examinationData.findings[findingIndex].records.splice(recordIndex, 1, recordObj)
  },
  */

  setExaminationInfos(state, payload) {
    state.examinationInfos = payload
  },

  setExaminationFinishDialog(state, payload) {
    state.examinationFinishDialog = payload
  },

  resetStoreHiddenParameter(state) {
    state.storeHiddenParameter = { ...defaultStoreParams }
  },

  resetStoreHiddenParameterExamination(state) {
    state.storeHiddenParameter = { ...defaultStoreParams }
    state.storeHiddenParameter.over = true
  },

  resetQuestionsList(state) {
    state.questionsList = null
  },

  setStoreHiddenParameter(state, payload) {
    if (payload.target == 'focus' && payload.value == '') {
      state.storeHiddenParameter = {
        ...state.storeHiddenParameter,
        [payload.target]: payload.value,
      }
    } else if (payload.target == 'focus' && payload.part) {
      state.storeHiddenParameter = {
        ...state.storeHiddenParameter,
        [payload.target]: payload.value,
        nowQuestionPart: payload.part,
      }
    } else {
      state.storeHiddenParameter = {
        ...state.storeHiddenParameter,
        [payload.target]: payload.value,
      }
    }
  },

  updateScreenshotStatusInAll(state, { imgID, item, newStatus }) {
    const imgIndex = forms.findIndexByID(state.examinationData.all, imgID)

    let updateItem = item
    updateItem.status = newStatus

    state.examinationData.all.splice(imgIndex, 1, updateItem)
  },

  updateScreenshotStatusInFinding(state, { findingID, imgID, item, newStatus }) {
    const findingIndex = forms.findIndexByID(state.examinationData.findings, findingID)
    const imgIndex = forms.findIndexByID(state.examinationData.findings[findingIndex].images, imgID)

    let updateItem = item
    updateItem.status = newStatus

    state.examinationData.findings[findingIndex].images.splice(imgIndex, 1, updateItem)
  },

  /******************************************************************
   * Update examinationData to reflect the screenshot being deleted  *
   ******************************************************************/
  screenshotDeletedFromExamination(state, payload) {
    const imgIndexInAll = forms.findIndexByID(state.examinationData.all, payload)
    const item = state.examinationData.all[imgIndexInAll]

    // update the all images array
    state.examinationData.all.splice(imgIndexInAll, 1)

    // if images is in finding, we need to update the finding images array too
    if (item.findingID !== 0) {
      const findingID = item.findingID
      const findingIndex = forms.findIndexByID(state.examinationData.findings, findingID)
      const imageIndex = forms.findIndexByID(state.examinationData.findings[findingIndex].images, payload)
      state.examinationData.findings[findingIndex].images.splice(imageIndex, 1)
    }
  },

  /******************************************************************
   * Add Screenshot to finding                                       *
   ******************************************************************/
  moveImgFromAllToFinding(state, { imgID, newValue }) {
    const imgIndexInAll = forms.findIndexByID(state.examinationData.all, imgID)
    const toFindingIndex = forms.findIndexByID(state.examinationData.findings, newValue)

    let item = state.examinationData.all[imgIndexInAll]
    const currFindingID = item.findingID
    item.findingID = newValue
    // item.landmarkID = 0
    if (Number(newValue) != 0) {
      item.status = '1'
    }

    // update all images array
    state.examinationData.all.splice(imgIndexInAll, 1, item)

    if (Number(newValue) > 0) {
      // add this image to finding
      if (!state.examinationData.findings[toFindingIndex].images) {
        state.examinationData.findings[toFindingIndex] = Object.assign(state.examinationData.findings[toFindingIndex], {
          images: [],
        })
      }

      // if already in some finding, need to remove from that finding first
      if (currFindingID > 0) {
        const currFindingIndex = forms.findIndexByID(state.examinationData.findings, currFindingID)
        const imgIndexInFinding = forms.findIndexByID(state.examinationData.findings[currFindingIndex].images, imgID)
        state.examinationData.findings[currFindingIndex].images.splice(imgIndexInFinding, 1)
      }

      state.examinationData.findings[toFindingIndex].images.push(item)
    } else {
      // remove from finding, only exist in all images
      const currFindingIndex = forms.findIndexByID(state.examinationData.findings, currFindingID)
      const imgIndexInFinding = forms.findIndexByID(state.examinationData.findings[currFindingIndex].images, imgID)
      state.examinationData.findings[currFindingIndex].images.splice(imgIndexInFinding, 1)
    }
  },

  /******************************************************************
   * Remove screenshot from finding                                  *
   ******************************************************************/
  removeImgFromFinding(state, { findingIndex, imgIndex, imgID, item }) {
    const imgIndexInAll = forms.findIndexByID(state.examinationData.all, imgID)

    let updateItem = item
    updateItem.findingID = 0

    // remove image from finding.images array
    state.examinationData.findings[findingIndex].images.splice(imgIndex, 1)
    // then update findingID in all images
    state.examinationData.all.splice(imgIndexInAll, 1, updateItem)
  },

  /******************************************************************
   * Move Screenshot between findings                                *
   ******************************************************************/
  /*
  moveImgBetweenFindings (state, { fromFindingIndex, toFindingIndex, toFindingID, fromImgIndex, imgID, item }) {
    const imgIndexInAll = forms.findIndexByID(state.examinationData.all, imgID)

    let updateItem = item
    updateItem.findingID = toFindingID

    // remove image from original finding
    state.examinationData.findings[fromFindingIndex].images.splice(fromImgIndex, 1)
    // add image to the destination finding, with updated findingID
    state.examinationData.findings[toFindingIndex].images.push(updateItem)
    // update image in all images
    state.examinationData.all.splice(imgIndexInAll, 1, updateItem)
  },
  */

  imgLandmarkChange(state, { imgID, newValue, page }) {
    const imgIndexInAll = forms.findIndexByID(state.examinationData.all, imgID)

    let item = state.examinationData.all[imgIndexInAll]
    var originItemFindingID = item.findingID
    item.landmarkID = newValue
    // item.findingID = 0
    if (Number(newValue) != 0) {
      item.status = '1'
    }
    // update the all array
    state.examinationData.all.splice(imgIndexInAll, 1, item)

    if (originItemFindingID !== 0 && page == 'r') {
      const findingIndex = forms.findIndexByID(state.examinationData.findings, originItemFindingID)
      const findingImgIndex = forms.findIndexByID(state.examinationData.findings[findingIndex].images, item.id)
      // remove the images from finding
      // state.examinationData.findings[findingIndex].images.splice(findingImgIndex, 1)
      state.examinationData.findings[findingIndex].images[findingImgIndex].landmarkID = newValue
      state.examinationData.findings[findingIndex].images[findingImgIndex].status = item.status
    }
  },

  imgCmChange(state, { imgID, newValue, page }) {
    const imgIndexInAll = forms.findIndexByID(state.examinationData.all, imgID)

    let item = state.examinationData.all[imgIndexInAll]
    var originItemFindingID = item.findingID
    item.cm = newValue

    // update the all array
    state.examinationData.all.splice(imgIndexInAll, 1, item)

    if (originItemFindingID !== 0 && page == 'r') {
      const findingIndex = forms.findIndexByID(state.examinationData.findings, originItemFindingID)
      const findingImgIndex = forms.findIndexByID(state.examinationData.findings[findingIndex].images, item.id)
      // state.examinationData.findings[findingIndex].images.splice(findingImgIndex, 1)
      state.examinationData.findings[findingIndex].images[findingImgIndex].cm = newValue
    }
  },

  imgTagChange(state, { imgID, tag, findingID, page }) {
    const imgIndexInAll = forms.findIndexByID(state.examinationData.all, imgID)
    let item = state.examinationData.all[imgIndexInAll]
    item.tag = tag
    const originItemFindingID = item.findingID
    item.findingID = findingID
    state.examinationData.all.splice(imgIndexInAll, 1, item)

    // page 'r' == report, 'e' == examination
    if (originItemFindingID !== 0 && page == 'r') {
      const findingIndex = forms.findIndexByID(state.examinationData.findings, originItemFindingID)
      const findingImgIndex = forms.findIndexByID(
        state.examinationData.findings[parseInt(findingIndex)].images,
        item.id,
      )
      console.log(item, originItemFindingID, findingIndex, findingImgIndex)
      //if (findingImgIndex !== -1) {
      state.examinationData.findings[findingIndex].images[findingImgIndex].tag = tag
      state.examinationData.findings[findingIndex].images[findingImgIndex].findingID = findingID
      //}
    }
  },

  addExaminationSedative(state, payload) {
    state.examinationData.sedatives.push(payload)
  },

  deleteExaminationSedative(state, sedativeID) {
    const index = forms.findIndexByID(state.examinationData.sedatives, sedativeID)
    state.examinationData.sedatives.splice(index, 1)
  },

  updateExaminationSedativeColumn(state, { column, value, id }) {
    const index = forms.findIndexByID(state.examinationData.sedatives, id)
    let updateObj = []
    updateObj[column] = value

    state.examinationData.sedatives[index] = Object.assign(state.examinationData.sedatives[index], updateObj)
  },

  addExaminationLocation(state, { data, recordID, findingIndex }) {
    const recordIndex = forms.findIndexByID(state.examinationData.findings[findingIndex].records, recordID)
    state.examinationData.findings[findingIndex].records[recordIndex].questions.location.push(data)
  },

  deleteExaminationLocation(state, { locationID, recordIndex, findingIndex }) {
    const locationIndex = forms.findIndexByID(
      state.examinationData.findings[findingIndex].records[recordIndex].questions.location,
      locationID,
    )
    state.examinationData.findings[findingIndex].records[recordIndex].questions.location.splice(locationIndex, 1)
  },

  updateExaminationLocationColumn(state, { findingIndex, recordIndex, data }) {
    const id = data.id
    const column = data.column
    const value = data.value
    const locationIndex = forms.findIndexByID(
      state.examinationData.findings[findingIndex].records[recordIndex].questions.location,
      id,
    )

    let item = state.examinationData.findings[findingIndex].records[recordIndex].questions.location[locationIndex]
    item.questions[column].value = value

    state.examinationData.findings[findingIndex].records[recordIndex].questions.location[locationIndex] = Object.assign(
      {},
      item,
    )
  },

  addScreenshot(state, payload) {
    if (payload.timestamp == null) {
      state.examinationData.all.push(payload.data)
      console.log('addScreenshot #1: ' + payload.data.timestamp + ' ' + new Date().getTime().toString())
    } else {
      for (let i = 0; i < state.examinationData.all.length; i++) {
        if (state.examinationData.all[i].timestamp == payload.timestamp) {
          payload.data.file = null
          payload.data['data'] = state.examinationData.all[i].data
          state.examinationData.all.splice(i, 1, payload.data)
          console.log('addScreenshot #2: ' + payload.timestamp + ' ' + new Date().getTime().toString())
          i = state.examinationData.all.length
        }
      }
    }
  },

  setRecording(state, payload) {
    state.storeHiddenParameter.isRecording = payload
  },
  setJudging(state, payload) {
    state.storeHiddenParameter.isJudging = payload
  },
  setTimeCounting(state, payload) {
    state.storeHiddenParameter.isTimeCounting = payload
  },
  setTimeCountingStep(state, payload) {
    const prev = state.storeHiddenParameter.nowTimeCountingStep
    state.storeHiddenParameter.prevTimeCountingStep = prev === 0 ? payload : prev
    state.storeHiddenParameter.nowTimeCountingStep = payload
  },
  setTimeFinish(state, payload) {
    state.storeHiddenParameter.isTimeFinish = payload
  },
  toggleWebrtcStatus() {
    state.webrtcStatus = !state.webrtcStatus
  },
  setCommandQueue(state, commands) {
    state.commandQueue = commands
  },
  setCurrentCommand(state, command) {
    state.currentCommand = command
  },
  popCommandQueue() {
    state.commandQueue.splice(0, 1)
  },
  setExaminationSearchResult(state, payload) {
    state.examinationSearchResult = payload
  },
  setExaminationSearchInfo(state, payload) {
    state.examinationSearchInfo = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
