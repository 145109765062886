<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" max-width="500" persistent>
    <v-card>
      <v-card-title
        primary-title
        cyan
        class="title deep-orange--text text--darken-1 justify-center"
      >
        {{ $t('dialog.warn') }}
      </v-card-title>
      <v-card-text class="headline grey--text text--darken-2 text-xs-center">
        {{ msg }}
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn outline color="info" @click="cancel">{{ $t('common.cancel') }}</v-btn>
        <v-btn color="info" @click="confirm">{{ $t('common.yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Confirm',
  inheritAttrs: false,
  props: {
    msg: {
      type: String,
      default: '',
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.onConfirm()
      this.cancel()
    },
  },
}
</script>
