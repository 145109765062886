<template>
  <!-- Procedure Images -->
  <div :class="printBreakClass">
    <!-- 圖片示意 -->
    <h3 class="print-color subtitle-border mb-2">
      {{ $t('procedureImages') }}:
    </h3>
    <div v-if="pringImages.length">
      <div
        class="ml-2 break-inside-page"
        v-for="finding in pringImages"
        :key="finding.name"
        style="padding: 5px"
      >
        <FindingItemImages :finding="finding" :imgArrange="imgArrange" />
      </div>
    </div>
    <h3 v-else class="pl-3">
      <template v-if="$i18n.locale === 'en'">
        Negative finding in the observable segments :
        <p>{{ $t('common.nil') }}</p>
      </template>
      <template v-if="$i18n.locale === 'zh' || $i18n.locale === 'ja'">無</template>
    </h3>
  </div>
</template>

<script>
import FindingItemImages from './FindingItemImages.vue'
import { PRINT_STATUS_YES } from '@/utils/examinations.config.js'

export default {
  name: 'FindingItemImagesGroup',
  components: {
    FindingItemImages,
  },
  props: {
    findings: {
      type: Array,
      default: () => [],
    },
    additionImages: {
      type: Array,
      default: () => [],
    },
    imgArrange: Number,
    printBreakClass: {
      type: String,
      default: 'break-inside',
    },
  },
  computed: {
    findingsImages() {
      const resultFn = this.generateValidFindingsImages((item, idx) => ({
        ...item,
        name: `${this.$t('finding')}${idx + 1}`,
        images:
          item.images?.filter((img) => img.status == PRINT_STATUS_YES) || [],
      }))
      const targetFinding = this.findings.filter(
        (f) => f.images && f.images.length,
      )
      return targetFinding ? resultFn(targetFinding) : []
    },
    otherImages() {
      return this.additionImages.length
        ? [
            {
              name: `${this.$t('additionalImage')}`,
              images: this.additionImages,
            },
          ]
        : []
    },
    pringImages() {
      return [...this.findingsImages, ...this.otherImages]
    },
  },
  methods: {
    generateValidFindingsImages(fn) {
      return (arr) => {
        return Array.isArray(arr) ? arr.map(fn) : []
      }
    },
  },
}
</script>
