export const coordinateOnSketch = {
  "Colonscopy": {
    "terminal ileum": { x: 81, y: 107 },
    "ileocecal valve": { x: 52, y: 99 },
    "appendix orifice": { x: 65, y: 144 },
    "cecum": { x: 49, y: 121 },
    "ascending colon": { x: 45, y: 84 },
    "hepatic flexure": { x: 40, y: 45 },
    "transverse colon": { x: 87, y: 53 },
    "splenic flexure": { x: 150, y: 21 },
    "descending colon": { x: 151, y: 74 },
    "sigmoid colon": { x: 127, y: 131 },
    "rs junction": { x: 149, y: 123 },
    "rectum": { x: 97, y: 148 },
    "anus": { x: 99, y: 179 },
  }
}