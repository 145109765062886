<template>
  <div class="wrapper">
    <finding-hint-popup
      :isShownFindingHint="isShownFindingHint"
      :examinationType="examinationType"
    ></finding-hint-popup>
    <v-tabs
      height:80px
      v-model="getStoreHiddenParameter.nowFindingIndex"
      class="mt-2"
      :class="[examinationType == 1 ? 'elevation-1' : 'elevation-2', {'no-image-list': closeImageList}]"
    >
      <v-tab
        :class="
          getStoreHiddenParameter.nowFindingIndex + 1 == n
            ? 'findingTabActive'
            : 'findingTabDeactivate'
        "
        v-for="n in getFindingsLength"
        :key="n"
        ripple
        @click="openRecordIndex(0, n)"
      >
        <div class="d-flex d-md-block align-center">
          <h1 class="fsz-10 fsz-md-12">{{ n }}</h1>
          <h2 class="fsz-14 fsz-md-18">{{ getFindingName( n ) }}</h2>
        </div>
      </v-tab>
      <v-spacer></v-spacer>
      <div class="v-tabs__div">
        <icon-button-with-tooltip
          className="add-finding-btn"
          color="grey darken-1"
          flat
          small
          iconName="add"
          tooltipText="Add new finding"
          @click="addFinding()"
        ></icon-button-with-tooltip>
        <icon-button-with-tooltip
          v-if="getFindings && getFindings.length <= 0"
          color="grey darken-1"
          flat
          small
          iconName="keyboard_voice"
          tooltipText="show hint"
          @click="showHint()"
        ></icon-button-with-tooltip>
      </div>
      <v-tab-item
        v-for="(finding, findingIndex) in getFindings"
        :key="findingIndex"
        :class="[getStoreHiddenParameter.over ? 'disabled' : '']"
      >
        <confirm-dialog-small
          :showDialog="deleteDialog"
          title="刪除"
          :message="deleteMessage"
          :onConfirm="onDeleteConfirm.bind(this)"
          :onCancel="onDeleteCancel.bind(this)"
        ></confirm-dialog-small>

        <examination-findings-form-level-one
          :finding="finding"
          :findingIndex="findingIndex"
          :questions="getQuestionsList"
          :hiddenParams="getStoreHiddenParameter"
          isExamination
          fieldIsRequired
          @change="
            saveQuestionInput({ findingIndex: findingIndex, event: $event })
          "
          class="pa-3"
        >
        </examination-findings-form-level-one>

        <v-tabs :value="getNowRecordIndex">
          <v-tab
            v-for="(record, recordIndex) in finding.records"
            :key="recordIndex"
            @click="openRecordIndex(recordIndex)"
          >
            <div v-if="isMostSignificant(finding)">The most significant</div>
            <div v-else-if="isOverall(finding)">Overall</div>
            <div v-else>
              Lesion
              <span v-if="finding.records.length > 1">{{
                recordIndex + 1
              }}</span>
            </div>
          </v-tab>
          <v-tab-item
            class="wavemed-horizontal-stepper-container px-2"
            v-for="(record, recordIndex) in finding.records"
            :key="recordIndex"
          >
            <examination-findings-form-level-two
              :findingIndex="findingIndex"
              :recordIndex="recordIndex"
              :record="record"
              :examination_typeID="
                getExaminationData.examination.examination_typeID
              "
              :questions="getQuestionsList"
              :hiddenParams="getStoreHiddenParameter"
              hideLocationImage
              isExamination
              @change="
                saveQuestionInput({
                  findingIndex: findingIndex,
                  recordIndex: recordIndex,
                  event: $event,
                })
              "
              @addLocation="
                addExaminationLocation({ recordID: record.id, findingIndex })
              "
              @deleteLocation="
                deleteExaminationLocation({
                  locationID: $event,
                  recordIndex,
                  findingIndex,
                })
              "
              @locationChange="
                updateExaminationLocationColumn({
                  findingIndex,
                  recordIndex,
                  $event,
                })
              "
            >
            </examination-findings-form-level-two>
          </v-tab-item>
        </v-tabs>

        <v-layout align-center justify-center class="pb-2">
          <v-btn flat outline color="red" @click.stop="onOpenDeleteDialog()">
            <v-icon>delete</v-icon>
            Delete
          </v-btn>
        </v-layout>
      </v-tab-item>
    </v-tabs>
    <div v-if="getFindings && getFindings.length <= 0" class="no-data">
      <p>No Finding</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import IconButtonWithTooltip from "@/components/IconButtonWithTooltip.vue";
import ExaminationFindingsFormLevelOne from "@/components/examinations/ExaminationFindingsFormLevelOne.vue";
import ExaminationFindingsFormLevelTwo from "@/components/examinations/ExaminationFindingsFormLevelTwo.vue";
import ConfirmDialogSmall from "@/components/ConfirmDialogSmall.vue";
import FindingHintPopup from "@/components/examinations/FindingHintPopup.vue";

export default {
  name: "ExaminationFinding",

  components: {
    IconButtonWithTooltip,
    ExaminationFindingsFormLevelOne,
    ExaminationFindingsFormLevelTwo,
    ConfirmDialogSmall,
    FindingHintPopup,
  },

  prop() {},

  data() {
    return {
      deleteDialog: false,
      deleteMessage: "",
      isShownFindingHint: false,
    };
  },

  computed: {
    ...mapGetters(['getSetting']),
    ...mapGetters("examinations", [
      "getDoctorList",
      "getTechnicalStaffList",
      "getExaminationData",
      "getLandmarkList",
      "getExaminationTypeList",
      "getPatient",
      "getQuestionsList",
      "getExaminationFinishDialog",
      "getStoreHiddenParameter",
    ]),

    ...mapGetters(["getLoading"]),

    getExaminationInfo() {
      return this.getExaminationData.examination;
    },

    getFindings() {
      return this.getExaminationData ? this.getExaminationData.findings : null;
    },

    getFindingsLength() {
      return this.getFindings ? this.getFindings.length : 0;
    },

    getNowRecordIndex() {
      return this.getStoreHiddenParameter.nowRecordIndex;
    },

    examinationID() {
      if (Number(this.$route.params.examinationID) != 0) {
        return this.$route.params.examinationID;
      } else {
        if (this.getExaminationData != null) {
          return this.getExaminationData.examination.id;
        }
      }
    },
    accNo() {
      return Number(this.$route.params.accNo) != 0
        ? this.$route.params.accNo
        : 0;
    },

    examinationFinishDialog() {
      return this.getExaminationFinishDialog;
    },
    isRecording() {
      return this.getStoreHiddenParameter.isRecording;
    },
    isTimeCounting() {
      return this.getStoreHiddenParameter.isTimeCounting;
    },
    isTimeFinish() {
      return this.getStoreHiddenParameter.isTimeFinish;
    },
    examinationType() {
      // 1 => Gastroscopy, 2=> Colonoscopy, 3 => Sigmoidoscopy
      return this.getExaminationData.examination.examination_typeID;
    },
    closeImageList() {
      return !this.getSetting.screenshot
    },
  },

  methods: {
      ...mapActions('examinations', [
        'updateExaminationColumn',
        'addExaminationSedative',
        'deleteExaminationSedative',
        'addExaminationLocation',
        'deleteExaminationLocation',
        'updateExaminationLocationColumn',
        'setExaminationFinishDialog',
        'setStoreHiddenParameter',
        'setRecording',
        'setTimeCounting',
        'setTimeFinish'
    ]),

    ...mapActions('screenshots', [
        'setScreenshotGroup',
        'setScreenshotStatus',
        'deleteScreenshot'
    ]),

    getFindingName(n) {
        const user = sessionStorage.getItem("user")
        const userObject = JSON.parse(user);
        const username = userObject.username;
        let arr = []
        let findingName = []
        if (username === "demo") {
          if (this.getQuestionsList == null) {
          return
        } else if (this.getQuestionsList[215]) {
          for (let i in this.getFindings) {
            if(this.getFindings[i] && this.getFindings[i].questions && this.getFindings[i].questions.finding && this.getFindings[i].questions.finding.value){
              arr.push(this.getFindings[i].questions.finding.value)
            }
          }
          // find the gastro          
          findingName = this.getQuestionsList[215].options[arr[n-1]]
        } else if (this.getQuestionsList[216]) {
          for (let i in this.getFindings) {
            if(this.getFindings[i] && this.getFindings[i].questions && this.getFindings[i].questions.finding && this.getFindings[i].questions.finding.value){
              arr.push(this.getFindings[i].questions.finding.value)
            }
          }
          findingName = this.getQuestionsList[216].options[arr[n - 1]];
        }
        }
 
        if (this.getQuestionsList == null) {
          return
        } else if (this.getQuestionsList[19]) {
          for (let i in this.getFindings) {
            if(this.getFindings[i] && this.getFindings[i].questions && this.getFindings[i].questions.finding && this.getFindings[i].questions.finding.value){
              arr.push(this.getFindings[i].questions.finding.value)
            }
          }
          // find the gastro          
          findingName = this.getQuestionsList[19].options[arr[n-1]]
        } else if (this.getQuestionsList[20]) {
          for (let i in this.getFindings) {
            if(this.getFindings[i] && this.getFindings[i].questions && this.getFindings[i].questions.finding && this.getFindings[i].questions.finding.value){
              arr.push(this.getFindings[i].questions.finding.value)
            }
          }
          findingName = this.getQuestionsList[20].options[arr[n - 1]];
        }
        // find the gastro
      return findingName;
    },

    isMostSignificant(finding) {
      return (
        finding.records.length > 0 &&
        finding.questions.takeNote &&
        Number(finding.questions.takeNote.value) == 499
      );
    },

    isOverall(finding) {
      return (
        finding.records.length > 0 &&
        finding.questions.takeNote &&
        Number(finding.questions.takeNote.value) == 501
      );
    },

    isEmptyStr(str) {
      return str && str.trim().replace(" ", "") === "" ? true : false;
    },

    setExaminationColumn(column, value) {
      let d = {
        id: this.examinationID,
        column: column,
        value: value,
      };
      this.updateExaminationColumn(d);
    },

    setExaminationStatus() {
      this.setExaminationColumn("status", 2);
      this.$router.push({
        path: `/home`,
      });
    },

    addFinding() {
      const examinationID = this.getExaminationData.examination.id;
      this.$store
        .dispatch("examinations/addFinding", examinationID)
        .then(() => {
          this.setStoreHiddenParameter({
            target: "nowFindingIndex",
            value: this.getExaminationData.findings.length - 1,
          });
          this.setStoreHiddenParameter({
            target: "nowQuestionPart",
            value: "d",
          });
          this.setStoreHiddenParameter({
            target: "focus",
            value: "finding",
          });
          this.setStoreHiddenParameter({
            target: "over",
            value: false,
          });
        });
    },

    deleteFinding(n) {
      const findingID = this.getFindings[n].id;

      this.$store.dispatch("examinations/deleteFinding", {
        findingID,
        findingIndex: n,
      });
    },

    saveQuestionInput(obj) {
      const findingIndex = obj.findingIndex;
      const recordIndex = obj.recordIndex;
      const field = obj.event.field;
      const value = obj.event.newValue;
      const item = obj.event.item;

      let d = {
        examination_typeID: this.getExaminationInfo.examination_typeID,
        findingIndex,
        recordIndex,
        findingID: this.getFindings[findingIndex].id,
        field,
        value,
        item,
      };

      if (typeof d.value == "string") {
        d.value = d.value.replace(/\'/gi, "\\'");
      }

      if (recordIndex !== undefined) {
        d.recordID = this.getFindings[findingIndex].records[recordIndex].id;
      }

      if (field == "takeNote") {
        if (value == "499" || value == "501") {
          this.setStoreHiddenParameter({
            target: "nowRecordIndex",
            value: 0,
          });
        }
      }
      if (field == "number") {
        if (!isNaN(value) || value == "several" || value == "multiple") {
          this.setStoreHiddenParameter({
            target: "nowRecordIndex",
            value: 0,
          });
          this.$store.dispatch("examinations/updateFindingQuestions", d);
        } else {
          d.value = 1;
          this.$store.dispatch("examinations/updateFindingQuestions", d);
        }
      } else {
        let maneuver = ["coldSnare", "hotSnare", "snare"];
        if (
          d.field &&
          maneuver.indexOf(d.field) > -1 &&
          this.getFindings[findingIndex] &&
          this.getFindings[findingIndex].records &&
          this.getFindings[findingIndex].records[recordIndex]
        ) {
          let record = this.getFindings[findingIndex].records[recordIndex];
          for (let i = 0; i < maneuver.length; i++) {
            if (
              record.questions[maneuver[i]] &&
              record.questions[maneuver[i]].value &&
              record.questions[maneuver[i]].value[0]
            ) {
              console.log("which one not null? ", maneuver[i]);
              console.log(
                "value is ? ",
                record.questions[maneuver[i]].value[0]
              );
              let o = {
                examination_typeID: this.getExaminationInfo.examination_typeID,
                findingIndex,
                recordIndex,
                findingID: this.getFindings[findingIndex].id,
                field: record.questions[maneuver[i]].config.field,
                recordID:
                  this.getFindings[findingIndex].records[recordIndex].id,
                value: [null],
                item,
              };
              this.$store.dispatch("examinations/updateFindingQuestions", o);
            }
          }
        }
        this.$store.dispatch("examinations/updateFindingQuestions", d);
      }
    },

    handleExaminationChange({ column, field, event }) {
      if (typeof field != "undefined") {
        this.setExaminationColumn(field, event);
      } else {
        this.setExaminationColumn(column, event);
      }
    },

    openRecordIndex(recordIndex, findingIndex) {
      // BUG :: When double tap record index, it will jump to the next one
      if (recordIndex !== undefined) {
        this.setStoreHiddenParameter({
          target: "nowRecordIndex",
          value: recordIndex,
        });
      }
      if (findingIndex !== undefined) {
        if (findingIndex - 1 == this.getStoreHiddenParameter.nowFindingIndex) {
          findingIndex--;
        }
        this.setStoreHiddenParameter({
          target: "nowFindingIndex",
          value: findingIndex,
        });
      }
      this.setStoreHiddenParameter({
        target: "nowQuestionPart",
        value: "d",
      });

      this.removeDisable();
    },

    onOpenDeleteDialog() {
      this.deleteMessage = `刪除 Finding${
        this.getStoreHiddenParameter.nowFindingIndex + 1
      }, 原本的Finding編號，將會被取代，確定要刪除嗎？`;
      this.deleteDialog = true;
    },

    onDeleteCancel() {
      this.deleteDialog = false;
    },

    onDeleteConfirm() {
      this.deleteDialog = false;
      this.deleteFinding(this.getStoreHiddenParameter.nowFindingIndex);
    },

    removeDisable() {
      const isDisable = this.getStoreHiddenParameter.over;
      if (isDisable) {
        this.setStoreHiddenParameter({
          target: "over",
          value: false,
        });
      }
    },

    showHint() {
      this.isShownFindingHint = true;

      setTimeout(() => {
        this.isShownFindingHint = false;
      }, 5000);
    },
  },
  // End of method
};
</script>

<style lang="scss" scoped>
@import "@/styles/pages/page_examinationDetail.scss";

.wrapper {
  position: relative;
}
.disabled {
  filter: grayscale(1);
  pointer-events: none;
  opacity: 0.3;
}

.findingTabActive, .findingTabDeactivate {
  display: inline-table;
  justify-content: center;
  width: 100px;
  h1 {
    height: 20px;
    width: 20px;
    font-size: 12px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    margin: 3px auto;
    padding: 3px;
  }
  h2 {
    text-overflow: ellipsis;
    display: -webkit-box;
    width: 100px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    margin-bottom: 3px;
    -webkit-line-clamp: 1;
  }
}
.findingTabActive {
  h1 {
    color: white;
    background: #82b1ff;
  }
}
.findingTabDeactivate {
  h1 {
    color: white;
    background: #787878;
  }
}

@media (max-width: 1200px) {
  .findingTabActive, .findingTabDeactivate {
    h2 {
      height: auto;
      max-height: 20px;
      -webkit-line-clamp: 2;
    }
  }
 }


</style>