<template>
    <div v-if="showDialog" class="delete-dialog-overlay">
        <div class="delete-dialog">
            <h4> {{ title }} </h4>
            <p class="content"> {{ message }}</p>
            <div class="btn-wrapper">
                <v-btn outline color="info" @click="onCancel()"> 取消 </v-btn>
                <v-btn color="info" @click="onConfirm()"> 是的 </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConfirmDialogSmall',

    props: {
        showDialog: {
            required: true,
            type: Boolean,
            default: false
        },
        title: {
            required: true,
            type: String,
        },
        message: {
            required: true,
            type: String,
        },
        onConfirm: {
            required: true,
            type: Function
        },
        onCancel: {
            required: true,
            type: Function
        }
    }
}
</script>