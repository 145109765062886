export const ja = {
  report: {
    Unedited: '未編集',
    Editing: '編集',
    Finalized: '完成',
    exam_info: '検査情報',
    findings: '所見',
    diagnosis: '診断',
    suggestion: 'アドバイス',
    confirm: {
      title: '削除',
      text: '所見（{0}）を削除していいですか？',
      delete_image: 'この画像を削除していいですか？',
    },
    reset_timer: 'タイマーをリセット',
    simple: 'ダイジェスト版',
    normal: '詳細版',
  },
  report_preview: {
    title: 'レポートプレビュー',
    ai: 'AIメディカルレポート',
    back_to: '編集に戻る',
    Gastroscopy: '上部消化管内視鏡報告',
    Colonoscopy: '大腸内視鏡レポート',
    name: '@:patient.name',
    gender: '性別',
    age: '@:patient.age',
    recordNo: '患者番号',
    examDate: '@:examination.date',
    examTime: '検査時間',
    examItem: '検査種別',
    GastroscopyCheckNo: '28016C',
    ColonoscopyCheckNo: '28017C',
    findings: '@:report.findings',
    report_date: '作成日',
    technician: '技師',
    print: '印刷',
    explanation: '結果説明',
    personal_info: '患者基本情報',
    diff: '檢查前後對比 (翻)',
    cleanse_off: '已清理乾淨，請您放心！ (翻)',
    deepest: '檢查最深處位置 (翻)',
    timer: {
      title: '内視鏡抜去時間',
      withdrawal: '抜去時間',
      proximal: '近端大腸退出時間 (翻)',
    },
  },
  organization: {
    title: '施設',
    number: 'Organization No',
    name: '氏名',
    phone: '電話番号',
    email: 'Email',
    connection: 'PACS連携',
    ae_title: 'Called_AE ',
    called_ip: 'Called_IP',
    called_port: 'Called_PORT',
    calling_ae: 'Calling_AE ',
    modality: 'モダリティ',
    record: 'リアルタイム収音',
  },
  doctor: {
    title: '医師',
  },
  examination: {
    title: '検査',
    number: '検査No.',
    patient: '@:patient.name',
    date: '検査日',
    doctor: '担当医',
    type: '検査種別',
    add: '新しい検査を追加',
  },
  inspection_instrument: {
    title: '検査機器',
  },
  wash_Instrument: {
    title: '洗浄機',
  },
  patient: {
    title: '患者',
    name: '患者氏名',
    id: '患者ID',
    dob: '生年月日',
    sex: '性別',
    age: '年齢',
  },
  nurse: {
    title: '看護師',
  },
  user: {
    title: 'ユーザー',
  },
  analytics: {
    title: '分析',
  },
  enviroment_test: {
    title: 'テスト環境',
  },
  common: {
    yes: 'はい',
    no: "いいえ",
    cancel: 'キャンセル',
    nil: "なし",
    summary: '概要',
    search: '検索',
    reset: 'リセット',
    add: '追加',
    other: 'その他',
    day: '日',
    ESOPHAGUS: '食道',
    STOMACH: '胃',
    DUODENUM: '十二指腸',
    NONSPECIFIC: '特になし',
    indication: '適応',
    preparation_agent: '前処置法',
    preparation_time: '腸管前処置',
    anti_platelet: '抗血栓薬',
    anti_platelet_stop: '休薬期間',
    cleanse_level: {
      before: '腸管洗浄度（検査前）',
      after: '腸管洗浄度（検査後）',
    },
    scope_id: 'スコープID',
    sedatives: '鎮痛剤・鎮静剤',
    washing_machine: '洗浄機',
    premedication: {
      title: '前投薬',
      '1': '緑内障',
      '2': '前立腺肥大症',
      '3': '心臓病・不整脈',
      '4': '薬物アレルギー',
    },
    antispasmodics: {
      on: '抗てんかん薬を使用',
      off: '抗てんかん薬を使用せず',
    },
    insertion_level: '伸入最深位置',
    insertion_length: '伸入最深長度',
    complication: '合併症',
    clo_test: 'CLO Test',
    additional_remarks: '備考',
    incomplete: {
      title: '困難要因',
      gastro: {
        '0': '',
        '1': 'intolerance',
        '2': '出血',
        '3': '食物残渣',
        '4': 'undigested medicine',
      },
      colon: {
        '0': '',
        '1': 'Poor Preparation',
        '2': '出血',
        '3': 'Bowel Perforation',
        '4': 'Patient Intolerance',
        '5': 'その他',
        '6': '食物残渣',
        '7': 'Undigested Medicine',
      }
    },
    procedure_note: '検査結果説明',
  },
  dialog: {
    warn: '確認',
  },
  login:{
    username: "ユーザーID",
    password: "パスワード",
    verificationcode: "認証コード",
    signin: "ログイン"
  },
  homepage:{
    from: "From（期間を指定）",
    to: "To（期間を指定）",
    examination_records: "検査No.",
    medical_records: "患者ID",
    search: "検索",
    today: "本日",
    sync_worklist: "リストを同期",
    examinations: "検査",
    date: "検査日",
    examination_record: "検査No.",
    examination_type: "検査種別",
    patient_name: "患者氏名",
    medical_record: "患者ID",
    status: "ステータス",
    no_data_available: "データなし",
    rows_per_page: "行数を変更",
    singout: "ログアウト",
    select_examination: "左から選択すると詳細が表示されます",
    finished: "検査完了",
    unfinished: "検査待ち",
    colonoscopy: "下部消化管内視鏡",
    gastoscopy: "上部消化管内視鏡",
    gast:{
      birth: "生年月日",
      age: "@:patient.age",
      gender: "性別",
      male: "男性",
      female: "女性",
      scope_id: "スコープID",
      washing_machine: "洗浄機",
      docter: "医師",
      nurse: "看護師",
      go_to_report: "レポートへ",
      start_examination: "検査を開始",
    },
    colon:{
      premedication: "@:common.premedication.title",
      preparation_agent: "前処置薬",
      preparationTime: "腸管前処置",
      antiplatelet: "抗血栓薬",
      complication: "@:common.complication",
      without_medication: "休薬期間",
      please_select: "選択",
    }
  },

  poweredByFirst: 'Powered By',
  poweredBySecond: 'KENKON EVAS',
  from: 'From',
  ward: 'Ward',
  applicationDate: 'Application Date',
  applicationTime: 'Application Time',
  applicationDr: 'Application DR',
  reportTime: 'Report Time',
  vs: 'VS.',
  examDr: 'EXAM. DR.',

  // ColonCloeanseForm
  informedConsent: 'Informed consent',
  insertionTime: 'Insertion Time',
  withdrawTime: 'Withdrawal Time',
  totalExamTime: 'Total Exam',
  proximalColonWithTime: 'Proximal Colon Withdrawal Time',

  // Colon finding
  finding: 'Finding',
  lesion: 'Lesion',
  procedureImages: 'Procedure Images',
  additionalImage: 'Additional Image',

  http: {
    error: {
      400: 'Bad Request',
      401: 'Unauthorized',
      403: 'Forbidden',
      404: 'Not Found',
      405: 'Method Not Allowed',
      406: 'Not Acceptable',
      408: 'Request Timeout',
      500: 'Internal Server Error',
      502: 'Bad Gateway',
      503: 'Service Unavailable',
      504: 'Gateway Timeout',
      505: 'HTTP Version Not Supported',
      999: 'JavaScript Error',
    },
  },
  i18n: {
    zh: '繁體中文',
    ja: '日本語',
    en: 'English',
  },
}
