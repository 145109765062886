<template>
  <div class="btn-wrapper fill-height">
    <v-container fluid fill-height>
      <v-layout align-start justify-center row style="display: inline-block; 
  width: 100%;">
        <div style="float: left;">
          <v-tabs fixed-tabs slider-color="#3b587d" color="transparent" class="pa-2">
          <v-tab  @click="setCategory('summary', $event)"><h2>{{ $t('common.summary') }}</h2></v-tab>
          <v-tab  v-if="isColonReport" @click="setCategory('quality', $event)"><h2>品質報告</h2></v-tab>
          <v-tab  @click="setCategory('en', $event)"><h2>English Report</h2></v-tab>
          </v-tabs>
        </div>
        <div>
        <div id="tool" style="float: right;">
          <div>        
            <div data-app>
              <v-menu v-if="choosType!= 'summary'" offset-y transition="slide-y-transition">
                <v-btn slot="activator" icon>
                  <div class="tool-btn">
                    <v-icon>{{imgArrangeSeleted}}</v-icon>
                    <span>圖片排列方式</span>
                  </div>  
                </v-btn>
                <v-list>
                  <v-list-tile
                    v-for="(item, index) in imgArrangeOptions"
                    :key="index"
                    @click="setImgArrange(index)"
                  >
                    <v-icon v-text="item.icon"></v-icon>
                    <v-list-tile-title>{{ item.label }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div>
            <div style="width:100%;height:100%;">
              <v-btn v-if="choosType!= 'summary'" @click="print()" flat icon color="dark" class="btn vertical">
                <div class="tool-btn">
                  <v-icon >print</v-icon>
                  <span>{{ $t('report_preview.print') }}</span>
                </div>
              </v-btn>
            </div>
          </div>
        </div>  
        </div>
    </v-layout>
  </v-container>
  </div>
</template>
<script>
export default {
  props: {
    isColonReport: Boolean,
  },
  data () {
			return {
        choosType: "summary",
        toggleSelect: false,
        selectedHeaders: [],
        value: "42",
        selectedValue: 3,
        imgArrangeSeleted: 'grid_on',
        imgArrangeOptions: [                
          { label: '3*n', value: 3 , icon: 'grid_on'},
          { label: '2*n', value: 2 , icon: 'grid_view'},
          { label: '1*n', value: 1 , icon: 'crop_square'}],
        hihi: '3*3',
        offset: true,
        segments: [
          {
            title: "Apple Music",
            id: "0",
            icon: 'grid_on'
          },
          {
            title: "Spotify",
            id: "1",
            icon: 'grid_view'
          },
          {
            title: "Deezer",
            id: "2",
            icon: 'crop_square'
          },
        ]
			}
    },
  methods: {
    print () {
      window.print();
    },
    setCategory(val) {
      this.$root.set_locale(val)
      this.choosType = val
      this.$emit("previewCategory",val);
    },
    setImgArrange(i){
      this.imgArrangeSeleted = this.imgArrangeOptions[i].icon
      this.$emit("getImgArrange",this.imgArrangeOptions[i].value);
    }
  },
}
</script>
<style lang="scss" scoped>
  .tool-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #tool {
    width:10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 2px;
  }

@media print {
  .btn-wrapper {
    display: none;
  }
  .v-image {
    -webkit-print-color-adjust: exact;
  }
  #print {
    position: absolute;
    top: 0;
    left:0;
  }
  .screenshot {
    width: 150px;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: text-top;
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
    img {
      page-break-before: auto;
      page-break-after: auto;
      page-break-inside: avoid;
      width: 100%;
      height: auto;
    }
  }
}

@page :first {
  margin-top: -1cm;
}

@page {
  size: A4 portrait;
  margin:  27mm 5mm 27mm 5mm; 
}

#print {
  .v-image {
    margin: 5px 5px 0 5px;
    &:first-child {
      margin-left: 0;
    }
  }
  .title {
    margin-bottom: 16px;
  }
  .examination-info-component, 
  .bowel-cleanse-forms {
    margin-top: 16px;
    padding: 0;
  }
  .label {
    font-weight: bold;
    color: $defaultGreyText;
  }
  p {
    margin: 0;
    display: block;
  }
  .sedative-list{
    width: 50%;
    display: inline-block;
    vertical-align: text-top;
    p {
      width: 100%;
    }
  }
  .row {
    margin: 16px 0;
  }
  h6 {
    color: $defaultGreyText;
  }
  h3 p, h4 p{ 
    font-weight: normal; 
    display: inline-block;
  }
  .landmark {
    background: grey;
    color: white;
    text-align: center;
    padding: 0;
    margin-top: -6px;
  }
  .location-warp {
    padding-top: 5px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    display: block;
    .black {
      width: 100%;
      height: 100%;
    }
  }
  .screenshot {
    width: 150px;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: text-top;
    img {
      width: 100%;
      height: auto;
    }
  }
}

</style>