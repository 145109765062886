<template>
  <div class="colon-finding-info">
    <div id="colon-finding-info" v-for="(finding, findingIndex) in getFindings"
      :key="finding.id">
      <v-layout row>
        <v-flex xs1>
          <h3>  {{ $t('finding') }} {{ findingIndex + 1 }} : </h3>
        </v-flex>   
        <v-flex xs10>
          <template>
            <font class="findingAndNumber" v-if="finding.questions.number">
              {{ finding.questions.number.value | word }}
            </font>
            <font class="findingAndNumber" v-if="getQuestionsList && getQuestionsList[finding.questions.finding.questionID] && finding.questions.finding.value != null && Number(finding.questions.finding.value) > 0">
              {{ getQuestionsList[finding.questions.finding.questionID].options[finding.questions.finding.value] }}{{ ([35,36,37].indexOf(Number(finding.questions.finding.value)) == -1) ? (finding.questions.number) ? (Number(finding.questions.number.value) > 1) ? 's' : '' : '' : '' }}
            </font>
          </template>
          <template v-for="(record, recordIndex) in finding.records">
            <h3 :key="record.id">
              <b>
                <span v-if="finding.records.length > 1 && finding.questions.number && finding.questions.takeNote"><!-- 有 number 有 takeNote -->
                  <font v-if="Number(finding.questions.takeNote.value) == 500">
                    {{ $t('lesion')}} {{ recordIndex + 1 }} :
                  </font>
                </span>
                <span v-else-if="finding.records.length > 1 && finding.questions.number"><!-- 有 number 無 takeNote -->
                  {{ $t('lesion')}} {{ recordIndex + 1 }} :
                </span>
                <span v-else></span><!-- 無 number -->
              </b>
              <font class="article" v-html="record.diagnosis">
                <span v-if="getIntervenOther(record).length > 0">
                  . others: {{ getIntervenOther(record) }}
                </span>
              </font>
            </h3>
          </template>
        </v-flex> 
      </v-layout>
    </div>
    <div v-for="(finding, findingIndex) in getFindings" :key="findingIndex" style="padding:5px;">
      <div v-if="(finding.images)">
        <h3> {{ $t('finding') }} {{ findingIndex + 1 }} :</h3>
        <div class="flex xs12 report-img-arrange">
          <img :class="setImgArrangeCss(imgArrange)"
              v-for="image in finding.images" v-if="image.status == 1" :key="image.id + findingIndex"
              :src="prefixServerImgPath(`${finding.examinationID}/${image.file}`)" />
        </div>
      </div> 
    </div>
    <div>
    <v-layout row v-if="calAdditionalImages() > 0 ">
      <v-flex xs2>
        <h3>Additional Image :</h3>
      </v-flex>
    </v-layout>
    </div>
    <div class="report-img-arrange">
      <div
        v-for="image in additionalImages()"
        :key="image.id + image.examinationID"
        v-if="image.status == 1"
        :class="setImgArrangeCss(imgArrange)"
      >
        <img :src="prefixServerImgPath(`${image.examinationID}/${image.file}`)" />
        <p class="landmark" v-if="image.landmarkID">{{ getLandMarkInfoFromID(image.landmarkID) }} </p>
      </div>
    </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import forms from '@/utils/forms'
const NUMBER_TO_WORD = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen', 'twenty', 'twenty-one', 'twenty-two', 'twenty-three', 'twenty-four', 'twenty-five', 'twenty-six', 'twenty-seven', 'twenty-eight', 'twenty-nine', 'thirty', 'thirty-one', 'thirty-two', 'thirty-three', 'thirty-four', 'thirty-five', 'thirty-six', 'thirty-seven', 'thirty-eight', 'thirty-nine', 'forty', 'forty-one', 'forty-two', 'forty-three', 'forty-four', 'forty-five', 'forty-six', 'forty-seven', 'forty-eight', 'forty-nine', 'fifty', 'fifty-one', 'fifty-two', 'fifty-three', 'fifty-four', 'fifty-five', 'fifty-six', 'fifty-seven', 'fifty-eight', 'fifty-nine', 'sixty', 'sixty-one', 'sixty-two', 'sixty-three', 'sixty-four', 'sixty-five', 'sixty-six', 'sixty-seven', 'sixty-eight', 'sixty-nine', 'seventy', 'seventy-one', 'seventy-two', 'seventy-three', 'seventy-four', 'seventy-five', 'seventy-six', 'seventy-seven', 'seventy-eight', 'seventy-nine', 'eighty', 'eighty-one', 'eighty-two', 'eighty-three', 'eighty-four', 'eighty-five', 'eighty-six', 'eighty-seven', 'eighty-eight', 'eighty-nine', 'ninety', 'ninety-one', 'ninety-two', 'ninety-three', 'ninety-four', 'ninety-five', 'ninety-six', 'ninety-seven', 'ninety-eight', 'ninety-nine', 'hundred']

export default {
  name: 'ColonFindingInfoGroup',
  props: {
    hasAdditionalImage: Boolean,
    imgArrange: Number
  },
  filters: {
    word(number_string) {
      const n = Number.parseInt(number_string, 10)
      if (Number.isNaN(n)) {
        return number_string
      }

      const word = NUMBER_TO_WORD[n - 1]
      return word.replace(word.substring(0, 1), word.substring(0, 1).toUpperCase())
    },
  },
  computed: {
    ...mapGetters('examinations', [
      'getExaminationData',
      'getLandmarkList',
      'getFindingsRecordsQuestions',
      'getQuestionsList'
    ]),
    getFindings () {
      if (this.getExaminationData) {
        var result = this.getExaminationData.findings;
        for (var i = 0; i < result.length; i++) {
          for (var i2 = 0; i2 < result[i].records.length; i2++) {
            // result[i].records[i2].diagnosis = (result[i].records[i2].diagnosis != null) ? result[i].records[i2].diagnosis.replace(/<i id="([0-9a-zA-Z_]*)" data-focus="([a-zA-Z]*)">/gi, "").replace(/<\/i>/gi, "").replace(/<font>/gi, "").replace(/<\/font>/gi, "").replace(/<br><br>/gi, " ") : result[i].records[i2].diagnosis;
            result[i].records[i2].diagnosis = (result[i].records[i2].diagnosis != null) ? result[i].records[i2].diagnosis.replace(/<i id="([0-9a-zA-Z_]*)" data-focus="([a-zA-Z]*)">/gi, "").replace(/<\/i>/gi, "").replace(/<font>/gi, "").replace(/<\/font>/gi, "").replace(/<br><br>/gi, "<br><span style=\"color: #2894FF;\">Intervention</span> : ") : result[i].records[i2].diagnosis;
          }
        }
        return result;
      } else {
        return null;
      }
      // return this.getExaminationData ? this.getExaminationData.findings : null
    }
  },
  methods: {
    prefixServerImgPath(path) {
      return `/api/upload/image/${path}`
    },
    additionalImages () {
      return this.getExaminationData.all.filter(item => item.findingID == 0 || item.landmarkID != 0)
    },
    getLandMarkInfoFromID(id) {
      return this.getLandmarkList.filter( item => item.id == id).length ? this.getLandmarkList.filter( item => item.id == id)[0].landmark : null
    },
    getLocationImage (question) {
      return forms.getLocationImage(question)
    },
    getLocations (finding) {
      let locationValues = []
      if(finding.records[0].questions.location[0].questions.loca){
        finding.records.map( record => {
          const localInfo = record.questions.location
          const ifLocalValueExist = localInfo && localInfo[0].questions.loca.value
          const ifValueAlreadyCaptured = locationValues.filter(item => item.loca.value === localInfo[0].questions.loca.value).length > 0
          if (ifLocalValueExist && !ifValueAlreadyCaptured) {          
            locationValues.push(localInfo[0].questions)

          }
        })
      }
      return locationValues
    },
    getIntervenOther(record) {
      return record.questions.intervenOther && record.questions.intervenOther.value ?  record.questions.intervenOther.value : ''
    },
    calAdditionalImages() {
      let count = 0;
      let nums = this.getExaminationData.all.filter(item => item.findingID == 0 || item.landmarkID != 0);
      for(let i = 0 ; i < nums.length ; i++){          
        if(nums[i].status == 1){
          count = count + 1;
        }        
      }
      return count ; 
    },
    setImgArrangeCss(n) {
      return `screenshot${n}`
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@/styles/components/reportImgArrange.scss';
  .article {
    font-style: normal;
    font-weight: normal;
  }
  .findingAndNumber {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
  .location-warp {
    background-position: center;
    background-size: contain;
    max-height: 150px; 
    min-height: 150px; 
  }
  @media print {
    #colon-info-group div {
      display: block;
      page-break-before: auto;
      page-break-after: auto;
      page-break-inside: avoid;
    }
    .location-warp {
      -webkit-print-color-adjust: exact;
    }
  }
</style>