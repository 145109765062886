<template>
	<v-btn 
		v-if="type === 'all'"
    absolute fab bottom right small 
    :class="imgSelectedToPrint ? 'white outline theme--light' : 'primary theme--dark'" 
    @click="$emit('click')"
  ><v-icon dark>attach_file</v-icon>
  </v-btn>

  <v-btn 
		v-else
    absolute fab bottom right small 
    :class="imgSelectedToPrint ? 'white outline theme--light' : 'primary theme--dark'" 
    @click="$emit('click')"
  >
    <v-icon dark>attach_file</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'AttachToPrintBtn',

    props: {
      data: Object,
      type: String,
    },

    data() {
      return {
      }
    },

    computed: {
			imgIndex () {
				return this.data.imgIndex
			},

			findingIndex () {
				return this.data.findingIndex || null
			},

			imgSelectedToPrint () {
				return this.data.item.status === '0' ? true : false
			}
    }
  }
</script>