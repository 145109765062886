<template>
  <v-menu offset-y left>
    <v-btn slot="activator" small icon class="icon-btn">
      <v-icon class="text-defaultGreyText fsz-14 fsz-md-18">{{ icon }}</v-icon>
    </v-btn>
    <v-list class="overflow-y-auto menu-height">
      <v-list-tile
        v-for="item in items"
        :key="item.id"
        @click="$emit('change', item.id)"
      >
        <v-list-tile-title
          class="d-flex align-center"
          :class="{
            'text-primary font-weight-bold': item.id === value,
          }"
        >
          <v-icon
            class="flex-grow-0"
            :color="item.id === value ? 'success' : '#ddd'"
            >check_circle</v-icon
          >
          <span class="pl-1">{{ item.label }}</span></v-list-tile-title
        >
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'IconDropdown',
  props: {
    icon: {
      type: String,
      default: 'information',
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: String,
  },
}
</script>

<style lang="scss">
.icon-btn {
  @media screen and (max-width:600px) {
    height: 14px   
  }
}
</style>