export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    values() {
      return this.items.map(e => e.value)
    },
    valuesWithoutAll() {
      return this.values.filter(v => v !== 'ALL')
    },
    checkedAll() {
      return (
        !!this.value.length &&
        this.valuesWithoutAll.every(v => this.value.includes(v))
      )
    },
  },
  watch: {
    checkedAll(v) {
      this.$emit('checked', v)
    },
  },
}
