<template>
  <div class="laid-out-select-input">
    <template v-for="{key, value, caption} in modifyOptions">
      <div :key="key" class="d-flex" :class="{'mb-2': caption}">
        <v-chip
          label
          :outline="!isSelected(key)"
          :dark="isSelected(key)"
          :color="isSelected(key) ? 'primary' : '#616161'"
          @click="$emit('change', key)"
          class="py-2"
        >{{ value }}</v-chip>
        <label v-if="caption" class="fsz-16 mt-1 ml-1">{{caption}}</label> 
      </div>
    </template>
  </div>
</template>

<script>
	export default {
		name: 'LaidOutSelectInput',

		props: {
      options: {
        required: true,
        type: Object
      },

      value: {
        required: false,
        type: [Number, String, Array]
      }
    },
    data () {
      return {
        prefixObj: {}
      }
    },
    methods: {
      isSelected (optionValue) {
        return Number(this.value) === Number(optionValue)
      }
    },
    computed: {
      modifyOptions () {
        return Object.entries(this.options).map(([key, value]) => {
          return {
            key,
            value,
            caption: this.prefixObj[value] || ''
          }
        })
      }
    }
	}
</script>

<style lang="scss">
  @import '@/styles/components/laidOutSelectInput.scss';
</style>