<template>
  <section class="pos-relative">
    <v-card class="pos-absolute" style="z-index: -1">
      <div
        id="monitor"
        class="monitor"
        :class="{ 'pos-absolute': !getWebrtcStatus }"
        style="z-index: -1"
      >
        <video ref="video" autoplay playsinline />
      </div>
    </v-card>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import IconDropdown from '../commons/IconDropdown.vue'

const JUDGING_TIMOUT = 3500

export default {
  name: 'AllForOne',
  props: {
    speechRecognition: {
      type: Function,
      required: true,
    },
    showDeviceHint: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    IconDropdown,
  },
  computed: {
    ...mapGetters(['getUser']),
    ...mapGetters('examinations', [
      'getExaminationData',
      'getVoiceCommandTestKeyword',
      'getStoreHiddenParameter',
      'getWebrtcStatus',
    ]),
    examination_typeID() {
      return this.getExaminationData.examination.examination_typeID
    },

    examinationID() {
      return this.getExaminationData.examination.id
    },

    accNo() {
      return this.getExaminationData.examination.accNo
    },
    video() {
      return this.$refs.video
    },
    canvas() {
      return this.$refs.canvas
    },
    showDeviceList() {
      return this.getUser != null && this.getUser.level == 9 ? 'block' : 'none'
    },
  },
  data() {
    return {
      audioContext: null,
      cameraStream: null,
      micStream: null,
      recorder: null,
      recording: false,
      startTimestamp: 0,
      endTimestamp: 0,
      keydownTime: 0,
      currentDevice: {
        audio: null,
        video: null,
      },
      audioId: '',
      videoId: '',
      audioDevices: [],
      videoDevices: [],
      intervalID: null,
    }
  },
  methods: {
    ...mapActions('examinations', [
      'setRecording',
      'setJudging',
      'setTimeCounting',
      'toggleWebrtcStatus',
      'popCommandQueue',
      'setCommandQueue',
      'cleanCommands',
    ]),
    // stream start
    async getUserMediaStream(device, deviceId) {
      const stream = await navigator.mediaDevices.getUserMedia({
        [device]: { deviceId },
      })
      return { stream }
    },
    async handleAudio(id) {
      try {
        const { stream } = await this.getUserMediaStream('audio', id)
        await this.handleMic(stream)

        const info = stream.getAudioTracks() && stream.getAudioTracks()[0]
        this.currentDevice[info.kind] = info.label
        this.audioId = id
      } catch (e) {
        this.handleError(e)
      }
    },
    async handleVideo(id) {
      try {
        const { stream } = await this.getUserMediaStream('video', id)
        await this.handleCamera(stream)
        const info = stream.getVideoTracks() && stream.getVideoTracks()[0]
        this.currentDevice[info.kind] = info.label
        this.videoId = id
      } catch (e) {
        this.handleError(e)
      }
    },
    async getBrowserMediaDevices() {
      try {
        const __filter = (fn, arr) => arr.filter(fn)
  
        const devices = await navigator.mediaDevices.enumerateDevices()
        const audioArr = __filter((d) => d.kind === 'audioinput', devices)
        // const videoArr = __filter((d) => d.kind === 'videoinput', devices)
  
        if (audioArr) {
          await this.handleAudio(audioArr[0].deviceId)
          this.audioDevices = audioArr
        }        
      } catch (error) {
        // ingore
      }

      // if (videoArr) {
      //   this.handleVideo(videoArr[0].deviceId)
      //   this.videoDevices = videoArr
      // }
    },
    handleMic(stream) {
      this.micStream = stream
      this.audioContext = new AudioContext({
        sampleRate: 16000,
      }) //44000 -> 16000
      this.audioContext.destination.channelCount = 1
      let input = this.audioContext.createMediaStreamSource(stream)

      // Firefox loses the audio input stream every five seconds
      // To fix added the input to window.source
      window.source = input

      // make the analyser available in window context
      window.userSpeechAnalyser = this.audioContext.createAnalyser()
      input.connect(window.userSpeechAnalyser)
      // window.userSpeechAnalyser.connect(this.audioContext.destination);

      // window.userSpeechAnalyser.fftSize = 2048;

      this.recorder = new Recorder(input)
      return Promise.resolve()
    },
    stopStreamTrack(stream) {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop())
      }
    },
    cleanStream() {
      this.stopStreamTrack(this.micStream)
    },
    async changeDeviceType(type, id) {
      if (type === 'audio') {
        this.handleAudio(id)
        this.stopStreamTrack(this.micStream)
      }
    },
    deviceNotify() {
      this.$notify({
        group: 'bottomCenter',
        text: '裝置切換',
      })
    },
    handleDeviceChange() {
      this.getBrowserMediaDevices()
    },
    handleError(error) {
      console.log('Error:' + error.name, error)
    },
    sendRecordedAudio() {
      const that = this

      this.recorder.export16kMono(function (blob) {
        if (that.$socket.readyState == 1) {
          that.$socket.send(blob)
          that.recorder.clear()
          that.recorder.record()
          // that.recorder.start()
        }
      }, 'audio/x-raw')
    },
    loopAction() {
      this.stopRecording(true)
      clearInterval(this.intervalID)
      this.startRecording()
    },
    startRecording() {
      if (this.recorder) {
        this.recorder.record()
        this.startTimestamp = this.examinationID + '-' + Date.now()
        this.sendLog('Bg Recording start...')
        this.intervalID = setInterval(this.loopAction, 1000 * 60 * 5)
        // this.$connect()
      }
    },
    stopRecording(upload) {
      if (this.recorder) {
        const that = this
        this.setRecording(false)
        setTimeout(() => this.setJudging(false), JUDGING_TIMOUT)
        this.recorder.stop()

        if (upload) {
          this.endTimestamp = this.examinationID + '-' + Date.now()
          this.recorder.exportWAV(function (blob) {
            var reader = new FileReader()
            reader.onload = function () {
              var rawData = reader.result
              that.speechRecognition(
                rawData,
                'websocket2',
                that.startTimestamp,
                that.endTimestamp
              )
            }
            reader.readAsDataURL(blob)
          })
        }

        this.recorder.clear()
      }
    },
    sendLog(text) {
      const timestamp = Date.now()
      const frontEndLog = `${text}: ${timestamp}`
      console.info(frontEndLog)
      this.$http.post(
        `/api/frontEndLog?t=${timestamp}`,
        JSON.stringify({ frontEndLog }),
        { headers: { 'Content-Type': 'application/json' } }
      )
    },

    async init() {
      try {
        await this.getBrowserMediaDevices()
        navigator.mediaDevices.addEventListener(
          'devicechange',
          this.handleDeviceChange
        )
        this.startRecording()
      } catch (e) {
        this.handleError(e)
      }
    },
  },
  async mounted() {
    await this.init()
  },
  beforeDestroy() {
    this.cleanCommands()
    this.cleanStream()

    navigator.mediaDevices.removeEventListener(
      'devicechange',
      this.handleDeviceChange
    )
    if (this.recorder) {
      this.stopRecording(true)
      clearInterval(this.intervalID)
    }
  },
  created() {
    const that = this
    this.$options.sockets.onmessage = function (msg) {
      var reader = new FileReader()
      reader.onload = function () {
        if (reader.result) {
          const body = JSON.parse(reader.result)
          if (body && Array.isArray(body.data) && body.data.length) {
            const [voice] = body.data
            that.setJudging(false)
            that.sendLog(`final # ${voice.text}`)
            that.popCommandQueue()
            that.setCommandQueue(voice.text)
          }
        }
      }
      reader.readAsText(msg.data)
    }
  },
}
</script>
