import {  ttsServices } from "@/services/db";
import common from '@/utils/common'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      audioList_ttsMixins: [],
    }
  },
  ...mapGetters(['getSetting']),
  methods: {
    async fetchAudio_ttsMixins(arr, deviceId) {
      try {
          const res = await ttsServices.getAudioFeedBack(
            arr,
            common.get_tts_url(
              this.getSetting.asr_domain,
              this.getSetting.tts_port,
              this.getSetting.tts_path,
            ),
          );
          if(!res) {
              return res
          }
          const audioURL = URL.createObjectURL(res)
          const audio = new Audio(audioURL);
          await audio.setSinkId(deviceId)
          return new Promise((resolve, reject) => {
              audio.addEventListener("loadedmetadata", () => {
                  resolve(audio);
              });
              audio.addEventListener("error", (error) => {
                  reject(error);
              });
          });
      } catch (e) {
          // Handle any errors
      }
    },
    playAudio_ttsMixins (audio, waitT) {
      return new Promise((resolve) => {
          setTimeout(() => {
              this.audioList_ttsMixins.push(audio)
              audio.play()
              resolve()
          }, waitT)
      })
    },
    stopAudio_ttsMixins () {
        this.audioList_ttsMixins.forEach(au => {
            au.pause()
        })
        this.audioList_ttsMixins = []
    },
  }
}
