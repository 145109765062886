<template>
  <div>
    <audio id="write" :src="require(`@/assets/write.mp3`)" crossorigin="anonymous"></audio>
    <audio id="saved" :src="require(`@/assets/saved.wav`)" crossorigin="anonymous"></audio>
    <audio id="success" :src="require(`@/assets/success.wav`)" crossorigin="anonymous"></audio>
    <audio id="evasgo" :src="require(`@/assets/evasgo_1.wav`)" crossorigin="anonymous"></audio>
    <audio
      id="insertion2cecum"
      :src="require(`@/assets/insertion2cecum_1.wav`)"
      crossorigin="anonymous"
    ></audio>
    <audio id="finish" :src="require(`@/assets/finish_1.wav`)" crossorigin="anonymous"></audio>
    <!-- guide voices -->
    <audio id="location" src="/guide/location.mp3" crossorigin="anonymous" />
    <audio id="distance" src="/guide/distance.mp3" crossorigin="anonymous" />
    <audio id="size" src="/guide/size.mp3" crossorigin="anonymous" />
    <audio id="intervention" src="/guide/intervention.mp3" crossorigin="anonymous" />
    <audio id="try-again" src="/guide/try-again.mp3" crossorigin="anonymous" />
  </div>
</template>

<script>
export default {
  name: 'ExamAudio',
  data() {
    return {
      audio: '',
    }
  },
  methods: {
    audioPlay(voiceinput) {
      this.audio = document.getElementById(voiceinput)
      if (this.audio) {
        return this.audio.play()
      }
    },
    audioPause() {
      if (this.audio) {
        this.audio.pause()
        this.audio.currentTime = 0
      }
    },
  },
}
</script>
