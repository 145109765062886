<template>
  <div>
    <div id="bowelCleanseForms" class="bowel-cleanse-forms print">
      <div v-if="isShow(getTextFromIDsAndGetters(removeOthersFromIndicationList(examination.examination.indication), 'getIndicationList'))">
        <h3>Indication :</h3>
        <p>
          {{ getTextFromIDsAndGetters(removeOthersFromIndicationList(examination.examination.indication), 'getIndicationList') }}
          <br
            v-if="
              removeOthersFromIndicationList(examination.examination.indication) &&
              removeOthersFromIndicationList(examination.examination.indication) > 0
            "
          />
          <span v-if="examination.examination.indicationOther">
            其他臨床目的施行之大腸鏡 :
            {{ examination.examination.indicationOther }}
          </span>
        </p>
      </div>
      <div
        v-if="
          isShow(getTextFromGetterAndKey([examination.examination.inspection_instrumentID], 'getInspectionInstrumentList', 'inspection_instrument'))
        "
        class="half"
      >
        <h3>Scope ID :</h3>
        <p>
          {{ getTextFromGetterAndKey([examination.examination.inspection_instrumentID], 'getInspectionInstrumentList', 'inspection_instrument') }}
        </p>
      </div>
      <div
        v-if="isShow(getTextFromGetterAndKey([examination.examination.wash_instrumentID], 'getWashInstrumentList', 'wash_instrument'))"
        class="half"
      >
        <h3>Washing machine :</h3>
        <p>
          {{ getTextFromGetterAndKey([examination.examination.wash_instrumentID], 'getWashInstrumentList', 'wash_instrument') }}
        </p>
      </div>
      <div v-if="isShow(getTextFromIDsAndGetters(examination.examination.complication, 'getComplicationList'))" class="half">
        <h3>Complication :</h3>
        <p>
          {{ getTextFromIDsAndGetters(examination.examination.complication, 'getComplicationList') }}
          <br v-if="examination.examination.complication && examination.examination.complication.length > 0" />
          <span v-if="examination.examination.complicationOther"> 其他 : {{ examination.examination.complicationOther }} </span>
        </p>
      </div>
      <div v-if="isShow(examination.sedatives)" class="half" style="display: flex">
        <h3 style="flex: 1">Sedatives :</h3>
        <div class="sedative-list" style="width: 63%">
          <table>
            <template v-for="(item, index) in examination.sedatives">
              <tr v-if="item.id !== 1" :key="index">
                <td style="padding-right: 10px">
                  {{ getSedativeNameFromID(item.sedativeID) }}
                </td>
                <td>{{ item.value }}mg</td>
              </tr>
            </template>
            <tr v-if="examination.examination.sedativeOther">
              <td>{{ $t('common.other') }} :</td>
              <td>{{ examination.examination.sedativeOther }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="half">
        <h3>{{ $t('common.premedication.title') }} :</h3>
        <p>
          {{ Number(examination.examination.antispasmodicHave) ? $t('common.antispasmodics.on') : $t('common.antispasmodics.off') }} <br />
          <span
            v-if="
              isShow(getTextFromGetterAndKey(examination.examination.antispasmodicID, 'getAntispasmodicList', 'antispasmodic')) &&
              isShow(getTextFromIDsAndGetters(examination.examination.antispasmodicReason, 'getNoUseAntipasmodicReasonList'))
            "
          >
            原因 :
            {{
              Number(examination.examination.antispasmodicHave)
                ? getTextFromGetterAndKey(examination.examination.antispasmodicID, 'getAntispasmodicList', 'antispasmodic')
                : getTextFromIDsAndGetters(examination.examination.antispasmodicReason, 'getNoUseAntipasmodicReasonList')
            }}
            <br v-if="examination.examination.antispasmodicReason.length > 0 || examination.examination.antispasmodicID.length > 0" />
          </span>
          <span v-if="examination.examination.antispasmodicOther"> 其他 : {{ examination.examination.antispasmodicOther }} </span>
        </p>
      </div>
      <div v-if="examination.examination.preparationTime" class="half">
        <h3>Preparation Time :</h3>
        <p>{{ examination.examination.preparationTime }}</p>
      </div>
      <div v-if="isShow(getTextFromGetterAndKey(examination.examination.clean_medID, 'getCleanMedList', 'clean_med'))" class="half">
        <h3>Preparation Agent :</h3>
        <p>
          {{ getTextFromGetterAndKey(examination.examination.clean_medID, 'getCleanMedList', 'clean_med') }}
        </p>
      </div>
      <div v-if="isShow(getTextFromIDsAndGetters([examination.examination.cleanScale], 'getCleanScaleList'))" class="half">
        <h3>Cleanse Level(before) :</h3>
        <p>
          {{ getTextFromIDsAndGetters([examination.examination.cleanScale], 'getCleanScaleList') }}
        </p>
      </div>
      <div v-if="isShow(getTextFromIDsAndGetters([examination.examination.cleanScaleAfter], 'getCleanScaleList'))" class="half">
        <h3>Cleanse Level(after) :</h3>
        <p>
          {{ getTextFromIDsAndGetters([examination.examination.cleanScaleAfter], 'getCleanScaleList') }}
        </p>
      </div>
      <div v-if="isShow(getTextFromIDsAndGetters([examination.examination.insertionLevel], 'getInsertionLevelList'))" class="half">
        <h3>Insertion Level :</h3>
        <p>
          {{ getTextFromIDsAndGetters([examination.examination.insertionLevel], 'getInsertionLevelList') }}
        </p>
      </div>
      <div v-if="examination.examination.insertionLength" class="half">
        <h3>Insertion Length :</h3>
        <p>{{ `${examination.examination.insertionLength} cm` || $t('common.nil') }} </p>
      </div>
      <div v-if="isShow(getTextFromIDsAndGetters([examination.examination.incompleteDue], 'getIncompleteReasonList'))" class="half">
        <h3>Incomplete study due to :</h3>
        <p>
          {{ getTextFromIDsAndGetters([examination.examination.incompleteDue], 'getIncompleteReasonList') }}
        </p>
      </div>
      <div v-if="isShow(getTextFromGetterAndKey(examination.examination.antiplateletID, 'getAntiplateletList', 'antiplatelet'))" class="half">
        <h3>Anti platelet :</h3>
        <p>
          {{ getTextFromGetterAndKey(examination.examination.antiplateletID, 'getAntiplateletList', 'antiplatelet') }}
          <br v-if="examination.examination.antiplateletID && examination.examination.antiplateletID.length > 0" />
          <span v-if="examination.examination.antiplateletOther"> 其他 : {{ examination.examination.antiplateletOther }} </span>
          <br v-if="examination.examination.antiplateletOther" />
          <span>
            抗凝血藥物停藥天數
            {{ examination.examination.antiplateletStopDays }} 天
          </span>
        </p>
      </div>
      <div v-if="examination.examination.additionalRemarks">
        <h3>Additional remarks :</h3>
        <p>{{ examination.examination.additionalRemarks }}</p>
      </div>
    </div>

    <!-- all time info -->
    <div style="padding: 12px 0px 12px">
      <hr v-if="is1hrShow" />
    </div>
    <time-info-group :data="examination.examination"></time-info-group>
    <hr />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TimeInfoGroup from '@/components/bowelCleanseForms/TimeInfoGroup.vue'

export default {
  name: 'ColonPrintBowelCleanseForms',

  components: {
    TimeInfoGroup,
  },

  props: {
    examination: {
      type: Object,
      required: true,
    },

    sedatives: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters('examinations', [
      'getIndicationList',
      'getComplicationList',
      'getCleanMedList',
      'getAntiplateletList',
      'getAntispasmodicList',
      'getNoUseAntipasmodicReasonList',
      'getInspectionInstrumentList',
      'getWashInstrumentList',
      'getCleanScaleList',
      'getInsertionLevelList',
      'getIncompleteReasonList',
    ]),
  },
  mounted() {
    this.is1hrShow = this.calcElementChild('bowelCleanseForms') === 0 ? false : true
  },
  data() {
    return {
      is1hrShow: true,
    }
  },
  methods: {
    getSedativeNameFromID(id) {
      return this.sedatives.filter((item) => item.id === id)[0].sedative
    },
    // generic list internally
    getTextFromIDsAndGetters(ids, getter) {
      let text = ''

      let dataGroup = this[getter]
      if (getter == 'getIncompleteReasonList') {
        dataGroup = dataGroup[2]
      }

      dataGroup.map((item, index) => {
        if (ids.indexOf(item.value) >= 0) {
          if (text.length <= 0) {
            text += item.text
          } else {
            text += ', ' + item.text
          }
        }
      })
      return text
    },
    getTextFromGetterAndKey(ids, getter, key) {
      let text = ''
      this[getter].map((item, index) => {
        if (ids.indexOf(item.id) >= 0) {
          if (text.length <= 0) {
            text += item[key]
          } else {
            text += ', ' + item[key]
          }
        }
      })
      return text
    },
    removeOthersFromIndicationList(list) {
      const othersValue = 7
      return list.filter((item) => item !== othersValue)
    },
    isShow(data) {
      let result = true
      if (data == 'Choose' || data == '' || (Array.isArray(data) && data.length === 0)) {
        result = false
      }
      return result
    },
    calcElementChild(elementId) {
      return window.document.getElementById(elementId).childElementCount
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/components/bowelCleanseForms.scss';
</style>
