<template>
  <v-layout :class="{ 'field--is-required': isRequired }">
    <v-text-field
      :value="data1"
      type="number"
      :min="float == true ? 0.1 : 0"
      :suffix="unit"
      :step="float == true ? 0.1 : 1"
      :label="label"
      hide-details
      @change="submitData($event)"
    />
    <!--<v-btn xs-1 icon
            @click="submitData (null, (float == true ? 0.1 : 1))"
        > <v-icon small>arrow_upward</v-icon> </v-btn>
        <v-btn xs-1 icon
            @click="submitData (null, (float == true ? -0.1 : -1))"
        > <v-icon small>arrow_downward</v-icon> </v-btn>-->
  </v-layout>
</template>
<script>
export default {
  name: "NumberselectInputTwo",
  props: {
    data1: {
      required: false,
      type: String,
    },
    float: {
      required: false,
      type: Boolean,
      default: false,
    },
    unit: {
      required: false,
      type: String,
      default: "",
    },
    examination_typeID: Number,
    label: String,
    isRequired: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submitData(changedValue, calcuNumber) {
      let newValue = null;
      let temp_data1 = this.data1;
      if (isNaN(Number(temp_data1))) {
        temp_data1 = 0;
      }
      if (changedValue !== null && changedValue >= 0) {
        newValue = Number(changedValue);
      } else if (calcuNumber < 0) {
        newValue =
          Number(temp_data1) + calcuNumber >= 0
            ? Number(temp_data1) + calcuNumber
            : 0;
      } else if (calcuNumber > 0) {
        newValue = Number(temp_data1) + calcuNumber;
      }
      this.$emit(
        "locationChange",
        this.float == true ? newValue.toFixed(1) : newValue
      );
    },
    getFontColor() {
      if (this.required) return "#ff5252";
    },
  },
};
</script>
<style lang="scss" scoped>
.layout {
    margin: 0;
}
div {
  padding: 0;
  margin: 0;
}
p {
  white-space: nowrap;
  margin: 0px;
  color: #616161;
  padding: 0px;
  height: 11px;
  font-size: 10px;
  position: static;
  float: left;
}
.v-input {
  max-width: 105px;
  font-size: 20px;
}
.v-btn {
  margin-top: 15px;
  margin-left: 3px;
  margin-right: 0;
  width: 15px;
}
</style>

<style lang="scss">
.field--is-required .v-text-field .v-label {
  color: #ff5252;
}
</style>
