<template>
  <v-select
    :label="label"
    :items="items[examinationTypeID]"
    :hide-details="shouldHideDetails"
    :value="data"
    @change="$emit('change', $event)"
  ></v-select>
</template>

<script>
export default {
    name: 'IncompleteDueToInput',

    props: {
        data: {
            type: [String, Number],
            required: false
        },
        examinationTypeID: {
            type: [Number],
            required: true
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            field: 'incompleteDue',
            label: 'Incomplete Study Due To'
        }
    },

    computed: {
        items() {
            return this.$store.getters['examinations/getIncompleteReasonList']
        },
        shouldHideDetails() {
            return this.hideDetails
        }
    }
}
</script>