<template>
  <v-layout row>
    <v-flex xs12>
      <v-layout row wrap align-center class="mb-1">
        <v-flex>
          <h3>
            <span class="pr-1">{{ finding.name }} </span>
            <template v-if="finding.lesions && finding.lesions.length < 2">
              <span class="font-weight-light"> {{ ro }}</span>
              <span class="font-weight-light"> {{ earlyAdv }}</span>
            </template>
          </h3>
          <!--ro /sadf -->
        </v-flex>
        <v-flex>
          <slot name="findingAction" />
        </v-flex>
      </v-layout>
      <ul class="pl-0">
        <li
          class="list-style-none mb-2"
          v-for="(lesion, idx) in finding.lesions"
          :key="lesion.id"
        >
          <template
            v-if="
              lesion.observation ||
              lesion.intervention ||
              lesion.ro ||
              lesion.earlyAdv
            "
          >
            <h4
              v-if="
                (finding.takeNote === 'each' && finding.lesions.length > 1) ||
                finding.takeNote !== 'each'
              "
            >
              {{
                finding.takeNote === 'each'
                  ? `Lesion ${idx + 1}:`
                  : `${
                      finding.takeNote.slice(0, 1).toUpperCase() +
                      finding.takeNote.slice(1)
                    }:`
              }}
            </h4>
          </template>

          <p class="mb-0">
            <template v-if="finding.lesions && finding.lesions.length > 1">
              <span class="font-weight-light">{{
                lesion.ro && `r/o:${lesion.ro}`
              }}</span>
              <span class="font-weight-light"> {{ lesion.earlyAdv }}</span>
            </template>
            <span v-if="lesion.observation">
              {{ lesion.observation }}
            </span>
          </p>
          <p v-if="lesion.intervention">
            <span style="color: #2894ff">Intervention</span>:
            {{ lesion.intervention }}
          </p>
        </li>
      </ul>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'FindingItemContent',
  props: {
    finding: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ro() {
      if (!this.finding) return
      const text = this.finding.lesions.map((lesion) => lesion.ro).join('')
      return text && `r/o:${text}`
    },
    // 目前只看到在胃鏡出現
    earlyAdv() {
      if (!this.finding) return
      return this.finding.lesions.map((lesion) => lesion.earlyAdv).join('')
    },
  },
}
</script>
