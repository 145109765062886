<template>
  <div>
    <p v-show="getFindings?.length == 0">Negative finding</p>

    <!-- Negative finding tab -->
    <v-card
      flat
      class="mt-2 pt-2 findings-container"
      v-show="getFindings?.length == 0"
    >
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 class="mb-2">
            <div class="findings-tab-title title font-weight-light">
              {{ negativeFinding }}
            </div>
          </v-flex>

          <div class="finding-bottom">
            <v-textarea
              label="Negative Suggestion"
              :value="getNegaSuggestion"
              @change="
                saveNegaSuggestion({
                  event: { field: 'negaSuggestion', newValue: $event },
                })
              "
              no-resize
              outline
              height="120px"
            ></v-textarea>
          </div>
        </v-layout>
      </v-card-text>
    </v-card>

    <!-- findings -->
    <v-card flat class="mt-2 pt-2">
      <v-card-text>
        <v-layout row wrap>
          <v-flex v-if="finding && getQuestionsList">
            <section class="pa-2 border-b border-b--dashed">
              <h3>Finding {{ findingIndex + 1 }}</h3>
              <examination-findings-form-level-one
                :finding="finding"
                :findingIndex="findingIndex"
                :questions="getQuestionsList"
                @change="
                  saveQuestionInput({
                    findingIndex: findingIndex,
                    event: $event,
                  })
                "
                class="pa-0 mb-4"
              />
            </section>

            <div class="wavemed-horizontal-stepper-container">
              <!-- 有多個 lesions -->
              <v-layout row align-items-start class="pa-2">
                <v-flex xs8>
                  <v-tabs v-model="getNowRecordIndex">
                    <v-tab
                      v-for="(record, recordIndex) in finding.records"
                      :key="recordIndex"
                      @click="getNowRecordIndex = recordIndex"
                    >
                      <div v-if="isMostSignificant(finding)">
                        The most significant
                      </div>
                      <div v-else-if="isOverall(finding)">Overall</div>
                      <div v-else>
                        Lesion
                        <span v-if="finding.records.length > 1">{{
                          recordIndex + 1
                        }}</span>
                      </div>
                    </v-tab>

                    <v-tab-item
                      class="wavemed-horizontal-stepper-container px-2"
                      v-for="(record, recordIndex) in finding.records"
                      :key="recordIndex"
                    >
                      <examination-findings-form-level-two
                        :findingIndex="findingIndex"
                        :recordIndex="recordIndex"
                        :record="record"
                        :examination_typeID="
                          getExaminationData.examination.examination_typeID
                        "
                        :questions="getQuestionsList"
                        :hiddenParams="getStoreHiddenParameter"
                        hideLocationImage
                        isExamination
                        @change="
                          saveQuestionInput({
                            findingIndex: findingIndex,
                            recordIndex: recordIndex,
                            event: $event,
                          })
                        "
                        @addLocation="
                          addExaminationLocation({
                            recordID: record.id,
                            findingIndex,
                          })
                        "
                        @deleteLocation="
                          deleteExaminationLocation({
                            locationID: $event,
                            recordIndex,
                            findingIndex,
                          })
                        "
                        @locationChange="
                          updateExaminationLocationColumn({
                            findingIndex,
                            recordIndex,
                            $event,
                          })
                        "
                      >
                      </examination-findings-form-level-two>
                    </v-tab-item>
                  </v-tabs>
                </v-flex>
                <v-flex xs4>
                  <div data-descript="產生文章">
                    <v-btn
                      color="info"
                      v-show="
                        !getEditArticleStatus[findingIndex][getNowRecordIndex]
                      "
                      :disabled="isFetchParagrah"
                      @click="
                        getSummaryAndDiagnosis(
                          findingIndex,
                          getNowRecordIndex,
                          finding.records[getNowRecordIndex]?.id,
                        )
                      "
                    >
                      自動產生文章
                    </v-btn>
                    <!-- 20230119 目前編輯的邏輯待討論之必要 -->
                    <!-- <v-btn
                      v-show="
                        !getEditArticleStatus[findingIndex][getNowRecordIndex]
                      "
                      color="info"
                      @click="editArticle(findingIndex, getNowRecordIndex)"
                    >
                      編輯文章
                    </v-btn> -->
                    <!-- <v-btn
                      v-show="
                        getEditArticleStatus[findingIndex][getNowRecordIndex]
                      "
                      color="info"
                      @click="saveArticle(findingIndex, getNowRecordIndex)"
                    >
                      儲存文章
                    </v-btn> -->
                  </div>

                  <section class="pa-2" v-if="getOrderArticle && findingContent">
                    <!-- article -->
                    <FindingItemContent
                      :hasActionTrigger="false"
                      :finding="findingContent"
                    />
                  </section>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* methods */
import { mapGetters, mapActions } from 'vuex'
import forms from '@/utils/forms'

/* components */
import { VTextField, VCheckbox, VSelect } from 'vuetify/lib'
import ReportFindingsFormLevelOne from '@/components/ReportFindingsFormLevelOne.vue'
import AttachToPrintBtn from '@/components/imgOverlay/attachToPrintBtn.vue'
// import ImgOverlay from '@/components/imgOverlay/imgOverlay.vue'

import ReportFilteringImages from '@/components/ReportFilteringImages.vue'
import ExaminationFindingsFormLevelOne from '@/components/examinations/ExaminationFindingsFormLevelOne.vue'
import ExaminationFindingsFormLevelTwo from '@/components/examinations/ExaminationFindingsFormLevelTwo.vue'
import ScreenshotImageItem from '@/components/screenshots/ScreenshotImageItem.vue'
import FindingItemContent from '@/components/commons/FindingItemContent.vue'

import { EXAMINATION_TYPE_OF_GASTROSCOPY } from '../../utils/examinations.config'

export default {
  name: 'ReportFindingDetails',
  props: {
    reporType: Number,
    findingIndex: Number,
    findingId: Number
  },
  components: {
    ReportFindingsFormLevelOne,
    VTextField,
    VCheckbox,
    VSelect,
    AttachToPrintBtn,
    // RemoveFromFindingBtn,
    // ImgOverlay,
    ReportFilteringImages,
    ExaminationFindingsFormLevelOne,
    ExaminationFindingsFormLevelTwo,
    ScreenshotImageItem,
    FindingItemContent
  },

  data() {
    return {
      // active: null,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', //
      examinationTypeID: this.reporType,
      findingsTabActive: 1,

      getNowRecordIndex: 1,

      // showOverlay is an object, to control each image's overlay state
      showOverlay: null,
      coreFormItems: [],
      questionsJSON: null,

      imgDownloadLink: null,

      confirmMessage: '',
      confirmTitle: '',
      onConfirmHandler: () => {},
      showDialog: false,
      openedRecordIndex: 0,
      eventListenerArray: [],
      isFetchParagrah: false,
    }
  },

  created() {
    this.initShowOverlayObj()
  },
  // TODO: TO UNDERSTAND
  updated() {
    const _this = this
    var temp = document.querySelectorAll('i[data-focus]')
    for (var i = 0; i < temp.length; i++) {
      if (_this.eventListenerArray.indexOf(temp[i]) == -1) {
        _this.eventListenerArray.push(temp[i])
        temp[i].addEventListener('click', function () {
          var part = this.getAttribute('id').split('_').length == 3 ? 'i' : 'd'

          if (this.getAttribute('data-focus') == 'interven') {
            part = 'i'
          }
          _this.openTabIndex(this.parentNode.getAttribute('id').split('_')[2])
          _this.setStoreHiddenParameter({
            target: 'focus',
            value: this.getAttribute('data-focus'),
            part: part,
          })
        })
      }
    }
  },

  computed: {
    ...mapGetters(['getSetting']),
    ...mapGetters('examinations', [
      'getExaminationData',
      'getLandmarkList',
      'getFindingsRecordsQuestions',
      'getQuestionsList',
      'getStoreHiddenParameter',
      'getEditArticleStatus',
      'getOrderArticle',
    ]),
    findingContent () {
      return this.getOrderArticle ? this.getOrderArticle.find(i => i.id === this.findingId) : {}
    },
    getFindings() {
      return this.getExaminationData
        ? this.getExaminationData.findings.map((finding) => {
            if (finding.images) {
              return {
                ...finding,
                images: finding.images.sort((a, b) => b.id - a.id),
              }
            }
            return finding
          })
        : null
    },
    finding() {
      return this.getFindings?.length > 0
        ? this.getFindings[this.findingIndex]
        : []
    },
    negativeFinding() {
      var findingName = 'Negative finding'
      var insertionLevel = '1,4'

      if (this.getExaminationData) {
        if (
          this.getExaminationData.examination.examination_typeID != 1 &&
          insertionLevel.indexOf(
            this.getExaminationData.examination.insertionLevel,
          ) != -1
        ) {
          findingName = 'Negative finding in the observable segments'
        }
      }

      return findingName
    },

    getNegaSuggestion() {
      return this.getExaminationData
        ? this.getExaminationData.examination.negaSuggestion
        : null
    },

    findingsLength() {
      return this.getFindings ? this.getFindings.length : 0
    },

    findingsRecordsLength() {
      return this.getFindings.map((finding) => finding.records.length)
    },

    getAllImages() {
      if (this.getExaminationData && this.getExaminationData.all) {
        return this.getExaminationData.all.sort((a, b) => b.id - a.id)
      }
      return []
    },

    getAllImagesLength() {
      return this.getAllImages.length
    },

    landmarkSelectItems() {
      let items = this.getLandmarkList.map((elm) => {
        return {
          value: elm.id,
          text: elm.landmark,
        }
      })

      items.unshift({
        value: 0,
        text: 'Choose',
      })

      return items
    },

    findingSelectItems() {
      let items = this.getFindings.map((elm, index) => {
        return {
          value: elm.id,
          text: `Finding ${index + 1}`,
        }
      })

      items.unshift({
        value: 0,
        text: 'Choose',
      })

      return items
    },
  },

  methods: {
    ...mapActions('screenshots', [
      'setScreenshotGroup',
      'setScreenshotStatus',
      'deleteScreenshot',
    ]),

    ...mapActions('examinations', [
      'addExaminationLocation',
      'deleteExaminationLocation',
      'updateExaminationLocationColumn',
      'setStoreHiddenParameter',
    ]),
    // 202211
    isMostSignificant(finding) {
      return (
        finding.records.length > 0 &&
        finding.questions.takeNote &&
        Number(finding.questions.takeNote.value) == 499
      )
    },

    isOverall(finding) {
      return (
        finding.records.length > 0 &&
        finding.questions.takeNote &&
        Number(finding.questions.takeNote.value) == 501
      )
    },
    next() {
      const active = parseInt(this.active)
      this.active = active < 2 ? active + 1 : 0
    },
    //
    getFindingName(n) {
      let arr = []
      let findingName = []
      if (this.getQuestionsList == null) {
        return
      } else if (this.getQuestionsList[19]) {
        for (let i in this.getFindings) {
          arr.push(this.getFindings[i].questions.finding.value)
        }
        // find the gastro
        findingName = this.getQuestionsList[19].options[arr[n - 1]]
      } else if (this.getQuestionsList[20]) {
        for (let i in this.getFindings) {
          arr.push(this.getFindings[i].questions.finding.value)
        }
        // find the colon & sig
        findingName = this.getQuestionsList[20].options[arr[n - 1]]
      }
      return findingName
    },

    initShowOverlayObj() {
      let obj = {}

      if (this.findingsLength > 0) {
        this.findingsTabActive = 2
      } else {
        this.findingsTabActive = 'tab-nega'
      }

      for (let i = 0; i < this.findingsLength; i++) {
        if (!this.getFindings[i].images) {
          continue
        }
        const imgLen = this.getFindings[i].images.length
        const arrWithFalseValues = this.createArrayWithSameValue(imgLen, false)

        obj[`findings${i}`] = arrWithFalseValues
      }

      obj.all = this.createArrayWithSameValue(this.getAllImagesLength, false)

      this.showOverlay = obj
    },
    createArrayWithSameValue: (size, item) => Array(size).fill(item),
    prepareSelectItems: (options) => forms.prepareSelectItems(options),

    saveQuestionInput(obj) {
      const findingIndex = obj.findingIndex
      const recordIndex = obj.recordIndex
      const field = obj.event.field
      const value = obj.event.newValue
      const item = obj.event.item

      let d = {
        examination_typeID: this.examinationTypeID,
        findingIndex,
        recordIndex,
        findingID: this.getFindings[findingIndex].id,
        field,
        value,
        item,
      }

      if (typeof d.value == 'string') {
        d.value = d.value.replace(/\'/gi, "\\'")
      }

      if (recordIndex !== undefined) {
        d.recordID = this.getFindings[findingIndex].records[recordIndex].id
      }

      if (field == 'takeNote') {
        if (value == '499' || value == '501') {
          this.openedRecordIndex = 0
        }
      }
      if (field == 'number') {
        if (!isNaN(value) || value == 'several' || value == 'multiple') {
          this.$store.dispatch('examinations/updateFindingQuestions', d)
        } else {
          d.value = 1
          this.$store.dispatch('examinations/updateFindingQuestions', d)
        }
      } else {
        if (obj.event.inputType) {
          let inputType = obj.event.inputType
          let temp_value = null
          if (inputType == 's') {
            if (d.recordID) {
              temp_value =
                this.getFindings[findingIndex].records[recordIndex].questions[
                  field
                ].value
            } else {
              temp_value = this.getFindings[findingIndex].questions[field].value
            }
            if (d.value == temp_value) {
              d.value = ''
            }
          } else if (inputType == 'n') {
            if (d.value == 0) {
              d.value = ''
            }
          }
        }
        let maneuver = ['coldSnare', 'hotSnare', 'snare']
        if (
          d.field &&
          maneuver.indexOf(d.field) > -1 &&
          this.getFindings[findingIndex] &&
          this.getFindings[findingIndex].records &&
          this.getFindings[findingIndex].records[recordIndex]
        ) {
          let record = this.getFindings[findingIndex].records[recordIndex]
          for (let i = 0; i < maneuver.length; i++) {
            if (
              record.questions[maneuver[i]] &&
              record.questions[maneuver[i]].value &&
              record.questions[maneuver[i]].value[0]
            ) {
              let o = {
                examination_typeID: this.examinationTypeID,
                findingIndex,
                recordIndex,
                findingID: this.getFindings[findingIndex].id,
                field: record.questions[maneuver[i]].config.field,
                recordID:
                  this.getFindings[findingIndex].records[recordIndex].id,
                value: [null],
                item,
              }
              this.$store.dispatch('examinations/updateFindingQuestions', o)
            }
          }
        }
        this.$store.dispatch('examinations/updateFindingQuestions', d)
      }
    },

    saveNegaSuggestion(obj) {
      const field = obj.event.field
      const value = obj.event.newValue

      let d = {
        examinationID: this.getExaminationData.examination.id,
        field,
        value,
      }
      this.$store.dispatch('examinations/updateColumnById', d)
    },

    openTabIndex(index) {
      this.openedRecordIndex = index

      this.setStoreHiddenParameter({
        target: 'nowQuestionPart',
        value: 'd',
      })
    },
    async getSummaryAndDiagnosis(findingIndex, recordIndex, recordId) {
      this.isFetchParagrah = true 
      const payload = {
        findingIndex,
        recordIndex,
        recordId,
      }
      await this.$store.dispatch('examinations/writeRecordDiagnosis', payload)

      const { accNo, examination_typeID, examinationID, id } = this.getExaminationData.examination 
      if (examination_typeID === EXAMINATION_TYPE_OF_GASTROSCOPY) {
        this.$store.dispatch('examinations/fetchExaminationDetailAndOtherList', {
          accNo,
          examinationID,
        })
      }

      this.$store.dispatch('examinations/fetchOrderArticle', { examinationID: id })
      if (this.getSetting.descriptive) {
        await this.$store.dispatch('examinations/updateDescriptiveParagraph', {
          eid: id,
          etype: examination_typeID,
        })
      }
      this.isFetchParagrah = false
    },
  },
}
</script>

<style lang="scss" scoped>
.records-form-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.forms-container {
  position: relative;
}

#forms-loading-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(225, 225, 225, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
}

.findings-tab-title {
  position: relative;
}
.finding-bottom {
  margin-top: 20px;
  text-align: center;
}
.findingTabActive {
  display: inline-table;
  justify-content: center;

  h1 {
    height: 20px;
    width: 20px;
    color: white;
    background: #82b1ff;
    font-size: 12px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    margin: 3px auto;
    padding: 3px;
  }

  h4 {
    margin: 0 0 3px 0;
    text-overflow: ellipsis;
  }
}
.findingTabDeactivate {
  display: inline-table;
  justify-content: center;
  width: 100px;

  h1 {
    height: 20px;
    width: 20px;
    color: white;
    background: #787878;
    font-size: 12px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    margin: 3px auto;
    padding: 3px;
  }

  h4 {
    display: -webkit-box;
    margin: 0 0 3px 0;
    height: 12px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
}
</style>

<style lang="scss">
.article {
  text-align: left;

  font {
    vertical-align: middle;
    word-break: normal;
  }

  i {
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    color: #2894ff;
  }

  textarea {
    min-height: 21px;
    max-height: 21px;
    line-height: -20px;
    resize: none;
    outline: none;
    border: 0px;
    border-radius: 10px;
    background-color: rgb(220, 220, 220);
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
