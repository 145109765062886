<template>
  <v-container id="colon-quality-report" class="quality-report px-0 py-2">
    <h3>{{ $t('report_preview.explanation') }}</h3>
    <div class="section-start">
      <h3>{{ $t('report_preview.personal_info') }}</h3>
      <hr />
    </div>
    <div class="client">
      <p><b>{{ $t('patient.name') }}: </b> {{ getPatient.name }}</p>
      <p><b>{{ $t('patient.sex') }}: </b> {{ getGender(getPatient.gender) }}</p>
      <p><b>{{ $t('patient.age') }}: </b> {{ getAge(getPatient.birthday, 'YYYY-MM-DD') }}</p>
      <p><b>{{ $t('examination.date') }}: </b> {{ getExaminationData.examination.date.replace(/-/gi, '/') }}</p>
    </div>

    <div class="section-start">
      <h3>{{ $t('report_preview.diff') }}</h3>
      <hr />
    </div>
    <!-- $t('report_preview.') -->
    <v-layout align-start justify-end column fill-height class="compare">
      <v-flex xs4>
        <div id="cleansingLevel">
          <p>
            {{ $t('common.cleanse_level.before') }}
            <span>
              <v-select
                label="Grade of Cleansing Level(before)"
                :items="cleansingGrades"
                @change="setCleansingBeforeGrades($event)"
              ></v-select></span
            >{{ $t('common.cleanse_level.after') }}<span
              ><v-select
                label="Grade of Cleansing Level(after)"
                :items="cleansingGrades"
                @change="setCleansingAfterGrades($event)"
              ></v-select></span
            >
            {{ $t('report_preview.cleanse_off') }}
          </p>
        </div>
        <p id="cleansingLevelComment">{{ getCleansingComment() }}</p>
      </v-flex>
      <v-flex>
        <div v-for="(n, i) in pageBeforeAfter" :key="n">
          <div v-if="qualityReport.beforeImages.slice(i * 3, n * 3)">
            <div class="before">
              <h3>檢查前</h3>
              <div id="beforeImgs">
                <template v-if="Array.isArray(qualityReport.beforeImages)">
                  <div
                    v-for="(beforeImage, imageIndex) in qualityReport.beforeImages.slice(i * 3, n * 3)"
                    :key="i * 3 + imageIndex"
                  >
                    <img :src="prefixServerImgPath(`${examinationID}/${beforeImage.file}`)" />
                  </div>
                </template>
              </div>
            </div>
            <div class="after">
              <h3>檢查後</h3>
              <div id="afterImgs">
                <template v-if="Array.isArray(qualityReport.afterImages)">
                  <div
                    v-for="(afterImage, imageIndex) in qualityReport.afterImages.slice(i * 3, n * 3)"
                    :key="i * 3 + imageIndex"
                  >
                    <img :src="prefixServerImgPath(`${examinationID}/${afterImage.file}`)" />
                  </div>
                </template>
              </div>
            </div>
            <!-- <br> -->
            <hr v-if="n < pageBeforeAfter" />
            <br />
          </div>
        </div>
      </v-flex>
    </v-layout>

    <div class="section-start">
      <h3>{{ $t('report_preview.deepest') }}</h3>
      <hr />
    </div>

    <v-layout align-start justify-end column fill-height class="insertion">
      <v-flex xs4>
        <div style="display: flex">
          <div style="flex: 1; width: 50%" v-show="qualityReport.deepestInsertionLevelImages && isGetReport">
            <canvas id="colonSketchCanvas" ref="canRef" width="200" height="200" style="border: 1px solid #d3d3d3">
            </canvas>
          </div>
          <div id="insertionLevel" v-if="qualityReport.deepestInsertionLevelImages.length > 0">
            <p>{{ getInsertionLevelCommentPattern(qualityReport.deepestInsertionLevelImages[0]) }}</p>
            <div v-if="isDeep == false">
              <v-select
                label="原因"
                :items="insertionLevelNotDeeperReasons"
                @change="setInsertionLevelNotDeeperReason($event)"
              ></v-select>
              <v-textarea
                v-if="insertionLevelNotDeeperReason == '其他(others)'"
                label="原因描述"
                v-model="insertionLevelNotDeeperOtherReason"
                no-resize
                outline
                height="88px"
                row-height="20px"
              ></v-textarea>
            </div>
          </div>
          <p id="insertionLevelComment">{{ getInsertionLevelComment() }}</p>
        </div>
      </v-flex>
      <v-flex xs4>
        <div v-if="!qualityReport.deepestInsertionLevelImages && isGetReport == false">
          <p>此檢查沒有大腸最深處的照片，若欲顯示，請醫生幫病人標示檢查照片之大腸最深處位置</p>
        </div>
        <div
          class="deep-screenshots"
          v-for="(image, imageIndex) in qualityReport.deepestInsertionLevelImages"
          :key="imageIndex"
        >
          <img :src="prefixServerImgPath(`${examinationID}/${image.file}`)" />
          <p class="landmark">{{ image.landmarkEnName || image.landmarkCName || '' }}</p>
        </div>
      </v-flex>
    </v-layout>

    <div class="section-start">
      <h3>{{ $t('report_preview.timer.title') }}</h3>
      <hr />
    </div>

    <v-layout class="backup-time">
      <v-flex xs4>
        <br /><br />
        <img src="@/assets/quality_report_images/withdrawalTime.png" />
      </v-flex>
      <v-flex xs8>
        <br /><br />
        <p>
          <b>{{ $t('report_preview.timer.withdrawal') }}</b> <span>{{ getExaminationData.examination.insertionTime }} </span>
        </p>
        <p>
          <b>{{ $t('report_preview.timer.proximal') }}</b
          ><span>{{ getExaminationData.examination.proximalColonWithTime }}</span>
        </p>
      </v-flex>
      <v-flex xs4>
        <p>{{ getColonWithTimeComment() }}</p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import common from '@/utils/common'
import PreviewHeader from '@/components/preview/PreviewHeader.vue'
import { reportServices } from '@/services/db'
import colonSketch from '@/assets/quality_report_images/colon_sketch.png'
import { coordinateOnSketch } from '@/settings/coordinateOnSketch.js'

export default {
  name: 'ColonQualityReport',
  props: {
    examinationID: String,
    examination: {
      type: Object,
      required: true,
    },
  },
  components: {
    PreviewHeader,
  },
  watch: {
    qualityReport() {
      if (
        this.qualityReport.deepestInsertionLevelImages.length > 1 &&
        this.qualityReport.deepestInsertionLevelImages[0].landmarkEnName != 'Cecum' &&
        this.qualityReport.deepestInsertionLevelImages[0].landmarkEnName != 'Terminal ileum'
      ) {
        this.isDeep = false
      }
    },
    isGetReport() {
      if (this.isGetReport) {
        this.loadColonSketch()
      }
    },
  },
  data() {
    return {
      qualityReport: {
        beforeImages: [],
        afterImages: [],
        deepestInsertionLevelImages: [],
      },
      insertionLevelComment: '',
      insertionLevelNotDeeperReasons: [
        '病患術中難以忍受無法繼續(patient intolerance)',
        '腸道術前準備不足(poor preparation)',
        '腸道過於彎曲(colon redundancy)',
        '其他(others)',
        '未消化食物(food stasis)',
        '未消化藥物(undigested medicine)',
      ],
      cleansingGrades: ['不佳(poor)', '尚可(fair)', '良好(good)', '優良(excellent)'],
      cleansingBeforeGrades: '',
      cleansingAfterGrades: '',
      insertionLevelNotDeeperReason: '',
      insertionLevelNotDeeperOtherReason: '',
      isDeep: true,
      isGetReport: false,
      cvn: null,
      pageBeforeAfter: 1,
      screenshotAroundSketch: 'splenic-flexure',
      examinationTypeName: 'Colonscopy',
    }
  },
  async created() {
    const getQualityReport = await reportServices.fetchQualityReport({
      examinationID: Number(this.examinationID),
    })
    this.setQualityReport(getQualityReport)
  },
  mounted() {
    this.cvn = this.$refs.canRef
  },
  computed: {
    ...mapGetters('examinations', ['getExaminationData', 'getTechnicalStaffList', 'getPatient']),
  },
  methods: {
    getGender: common.getGender,
    getAge: common.calculageAge,
    getTechnicial(id) {
      const technician = this.getTechnicalStaffList.filter((item) => item.id === id)
      if (technician.length > 0) {
        return technician[0].name
      }
      return ''
    },
    getInsertionLevelCommentPattern(insertionLevel) {
      const defaultInfo = `內視鏡最深抵達${insertionLevel.landmarkCName || insertionLevel.landmarkEnName}。`
      if (insertionLevel.landmarkEnName == 'Cecum' || insertionLevel.landmarkEnName == 'Terminal ileum') {
        this.insertionLevelComment = `${defaultInfo}大腸有被完整地檢查到，沒有遺漏，請放心。`
      } else {
        this.insertionLevelComment = `${defaultInfo}因為`
      }
      return this.insertionLevelComment
    },
    getInsertionLevelComment() {
      return (
        this.insertionLevelComment +
        (this.insertionLevelNotDeeperReason == '其他(others)'
          ? this.insertionLevelNotDeeperOtherReason
          : this.insertionLevelNotDeeperReason)
      )
    },
    getColonWithTimeComment() {
      let colonWithTimeComment = ''
      if (
        this.getExaminationData.examination.insertionTime &&
        this.getExaminationData.examination.proximalColonWithTime
      ) {
        const insertionTimeSeconds = this.calTotalSeconds(this.getExaminationData.examination.insertionTime)
        const proximalColonWithTimeSeconds = this.calTotalSeconds(
          this.getExaminationData.examination.proximalColonWithTime,
        )
        if (insertionTimeSeconds > 360 && proximalColonWithTimeSeconds > 120) {
          colonWithTimeComment =
            '退出時間就是觀察大腸的時間。 根據實證醫學文獻，腺瘤偵測率與 內視鏡退出時間有顯著正比關係。 近端大腸的標準退出時間要大於2分 鐘，總退出時間要大於6分鐘。 您本次的檢查皆有超過標準時間， 可以確保檢查息肉時不容易遺漏 醫師非常用心，用最高標準的規格 完成您的檢查。'
        }
      }
      return colonWithTimeComment
    },
    getCleansingComment() {
      return `醫師在檢查過程中有把大腸內留存的殘渣沖洗乾淨。
        您的清腸程度從 ${this.cleansingBeforeGrades} 變成 ${this.cleansingAfterGrades}，所有不乾淨的地方現在都變乾淨了，請您放心。`
    },
    setQualityReport(res) {
      this.qualityReport = res.data
      this.isGetReport = true
      this.pageBeforeAfter = Math.ceil(this.qualityReport.beforeImages.length / 3)
    },
    setInsertionLevelNotDeeperReason(v) {
      this.insertionLevelNotDeeperReason = v
    },
    setCleansingBeforeGrades(v) {
      this.cleansingBeforeGrades = v
    },
    setCleansingAfterGrades(v) {
      this.cleansingAfterGrades = v
    },
    prefixServerImgPath(path) {
      return `/api/upload/image/${path}`
    },
    calTotalSeconds(hms) {
      // split it at the colons
      const t = hms.split(':')
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      const seconds = +t[0] * 60 * 60 + +t[1] * 60 + +t[2]
      return seconds
    },
    markExamPosition(examinationTypeName, part) {
      let position = { x: 0, y: 0 }
      if (coordinateOnSketch[examinationTypeName] && coordinateOnSketch[examinationTypeName][part]) {
        position = coordinateOnSketch[examinationTypeName][part]
      }
      return position
    },
    drawLandmark(ctx) {
      if (this.qualityReport.deepestInsertionLevelImages.length > 1) {
        const colonDeepestPart = this.qualityReport.deepestInsertionLevelImages[0].landmarkEnName.toLowerCase()
        const colonPartCoordinate = this.markExamPosition(this.examinationTypeName, colonDeepestPart)
        ctx.fillStyle = '#211aa1'
        ctx.beginPath()
        ctx.arc(colonPartCoordinate.x, colonPartCoordinate.y, 3, 0, 2 * Math.PI, true)
        ctx.fill()

        const textMeasurements = ctx.measureText(this.qualityReport.deepestInsertionLevelImages[0].landmarkEnName)
        const gradient = ctx.createLinearGradient(
          colonPartCoordinate.x - 10,
          colonPartCoordinate.y - 10,
          colonPartCoordinate.x - 10 + textMeasurements.width,
          colonPartCoordinate.y - 10,
        )
        gradient.addColorStop(0, '#1001b5')
        gradient.addColorStop(1, '#391585')
        ctx.fillStyle = gradient
        ctx.font = 'bold 8pt Courier'
        ctx.fillText(
          this.qualityReport.deepestInsertionLevelImages[0].landmarkEnName,
          colonPartCoordinate.x - 50,
          colonPartCoordinate.y - 10,
        )
      }
    },
    loadColonSketch() {
      let vm = this
      let ctx = this.cvn.getContext('2d')
      let bg = new Image()
      bg.src = colonSketch
      bg.onload = function () {
        ctx.drawImage(bg, 0, 0, ctx.canvas.width, ctx.canvas.height)
        vm.drawLandmark(ctx)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.quality-report {
  font-size: 14px;
  > h3 {
    text-align: right;
  }
}

.deep-screenshots {
  width: 31%;
  height: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: text-top;
  img {
    width: 100%;
    height: auto;
  }
}

.before,
.after {
  display: flex;
  #beforeImgs,
  #afterImgs {
    flex: 1;
  }
  div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 31%;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: text-top;
  }
  img {
    width: 100%;
    height: auto;
  }
  h3 {
    width: 10%;
  }
}

.section-start {
  margin-top: 5px;
  h3 {
    background: #3b587d;
    color: white;
    padding: 5px 10px;
    display: inline-block;
    &:before {
      content: '\26AC';
      margin-right: 5px;
    }
  }
  hr {
    margin-top: -20px;
    margin-bottom: 30px;
  }
}

.client {
  padding-bottom: 12px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  p {
    white-space: nowrap;
  }
}
.compare {
  min-height: 200px;
  h3 {
    display: inline-block;
    width: 24px;
    vertical-align: top;
  }
  .img-wrap {
    display: inline-block;
    img {
      margin-right: 5px;
    }
  }
  hr {
    width: 100%;
    border: 0.5px solid;
    border-radius: 5px;
    background-color: #f7f7f7;
    color: #f7f7f7;
  }
}
.backup-time {
  span {
    float: right;
    margin-right: 20px;
  }
  img {
    width: 95%;
  }
}

#colon-quality-report {
  page-break-after: always;
}

#cleansingLevel {
  padding: 10px;
}

#insertionLevel {
  padding-left: 15%;
  padding-bottom: 20px;
}

#insertionLevelComment,
#cleansingLevelComment {
  font-size: 0px;
}

@media print {
  .section-start {
    hr {
      background-color: grey;
    }
    -webkit-print-color-adjust: exact;
  }
  #insertionLevel,
  #cleansingLevel {
    display: none;
  }
  #insertionLevelComment,
  #cleansingLevelComment {
    padding: 5px;
    font-size: 14px;
    display: inline-block;
  }
}
</style>
