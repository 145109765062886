<template>
  <v-layout
    v-if="!getLoading"
    row
    wrap
    fill-height
    align-content-start
    class="examination-detail-page"
  >
    <v-flex xs10 class="pr-2 left">
      <v-layout row wrap class="webrtc-container">
        <v-flex xs12>
          <Webrtc
            ref="webrtc"
            @updateDevice="
              ({ type, name }) => {
                currentDevice[type] = name
              }
            "
            @cloLoca="setCloLocaInputId"
            @indication="indicationWebRtc"
            @cancel="examCommand({ state: 'indication', value: 'closed' })"
            @examCommand="examCommand"
            @isHandlingAnalysis="(val) => isHandlingAnalysis = val"
          />
        </v-flex>
        <v-flex xs12 class="mb-3" fill-height>
          <examination-finding> </examination-finding>
        </v-flex>
      </v-layout>
      <examination-filtering-images
        v-if="!closeImageList"
        horizontal
        :findings="getFindings"
        :allImages="getAllImages"
        :landmarksList="getLandmarkList"
        @imgStatusChange="toggleImgStatus('all', $event)"
        @findingChange="moveImgFromAllToFinding"
        @landmarkChange="imgLandmarkChange"
        @deleteImg="deleteScreenshot($event)"
        class="elevation-1"
        style="z-index: 1"
      ></examination-filtering-images>
    </v-flex>

    <v-flex xs2 class="examination-finding-sidebar px-2">
      <v-layout row wrap class="finish-bar">
        <v-spacer />
        <v-menu offset-y right>
          <v-btn slot="activator" icon dark>
            <v-icon dark>more_vert</v-icon>
          </v-btn>
          <v-list>
            <v-list-tile
              color="primary"
              @click="setExaminationFinishDialog(true)"
            >
              <v-list-tile-title>Finish</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-layout>
      <v-layout row wrap>
        <voice-command-display></voice-command-display>
      </v-layout>
      <v-layout row wrap>
        <div class="alarm-wrap" v-if="isHandlingAnalysis && getIsAutoRecognize">
          <span class="alarm-wrap__light"></span>
          <p class="alarm-wrap__text">辨識中...</p>
        </div>
        <h2 class="bg-primary text-warning text-align-center px-2 py-1 --full-width l-space-1" v-show="getIsAutoRecognize">
          {{!isHandlingAnalysis ? 'HEY EVAS' : 'EVAS STOP'}}
        </h2>
        <microphone-status
          v-if="!getIsAutoRecognize"
          @triggerRecordKey="triggerRecordKey"
          :isRecording="isRecording"
          :isJudging="isJudging"
        ></microphone-status>
      </v-layout>
      <v-layout class="examination-finding-timer" v-if="examinationType != 1">
        <template>
          <time-inputs-group
            :data="getExaminationData.examination"
            horizontal
            hideDetails
            @change="handleExaminationChange"
          >
            <template #content>
              <div class="d-flex flex-align-center flex-wrap" v-if="isDemo">
                <h2 class="text-accent-1 fsz-24 flex-grow-0 mr-1 l-space-1">
                  EVAS配速員
                </h2>
                <p class="text-light font-italic mb-0 flex-grow-0">速度 vs 精確</p>
              </div>

            </template>
          </time-inputs-group>
        </template>
      </v-layout>
      <v-layout row wrap class="pt-2 pb-2">
        <v-flex xs12 class="findings-container">
          <div
            class="exam-col-container input-wrapper"
            v-if="examinationType == 1"
            :class="{
              'border border-warning':
                getStoreHiddenParameter.focus === 'clo' ||
                getStoreHiddenParameter.focus === 'cloLoca',
            }"
          >
            <h2 class="fsz-20 mb-3">
              <v-icon v-if="cloTest.check" class="flex-grow-0" color="success"
                >check_circle</v-icon
              >
              <v-icon v-else class="flex-grow-0" color="error">close</v-icon>
              CLO test
            </h2>
            <p class="mb-1 fsz-14 text-defaultGreyText">Location</p>
            <HorizontalCheckBoxes
              color="white"
              :field="cloTest.field"
              :options="cloTest.options"
              :value="cloTest.value"
              @change="setColLoca"
            />
          </div>
          <div
            class="exam-col-container input-wrapper"
            v-if="examinationType == 2"
            :class="{
              'border border-warning':
                getStoreHiddenParameter.focus === 'cleanScale',
            }"
          >
            <h2 class="fsz-20 mb-3">Cleansing Level</h2>
            <HorizontalRadio
              :field="cleansingLevel.field"
              :options="cleansingLevelOptions"
              color="white"
              :value="Number(getExaminationData.examination.cleanScale)"
              @change="
                handleExaminationChange({
                  column: 'cleanScale',
                  event: $event,
                })
              "
            />
          </div>
          <!-- 清腸程度 -->
          <insertion-level-input
            v-if="examinationType != 1"
            :data="insertionLevel"
            @change="setInsertionLevelColumn"
          />
          <v-select
            v-if="examinationType == 2"
            class="mb-3"
            label="Complications"
            multiple
            :items="filterNilSelect(getComplicationList)"
            :value="complication"
            hide-details
            @change="($event) => setExaminationColumn('complication', $event)"
          />
          <quick-commend />
          <!-- <preparation-input
            :data="getExaminationData"
            @change="handleExaminationChange"
          ></preparation-input> -->
        </v-flex>
      </v-layout>
    </v-flex>
    <counting-start-anim
      v-if="isTimeCounting"
      @close="setTimeCounting(false)"
    ></counting-start-anim>
    <finish-anim
      v-if="isTimeFinish"
      @close="setTimeFinish(false)"
    ></finish-anim>
    <v-dialog
      v-if="getExaminationData.examination"
      v-model="examinationFinishDialog"
      max-width="600px"
      persistent
    >
      <examination-finish-dialog
        :examination="getExaminationData.examination"
        :noFinding="noFinding"
        :hiddenParams="getStoreHiddenParameter"
        @close="setExaminationFinishDialog(false)"
        @endOfExam="setExaminationStatus()"
        @change="handleExaminationChange"
      ></examination-finish-dialog>
    </v-dialog>

    <v-dialog
      v-model="showIndication"
      persistent
      max-width="400px"
      v-if="examinationType !== 1"
    >
      <v-card>
        <v-card-text>
          <IndicationForm
            :value="getExaminationData.examination.indication"
            @change="checkIndication"
          />
          <div class="d-flex justify-end">
            <v-btn outline color="info" @click="showIndication = false"
              >關閉</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/utils/common'
import CountingStartAnim from '@/components/examinations/CountingStartAnim.vue'
import FinishAnim from '@/components/examinations/FinishAnim.vue'
import VoiceActionControl from '@/components/VoiceActionControl.vue'
import Webrtc from '@/components/Webrtc.vue'
import ExaminationFinding from '@/components/ExaminationFinding.vue'
import ExaminationInfo from '@/components/ExaminationInfo.vue'
import ExaminationFilteringImages from '@/components/examinations/ExaminationFilteringImages.vue'
import IconButtonWithTooltip from '@/components/IconButtonWithTooltip.vue'
import ExaminationFindingsFormLevelOne from '@/components/examinations/ExaminationFindingsFormLevelOne.vue'
import ExaminationFindingsFormLevelTwo from '@/components/examinations/ExaminationFindingsFormLevelTwo.vue'
import ExaminationBowelCleanseForms from '@/components/examinations/ExaminationBowelCleanseForms.vue'
import MicrophoneStatus from '@/components/examinations/MicrophoneStatus.vue'
import VoiceCommandDisplay from '@/components/examinations/VoiceCommandDisplay.vue'
import ConfirmDialogSmall from '@/components/ConfirmDialogSmall.vue'
import ExaminationNoFindingDialog from '@/components/examinations/ExaminationNoFindingDialog.vue'
import ExaminationFinishDialog from '@/components/examinations/ExaminationFinishDialog.vue'
import TimeInputsGroup from '@/components/TimeInputsGroup.vue'
import InsertionLevelInput from '@/components/bowelCleanseForms/InsertionLevelInput.vue'
import PreparationInput from '@/components/bowelCleanseForms/PreparationInput.vue'
import QuickCommend from '@/components/examinations/QuickCommend.vue'
import InsertionLengthInput from '@/components/bowelCleanseForms/InsertionLengthInput.vue'
import HorizontalCheckBoxes from '@/components/HorizontalCheckBoxes.vue'
import HorizontalRadio from '@/components/HorizontalRadio.vue'
import IndicationForm from '@/components/hpaForm/IndicationForm.vue'

export default {
  components: {
    TimeInputsGroup,
    VoiceActionControl,
    Webrtc,
    ExaminationFinding,
    ExaminationInfo,
    ExaminationFilteringImages,
    IconButtonWithTooltip,
    ExaminationFindingsFormLevelOne,
    ExaminationFindingsFormLevelTwo,
    ExaminationBowelCleanseForms,
    ExaminationNoFindingDialog,
    ExaminationFinishDialog,
    ConfirmDialogSmall,
    CountingStartAnim,
    FinishAnim,
    InsertionLevelInput,
    PreparationInput,
    MicrophoneStatus,
    VoiceCommandDisplay,
    QuickCommend,
    InsertionLengthInput,
    HorizontalCheckBoxes,
    HorizontalRadio,
    IndicationForm,
  },

  data() {
    return {
      showIndication: false,
      currentDevice: { audio: null, video: null },
      imagesTabs: 'all',
      deleteDialog: false,
      deleteMessage: '',
      keyPressing: false,
      isRecord: false,
      flagTime: 0,
      cleansingLevel: {
        field: 'cleanScale',
        value: '',
      },
      isHandlingAnalysis: false,
    }
  },

  created() {
    this.$store.dispatch('examinations/fetchExaminationDetailAndOtherList', {
      examinationID: this.examinationID,
      accNo: this.accNo,
    })
    this.$store.dispatch('examinations/resetStoreHiddenParameterExamination')
    this.$store.dispatch('examinations/resetQuestionsList')
    this.$store.dispatch('examinations/setExaminationFinishDialog', false)
  },

  computed: {
    ...mapGetters('examinations', [
      'getDoctorList',
      'getTechnicalStaffList',
      'getExaminationData',
      'getLandmarkList',
      'getExaminationTypeList',
      'getPatient',
      'getIndicationList',
      'getQuestionsList',
      'getExaminationFinishDialog',
      'getStoreHiddenParameter',
      'getCleanScaleList',
      'getComplicationList'
    ]),
    ...mapGetters(['getLoading', 'getIsAutoRecognize', 'getSetting']),
    isDemo () {
      return this.getSetting.demo
    },
    cloTest() {
      return {
        check: Boolean(
          this.getExaminationInfo && +this.getExaminationInfo.cloTest
        ),
        field: 'cloLoca',
        value:
          (this.getExaminationInfo &&
            this.getExaminationInfo.cloTestLocations) ||
          null,
        options: {
          153: 'pylorus',
          154: 'antrum',
          155: 'low-body',
        },
      }
    },
    getExaminationInfo() {
      return this.getExaminationData.examination
    },
    getFindings() {
      return this.getExaminationData ? this.getExaminationData.findings : null
    },
    getFindingsLength() {
      return this.getFindings ? this.getFindings.length : 0
    },
    getAllImages() {
      if (this.getExaminationData && this.getExaminationData.all) {
        return this.getExaminationData.all.sort((a, b) => b.id - a.id)
      }
      return []
    },
    examinationID() {
      if (Number(this.$route.params.examinationID) != 0) {
        return this.$route.params.examinationID
      } else {
        if (this.getExaminationData != null) {
          return this.getExaminationData.examination.id
        }
      }
    },
    accNo() {
      return Number(this.$route.params.accNo) != 0
        ? this.$route.params.accNo
        : 0
    },
    examinationFinishDialog() {
      return this.getExaminationFinishDialog
    },
    isRecording() {
      return this.getStoreHiddenParameter.isRecording
    },
    isJudging() {
      return this.getStoreHiddenParameter.isJudging
    },
    isTimeCounting() {
      return this.getStoreHiddenParameter.isTimeCounting
    },
    isTimeFinish() {
      return this.getStoreHiddenParameter.isTimeFinish
    },
    examinationType() {
      // 1 => Gastroscopy, 2=> Colonoscopy
      return this.getExaminationData.examination.examination_typeID
    },
    insertionLevel() {
      return this.getExaminationData.examination.insertionLevel
    },
    insertionLength() {
      return this.getExaminationData.examination.insertionLength
    },
    complication () {
      return this.getExaminationData.examination.complication
    },
    noFinding() {
      return this.getFindings && this.getFindings.length <= 0
    },
    indicationKey() {
      return (
        this.getIndicationList &&
        this.getIndicationList.map((indi) => indi.value)
      )
    },
    closeImageList() {
      return !this.getSetting.screenshot
    },
    cleansingLevelOptions() {
      const array2Object = (fn, arr) => arr.reduce(fn, {})
      const combineResultWithItem = (result, item) => ({
        ...result,
        [item.value]: item.text,
      })
      return array2Object(combineResultWithItem, this.getCleanScaleList)
    },
  },

  methods: {
    ...mapActions('examinations', [
      'updateExaminationStatus',
      'updateExaminationColumn',
      'addExaminationSedative',
      'deleteExaminationSedative',
      'addExaminationLocation',
      'deleteExaminationLocation',
      'updateExaminationLocationColumn',
      'setExaminationFinishDialog',
      'setStoreHiddenParameter',
      'setRecording',
      'setJudging',
      'setTimeCounting',
      'setTimeFinish',
    ]),
    ...mapActions('screenshots', [
      'setScreenshotGroup',
      'setScreenshotStatus',
      'deleteScreenshot',
    ]),

    examCommand(item) {
      const { state, value } = item
      switch (state) {
        case 'indication':
          this.indicationWebRtc(value)
          break
        case 'clo':
          this.setClo()
          break
        case 'cloLoca':
          this.setCloLocaInputId(value)
          break
        default:
          break
      }
    },
    indicationWebRtc(voiceInput) {
      switch (voiceInput) {
        case 'open':
          this.showIndication = true
          break
        case 'closed':
          this.showIndication = false
          break
        default:
          this.checkIndication(voiceInput)
          break
      }
    },
    checkIndication(voiceInput) {
      const indication =
        this.getExaminationData &&
        this.getExaminationData.examination &&
        this.getExaminationData.examination.indication
      const pattern = /\b9[a-g]\b/

      const inputletter = pattern.test(voiceInput)
        ? voiceInput.replace('9', '')
        : voiceInput
      const valid = this.indicationKey.includes(inputletter)

      if (valid) {
        let mainGroup = ['1', '2']
        let isMainSelect = mainGroup.includes(inputletter)
        let setData = isMainSelect
          ? indication.includes(inputletter) ? [] : [inputletter]
          : common.toggleItemInArr(
              indication.filter((i) => !mainGroup.includes(i)),
              inputletter
            )
        this.setExaminationColumn('indication', [...setData])
      }
    },
    setClo() {
      const changeStatus = !this.cloTest.check
      this.setExaminationColumn('cloTest', changeStatus ? '1' : '0')
      if (!changeStatus) {
        this.setExaminationColumn('cloTestLocations', [])
      }
    },
    setColLoca(arr) {
      this.setExaminationColumn('cloTestLocations', arr)
      if (arr.length) {
        this.setExaminationColumn('cloTest', '1')
      }
    },
    setCloLocaInputId(voiceInput) {
      const { options: cloOptions, value: cloValues } = this.cloTest
      const options = Object.entries(cloOptions).reduce(
        (acc, [key, value]) => ({ ...acc, [value]: Number(key) }),
        {}
      )
      const targetId = options[voiceInput]
      if (targetId) {
        const result = common.toggleItemInArr(cloValues, targetId)
        this.setColLoca(result)
      }
    },
    isEmptyStr(str) {
      return str && str.trim().replace(' ', '') === '' ? true : false
    },
    setExaminationColumn(column, value) {
      let d = {
        id: this.examinationID,
        column: column,
        value: value,
      }
      this.updateExaminationColumn(d)
    },

    setInsertionLevelColumn(event) {
      this.setExaminationColumn('insertionLevel', event)
    },

    setInsertionLengthColumn(event) {
      this.setExaminationColumn('insertionLength', event)
    },

    setExaminationStatus() {
      this.setExaminationColumn('status', 2)
      this.$router.push({
        path: `/home`,
      })
    },

    /* same as in report view, we can merge them later */
    toggleImgStatus(n, i) {
      const item =
        n === 'all' ? this.getAllImages[i] : this.getFindings[n].images[i]
      const currStatus = item.status
      const newStatus = !Number(currStatus) == true ? '1' : '0'
      const imgID = item.id
      const findingID = item.findingID
      const landmarkID = item.landmarkID
      const obj = {
        imgID,
        newStatus,
        item,
        findingID,
        landmarkID,
      }

      if (item.findingID !== 0) {
        this.$store.dispatch(
          'examinations/updateScreenshotStatusInFinding',
          obj
        )
      }

      this.$store.dispatch('examinations/updateScreenshotStatusInAll', {
        ...obj,
        imgIndex: i,
      })
    },

    moveImgFromAllToFinding({ id, newValue }) {
      const updateObj = {
        imgID: id,
        newValue,
      }

      this.$store.dispatch('examinations/moveImgFromAllToFinding', updateObj)
    },

    imgLandmarkChange({ id, newValue }) {
      const updateObj = {
        imgID: id,
        newValue,
        page: 'e',
      }

      this.$store.dispatch('examinations/imgLandmarkChange', updateObj)
    },

    handleSedative(action, event) {
      const examinationSedativeID = event ? event.id : null
      this[`${action}ExaminationSedative`]({
        examinationID: this.examinationID,
        examinationSedativeID,
      })
    },

    handleExaminationChange({ column, field, event }) {
      if (typeof field != 'undefined') {
        this.setExaminationColumn(field, event)
      } else {
        this.setExaminationColumn(column, event)
      }
    },

    isEmptyObject: (obj) => common.isEmptyObject(obj),

    triggerRecordKey(eventName) {
      if (eventName == 'keydown') {
        this.flagTime = setTimeout(() => {
          this.isRecord = true
          this.handleTriggerKeyEvent(eventName, 88)
          this.keyPressing = true
          if (this.keyPressing !== false) {
            this.setRecording(true)
            this.setJudging(true)
          }
        }, 500)
      } else {
        const targetKeyCode = this.isRecord ? 88 : 90
        this.handleTriggerKeyEvent(eventName, targetKeyCode)
        clearTimeout(this.flagTime)
        this.keyPressing = false
        this.setRecording(false)
        if (this.isRecord) {
          let JUDGING_TIMOUT = 3500
          setTimeout(() => {
            this.setJudging(false)
            this.isRecord = false
          }, JUDGING_TIMOUT)
        }
      }
    },

    handleTriggerKeyEvent(eventName, keyCode) {
      var evObj = new KeyboardEvent(eventName, {
        keyCode: keyCode,
        which: keyCode,
      })
      // Chrome bug workaround:
      if (evObj.keyCode != keyCode) {
        evObj.keyCode = keyCode
        evObj.which = keyCode
      }
      window.dispatchEvent(evObj)
    },
    addCancelVoiceFocusListener() {
      const main = document.getElementsByTagName('main')[0]
      const _this = this

      main.addEventListener('click', () => {
        _this.setStoreHiddenParameter({
          target: 'focus',
          value: '',
        })
      })
    },
    filterNilSelect(arr) {
      // filter text = nil / aka value = 0
      return arr.filter((i) => i.value !== 0)
    },
  },

  watch: {
    getIsAutoRecognize(val) {
      if (!val) {
        this.isHandlingAnalysis = false
      }
    }
  }
}
</script>
<style lang="scss">
.insertion-length-input input[type='text'] {
  color: #5a81a3 !important;
}
</style>
<style lang="scss" scoped>
@import '@/styles/pages/page_examinationDetail.scss';
@import '@/styles/components/textInputsTextfield.scss';

.examination-detail-page {
  user-select: none;
}

.examination-finding-sidebar {
  background-color: #000000; // color-code: background
  color: #98999a; //color-code: sidebar text color
  font-weight: bold;
}

.finish-bar {
  overflow: hidden;
  position: absolute;
  z-index: 10;
  width: 25%;
}

.hidden {
  visibility: hidden;
  position: absolute;
  top: 0;
}

hr {
  color: #252627; // color-code
}

.exam-col-container {
  padding: 1rem 0.5rem 0;
  background: rgba(#3b587d, 0.8);
  color: white;
  border-radius: 6px;
  overflow: hidden;
}

.alarm-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  &__light {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    animation: showLight 1s ease infinite;
  }
  &__text {
    margin-left: 5px;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 2px;
    color: white;
  }
}

@keyframes showLight {
  0% {
    background-color: red;
  }
  25% {
    background-color: white;
  }
  50% {
    background-color: red;
  }
  75% {
    background-color: white;
  }
  100% {
    background-color: red;
  }
}

</style>
