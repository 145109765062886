<template>
  <div class="flex xs12">
    <div v-for="list in data" :key="list.category">
      <div :class="['input-wrapper', isFocus(list.category)]">
        <div :class="['header', 'subtitle-border', { 'is-required': list.category === 'intervention' && isExamination }]">
          {{ list.category }}
        </div>
        <template v-for="item in list.items">
            <laid-out-select-input
              v-if="item.config.inputType == 's'"
              :key="item.questionID"
              :options="item.config.options"
              :value="item.value"
              :multiple="true"
              @change="$emit('change', { newValue: $event, qID: item.questionID })"
            />
            <laid-out-check-boxes
              v-if="item.config.inputType == 'c2'"
              :key="item.questionID"
              :options="item.config.options"
              :value="Array.isArray(item.value) ? item.value : [item.value]"
              @change="$emit('change', { newValue: [$event], qID: item.questionID })"
            />
            <number-select-input
              v-if="item.config.inputType == 'n'"
              :key="item.questionID"
              :data="item"
              @change="$emit('change', { newValue: $event, qID: item.questionID })"
            />
            <textfield-landscape
              v-if="item.config.inputType == 't'"
              :key="item.questionID"
              :data="item"
              :isRequired="false"
              @change="$emit('change', { newValue: $event, qID: item.questionID })"
            />
            <v-textarea
              v-if="item.config.inputType == 'ta'"
              :key="item.questionID"
              :label="item.config.question"
              :value="item.value"
              @change="$emit('change', { newValue: $event, qID: item.questionID })"
              no-resize
              outline
              hide-details
              height="88px"
              row-height="20px"
            />
            <TheCheckboxes
              v-if="item.config.inputType == 'c' && item.config.field == 'nil'"
              :key="item.questionID"
              :options="item.config.options"
              :value="item.value"
              @change="$emit('change', { newValue: $event, qID: item.questionID })"
            />
            <horizontal-check-boxes
              v-if="item.config.inputType == 'c' && item.config.field !== 'nil'"
              :key="item.questionID"
              :options="item.config.options"
              :value="item.value"
              @change="$emit('change', { newValue: $event, qID: item.questionID })"
            />
        </template>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import LaidOutSelectInput from "@/components/LaidOutSelectInput.vue";
import LaidOutCheckBoxes from "@/components/LaidOutCheckBoxes.vue";
import NumberSelectInput from "@/components/NumberSelectInput.vue";
import TextfieldLandscape from "@/components/TextfieldLandscape.vue";
import HorizontalCheckBoxes from "@/components/HorizontalCheckBoxes.vue";
import TheCheckboxes from '@/components/TheCheckboxes.vue';

export default {
  name: "InterventionRecordsForm",
  props: {
    data: {
      required: true,
      type: Array,
    },
    hiddenParams: {
      required: true,
      type: Object,
    },
    isExamination: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  components: {
    LaidOutSelectInput,
    LaidOutCheckBoxes,
    NumberSelectInput,
    TextfieldLandscape,
    HorizontalCheckBoxes,
    TheCheckboxes
  },
  computed: {
    categories() {
      if (Array.isArray(this.data) && this.data.length) {
        return this.data.map(({ category }) => category)
      }
      return []
    },
    focus() {
      if (this.hiddenParams && this.hiddenParams.focus && this.categories.length) {
          if (this.categories.includes(this.hiddenParams.focus)) {
            return this.hiddenParams.focus
          }

          const group = this.data.find(e => e.items.some(i => i.config.field === this.hiddenParams.focus))

          if (group) {
            return group.category
          }
      }
      return ''
    }
  },
  watch: {
    focus(nv, ov) {
      clearTimeout(this.timer)
      if (nv.trim() && nv !== ov) {
          this.timer = setTimeout(this.scrollToFocus, 300)
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  },
  methods: {
    isFocus(category) {
      if (this.focus === category) {
        return 'focus'
      }
      return ''
    },
    scrollToFocus () {
      const focusdEls = document.querySelectorAll('.input-wrapper.focus')
      if (focusdEls.length) {
        const [focusd] = Array.from(focusdEls).filter(el => el.offsetParent !== null)
        if (focusd) {
          const [container] = document.querySelectorAll('.v-window')
        if (container) {
              container.scrollTo({ left: 0, top: focusd.offsetTop, behavior: 'smooth' })
          }
        }
      }
    }
  },
};
</script>

<style lang="scss">
.header {
  &.is-required {
    color: #ff5252;
  }
}
</style>
