export const zh = {
  report: {
    Unedited: 'Unedited',
    Editing: 'Editing',
    Finalized: 'Finalized',
    exam_info: 'Exam. Info',
    findings: 'Findings',
    diagnosis: 'Diagnosis',
    suggestion: '醫師建議',
    confirm: {
      title: '刪除',
      text: '確定要刪除 Finding {0} 嗎？',
      delete_image: '確定要刪除此圖片?',
    },
    reset_timer: '重新計時',
    simple: '簡易版',
    normal: '完整版',
  },
  report_preview: {
    title: 'REPORT PREVIEW',
    ai: 'AI智慧醫療報告',
    back_to: 'Go back to editing',
    Gastroscopy: '胃鏡檢查報告',
    Colonoscopy: '大腸鏡檢查報告',
    name: '@:patient.name',
    gender: '性別',
    age: '@:patient.age',
    recordNo: '病歷號碼',
    examDate: '@:examination.date',
    examTime: '檢查時間',
    examItem: '檢查項目',
    GastroscopyCheckNo: '胃鏡檢查',
    ColonoscopyCheckNo: '大腸鏡檢查',
    findings: '@:report.findings',
    report_date: '報告日期',
    technician: '技術員',
    print: '列印',
    explanation: '醫師說明',
    personal_info: '個人資料',
    diff: '檢查前後對比',
    cleanse_off: '已清理乾淨，請您放心！',
    deepest: '檢查最深處位置',
    timer: {
      title: '內視鏡退出時間',
      withdrawal: '總退出時間',
      proximal: '近端大腸退出時間',
    },
  },
  organization: {
    title: 'Organization',
    number: 'Organization No',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    connection: 'Connect to PACS Worklist',
    ae_title: 'Called_AE ',
    called_ip: 'Called_IP',
    called_port: 'Called_PORT',
    calling_ae: 'Calling_AE ',
    modality: 'Modality',
    record: '全時收音',
  },
  doctor: {
    title: 'Doctors',
  },
  examination: {
    title: 'Examinations',
    number: '檢查單號',
    patient: '@:patient.name',
    date: '檢查日期',
    doctor: 'Order Doctor',
    type: '檢查類型',
    add: '新增檢查',
  },
  inspection_instrument: {
    title: 'Inspection Instruments',
  },
  wash_Instrument: {
    title: 'Wash Instruments',
  },
  patient: {
    title: 'Patients',
    name: '病患姓名',
    id: '病歷號',
    dob: '生日',
    sex: '性別',
    age: '年齡',
  },
  nurse: {
    title: 'Nurse',
  },
  user: {
    title: 'Users',
  },
  analytics: {
    title: 'Analytics',
  },
  enviroment_test: {
    title: 'Enviroment Test',
  },
  common: {
    yes: '是的',
    no: "否",
    cancel: '取消',
    nil: "Nil",
    summary: '總結',
    search: 'Search',
    reset: 'Reset',
    add: 'ADD',
    other: '其他',
    day: '天',
    ESOPHAGUS: 'ESOPHAGUS',
    STOMACH: 'STOMACH',
    DUODENUM: 'DUODENUM',
    NONSPECIFIC: 'NONSPECIFIC',
    indication: '適應症(檢查原因)',
    preparation_agent: '清腸用藥',
    preparation_time: '清腸時間',
    anti_platelet: '抗凝血藥物',
    anti_platelet_stop: '抗凝血藥物停藥天數',
    cleanse_level: {
      before: '清腸程度(檢查前)',
      after: '清腸程度(檢查後)',
    },
    scope_id: '內視鏡型號',
    sedatives: '止痛鎮靜藥物',
    washing_machine: '洗滌機型號',
    premedication: {
      title: '檢查前藥物',
      '1': 'Glaucoma',
      '2': 'BPH',
      '3': '心跳過速',
      '4': '過敏',
    },
    antispasmodics: {
      on: '無使用止痙攣藥物',
      off: '有使用止痙攣藥物',
    },
    insertion_level: '伸入最深位置',
    insertion_length: '伸入最深長度',
    complication: '併發症',
    clo_test: 'CLO Test',
    additional_remarks: '其他標記',
    incomplete: {
      title: '未完成檢查原因',
      gastro: {
        '0': '',
        '1': 'intolerance',
        '2': 'bleeding',
        '3': 'food stasis',
        '4': 'undigested medicine',
      },
      colon: {
        '0': '',
        '1': 'Poor Preparation',
        '2': 'Bleeding',
        '3': 'Bowel Perforation',
        '4': 'Patient Intolerance',
        '5': 'Other',
        '6': 'Food Stasis',
        '7': 'Undigested Medicine',
      }
    },
    procedure_note: '檢查說明',
  },
  dialog: {
    warn: '提示',
  },
  login:{
    username: "username",
    password: "password",
    verificationcode: "verification code",
    signin: "sign in"
  },
  homepage:{
    from: "From",
    to: "To",
    examination_records: "exammination records",
    medical_records: "Medical records",
    search: "search",
    today: "today",
    sync_worklist: "SYNC WORKLIST",
    examinations: "examinations",
    date: "date",
    examination_record: "examination record",
    examination_type: "examination type",
    patient_name: "Patient name",
    medical_record: "medical record",
    status: "status",
    no_data_available: "No data available",
    rows_per_page: "Rows per page",
    singout: "SING OUT",
    select_examination: "Select an examination from the left to see detail information.",
    finished: "Finished",
    unfinished: "Unfinished",
    colonoscopy: "Colonoscopy",
    gastoscopy: "Gastoscopy",
    gast:{
      birth: "DATE OF BIRTH",
      age: '@:patient.age',
      gender: "GENDER",
      male: "male",
      female: "female",
      scope_id: "Scope ID",
      washing_machine: "Washing Machine",
      docter: "Doctor",
      nurse: "Nurse",
      go_to_report: "GO TO REPORT",
      start_examination: "START EXAMINATION",
    },
    colon:{
      premedication: "檢查前藥物 Premedication",
      preparation_agent: "清腸用藥 Preparation Agent",
      preparationTime: "清腸時間 Preparation Time",
      antiplatelet: "抗凝血藥物 Antiplatelet",
      complication: "@:common.complication",
      without_medication: "",
      please_select: "Please select",
    }
  },

  poweredByFirst: '由智能語音助理',
  poweredBySecond: 'EVAS提供',
  from: '病患來源',
  ward: '病房',
  applicationDate: '申請日期',
  applicationTime: '申請時間',
  applicationDr: '申請醫師',
  reportTime: '報告時間',
  vs: '主治醫師',
  examDr: '檢查醫師',

  // ColonCloeanseForm
  informedConsent: '同意書',
  insertionTime: '插入時間',
  withdrawTime: '退出時間',
  totalExamTime: '總時長',
  proximalColonWithTime: '近端退出時間',

  // Colon finding
  finding: '發現',
  lesion: '病灶',
  procedureImages: '檢查照片',
  additionalImage: '其他照片',

  http: {
    error: {
      400: '發生不正確的請，請聯繫開發人員',
      401: '未經授權的訪問，請重新登入',
      403: '無訪問權限，請聯絡管理人員',
      404: '請求路徑無效，請聯繫開發人員',
      405: '不允許的函式',
      406: 'Http協議錯誤，伺服器端未允許 Accept-Charset 以及 Accept-Language，請聯繫開發人員',
      408: '請求逾時，請檢查裝置的網路連線',
      500: 'Server端發生錯誤',
      502: 'Server透過代理或匝道請求時，發生不明錯誤',
      503: 'Server端無法處理目前連線的請求，請稍後再嘗試',
      504: 'Server透過代理或匝道請求逾時',
      505: 'HTTP Version Not Supported',
      999: 'JavaScript發生錯誤',
    },
  },
  i18n: {
    zh: '繁體中文',
    ja: '日本語',
    en: 'English',
  },
}
