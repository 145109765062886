<template>
  <v-flex xs12 class="voice-command-display-wrapper">
    <div class="text">
      <h3
        v-for="(comment, index) in getCommandQueue"
        :key="`${comment}${index}`">
        {{ comment }}
      </h3>
    </div>
  </v-flex>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'VoiceCommandDisplay',
    props: {
    },
    computed:{
       ...mapGetters('examinations', [
            'getCommandQueue'
        ]),
    },
    methods: {
    }
  }

</script>
<style lang="scss" scoped>
.voice-command-display-wrapper {
  overflow: hidden;
  height: 150px;
  position: relative;
  background-image: url("@/assets/voice-background.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  .text {
    position: absolute;
    text-align: center;
    width: 100%;
  }
  h3 {
    font-size: 34px;
    color: #6EFFF6;
    line-height: 1.1em;
    font-weight: 300;
    text-shadow: 0px 0px 7px #13fff0;
  }
  h3:first-child {
    font-weight: 500;
  }
}
</style>
