<template>
  <section class="basicExamFindingRecord">
    <template>
      <ul
        class="pl-0 basicExamFindingRecord__descript"
        v-if="
          getFirstLocationItem ||
          getDescOptions('size') ||
          getGastroDescSelectInput.length
        "
      >
        <BasicRecordFieldItem
          data-test="desc-location"
          v-for="(item, key) in getFirstLocationItem"
          :class="[
            {
              'basicExamFindingRecord__descript--fullhalf':
                (getDescOptions('size') && key !== 'dist') ||
                (EXAMINATION_TYPE_OF_GASTROSCOPY === examType &&
                  key === 'loca'),
            },
            {
              'basicExamFindingRecord__descript--fullfull':
                Object.keys(getFirstLocationItem).length === 1 &&
                getGastroDescSelectInput.length === 0 &&
                !getDescOptions('size'),
            },
            {
              'basicExamFindingRecord__descript--fullhalf':
                Object.keys(getFirstLocationItem).length === 2 &&
                getGastroDescSelectInput.length === 0 &&
                !getDescOptions('size'),
            },
          ]"
          :key="key"
          tag="li"
          :inputType="item.inputType"
          :title="item.title"
          :value="item.value"
          :options="item.questionID && getLocationQuestion(item.questionID)"
          :disabled="!item.questionID"
          v-bind="item.option"
          @change="
            $emit('locationChange', {
              value: $event,
              column: key,
              id: item.locationId,
            })
          "
        />
        <BasicRecordFieldItem
          v-if="getDescOptions('size')"
          tag="li"
          inputType="text"
          title="Size"
          suffix="cm"
          textType="number"
          :value="getDescOptions('size') && getDescOptions('size').value"
          :class="{
            'basicExamFindingRecord__descript--fullhalf':
              getFirstLocationItem &&
              Object.keys(getFirstLocationItem).length === 1 &&
              !getGastroDescSelectInput.length,
          }"
          @change="
            $emit('change', {
              newValue: $event,
              ...getSize.config,
            })
          "
        />
        <template v-if="EXAMINATION_TYPE_OF_GASTROSCOPY === examType">
          <BasicRecordFieldItem
            data-test="gastro-desc-specific"
            v-for="desc in getGastroDescSelectInput"
            :key="desc.field"
            class="h-100"
            :class="[
              {
                'basicExamFindingRecord__descript--fullhalf':
                  !getFirstLocationItem ||
                  (getFirstLocationItem &&
                    Object.keys(getFirstLocationItem).length === 1),
              },
              {
                'basicExamFindingRecord__descript--fullfull':
                  !getFirstLocationItem &&
                  getGastroDescSelectInput.length === 1,
              },
            ]"
            tag="li"
            inputType="selectInput"
            :title="desc.title"
            :value="desc.items.value"
            :options="generateQList(desc.items.config.options)"
            @change="
              $emit('change', {
                newValue: $event,
                ...desc.items.config,
              })
            "
          />
        </template>
      </ul>
      <section
        v-else
        data-test="descript--empty"
        class="bg-light w-100 h-100"
      ></section>
    </template>

    <ul class="pl-0 basicExamFindingRecord__intervention">
      <BasicRecordFieldItem
        v-if="getIntervenOptions('intervention')"
        title="Intervention"
        tag="li"
        bgColor="bg-warning-1"
        inputType="selectInput"
        :value="getIntervenOptions('intervention').value"
        :options="
          generateQList(getIntervenOptions('intervention').config.options)
        "
        @change="
          $emit('change', {
            newValue: $event,
            ...getIntervenOptions('intervention').config,
          })
        "
      />

      <BasicRecordFieldItem
        tag="li"
        inputType="text"
        title="Specimen Label"
        bgColor="bg-warning-1"
        :disabled="!getSpecimenLabel"
        :value="getSpecimenLabel && getSpecimenLabel.value"
        @change="
          $emit('change', {
            newValue: $event,
            ...getSpecimenLabel.config,
          })
        "
      />
    </ul>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import BasicRecordFieldItem from "./BasicRecordFieldItem.vue";
import { EXAMINATION_TYPE_OF_GASTROSCOPY } from "@/utils/examinations.config";

export default {
  name: "BasicExamFindingRecord",
  components: {
    BasicRecordFieldItem,
  },
  props: {
    examType: Number,
    record: Object,
  },
  data() {
    return {
      EXAMINATION_TYPE_OF_GASTROSCOPY,
      gastroDesc: [
        {
          title: "L.A Grade",
          field: "laGrade",
          category: "L.A. grade",
        },
        {
          title: "Form",
          field: "form",
          category: "form",
        },
        {
          title: "Ulcer Stage",
          field: "ulcerStage",
          category: "ulcer stage",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("examinations", ["getQuestionsList"]),
    questionsByPart() {
      const qValues = this.record.questions;
      const result = Object.keys(qValues).reduce(
        (acc, field) => {
          const { part, questionID } = qValues[field];
          if (questionID) {
            const category =
              this.getQuestionsList &&
              this.getQuestionsList[questionID].category;
            qValues[field].category = category;
          }
          return field === "location" || part === "d"
            ? {
                description: [...acc.description, qValues[field]],
                intervention: acc.intervention,
              }
            : {
                description: acc.description,
                intervention: [...acc.intervention, qValues[field]],
              };
        },
        { description: [], intervention: [] }
      );
      return {
        description: this.sortQuestionByCategories(result.description),
        intervention: this.sortQuestionByCategories(result.intervention),
      };
    },
    generateQList() {
      return (options) => {
        if (!options) return [];
        return Object.keys(options).map((o) => ({
          value: o,
          text: options[o].charAt(0).toUpperCase() + options[o].slice(1),
        }));
      };
    },
    getGastroDescSelectInput() {
      let data = [];
      this.gastroDesc.forEach((desc) => {
        const items = this.getDescOptions(desc.category);
        if (items) {
          data.push({ ...desc, items });
        }
      });
      return data;
    },
    getFirstLocationItem() {
      const loca = this.questionsByPart.description
        ? this.questionsByPart.description.find((i) => i.category === "loca")
        : {};
      const data = {
        loca: {
          title: "Location",
          inputType: "select",
          value: "",
          option: {},
        },
        dist: {
          title:
            EXAMINATION_TYPE_OF_GASTROSCOPY === this.examType
              ? "From Incisor"
              : "Distance",
          inputType: "text",
          value: "",
          option: {
            textType: "number",
            suffix: "cm",
          },
        },
      };
      if (loca && loca.items) {
        Object.keys(data).forEach((key) => {
          const firstLoca = loca.items[0];
          if (loca.items[0].questions[key]) {
            data[key].value = firstLoca.questions[key]?.value;
            data[key].questionID = firstLoca.questions[key]?.questionID;
            data[key].locationId = firstLoca.id;
          } else {
            delete data[key];
          }
        });
        return data;
      }
      return null;
    },
    getLocationQuestion() {
      return (qID) => {
        if (!this.getQuestionsList || !qID || !this.getQuestionsList[qID])
          return [];
        const OPTIONS = this.getQuestionsList[qID].options;
        const result = Object.keys(OPTIONS).map((o) => ({
          value: o,
          text: OPTIONS[o].charAt(0).toUpperCase() + OPTIONS[o].slice(1),
        }));
        return result;
      };
    },
    getDescOptions() {
      return (category) => {
        const result = this.questionsByPart.description.find(
          (i) => i.category === category
        );
        return result && result.items[0];
      };
    },
    getIntervenOptions() {
      return (category) => {
        const result = this.questionsByPart.intervention.find(
          (i) => i.category === category
        );
        return result && result.items[0];
      };
    },
    getSpecimenLabel() {
      const result = this.questionsByPart.intervention.find(
        (i) => i.category === "patho"
      );
      return result
        ? result.items.find((i) => i.config.field === "label")
        : null;
    },
  },
  methods: {
    sortQuestionByCategories(dataArray) {
      const categorymap = {
        // n: null,
        m: "maneuver", //
        l: "clo location",
        p: "patho",
        d: "device",
        i: "injection",
        ic: "intraoperative condition",
        o: "other",
      };
      let categoryList = {};

      dataArray.map((item) => {
        /**
         * 1. 若有questionID 將對應的問題裝進 item.config 裡
         * 2. 若 item.config 有值確認他是否有選擇 intervenOther
         * 3. item[0] 用來處理 locations
         * 4. 依據不同情境把 item 放進特定 key值 aka question
         * 5. 關鍵在handle catArray
         */

        if (item.questionID) {
          item.config =
            this.getQuestionsList && this.getQuestionsList[item.questionID];
        }

        if (item.config) {
          const textAreaFields = ["intervenOther"]; // 選擇 other 會有填寫 other 的input 匡
          textAreaFields.indexOf(item.config.field) >= 0 &&
            (item.config.inputType = "ta");
        }

        if (
          item[0] &&
          item[0].questions &&
          (item[0].questions.loca || item[0].questions.dist)
        ) {
          categoryList.loca = item;
        }

        const ifICSelect =
          item.category == "ic" && item.config.inputType == "s";
        const catName = categorymap[item.category];

        if (ifICSelect) {
          categoryList[item.config.question] = [];
          categoryList[item.config.question].push(item);
        } else if (catName) {
          !categoryList[catName] && (categoryList[catName] = []);
          categoryList[catName].push(item);
        } else if (item.questionID) {
          // 物件裡會顯示對應的物件名稱
          const question =
            this.getQuestionsList &&
            this.getQuestionsList[item.questionID].question;
          categoryList[question] = item;
        }
      });

      const catArray = Object.entries(categoryList).map(([key, value]) => {
        const items = Array.isArray(value) ? value : [value];
        return {
          category: key,
          items,
        };
      });

      return catArray;
    },
    getQuestionIDContent(questionID) {
      return this.getQuestionsList && this.getQuestionsList[questionID];
    },
  },
};
</script>

<style lang="scss">
.basicExamFindingRecord {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5rem;
  &__descript {
    display: grid;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    &--fullhalf {
      grid-column: 1/3;
    }
    &--fullfull {
      grid-row: 1/3;
      grid-column: 1/3;
    }
  }
  &__intervention {
    display: grid;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }
}
</style>
