const routesUtils = {
  withPrefix: (prefix, routes) => {
    return routes.map(route => {
      route.path = prefix + route.path
      route.meta = { privilege: true }

      if (route.children) {
        route.children = route.children.map(child => ({
          ...child,
          meta: { privilege: true },
        }))
      }
      return route
    })
  },
}

export default routesUtils
