<template>
  <v-container
    class="findings-form-level-one-container mx-0"
    :class="[getFindingOneClass()]"
  >
    <v-layout row wrap>
      <v-flex xs4>
        <v-select
          v-if="finding.questions.finding"
          hide-details
          :items="findingOptionItems"
          :value="finding.questions.finding.value"
          :class="[getFocusClass('finding')]"
          label="Finding"
          @change="$emit('change', { field: 'finding', newValue: $event })"
        ></v-select>
      </v-flex>
      <v-flex xs3 align-self-end >
        <FindingNumberInput :finding="finding" @change="$emit('change', $event)" v-if="isNumberInputShow" :class="['input-container', getFocusClass('number')]"/>
      </v-flex>
      <v-flex xs5 align-self-end v-if="isTakeNoteOfInputShow">
        <TakeNoteInput
          :options="takeNoteOptionItems"
          :value="finding.questions.takeNote.value"
          :getFocusClass="getFocusClass"
          @change="$emit('change', $event)"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import forms from '@/utils/forms'
import FindingNumberInput from "./FindingNumberInput.vue";
import TakeNoteInput from './TakeNoteInput.vue';

export default {
  name: 'ExaminationFindingsFormLevelOne',

  props: {
    findingIndex: Number,
    finding: Object,
    questions: Object,
    hiddenParams: {
      required: false,
      type: Object,
    },
    isExamination: Boolean,
  },

  data() {
    return {}
  },

  components: {
    FindingNumberInput,
    TakeNoteInput
  },

  computed: {
    findingQID() {
      return this.finding.questions.finding.questionID
    },

    takeNoteQID() {
      return this.finding.questions.takeNote.questionID
    },

    isCountableFinding() {
      return this.finding.questions.finding && this.finding.questions.number
    },

    isNumberInputShow() {
      return this.isCountableFinding && this.finding.questions.number
    },

    isTakeNoteOfInputShow() {

      return (
        this.isCountableFinding &&
        this.finding.questions &&
        this.finding.questions.number &&
        Number(this.finding.questions.number.value) > 1 &&
        this.questions[this.findingQID].options
      )
    },

    findingOptionItems() {
      const qID = this.findingQID
      const options = this.questions[qID].options

      let temp_options = []
      let temp_obj = {}
      const specialHandelText = { "colon maltoma": "Colon MALToma" }
      for (let key in options) {
        temp_options.push(options[key])
        temp_obj[options[key]] = {
          value: key,
          text: specialHandelText[options[key]] || options[key].charAt(0).toUpperCase() + options[key].slice(1),
        }
      }
      temp_options.sort()

      let arr = []
      for (let i in temp_options) {
        arr.push(temp_obj[temp_options[i]])
      }

      return arr
    },

    takeNoteOptionItems() {
      const qID = this.takeNoteQID
      const options = this.questions[qID].options

      let arr = []

      for (const key in options) {
        arr.push({
          value: key,
          text: options[key],
        })
      }

      return arr
    },
  },
  methods: {
    getFocusClass(key) {
      if (this.hiddenParams) {
        return this.hiddenParams.focus == key ? 'focus' : ''
      } else {
        return ''
      }
    },
    getFindingOneClass() {
      if (this.isExamination) return 'is-examination'
    },
  },
}
</script>
