<template>
  <div class="text-with-label-component" :class="{ '--dense': dense }">
    <div class="d-none d-md-block label">{{ label }}</div>
    <div class="text" :class="textClassList">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "TextWithLabel",

  props: {
    text: String,
    label: String,
    dense: {
      type: Boolean,
      default: false,
    },
    textClassList: Array,
  },
};
</script>

<style lang="scss">
@import "@/styles/components/textWithLabel.scss";
</style>
