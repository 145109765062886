<template>
  <v-layout row wrap data-testid="examFindingForm">
    <!-- <v-tabs v-model="recordTabs" class="elevation-0 records-tabs"> -->
    <v-flex xs7>
      <!-- observation questions -->
      <div class="template-header pt-1 py-md-2 fsz-14 fsz-md-20">Observation</div>
      <description-records-form
        class="pr-2"
        :id="record.id"
        :data="questionsByPart.description"
        :questions="questions"
        :hideLocationImage="hideLocationImage"
        :hiddenParams="hiddenParams"
        :examination_typeID="examination_typeID"
        :is-examination="isExamination"
        @change="saveQuestionInput($event)"
        @addLocation="$emit('addLocation')"
        @deleteLocation="$emit('deleteLocation', $event)"
        @locationChange="$emit('locationChange', $event)"
      ></description-records-form>
      <v-container fluid grid-list-lg class="px-0">
        <v-layout row wrap> </v-layout>
      </v-container>
    </v-flex>
    <v-flex xs5>
      <div class="template-header pt-2 pb-2 fsz-14 fsz-md-20" v-show="questionsByPart.intervention.length">Intervention</div>
      <!-- intervention questions -->
      <intervention-records-form
        :class="['pr-2', `interven-${record.id}`]"
        :data="questionsByPart.intervention"
        :hiddenParams="hiddenParams"
        :is-examination="isExamination"
        @change="saveQuestionInput($event)"
      ></intervention-records-form>
    </v-flex>
    <!-- slot for adding custom elements after the form -->
    <slot name="form-bottom"></slot>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'
import InterventionRecordsForm from '@/components/examinations/InterventionRecordsForm.vue'
import DescriptionRecordsForm from '@/components/examinations/DescriptionRecordsForm.vue'

export default {
  name: 'ExaminationFindingsFormLevelTwo',

  props: {
    show: Boolean,
    findingIndex: Number,
    recordIndex: Number,
    record: Object,
    questions: Object,
    hiddenParams: {
      required: true,
      type: Object,
    },
    hideLocationImage: {
      required: false,
      default: false,
      type: Boolean,
    },
    isExamination: {
      required: false,
      default: false,
      type: Boolean,
    },
    examination_typeID: Number,
  },
  components: {
    InterventionRecordsForm,
    DescriptionRecordsForm,
  },

  data() {
    return {
      recordTabs: 0,
    }
  },

  computed: {
    questionsByPart() {
      const qValues = this.record.questions
      let description = []
      let intervention = []

      for (const field in qValues) {
        const part = qValues[field].part
        const qID = qValues[field].questionID

        if (qID) {
          const category = this.questions[qID].category
          qValues[field].category = category
        }

        if (field === 'location' || part === 'd') {
          if (field === 'interven') {
            intervention.push(qValues[field])
          } else {
            description.push(qValues[field])
          }
        } else {
          intervention.push(qValues[field])
        }
      }
      return {
        description: this.sortQuestionByCategories(description),
        intervention: this.sortQuestionByCategories(intervention),
      }
    },
  },
  watch: {
    'hiddenParams.nowQuestionPart': {
      handler: function (newVal, oldVal) {
        if (newVal == 'i') {
          this.recordTabs = 1
        } else if (newVal == 'd') {
          this.recordTabs = 0
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('examinations', [
      'setStoreHiddenParameter',
      'fetchQuestionJSON',
    ]),
    saveQuestionInput({ newValue, qID }) {
      const field = this.questions[qID].field
      const integerQID = [85, 88, 124]

      if (integerQID.includes(qID) && newValue >= 0) {
        newValue = parseInt(newValue, 10)
      }

      this.fetchQuestionJSON(qID).then((result) => {
        const inputType = result.inputType
        this.$emit('change', {
          field,
          newValue,
          inputType: inputType,
        })
      })
    },
    setQuestionPart(value) {
      this.setStoreHiddenParameter({
        target: 'nowQuestionPart',
        value: value,
      })
    },
    sortQuestionByCategories(dataArray) {
      const categorymap = {
        // n: null,
        m: 'maneuver',
        l: 'clo location',
        p: 'patho',
        d: 'device',
        i: 'injection',
        ic: 'intraoperative condition',
        o: 'other',
      }
      let categoryList = {}
      const self = this
      const textAreaFields = ['intervenOther']

      dataArray.map((item, index) => {
        const catName = categorymap[item.category]
        if (item.questionID) {
          item.config = self.questions[item.questionID]
        }

        if (item.config) {
          textAreaFields.indexOf(item.config.field) >= 0 &&
            (item.config.inputType = 'ta')
        }
        //console.log(item.questionID)
        // search for default info
        if (
          item[0] &&
          item[0].questions &&
          (item[0].questions.loca || item[0].questions.dist)
        ) {
          categoryList.loca = item
        }

        const ifICSelect = item.category == 'ic' && item.config.inputType == 's'
        if (ifICSelect) {
          categoryList[item.config.question] = []
          categoryList[item.config.question].push(item)
        } else if (catName) {
          !categoryList[catName] && (categoryList[catName] = [])
          categoryList[catName].push(item)
        } else if (item.questionID) {
          const question = self.questions[item.questionID].question
          categoryList[question] = item
        }
      })

      const catArray = Object.entries(categoryList).map((arr) => {
        const items = Array.isArray(arr[1]) ? arr[1] : [arr[1]]
        return {
          category: arr[0],
          items,
        }
      })

      return catArray
    },
  },
}
</script>
<style lang="scss" scoped>
.template-header {
  text-transform: uppercase;
  color: grey; //color-code
  font-size: 20px;
}
</style>
