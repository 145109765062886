<template>
    <div class="d-flex flex-row">
        <div id="radarChart" v-for="(item, index) in series" :key="index">
            <apexchart
                type="radar"
                height="350"
                :options="chartOptions[index]"
                :series="series[index]"
            ></apexchart>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    name: "RadarChart",
    components: {},
    apexchart: VueApexCharts,
    data() {
        return {
            series: [
                [
                    {
                        name: "顏聖烈",
                        data: [80, 50, 30, 40, 100],
                    },
                    {
                        name: "楊宗勳",
                        data: [20, 30, 40, 80, 20],
                    },
                    {
                        name: "王俊偉",
                        data: [44, 76, 78, 13, 43],
                    },
                ],
                [
                    {
                        name: "顏聖烈",
                        data: [2, 11, 2, 0, 1],
                    },
                    {
                        name: "楊宗勳",
                        data: [3, 0, 4, 1, 5],
                    },
                    {
                        name: "王俊偉",
                        data: [1, 2, 0, 2, 3],
                    },
                ],
                [
                    {
                        name: "顏聖烈",
                        data: [1, 5, 2, 1, 1],
                    },
                    {
                        name: "楊宗勳",
                        data: [0, 6, 2, 1, 1],
                    },
                    {
                        name: "王俊偉",
                        data: [0, 3, 5, 4, 3],
                    },
                ],
                [
                    {
                        name: "顏聖烈",
                        data: [1, 0, 0, 1, 1],
                    },
                    {
                        name: "楊宗勳",
                        data: [1, 0, 1, 0, 1],
                    },
                    {
                        name: "王俊偉",
                        data: [0, 1, 0, 0, 2],
                    },
                ],
            ],
            chartOptions: [
                {
                    chart: {
                        height: 350,
                        type: "radar",
                        dropShadow: {
                            enabled: true,
                            blur: 1,
                            left: 1,
                            top: 1,
                        },
                    },
                    title: {
                        text: "0.1-0.6 CM",
                    },
                    stroke: {
                        width: 2,
                    },
                    fill: {
                        opacity: 0.1,
                    },
                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: [
                            "無處置",
                            "EMR",
                            "Polypectomy",
                            "Biopsy",
                            "ESD",
                        ],
                    },
                },
                {
                    chart: {
                        height: 350,
                        type: "radar",
                        dropShadow: {
                            enabled: true,
                            blur: 1,
                            left: 1,
                            top: 1,
                        },
                    },
                    title: {
                        text: "0.6-1.5 CM",
                    },
                    stroke: {
                        width: 2,
                    },
                    fill: {
                        opacity: 0.1,
                    },
                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: [
                            "無處置",
                            "EMR",
                            "Polypectomy",
                            "Biopsy",
                            "ESD",
                        ],
                    },
                },
                {
                    chart: {
                        height: 350,
                        type: "radar",
                        dropShadow: {
                            enabled: true,
                            blur: 1,
                            left: 1,
                            top: 1,
                        },
                    },
                    title: {
                        text: "1.5-3 CM",
                    },
                    stroke: {
                        width: 2,
                    },
                    fill: {
                        opacity: 0.1,
                    },
                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: [
                            "無處置",
                            "EMR",
                            "Polypectomy",
                            "Biopsy",
                            "ESD",
                        ],
                    },
                },
                {
                    chart: {
                        height: 350,
                        type: "radar",
                        dropShadow: {
                            enabled: true,
                            blur: 1,
                            left: 1,
                            top: 1,
                        },
                    },
                    title: {
                        text: ">3 CM",
                    },
                    stroke: {
                        width: 2,
                    },
                    fill: {
                        opacity: 0.1,
                    },
                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: [
                            "無處置",
                            "EMR",
                            "Polypectomy",
                            "Biopsy",
                            "ESD",
                        ],
                    },
                },
            ],
        };
    },
};
</script>
