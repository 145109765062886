<template>
  <article
    class="h-100 pos-relative px-2"
    :class="{ 'finding-over-cover ': getStoreHiddenParameter.over }"
  >
    <section
      v-show="findingIndex === activeIdx"
      v-for="(finding, findingIndex) in getFindings"
      :key="finding.id"
      class="pt-3 basicExamFindingInfo"
    >
      <BasicExamFindingForm
        :finding="finding"
        :findingsOptions="questionList"
        @change="
          saveQuestionInput({
            findingID: finding.id,
            findingIndex: findingIndex,
            ...$event,
          })
        "
      />

      <ul class="py-3 pl-0" data-test="record-list">
        <li
          data-test="record-list-item"
          class="list-style-none"
          :class="{ 'h-100': recordIndex === 0 }"
          v-for="(record, recordIndex) in finding.records"
          :key="record.id"
        >
          <BasicExamFindingRecord
            class="h-100"
            v-show="recordIndex === 0"
            :examType="examType"
            :record="record"
            @locationChange="
              updateExaminationLocationColumn({
                findingIndex,
                recordIndex,
                $event,
              })
            "
            @change="
              saveFindingRecord({
                findingID: finding.id,
                recordID: record.id,
                recordIndex,
                findingIndex,
                event: $event,
              })
            "
          />
        </li>
      </ul>
    </section>
  </article>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BasicExamFindingForm from "./BasicExamFindingForm.vue";
import BasicExamFindingRecord from "./BasicExamFindingRecord.vue";

export default {
  name: "BasicExamFindingInfo",
  components: {
    BasicExamFindingForm,
    BasicExamFindingRecord,
  },
  props: {
    examinationId: {
      required: true,
    },
    activeIdx: {
      required: true,
      type: Number,
    },
    examType: Number,
    findings: {
      type: Array,
      default: () => [],
    },
    questionList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("examinations", ["getStoreHiddenParameter"]),
    getFindings() {
      return this.findings || [];
    },
  },
  methods: {
    ...mapActions("examinations", [
      "updateFindingQuestions",
      "setStoreHiddenParameter",
      "updateExaminationLocationColumn",
    ]),
    saveFindingRecord(obj) {
      const { findingID, recordID, recordIndex, findingIndex } = obj;
      const { field, newValue } = obj.event;

      let maneuver = ["coldSnare", "hotSnare", "snare"];
      if (maneuver.indexOf(field) !== -1) {
        this.handleManeuver(findingIndex, recordIndex);
      }

      let data = {
        examination_typeID: this.examType,
        findingIndex,
        findingID,
        field,
        recordID,
        recordIndex,
        value: newValue,
      };
      this.updateFindingQuestions(data);
    },
    saveQuestionInput(obj) {
      const validField = ["finding", "number", "takeNote"];
      const { findingIndex, findingID, field, value, recordIndex, item } = obj;
      if (!validField.includes(field)) {
        console.error("invalid", field);
        return;
      }
      let d = {
        examination_typeID: this.examType,
        findingIndex,
        findingID,
        field,
        value,
        recordIndex,
        item,
      };
      // setRecordIndex
      this.handleFieldSetRecordIndex(d);
      // modify partial value of field
      const data = this.handleDifferentField(d);
      this.updateFindingQuestions(data);
    },
    setStoreRecordIndex(idx) {
      this.setStoreHiddenParameter({
        target: "nowRecordIndex",
        value: idx || 0,
      });
    },
    handleFieldSetRecordIndex(data) {
      const { field, value } = data;
      if (field === "number") {
        if (!isNaN(value) || value == "several" || value == "multiple") {
          this.setStoreRecordIndex(0);
        }
      } else if (field === "takeNote") {
        if (value == "499" || value == "501") {
          this.setStoreRecordIndex(0);
        }
      }
    },
    handleDifferentField(data) {
      const { field, value } = data;
      if (field === "number") {
        if (isNaN(value) && value !== "several" && value !== "multiple") {
          return { ...data, value: 1 };
        }
      }
      return data;
    },
  },
};
</script>

<style lang="scss">
.basicExamFindingInfo {
  display: grid;
  grid-template-rows: 2fr 5fr;
  height: 100%;
}
</style>
