<template>
  <v-layout row fill-height id="homepage">
    <v-flex xs8 id="home-list-all">
      <v-layout wrap class="pt-4">
        <v-flex xs12>
          <v-form @submit.prevent="searchExaminations">
            <v-layout wrap>
              <v-flex xs3 class="pl-4 pr-2">
                <v-menu
                  v-model="seachFromMenu"
                  :close-on-content-click="false"
                  full-width
                  max-width="290"
                >
                  <v-text-field
                    slot="activator"
                    :value="computedFromDate"
                    :label="$t('homepage.from')"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="searchFromDate"
                    @change="seachFromMenu = false"
                    no-title
                    :max="searchToDate"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs3 class="px-2">
                <v-menu
                  v-model="seachToMenu"
                  :close-on-content-click="false"
                  full-width
                  max-width="290"
                >
                  <v-text-field
                    slot="activator"
                    :value="computedToDate"
                    :label="$t('homepage.to')"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="searchToDate"
                    @change="seachToMenu = false"
                    no-title
                    :min="searchFromDate"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs3 class="pl-2 pr-4">
                <v-text-field
                  v-model="searchExaminationRecords"
                  :label="$t('homepage.examination_records')"
                  flat
                  hide-details
                />
              </v-flex>

              <v-flex xs3 class="pl-2 pr-4">
                <v-text-field
                  v-model="seachMedicalRecords"
                  :label="$t('homepage.medical_records')"
                  flat
                  hide-details
                  :rules="xssRules"
                ></v-text-field>
              </v-flex>

              <v-flex id="search-btn-container" xs12>
                <v-btn
                  type="submit"
                  round
                  color="primary"
                  dark
                  @submit.prevent="search"
                  >{{$t('homepage.search')}}</v-btn
                >
                <v-btn
                  type="button"
                  round
                  color="primary"
                  dark
                  @click="fetchTodayExaminationsList()"
                  >{{$t('homepage.today')}}</v-btn
                >
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>

        <v-layout row justify-space-between class="pt-4 px-4">
          <h1 class="headline font-weight-light">{{$t('homepage.examinations')}}</h1>
          <v-btn
            :disabled="disabledSyncWorklist"
            type="submit"
            round
            color="primary"
            @click="handleWorklistSync"
          >
              {{ syncWorklistText }}
            </v-btn
          >
        </v-layout>

        <v-flex xs12 fill-height>
          <template>
            <v-data-table
              :headers="headers"
              :items="getExaminations"
              :search="search"
              class="ma-4"
              item-key="id"
              :rows-per-page-items="rowsPerPages"
              :rows-per-page-text="$t('homepage.rows_per_page')"
              :no-data-text="$t('homepage.no_data_available')"
            >
              <template slot="items" slot-scope="props">
                <tr :class="{ active: selectedExamination === props.item.url }" @click="goToPage(props.item.url); selectExamination(props.item.url)">
                  <td class="fz-20 font-weight-bold">{{ props.item.date.replace(/\//g, "-") }}</td>
                  <td class="fz-20 font-weight-bold">{{ props.item.accNo }}</td>
                  <td :class="['fz-20', 'font-weight-bold', getColor(props.item.examination_typeID)]">{{ examinationType(props.item.examination_typeID) }}</td>
                  <td class="fz-20 font-weight-bold">{{ props.item.patientName }}</td>
                  <td class="fz-20 font-weight-bold">{{ props.item.medicalRecord }}</td>
                  <td class="fz-20 font-weight-bold" :style="{ 'background-color': statusColor(props.item.examinationStatus)}">{{ examinationStatus(props.item.examinationStatus)}}</td>
                  <!-- ONLY FOR ZSCMH
                  <td class="fz-20 font-weight-bold">{{ reportStatus(props.item.reportStatus) }}</td>
                  <td>
                    <v-btn
                      :loading="loading5"
                      :disabled="loading5"
                      class="ma-2 black--text"
                      @click="searchExaminations2()"
                    >
                      {{ props.item.reportShippingStatus || "TODO" }}
                      <v-icon color="info"> cached </v-icon>
                    </v-btn>
                    <br />
                    {{ timeToUTC8(props.item.reportShippingTime) }}
                  </td>
                  <td>
                    <v-btn
                      class="ma-2 black--text"
                      @click="
                        selectExamination(props.item.url);
                        editDialog = true;
                      "
                    >
                      <label>傳送至新單號</label>
                      <v-icon color="info"> near_me </v-icon>
                    </v-btn>
                    <br />
                    {{ timeToUTC8(props.item.reportShippingTime) }}
                  </td>
                  -->
                </tr>
              </template>
            </v-data-table>
            <v-dialog
              v-if="editDialog"
              v-model="editDialog"
              persistent
              max-width="500px"
            >
              <v-card>
                <v-card-text>
                  <v-container>
                    <div>
                      <div cols="6">
                        <label>院內碼:</label
                        ><font>{{
                          selectedExaminationInfo.hospitalCode || ""
                        }}</font>
                      </div>
                    </div>
                    <div>
                      <div cols="6">
                        <label>日期:</label
                        ><font>{{
                          selectedExaminationInfo.examinationDate | Date
                        }}</font>
                      </div>
                    </div>
                    <div>
                      <div cols="6">
                        <label>病患姓名:</label
                        ><font>{{ selectedExaminationInfo.patientName }}</font>
                      </div>
                    </div>
                    <div>
                      <div cols="6">
                        <label>病歷號碼:</label
                        ><font>{{
                          selectedExaminationInfo.medicalRecord
                        }}</font>
                      </div>
                    </div>
                    <div>
                      <div cols="6">
                        <label>檢查單號:</label
                        ><font>{{
                          selectedExaminationInfo.examinationRecord
                        }}</font>
                      </div>
                    </div>
                    <div align="center">
                      <div cols="6">
                        <v-text-field
                          v-model="newAccNo"
                          filled
                          label="新檢查單號"
                          clearable
                        ></v-text-field>
                      </div>
                    </div>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="darken-2" text @click="editDialog = false">
                    Cancel
                  </v-btn>

                  <v-btn
                    color="darken-2"
                    text
                    @click="reportShippingToNewAccNo()"
                  >
                    Send
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-if="transferLoading"
              v-model="transferLoading"
              hide-overlay
              persistent
              width="300"
            >
              <v-card color="primary" dark>
                <v-card-text>
                  報告轉送中
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              v-if="notifyDialog"
              v-model="notifyDialog"
              max-width="290"
            >
              <v-card>
                <v-card-title class="text-h3"> 報告轉送結果 </v-card-title>

                <v-card-text>
                  {{ transferResultMessage }}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="darken-1" text @click="initialNotifyMessage()">
                    知道了
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs4 id="home-list-detail">
      <router-view />
    </v-flex>
  </v-layout>
</template>

<script>
import moment from "moment";
import { get } from "lodash";
import { mapGetters, mapActions } from "vuex";
import { reportServices } from "@/services/db";

const DISABLE_SYNC_SEC = 5

export default {
  data() {
    return {
      newAccNo: "",
      loading5: false,
      transferLoading: false,
      notifyDialog: false,
      transferResultMessage: "",
      editDialog: false,
      disabledSyncWorklist: false,
      syncWorklistCount: 0,
      syncWorklistTimer: 0,

      selectedExaminationInfo: {
        medicalRecord: "",
        examinationRecord: "",
        hospitalCode: "",
        patientName: "",
        examinationDate: "",
      },
      search: "",
      rowsPerPages: [10, 20, { text: this.$t('homepage.rows_per_page'), value: -1 }],
      selectedExamination: null,
      seachFromMenu: false,
      searchFromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10),
      seachToMenu: false,
      searchToDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10),
      seachMedicalRecords: "",
      searchExaminationRecords: "",
      reportStatusList: ["Unedited", "Editing", "Finalized"],
      xssRules: [
        (value) => {
          if (this.seachMedicalRecords == null) {
            return (this.seachMedicalRecords = "");
          } else {
            let xssFilter = new RegExp(
              /(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/gi
            );
            this.seachMedicalRecords = this.seachMedicalRecords.replace(
              /[&><%^&"]/g,
              ""
            );
            this.seachMedicalRecords = xssFilter.test(this.seachMedicalRecords)
              ? (this.seachMedicalRecords = "")
              : this.seachMedicalRecords;
            return true;
          }
        },
      ],
    };
  },

  created() {
    window.onkeydown = function (e) {}
    window.onkeyup = function (e) {}
    this.fetchExaminationsList({
      from: this.searchFromDate,
      to: this.searchToDate,
      medicalRecord: this.seachMedicalRecords,
      examinationRecord: this.searchExaminationRecords,
    })
  },

  computed: {
    ...mapGetters("examinations", ["getExaminations"]),

    computedFromDate() {
      return this.searchFromDate
        ? moment(this.searchFromDate).format("MM-DD-YYYY")
        : "";
    },

    computedToDate() {
      return this.searchToDate
        ? moment(this.searchToDate).format("MM-DD-YYYY")
        : "";
    },

    syncWorklistText() {
      return this.disabledSyncWorklist
        ? `TRY AGAIN ${this.syncWorklistCount}s`
        : this.$t('homepage.sync_worklist')
    },

    headers() {
      return [
        {
          text: this.$t('homepage.date'),
          align: "left",
          value: "date",
        },
        {
          text: this.$t('homepage.examination_record'),
          align: "left",
          value: "accNo",
        },
        {
          text: this.$t('homepage.examination_type'),
          align: "left",
          value: "examination_typeID",
        },
        {
          text: this.$t('homepage.patient_name'),
          align: "left",
          value: "patientName",
        },
        {
          text: this.$t('homepage.medical_record'),
          align: "left",
          value: "medicalRecord",
        },
        {
          text: this.$t('homepage.status'),
          align: "left",
          value: "examinationStatus",
        },
        /* ONLY FOR ZSCMH
        {
          text: "Report Status",
          align: "left",
          value: "reportStatus",
        },
        {
          text: "Report Shipping Status",
          align: "left",
          value: "reportShippingStatus",
        },
        {
          text: "Report Re-Shipping with AccNo",
          align: "left",
          value: "transferAccNo",
        },
        */
      ]
    },
    examinationTypesList() {
      return ["", this.$t('homepage.gastoscopy'), this.$t('homepage.colonoscopy'), "Sigmoidoscopy"]
    },
    examinationStatusList() {
      return [this.$t('homepage.unfinished'), "Executing", this.$t('homepage.finished')]
    },
  },

  methods: {
    ...mapActions("examinations", [
      "fetchExaminationsList",
      "fetchNewExaminationsList",
    ]),

    goToPage(page) {
      this.$router.replace({ path: `/home/${page}` });
    },

    async selectExamination(id) {
      this.selectedExamination = id;
      const row = await this.selectedRow(id);
      this.selectedExaminationInfo.examinationRecord = row[0].accNo;
      this.selectedExaminationInfo.medicalRecord = row[0].medicalRecord;
      this.selectedExaminationInfo.examinationDate = row[0].date;
      this.selectedExaminationInfo.patientName = row[0].patientName;
      this.selectedExaminationInfo.hospitalCode = row[0].hospitalCode;
    },

    fetchTodayExaminationsList() {
      this.searchFromDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10);
      this.searchToDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10);
      this.seachMedicalRecords = "";
      let condition = {
        from: this.searchFromDate,
        to: this.searchToDate,
        medicalRecord: this.seachMedicalRecords,
        examinationRecord: this.searchExaminationRecords,
      };
      this.fetchExaminationsList(condition);
      this.$forceUpdate();
    },
    searchExaminations() {
      let condition = {
        from: this.searchFromDate,
        to: this.searchToDate,
        medicalRecord: this.seachMedicalRecords,
        examinationRecord: this.searchExaminationRecords,
      };
      this.fetchExaminationsList(condition);
      this.$forceUpdate();
    },
    searchExaminations2() {
      this.loading5 = true;
      let condition = {
        from: this.searchFromDate,
        to: this.searchToDate,
        medicalRecord: this.seachMedicalRecords,
        examinationRecord: this.searchExaminationRecords,
      };
      this.fetchExaminationsList(condition);
      this.$forceUpdate();
      this.loading5 = false;
    },
    examinationType(number) {
      return this.examinationTypesList[number];
    },
    examinationStatus(number) {
      return this.examinationStatusList[number];
    },
    reportStatus(number) {
      return this.reportStatusList[number];
    },
    statusColor(number) {
      let condition = this.examinationStatusList[number];
      return condition === "Finished" ? "#FFC49BCC" : null;
    },
    timeToUTC8(dt) {
      let t = dt ? moment(dt).format("YYYY-MM-DD HH:mm:ss") : dt;
      return t;
    },
    getColor(type_id) {
      switch (type_id) {
        case 1:
          return 'color--gastroscopy'
        case 2:
        case 3:
          return 'color--colonoscopy'
        default:
          return ''
      }
    },
    async selectedRow(id) {
      return this.getExaminations.filter((d) => {
        return d.url == id;
      });
    },
    async reportShippingToNewAccNo() {
      this.editDialog = false;
      this.transferResultMessage = "";
      if (this.newAccNo.trim() !== "") {
        this.transferLoading = true;
        const res = await reportServices.transferToNewAccNo({
          patientId: this.selectedExaminationInfo.medicalRecord,
          accNo: this.selectedExaminationInfo.examinationRecord,
          newAccNo: this.newAccNo,
        });
        this.transferResultMessage = get(res, 'data.message', '') || '';
        this.transferLoading = false;
        this.notifyDialog = true;
      }
    },
    initialNotifyMessage() {
      this.notifyDialog = false;
      this.newAccNo = "";
    },
    countdown() {
      clearInterval(this.syncWorklistTimer)
      this.disabledSyncWorklist = true
      this.syncWorklistCount = DISABLE_SYNC_SEC

      this.syncWorklistTimer = setInterval(() => {
        if (this.syncWorklistCount > 0) {
          this.syncWorklistCount -= 1
        } else {
          this.syncWorklistCount = 0
          this.disabledSyncWorklist = false
        }
      }, 1000)
    },
    handleWorklistSync() {
      if (!this.disabledSyncWorklist) {
        this.fetchNewExaminationsList().then(this.countdown)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#homepage {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  h1 {
    font-size: 20px !important;
  }

  h4 {
    font-size: 18px !important;
  }
}

#home-list-all {
  overflow: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

#home-list-detail {
  overflow-y: auto;
}

.search-container {
  :deep()
    .v-text-field.v-text-field--enclosed
    > .v-input__control
    > .v-input__slot {
    padding: 0;
  }

  :deep() .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: transparent;
  }
}

#search-btn-container {
  position: relative;
  text-align: center;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  :deep(.v-btn) {
    z-index: 1;
    padding-left: 28px;
    padding-right: 28px;
  }
}

.headline {
  padding-top: 10px;
}

.color--colonoscopy {
  color: $defaultOrange;
}

.color--gastroscopy {
  color: $defaultBlue;
}
</style>

<style lang="scss">
tr.active,
.theme--light.v-table tbody tr.active:hover:not(.v-datatable__expand-row) {
  background: #d6d6d6;
}

.fz-20 {
  font-size: 20px !important;
}
</style>