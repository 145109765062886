import { patientServices } from '@/services/db'

const state = {
  patients: [],
  patientsListFormat: [],
  patientSearchResult: [],
  patientSearchInfo : {
    page: 1,
    pgsz: 100,
    total: 0, // may not need
    total_page: 0
  }
}

// getters
const getters = {
  getPatients: state => state.patients,
  getPatientsListFormat: state => state.patientsListFormat,
  getPatientSearchResult: state => state.patientSearchResult,
  getPatientSearchInfo: state => state.patientSearchInfo
}

// actions
const actions = {

  async searchPatients ({ commit }, payload) {
    try {
      commit('setLoading', true, { root: true })
      const res = await patientServices.searchPatients(payload)
      const { page, pgsz, list, total, total_page } = res.data.paginate
      commit('setPatients', list)
      commit('setPatientsListFormat', list.reverse())
      commit('setPatientSearchResult', list)
      commit('setPatientSearchInfo', { page, pgsz, total, total_page })
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  fetchPatients ({ commit, getters }, payload) {
    try {
      if (!getters.getPatients.length) {
        return patientServices.fetchPatients(payload).then(patients => {
          commit('setPatients', patients)
          commit('setPatientsListFormat', patients.reverse())
        })
      }
      return Promise.resolve()
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit}, payload) {
    /*************************************
    * ID: patient id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    

    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      const res = await patientServices.updatePatientColumn(payload)
      if (!res.success) {
        return res  // provide error info for the ExaminationList
      }
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addPatient ({commit}, object) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new doctor */
      /* code here */
      let obj = {
        ...object,
        id: ( Math.ceil( Math.random() * 1000 ) )
      }

      const res = await patientServices.addPatient(obj)
      if (!res.success) {
        return res  // provide error info for the ExaminationList
      }
      commit('setLoading', false, { root: true })
     
    } catch (e) { 
      console.log(e)
    }
  },
  async sortPatient({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.patients.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.patients.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setPatients', data)
    commit('setLoading', false, { root: true })
  },

  async sortPatientSearchResult({commit}, payload) {
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.patientSearchResult.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.patientSearchResult.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setPatientSearchResult', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetPatient (state) {
    state.patients = []
  },

  setPatients (state, payload) {
    state.patients = payload
  },

  setPatientsListFormat (state, payload) {
    state.patientsListFormat = payload
  },
  setPatientSearchResult (state, payload) {
    state.patientSearchResult = payload
  },
  setPatientSearchInfo (state, payload) {
    state.patientSearchInfo = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}