var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"mb-1",attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('h3',[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm.finding.name)+" ")]),(_vm.finding.lesions && _vm.finding.lesions.length < 2)?[_c('span',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.ro))]),_c('span',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.earlyAdv))])]:_vm._e()],2)]),_c('v-flex',[_vm._t("findingAction")],2)],1),_c('ul',{staticClass:"pl-0"},_vm._l((_vm.finding.lesions),function(lesion,idx){return _c('li',{key:lesion.id,staticClass:"list-style-none mb-2"},[(
            lesion.observation ||
            lesion.intervention ||
            lesion.ro ||
            lesion.earlyAdv
          )?[(
              (_vm.finding.takeNote === 'each' && _vm.finding.lesions.length > 1) ||
              _vm.finding.takeNote !== 'each'
            )?_c('h4',[_vm._v(" "+_vm._s(_vm.finding.takeNote === 'each' ? `Lesion ${idx + 1}:` : `${ _vm.finding.takeNote.slice(0, 1).toUpperCase() + _vm.finding.takeNote.slice(1) }:`)+" ")]):_vm._e()]:_vm._e(),_c('p',{staticClass:"mb-0"},[(_vm.finding.lesions && _vm.finding.lesions.length > 1)?[_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(lesion.ro && `r/o:${lesion.ro}`))]),_c('span',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(lesion.earlyAdv))])]:_vm._e(),(lesion.observation)?_c('span',[_vm._v(" "+_vm._s(lesion.observation)+" ")]):_vm._e()],2),(lesion.intervention)?_c('p',[_c('span',{staticStyle:{"color":"#2894ff"}},[_vm._v("Intervention")]),_vm._v(": "+_vm._s(lesion.intervention)+" ")]):_vm._e()],2)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }