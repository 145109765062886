<style lang="scss">
.screenshots {
  border-radius: 4px;
}
</style>

<template>
  <v-container fluid grid-list-lg class="py-0 screenshots">
    <v-layout wrap>
      <template v-if="images.length">
        <screenshot
          v-for="(image, index) in images"
          :key="image.id"
          :length="images.length"
          :info="image"
          :index="index"
          :findings="findings"
          :landmarks="landmarks"
          @notify="notify"
          @zoom="zoom"
        />
      </template>
      <template v-else>
        <v-flex>
          <div class="border">
            <h3 class="pa-4 mb-0">No Images</h3>
          </div>
        </v-flex>
      </template>

      <screenshot-zoom
        v-model="zoomDialog"
        :src="zoomSrc"
        @close="onZoom(false)"
      />
      <notify v-bind="notifyBind" @cancel="onCancel" />
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import Screenshot from './screenshot'
import ScreenshotZoom from './screenshot-zoom'
import Notify from './notify'

export default {
  name: 'Screenshots',
  inheritAttrs: false,
  components: {
    Screenshot,
    ScreenshotZoom,
    Notify,
  },
  data() {
    return {
      notifyBind: {},
      zoomDialog: false,
      zoomSrc: '',
    }
  },
  computed: {
    ...mapGetters('examinations', ['getExaminationData', 'getLandmarkList']),
    images() {
      if (this.getExaminationData && this.getExaminationData.all) {
        return this.getExaminationData.all.sort((a, b) => b.id - a.id)
      }
      return []
    },
    landmarks() {
      return [
        {
          value: 0,
          text: 'Choose',
        },
      ].concat(
        this.getLandmarkList.map(elm => {
          return {
            value: elm.id,
            text: elm.landmark,
          }
        }),
      )
    },
    findings() {
      return [
        {
          value: 0,
          text: 'Choose',
        },
      ].concat(
        this.getExaminationData.findings.map((f, i) => {
          return {
            value: f.id,
            text: `Finding ${i + 1}`,
          }
        }),
      )
    },
  },
  methods: {
    notify(notifyBind) {
      this.notifyBind = notifyBind
    },
    onCancel() {
      this.notifyBind = { value: false }
    },
    onZoom(status) {
      this.zoomDialog = status
    },
    zoom(src) {
      this.onZoom(true)
      this.zoomSrc = src
    },
  },
}
</script>
