<template>
  <div ref="finish" class="go active">
    <div class="circle-1">
      <h1>{{ title }}</h1>
    </div>
    <div class="circle-2"></div>
    <div class="circle-3"></div>
    <div class="circle-4"></div>
  </div>
</template>
<script>
export default {
  name: "TextAnimation",
  props: {
    title: String,
  },

  mounted() {
    this.$refs.finish.classList.add("active");

    setTimeout(() => {
      if (this.$refs && this.$refs.finish) {
        this.$refs.finish.classList.remove("active");
        this.$emit("close");
      }
    }, 2000);
  },
};
</script>
<style lang="scss" scoped>
@keyframes circle-1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
@keyframes circle-2 {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  70% {
    transform: scale(1.3);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
@keyframes circle-3 {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  70% {
    transform: scale(1.6);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes circle-4 {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  70% {
    transform: scale(1.9);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.9);
    opacity: 0;
  }
}
.go {
  position: absolute;
  left: 40%;
  transform: translate(-50%, -50%);
  top: 35%;
  div {
    position: absolute;
    border-radius: 50%;
    background: white;
    color: $defaultBlue;
    width: 350px;
    height: 350px;
    opacity: 0;
    text-align: center;
    line-height: 350px;
    font-size: 45px;
  }
  h1 {
    padding: 10px;
  }
}

.go.active {
  z-index: 99;
  .circle-1 {
    z-index: 3;
    animation: circle-1 1.8s ease forwards;
  }
  .circle-2 {
    animation: circle-2 0.8s ease forwards;
  }
  .circle-3 {
    animation: circle-3 1.2s ease forwards;
  }
  .circle-4 {
    animation: circle-4 1.6s ease forwards;
  }
}
</style>
