<template>
  <div>
    <div
      v-for="(location, index) in data"
      :key="location.id"
      class="locations-group"
    >
      <div
        v-for="(locationItem, locationKey) in location.questions"
        :key="locationItem.questionID"
        :class="['location-item', getFocusIndexClass(index)]"
      >
        <number-select-input-two
          data-testid="location-dist"
          v-if="getQuestionJSON(locationItem.questionID).inputType == 'n'"
          :class="`${location.id}-${locationItem.questionID}`"
          :data1="locationItem.value + ''"
          :examination_typeID="examination_typeID"
          :label="distance(examination_typeID)"
          :float="false"
          :unit="'cm'"
          :isRequired="isExamination"
          @locationChange="
            $emit('locationChange', {
              id: location.id,
              column: locationKey,
              value: $event,
            })
          "
        />
        <v-select
          v-else
          data-testid="location-loca-orien"
          :class="`${location.id}-${locationItem.questionID}`"
          v-model="locationItem.value"
          :items="getLocationItems(locationItem)"
          :label="getLocationSelectLabel(locationItem.questionID)"
          :error="locationKey === 'loca' && isExamination"
          @change="
            $emit('locationChange', {
              id: location.id,
              column: locationKey,
              value: $event,
            })
          "
          hide-details
        />
      </div>
      <div class="action-btn">
        <v-btn
          v-if="index === 0"
          class="add-location-btn"
          icon
          flat
          small
          @click="$emit('addLocation')"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn
          v-else
          class="delete-location-btn"
          icon
          flat
          small
          @click="$emit('deleteLocation', location.id)"
        >
          <v-icon>remove</v-icon>
        </v-btn>
      </div>
    </div>

    <div
      class="location-image-container"
      v-if="!hideLocationImage && data.length === 1"
    >
      <img
        v-if="
          getLocationImage(data[0].questions) &&
            getLocationImage(data[0].questions) !== ''
        "
        :src="
          require(`@/assets/location_image/${getLocationImage(
            data[0].questions,
          )}`)
        "
      />
    </div>
  </div>
</template>

<script>
import forms from '@/utils/forms'
import NumberSelectInputTwo from '@/components/NumberSelectInputTwo.vue'

export default {
  name: 'Locations',

  props: {
    data: {
      required: true,
      type: Array,
    },

    questions: {
      required: true,
      type: Object,
    },

    hideLocationImage: {
      required: false,
      default: false,
      type: Boolean,
    },
    focus: {
      required: false,
      default: false,
      type: Boolean,
    },
    focusIndex: {
      required: false,
      type: Number,
    },
    examination_typeID: Number,
    isExamination: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    NumberSelectInputTwo,
  },
  methods: {
    getLocationImage: forms.getLocationImage,

    getQuestionJSON(qID) {
      return this.questions[qID]
    },

    getLocationItems(item) {
      const question = this.getQuestionJSON(item.questionID)
      question.options[0] = ''
      return forms.prepareSelectItems(question.options)
    },

    distance(a) {
      if (a == 1) {
        return 'from incisor'
      } else if (a == 2) {
        return 'Distance'
      }
    },

    getLocationSelectLabel(qID) {
      return this.questions[qID] ? this.questions[qID].question : ''
    },

    getFocusIndexClass(index) {
      if (this.focusIndex >= 0 && this.focus) {
        return this.focusIndex == index && this.focus == true ? 'focus' : ''
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/components/locations.scss';
</style>
