var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.data),function(location,index){return _c('div',{key:location.id,staticClass:"locations-group"},[_vm._l((location.questions),function(locationItem,locationKey){return _c('div',{key:locationItem.questionID,class:['location-item', _vm.getFocusIndexClass(index)]},[(_vm.getQuestionJSON(locationItem.questionID).inputType == 'n')?_c('number-select-input-two',{class:`${location.id}-${locationItem.questionID}`,attrs:{"data-testid":"location-dist","data1":locationItem.value + '',"examination_typeID":_vm.examination_typeID,"label":_vm.distance(_vm.examination_typeID),"float":false,"unit":'cm',"isRequired":_vm.isExamination},on:{"locationChange":function($event){return _vm.$emit('locationChange', {
            id: location.id,
            column: locationKey,
            value: $event,
          })}}}):_c('v-select',{class:`${location.id}-${locationItem.questionID}`,attrs:{"data-testid":"location-loca-orien","items":_vm.getLocationItems(locationItem),"label":_vm.getLocationSelectLabel(locationItem.questionID),"error":locationKey === 'loca' && _vm.isExamination,"hide-details":""},on:{"change":function($event){return _vm.$emit('locationChange', {
            id: location.id,
            column: locationKey,
            value: $event,
          })}},model:{value:(locationItem.value),callback:function ($$v) {_vm.$set(locationItem, "value", $$v)},expression:"locationItem.value"}})],1)}),_c('div',{staticClass:"action-btn"},[(index === 0)?_c('v-btn',{staticClass:"add-location-btn",attrs:{"icon":"","flat":"","small":""},on:{"click":function($event){return _vm.$emit('addLocation')}}},[_c('v-icon',[_vm._v("add")])],1):_c('v-btn',{staticClass:"delete-location-btn",attrs:{"icon":"","flat":"","small":""},on:{"click":function($event){return _vm.$emit('deleteLocation', location.id)}}},[_c('v-icon',[_vm._v("remove")])],1)],1)],2)}),(!_vm.hideLocationImage && _vm.data.length === 1)?_c('div',{staticClass:"location-image-container"},[(
        _vm.getLocationImage(_vm.data[0].questions) &&
          _vm.getLocationImage(_vm.data[0].questions) !== ''
      )?_c('img',{attrs:{"src":require(`@/assets/location_image/${_vm.getLocationImage(
          _vm.data[0].questions,
        )}`)}}):_vm._e()]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }