var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasReportTime),expression:"hasReportTime"}],class:[
    'd-inline-block',
    'px-2',
    'report-time',
    `--${_vm.position}`,
    { '--paused': !_vm.hasReportTime },
    { '--small': _vm.small },
  ],on:{"click":_vm.reset,"mouseover":_vm.handleMouseover,"mouseleave":_vm.handleMouseleave}},[_vm._v(" "+_vm._s(_vm.display)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }