<template>
  <v-container v-if="horizontal" grid-list-lg class="pa-0 pb-4">
    <v-layout class="timer" v-html="timer"/>
    <div class="time-inputs-wrapper">
      <slot name="content"></slot>
      <v-layout
        row
        v-for="item in list"
        :key="item.field"
        class="time-inputs-textfield"
      >
        <v-flex xs12 class="pb-0">
          <v-text-field
            :label="item.label"
            :value="data[item.field]"
            v-bind:hide-details="shouldHideDetails"
            @change="$emit('change', { field: item.field, event: $event })"
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>
  </v-container>

  <v-layout v-else class="time-inputs">
    <v-layout
      row
      v-for="item in list"
      :key="item.field"
      class="time-inputs-textfield"
    >
      <v-text-field
        :label="item.label"
        :value="data[item.field]"
        v-bind:hide-details="shouldHideDetails"
        @change="$emit('change', { field: item.field, event: $event })"
      ></v-text-field>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

const NO_TIME = '00:00:00'

export default {
  name: "TimeInputsGroup",

  props: {
    data: {
      type: Object,
    },

    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      list: [
        {
          field: "insertionTime",
          label: "Insertion Time",
        },
        {
          field: "proximalColonWithTime",
          label: "Proximal Colon Withdrawal Time",
        },
        {
          field: "withdrawTime",
          label: "Withdrawal Time",
        },
        {
          field: "totalExamTime",
          label: "Total Exam",
        },
      ],
      timer:
        '<font class="text_black">Say&nbsp;</font><font class="text_blue">EVAS GO</font>',
      preTimeCountingStep: 0,
      colorValue: 0,
      timerInterval: null,
      colorInterval: null,
      colorIntervalTime: 0,
    };
  },

  mounted() {
    this.autoTimer();
  },

  destroyed() {
    if (this.timerInterval != null) {
      clearInterval(this.timerInterval);
    }
  },

  computed: {
    ...mapGetters("examinations", [
      "getStoreHiddenParameter",
      "getExaminationData",
    ]),
    shouldHideDetails() {
      // return true;
      return this.hideDetails;
    },

    time_start() {
      return this.getExaminationData.examination.time_start;
    },

    time_insertion_level() {
      return this.getExaminationData.examination.time_insertion_level;
    },

    time_landmark_splenic_flexure() {
      return this.getExaminationData.examination.time_landmark_splenic_flexure;
    },

    time_finished() {
      return this.getExaminationData.examination.time_finished;
    },

    totalExamTime() {
      return this.getExaminationData.examination.totalExamTime;
    },
  },

  methods: {
    autoTimer() {
      const _this = this;
      this.timerInterval = setInterval(() => {
        if (_this.getStoreHiddenParameter.nowTimeCountingStep > 0) {
          switch (_this.getStoreHiddenParameter.nowTimeCountingStep) {
            case 1:
              _this.timer =
                '<font class="text_black">Insertion Time: </font><font class="text_blue">' +
                _this.calculatingTime(_this.getHHiiss(), _this.time_start) +
                "</font>";
              break;
            case 2:
              _this.timer =
                '<font class="text_black">Proximal Colon Withdrawal Time: </font><font class="text_blue">' +
                (_this.time_insertion_level !== NO_TIME ? _this.calculatingTime(_this.getHHiiss(), _this.time_insertion_level) : NO_TIME) +
                '</font>'
              break;
            case 3:
              _this.timer =
                '<font class="text_black">Withdrawal Time: </font><font class="text_blue">' +
                _this.calculatingTime(
                  _this.getHHiiss(),
                  _this.time_insertion_level
                ) +
                "</font>";
              break;
            case 4:
              _this.timer =
                '<font class="text_black">Total Exam: </font><font class="text_blue">' +
                _this.totalExamTime +
                '</font><font class="text_blue"> &nbsp; Finish!</font>';
              break;
          }
          if (
            _this.preTimeCountingStep !=
            _this.getStoreHiddenParameter.nowTimeCountingStep
          ) {
            //rgb(226, 193, 221)
            _this.preTimeCountingStep =
              _this.getStoreHiddenParameter.nowTimeCountingStep;
            _this.colorInterval = setInterval(() => {
              if (document.getElementsByClassName("timer").length > 0) {
                document.getElementsByClassName("timer")[0].style =
                  "background-color: rgba(226, 193, 221, " +
                  _this.colorValue +
                  "); border-radius: 10px;";
              }

              if (_this.colorIntervalTime <= 800) {
                _this.colorValue += 0.01;
              } else {
                _this.colorValue -= 0.01;
              }
              _this.colorIntervalTime += 10;
            }, 10);
            setTimeout(function () {
              clearInterval(_this.colorInterval);
              _this.colorValue = 0;
              _this.colorIntervalTime = 0;
              if (document.getElementsByClassName("timer").length > 0) {
                document.getElementsByClassName("timer")[0].style =
                  "background-color: rgba(226, 193, 221, " +
                  _this.colorValue +
                  "); border-radius: 10px;";
              }
            }, 1600);
          }
          _this.$forceUpdate();
        }
      }, 100);
    },

    // 取得 HH:ii:ss
    getHHiiss() {
      var x = new Date();
      //var y = x.getFullYear().toString();
      //var m = (x.getMonth() + 1).toString();
      //var d = x.getDate().toString();
      var h = x.getHours().toString();
      var i = x.getMinutes().toString();
      var s = x.getSeconds().toString();
      //(d.length == 1) && (d = '0' + d);
      //(m.length == 1) && (m = '0' + m);
      h.length == 1 && (h = "0" + h);
      i.length == 1 && (i = "0" + i);
      s.length == 1 && (s = "0" + s);
      var HHiiss = h + ":" + i + ":" + s;
      return HHiiss;
    },

    // 計算時間
    calculatingTime(a, b) {
      if (a && b) {
        var a = a.split(":");
        var b = b.split(":");
        a = Number(a[0]) * 3600 + Number(a[1]) * 60 + Number(a[2]);
        b = Number(b[0]) * 3600 + Number(b[1]) * 60 + Number(b[2]);
        var c = a - b;
        var HHiiss = "00:00:00";
        if (c > 0) {
          var h = parseInt(c / 3600);
          var i = parseInt((c - h * 3600) / 60);
          var s = c - h * 3600 - i * 60;
          String(h).length == 1 && (h = "0" + String(h));
          String(i).length == 1 && (i = "0" + String(i));
          String(s).length == 1 && (s = "0" + String(s));
          HHiiss = h + ":" + i + ":" + s;
        }
        return HHiiss;
      }
      return ''
    },
  },
};
</script>

<style lang="scss">
// @import '@/styles/components/textWithLabel.scss';
@import "@/styles/components/textInputsTextfield.scss";

.timer {
  display: inline-block;
  padding: 0px 8px 0px 8px;
  font-size: 17px;
}

.text_blue {
  font-weight: bold;
  color: #2196f3;
  font-size: 25px;
}

.text_black {
  font-weight: bold;
}

.text_red {
  font-weight: bold;
  color: red;
  font-size: 25px;
}
</style>
