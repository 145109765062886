<template>
  <div>
    <v-btn
      v-if="examinationType != 1"
      color="#3b587d"
      class="mb-3 text-white"
      block
      @click="setCommend(['indication']); setCommend(['open'])"> 
      Indication
    </v-btn>
    <div class="quick-commen">
      <div>
        <button 
          @click="setCommend(['evas go'])"> 
          Evas go 
        </button>
        <button 
          @click="setCommend(['finish'])"> 
          Finish 
        </button>
      </div>
      <div class="long-btn">
        <button
          v-if="examinationType != 1" 
          @click="setCommend(['insertion to']); setCommend(['cecum'])"> 
          Insertion to Cecum 
        </button>
        <button 
          v-if="examinationType != 1" 
          @click="setCommend(['proximal time'])"> 
          Proximal Time
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios';

export default {
  name: 'QuickCommend',

  mounted() {
  },
  methods: {
    setCommend(commend) {
      window.voiceInput(commend)
    },
  },
  computed: {
    ...mapGetters('examinations', [
      'getExaminationData',
    ]),
    examinationType() {
      return this.getExaminationData.examination.examination_typeID
    },
    examinationID() {
            return this.getExaminationData.examination.id
    },
    examination_typeID() {
        return this.getExaminationData.examination.examination_typeID
    },
  }
}
</script>
<style lang="scss" scoped>
.quick-commen {
  > div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
  }
  button {
    place-self: stretch;
    background: #232B5E;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    &:hover {
      background: #4c5bbb;
    }
  }
}
</style>
