<template>
  <v-layout row>
    <v-flex xs3 v-for="item in list" :key="item.field" class="pb-0">
      <v-text-field
        :label="item.label"
        :value="data[item.field]"
        hide-details
        @change="$emit('change', { field: item.field, event: $event })"
      ></v-text-field>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ExaminationTimeField',
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      list: [
        {
          field: 'insertionTime',
          label: 'Insertion Time',
        },
        {
          field: 'proximalColonWithTime',
          label: 'Proximal Colon Withdrawal Time',
        },
        {
          field: 'withdrawTime',
          label: 'Withdrawal Time',
        },
        {
          field: 'totalExamTime',
          label: 'Total Exam',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('examinations', [, 'getExaminationData']),
  },
}
</script>

<style lang="scss">
@import '@/styles/components/textInputsTextfield.scss';
</style>
