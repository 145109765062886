// import moment from 'moment'

export const mutations = {
  setUser (state, user) {
    state.user = user

    if (user) {
      sessionStorage.setItem('user', JSON.stringify(state.user))
    } else {
      sessionStorage.removeItem('user')
    }
  },
  setError (state, payload) {
    state.error = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setLoadingDescript (state, payload) {
    state.loadingDescript = payload
  },
  setIsAutoRecognize (state, payload) {
    state.isAutoRecognize = payload
  },
  SET_SETTING(state, setting) {
    state.setting = setting
}
}