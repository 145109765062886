<style lang="scss">
.analytics-search-filter-children {
  width: 100%;
  padding-left: 32px;
}
</style>

<template>
  <v-expand-transition>
    <div v-show="expand" class="analytics-search-filter-children">
      <v-checkbox
        v-for="item in items"
        :key="item.value"
        :label="item.name"
        :input-value="value"
        :value="item.value"
        hide-details
        class="ma-0 py-2"
        @change="handleChange"
      />
    </div>
  </v-expand-transition>
</template>

<script>
import checkedMixin from './checked'

export default {
  name: 'SearchSelectChildren',
  mixins: [checkedMixin],
  props: {
    expand: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
