var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex xs12"},_vm._l((_vm.data),function(list){return _c('div',{key:list.category},_vm._l((list.items),function(item,index){return _c('div',{key:item.id || item.questionID,class:['input-wrapper', _vm.isFocus(list.category)]},[(list.category == 'loca')?_c('locations',{directives:[{name:"show",rawName:"v-show",value:(index == 0),expression:"index == 0"}],attrs:{"data":list.items,"questions":_vm.questions,"hideLocationImage":_vm.hideLocationImage,"examination_typeID":_vm.examination_typeID,"focus":_vm.hiddenParams &&
          ['orien', 'loca', 'dist', 'switchLocation'].indexOf(
            _vm.hiddenParams.focus
          ) >= 0,"focusIndex":_vm.hiddenParams ? _vm.hiddenParams.nowLocationsIndex : null,"isExamination":_vm.isExamination},on:{"addLocation":function($event){return _vm.$emit('addLocation')},"deleteLocation":function($event){return _vm.$emit('deleteLocation', $event)},"locationChange":function($event){return _vm.$emit('locationChange', $event)}}}):[_c('div',{staticClass:"header subtitle-border"},[_vm._v(_vm._s(list.category))]),(item.config.inputType == 's')?_c('laid-out-select-input',{attrs:{"options":item.config.options,"value":item.value,"multiple":true},on:{"change":function($event){return _vm.$emit('change', { newValue: $event, qID: item.questionID })}}}):_vm._e(),(item.config.inputType == 't')?_c('textfield-landscape',{class:`${list.category}-${_vm.id}`,attrs:{"data":item,"isRequired":item.config.field === 'size' && _vm.isExamination},on:{"change":function($event){return _vm.$emit('change', { newValue: $event, qID: item.questionID })}}}):_vm._e(),(item.config.inputType == 'ta')?_c('v-textarea',{attrs:{"label":item.config.question,"value":item.value,"no-resize":"","outline":"","hide-details":"","height":"88px","row-height":"20px"},on:{"change":function($event){return _vm.$emit('change', { newValue: $event, qID: item.questionID })}}}):_vm._e(),(item.config.inputType == 'c')?_c('horizontal-check-boxes',{attrs:{"field":item.config.field,"options":item.config.options,"value":item.value},on:{"change":function($event){return _vm.$emit('change', { newValue: $event, qID: item.questionID })}}}):_vm._e(),(
            item.config.field == 'prgC' ||
            item.config.field == 'prgM' ||
            item.config.field == 'dist'
          )?_c('number-select-input',{attrs:{"data":item,"float":false,"unit":"cm"},on:{"change":function($event){return _vm.$emit('change', { newValue: $event, qID: item.questionID })}}}):_vm._e()],_c('div',{staticClass:"clear"})],2)}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }