<style lang="scss">
.search-filter {
  & + & {
    margin-top: 15px;
  }

  &__group + &__group {
    margin-left: 24px;
  }

  &__label {
    word-break: keep-all;
  }

  &__input {
    width: 250px;

    input {
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }
}
</style>

<template>
  <v-layout align-center class="search-filter">
    <div class="d-flex align-center search-filter__group">
      <v-menu
        offset-y
        left
        max-width="250"
        min-width="250"
        max-height="350"
        :disabled="disabled"
      >
        <template slot="activator" slot-scope="props">
          <div v-on="props.on">
            <v-text-field
              v-model="filter"
              label="Field"
              outline
              readonly
              hide-details
              :rules="[v => !!v || '']"
              class="search-filter__input"
            />
          </div>
        </template>

        <v-list>
          <v-list-tile
            v-for="filter in filters"
            :key="filter"
            class="search-filter__item"
            @click="handleFieldClick(filter)"
          >
            <v-list-tile-content>
              <v-list-tile-title>
                {{ filter }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-menu>
    </div>
    <div class="d-flex align-center search-filter__group">
      <search-select
        v-model="self"
        :items="items"
        @checked="handleChecked"
        @unchecked="handleChecked"
      />
    </div>
    <v-btn icon left @click="handleRemove">
      <v-icon>delete_forever</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import SearchSelect from './SearchSelect'

export default {
  name: 'SearchFilter',
  components: {
    SearchSelect,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: String,
      default: '',
    },
    filters: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    disabled() {
      return !this.filters.length
    },
    self: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    values() {
      return this.items.map(({ value }) => value)
    },
  },
  methods: {
    handleFieldClick(value) {
      this.$emit('filter:change', value)
    },
    handleRemove() {
      this.$emit('remove')
    },
    handleChecked(checked) {
      console.warn('[AnalyticsSearchFilter] - handleChecked:', checked)
      if (checked) {
        this.$emit('input', this.values)
      } else {
        this.$emit('input', this.value.filter(v => !v === 'ALL'))
      }
    },
  },
}
</script>
