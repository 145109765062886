<template>
  <v-container fluid class="analytics-pdr">
    <analytics-compare v-model="compares" @compare="handleCompare" />

    <div class="mt-5">
      <component
        :is="componentName"
        :title="title"
        :compares="compares"
        :data="data"
      />
    </div>
  </v-container>
</template>

<script>
import AnalyticsCompare from '@/components/analytics/AnalyticsCompare'
import DonutChart from './components/DonutChart'
import BarChart from './components/BarChart'

import { analyticsServices } from '@/services/db'
const CHART = {
  DONUT: 'DONUT',
  BAR: 'BAR',
}

export default {
  name: 'AnalyticsPDR',
  components: {
    AnalyticsCompare,
  },
  data() {
    return {
      chartType: '',
      compares: [],
      data: [],
    }
  },
  computed: {
    isSingle() {
      return this.compares.length === 1
    },
    componentName() {
      switch (this.chartType) {
        case CHART.DONUT:
          return DonutChart
        case CHART.BAR:
          return BarChart
        default:
          return null
      }
    },
    title() {
      switch (this.chartType) {
        case CHART.DONUT:
          return 'Polyp Detection Rate'
        case CHART.BAR:
          return 'Polyp Detection Rate Comparison'
        default:
          return ''
      }
    },
  },
  methods: {
    genParam(item) {
      return {
        ...item.date,
        doctor: item.doctor,
      }
    },
    getParams() {
      return this.compares.map(this.genParam)
    },
    handleCompare() {
      this.chartType = this.isSingle ? CHART.DONUT : CHART.BAR
      analyticsServices
        .queryPDR(this.getParams())
        .then(data => (this.data = data))
    },
  },
}
</script>
