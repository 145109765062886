<template>
  <div class="word-break">
    <div
      id="bowelCleanseForms"
      class="bowel-cleanse-forms print"
      v-if="examinationInfo"
    >
      <ul class="bowel-cleanse-forms__group bowel-cleanse-forms__group--first">
        <!-- indication -->
        <PreviewExamItem
          title="indication"
          :value="removeOthersFromList(indicationList, 9)"
          refKey="value"
          refValue="text"
          :refArr="getIndicationList"
        >
          <template #extra>
            <p v-if="examinationInfo.indicationOther">
              其他臨床目的施行之大腸鏡 :
              {{ examinationInfo.indicationOther }}
            </p>
          </template>
        </PreviewExamItem>
        <!-- preparationAgent -->
        <PreviewExamItem
          title="preparation_agent"
          :value="examinationInfo.clean_medID"
          refKey="id"
          refValue="clean_med"
          :refArr="getCleanMedList"
        >
          <template #extra>
            <span v-if="examinationInfo.clean_medOther">
              {{ $t('common.other') }} : {{ examinationInfo.clean_medOther }}
            </span>
          </template>
        </PreviewExamItem>
        <!--preparationTime  -->
        <PreviewExamItem
          title="preparation_time"
          :value="[examinationInfo.preparationTime]"
          :needMapRef="false"
        />
        <!-- antiPlatelet -->
        <PreviewExamItem
          title="anti_platelet"
          :value="examinationInfo.antiplateletID"
          refKey="id"
          refValue="antiplatelet"
          :refArr="getAntiplateletList"
        >
          <template #extra>
            <span v-if="examinationInfo.antiplateletOther">
              {{ $t('common.other') }} : {{ examinationInfo.antiplateletOther }}
            </span>
            <br v-if="examinationInfo.antiplateletOther" />
            <span>
              {{ $t('report_preview.anti_platelet_stop') }}
              {{ examinationInfo.antiplateletStopDays }} 天
            </span>
          </template>
        </PreviewExamItem>
        <!-- cleanseLevel(before) -->
        <PreviewExamItem
          title="cleanse_level.before"
          :value="[examinationInfo.cleanScale]"
          refKey="value"
          refValue="text"
          :refArr="getCleanScaleList"
        />
        <!-- cleanseLevelAfter -->
        <PreviewExamItem
          title="cleanse_level.after"
          :value="[examinationInfo.cleanScaleAfter]"
          refKey="value"
          refValue="text"
          :refArr="getCleanScaleList"
        />
        <!-- incompleteStudy -->
        <PreviewExamItem
          title="incomplete.title"
          :value="[examinationInfo.incompleteDue]"
          refKey="value"
          refValue="text"
          :refArr="getIncompleteReasonList[2]"
        />
      </ul>
      <ul class="bowel-cleanse-forms__group bowel-cleanse-forms__group--second">
        <!-- Scoped Id -->
        <PreviewExamItem
          title="scope_id"
          :value="[examinationInfo.inspection_instrumentID]"
          refKey="id"
          refValue="inspection_instrument"
          :refArr="getInspectionInstrumentList"
        />

        <!-- washingMachine -->
        <PreviewExamItem
          title="washing_machine"
          :value="[examinationInfo.wash_instrumentID]"
          refKey="id"
          refValue="wash_instrument"
          :refArr="getWashInstrumentList"
        />

        <!-- premedication -->
        <PreviewExamItem
          title="premedication.title"
          :value="examinationInfo.antispasmodicReason"
          refKey="value"
          refValue="text"
          prefix="原因: "
          :refArr="
            Number(examinationInfo.antispasmodicHave)
              ? getAntispasmodicList
              : getNoUseAntipasmodicReasonList
          "
        >
          <template #extra-start>
            <div>
              {{ Number(examinationInfo.antispasmodicHave) ? this.$t('antispasmodics.on') : this.$t('antispasmodics.off') }}
            </div>
          </template>
          <template #extra>
            <span v-if="examinationInfo.antispasmodicOther">
              {{ $t('common.other') }} : {{ examinationInfo.antispasmodicOther }}
            </span>
          </template>
        </PreviewExamItem>

        <!-- sedatives -->
        <li>
          <h3>{{ $t('common.sedatives') }} :</h3>
          <div
            v-if="
              examination.sedatives.length ||
              examination.examination.sedativeOther
            "
            class="sedative-list"
          >
            <div v-for="item in examination.sedatives">
              {{ getSedativeNameFromID(item.sedativeID) }} {{ item.value }}mg
            </div>
            <div v-if="examination.examination.sedativeOther">
              {{ $t('common.other') }} : {{ examination.examination.sedativeOther }}
            </div>
          </div>
          <p v-else>{{ $t('common.nil') }}</p>
        </li>
        <!-- insertionLevel -->
        <PreviewExamItem
          title="insertion_level"
          :value="[examinationInfo.insertionLevel]"
          refKey="value"
          refValue="text"
          :refArr="getInsertionLevelList"
        />
        <!-- insertionLength -->
        <PreviewExamItem
          title="insertion_length"
          :value="[insertionLengthVal]"
          :needMapRef="false"
        />
        <!-- complication -->
        <PreviewExamItem
          title="complication"
          :value="examinationInfo.complication"
          refKey="value"
          refValue="text"
          :refArr="getComplicationList"
        >
          <template #extra>
            <p v-if="examinationInfo.complicationOther">
              {{ $t('common.other') }} :
              {{ examinationInfo.complicationOther }}
            </p>
          </template>
        </PreviewExamItem>
        <!-- addition remark -->
        <PreviewExamItem
          title="additional_remarks"
          :value="[examinationInfo.additionalRemarks]"
          :needMapRef="false"
        />
      </ul>
    </div>
    <div class="border-t">
      <time-info-group :data="examinationInfo" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TimeInfoGroup from '@/components/bowelCleanseForms/TimeInfoGroup.vue'
import PreviewExamItem from './PreviewExamItem.vue'

export default {
  name: 'ColonPreviewBowelCleanseForms',

  components: {
    TimeInfoGroup,
    PreviewExamItem,
  },

  props: {
    examination: {
      type: Object,
      required: true,
    },

    sedatives: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters('examinations', [
      'getIndicationList',
      'getComplicationList',
      'getCleanMedList',
      'getAntiplateletList',
      'getAntispasmodicList',
      'getNoUseAntipasmodicReasonList',
      'getInspectionInstrumentList',
      'getWashInstrumentList',
      'getCleanScaleList',
      'getInsertionLevelList',
      'getIncompleteReasonList',
    ]),
    examinationInfo() {
      return this.examination.examination
    },
    insertionLengthVal () {
      return this.examinationInfo.insertionLength ? `${this.examinationInfo.insertionLength} cm` : ''
    },
    indicationList () {
      return this.examinationInfo.indication.map(i => String(i))
    }
  },
  methods: {
    getSedativeNameFromID(id) {
      return this.sedatives.filter((item) => item.id === id)[0].sedative
    },
    removeOthersFromList(list, val) {
      const othersValue = val
      return list.filter((item) => item !== othersValue)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/components/bowelCleanseForms.scss';

.bowel-cleanse-forms.print {
  white-space: initial !important;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ul,
  li {
    margin-left: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
}

.bowel-cleanse-forms__group {
  li {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 0.5rem;
  }
  p {
    align-self: center;
  }
  &--first li {
    grid-template-columns: 35% 65%;
  }
  &--second li {
    grid-template-columns: 30% 70%;
  }
}
@media (max-width: 600px) {
  .bowel-cleanse-forms.print {
    grid-template-columns: 1fr;
  }
  .bowel-cleanse-forms__group {
    li {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media print {
  .bowel-cleanse-forms.print {
    grid-template-columns: repeat(2, 1fr);
  }
  .bowel-cleanse-forms__group {
    &--first li {
      grid-template-columns: 53% 47%;
    }
    &--second li {
      grid-template-columns: 44% 56%;
    }
  }
}
</style>
