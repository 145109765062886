
<template>
  <div :class="['input-container', getFocusClass('takeNote')]">
    <div class="input-header">Take Note Of</div>
    <v-select
      hide-details
      :items="options"
      :value="value"
      @change="$emit('change', { field: 'takeNote', newValue: $event })"
    ></v-select>
  </div>
</template>

<script>
  export default {
    props: {
      options: Array,
      value: String,
      getFocusClass: Function,
    },
  };
</script>
