<template>
  <v-layout row wrap align-content-start fill-height class="pa-4">
    <v-flex xs12 class="page-head-container mb-3">
      <v-flex xs12 class="mb-4 page-head-container">
        <h1 class="headline font-weight-light">{{ $t(`organization.title`) }}</h1>
      </v-flex>
      <v-flex xs12 class="search-container">
        <v-text-field
          :label="$t('common.search')"
          solo
          flat
          hide-details
          v-model="search"
        ></v-text-field>
      </v-flex>

      <v-flex xs12>
        <template>
          <v-data-table
            v-if="getOrganizations.length > 0"
            :headers="headers"
            :items="getOrganizations"
            :pagination.sync="pagination"
            :search="search"
            hide-actions
            class="my-4"
            :disable-initial-sort=true >


            <template slot="headers" slot-scope="props">
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.text"
                  :class="['column sortable', pagination.order ? 'desc' : 'asc', header.value === pagination.title ? 'active' : '']"
                  @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>


            <template slot="items" slot-scope="props">
              <tr @click="clickItem(props.item)">
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.orgNo"
                    lazy
                    @save="updateOrganization(props.item.id, 'orgNo', tempItem.orgNo)"
                  > {{ props.item.orgNo }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.orgNo"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.name"
                    lazy
                    @save="updateOrganization(props.item.id, 'name', tempItem.name)"
                  > {{ props.item.name }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.name"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.phone"
                    lazy
                    @save="updateOrganization(props.item.id, 'phone', tempItem.phone)"
                  > {{ props.item.phone }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.phone"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.email"
                    lazy
                    @save="updateOrganization(props.item.id, 'email', tempItem.email)"
                  > {{ props.item.email }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.email"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-select
                    :items="[ { text: 'Enable', value: '1' }, { text: 'Disable', value: '0' } ]"
                    v-model="props.item.connect_to_pacs_worklist" @change="updateOrganization(props.item.id,'connect_to_pacs_worklist',$event)"
                  ></v-select>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.called_ae"
                    lazy
                    @save="updateOrganization(props.item.id, 'called_ae', tempItem.called_ae)"
                  > {{ props.item.called_ae }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.called_ae"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.called_ip"
                    lazy
                    @save="updateOrganization(props.item.id, 'called_ip', tempItem.called_ip)"
                  > {{ props.item.called_ip }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.called_ip"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.called_port"
                    lazy
                    @save="updateOrganization(props.item.id, 'called_port', tempItem.called_port)"
                  > {{ props.item.called_port }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.called_port"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.calling_ae"
                    lazy
                    @save="updateOrganization(props.item.id, 'calling_ae', tempItem.calling_ae)"
                  > {{ props.item.calling_ae }}
                    <v-text-field                      
                      slot="input"
                      v-model="tempItem.calling_ae"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.modality"
                    lazy
                    @save="updateOrganization(props.item.id, 'modality', tempItem.modality)"
                  > {{ props.item.modality }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.modality"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>     
                <td>
                  <v-switch
                    data-test="switch-all-time"
                    :value="getAllTimeRecord"
                    color="teal"
                    hide-details
                    class="justify-center"
                    @change="toggleSwitch"
                  />
                </td>         
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-flex>
    </v-flex>

    <!--<v-btn icon fixed bottom right fab color="primary" @click="addOrganization">
      <v-icon>add</v-icon>
    </v-btn>-->

    <v-snackbar
      v-model="snackbar"
      bottom
      :timeout="timeout"
      color="green"
    >
      New Organization Added
    </v-snackbar>
  </v-layout>
</template>

<script>
  import { STORAGE } from '@/utils/localStorage'
  import { CLOSE_RECORD_ALL_TIME } from '@/utils/constants'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'AdminOrganizationList',
    data() {
      return {
        search: '',
        pagination: {
          rowsPerPage: -1,
          title:'',
          order:false
        },
        headers: [
          {
            text: this.$t('organization.number'),
            align: 'left',
            value: 'orgNo'
          },
          {
            text: this.$t('organization.name'),
            align: 'left',
            value: 'name'
          },
          {
            text: this.$t('organization.phone'),
            align: 'left',
            value: 'phone'
          },
          {
            text: this.$t('organization.email'),
            align: 'left',
            value: 'email'
          },
          {
            text: this.$t('organization.connection'),
            align: 'left',
            value: 'connect_to_pacs_worklist'
          },
          {
            text: this.$t('organization.ae_title'),
            align: 'left',
            value: 'called_ae'
          },
          {
            text: this.$t('organization.called_ip'),
            align: 'left',
            value: 'called_ip'
          },
          {
            text: this.$t('organization.called_port'),
            align: 'left',
            value: 'called_port'
          },
          {
            text: this.$t('organization.calling_ae'),
            align: 'left',
            value: 'calling_ae'
          },
          {
            text: this.$t('organization.modality'),
            align: 'left',
            value: 'modality'
          },
          {
            text: this.$t('organization.record'),
            align: 'left',
            value: 'record'
          }     
        ],
        snackbar: false,
        timeout: 3000,
        tempItem:'',
        tempValue:''
      }
    },

    created () {
      this.fetchOrganizations()
    },

    computed: {
      ...mapGetters('organizations', [
        'getOrganizations'
      ]),
      getAllTimeRecord () {
        return !STORAGE.get(CLOSE_RECORD_ALL_TIME)
      }
    },

    methods: {
      ...mapActions('organizations', [
        'fetchOrganizations'
      ]),
      toggleSwitch (val) {
        STORAGE.set({key: CLOSE_RECORD_ALL_TIME, val: !val})
      },
      changeSort (column) {
        if(column===''){return;}
        if (this.pagination.title === column) {
          this.pagination.order = !this.pagination.order
        } else {
          this.pagination.title = column
          this.pagination.order = false
        }
        this.$store.dispatch('organizations/sortOrganization', {title:column,order:this.pagination.order})

      },

      updateOrganization ( organizationID, field, value ) {
        // console.log(organizationID,field,value)
        const updateObj = {
          organizationID: organizationID,
          field: field,
          value: value
        }
        
        this.$store.dispatch('organizations/updateColumnById', updateObj).then(() => {
          if (field == 'name') {
            document.getElementById('app-logo').innerHTML = value;
          }
        });
      },

      async addOrganization () {
        try {
          await this.$store.dispatch('organizations/addOrganization')
          this.snackbar = true
        } catch (e) {
          this.snackbar = false
        }
      },
      clickItem(data){
        this.tempItem = Object.assign({}, data);
      }
    }
  }
</script>

<style lang="scss">
</style>