export const CLOSE_RECORD_ALL_TIME = "closeRecordAllTime"

export const FILTER = {
  FINDING: 'finding',
  LOCATION: 'location',
  SIZE: 'size',
  INTERVENTION: 'intervention',
}
export const FILTER_SIZE = [
  { name: 'ALL', value: 'ALL' },
  {
    name: '0.1-0.6 cm',
    value: '0.1-0.6',
    subItems: [
      { name: '0.1 cm', value: '0.1' },
      { name: '0.2 cm', value: '0.2' },
      { name: '0.3 cm', value: '0.3' },
      { name: '0.4 cm', value: '0.4' },
      { name: '0.5 cm', value: '0.5' },
    ],
  },
  {
    name: '0.6-1.5cm',
    value: '0.6-1.5',
    subItems: [
      { name: '0.6 cm', value: '0.6' },
      { name: '0.7 cm', value: '0.7' },
      { name: '0.8 cm', value: '0.8' },
      { name: '0.9 cm', value: '0.9' },
      { name: '1.0 cm', value: '1.0' },
      { name: '1.1 cm', value: '1.1' },
      { name: '1.2 cm', value: '1.2' },
      { name: '1.3 cm', value: '1.3' },
      { name: '1.4 cm', value: '1.4' },
    ],
  },
  {
    name: '1.5-3 cm',
    value: '1.5-3',
    subItems: [
      { name: '1.5 cm', value: '1.5' },
      { name: '1.6 cm', value: '1.6' },
      { name: '1.7 cm', value: '1.7' },
      { name: '1.8 cm', value: '1.8' },
      { name: '1.9 cm', value: '1.9' },
      { name: '2.0 cm', value: '2.0' },
      { name: '2.1 cm', value: '2.1' },
      { name: '2.2 cm', value: '2.2' },
      { name: '2.3 cm', value: '2.3' },
      { name: '2.4 cm', value: '2.4' },
      { name: '2.5 cm', value: '2.5' },
      { name: '2.6 cm', value: '2.6' },
      { name: '2.7 cm', value: '2.7' },
      { name: '2.8 cm', value: '2.8' },
      { name: '2.9 cm', value: '2.9' },
    ],
  },
  { name: '大於 3 cm', value: '3.0' },
]
export const FILTER_MAP = {
  finding: [{ name: 'ALL', value: 'ALL' }],
  location: [{ name: 'ALL', value: 'ALL' }],
  size: FILTER_SIZE,
  intervention: [{ name: 'ALL', value: 'ALL' }],
}
export const SEARCH_FIELDS = [
  [
    { text: 'Finding', value: 'Finding' },
    { text: 'Location', value: 'Location' },
    { text: 'Orientation', value: 'Orientation' },
    { text: 'Size', value: 'Size' },
  ],
  [
    { text: 'morphology', value: 'morphology' },
    { text: 'JNET', value: 'JNET' },
    { text: 'Kudo', value: 'Kudo' },
  ],
  [{ text: 'Intervention', value: 'Intervention' }],
]
export const INPUT_TYPES = {
  TEXT: 't',
  NUMBER: 'n',
  CHECKBOX: 'c',
  CHECKBOX2: 'c2',
  SELECT: 's',
}

export const STORE_ACTIONS = {
  ADD_FINIDNG: "ADD_FINIDNG",
  DEL_FINIDNG: "DEL_FINIDNG",
  OVER_FINIDNG: "OVER_FINIDNG",
  FOCUS_KEY: "FOCUS_KEY",
};

export const COMMON_COMMAND = {
  DELETE_FINIDNG: "delete finding",
  FINDING_OVER: "finding over",
  EVAS_GO: "evas go",
  INSERTION_TO: "insertion to",
  PROXIMAL_TIME: "proximal time",
  FINISH: "finish",
  NEW_LOCATION: "new location",
  DELETE_LOCATION: "delete location",
  CLEANSING_LEVEL: "cleansing level",
  INSERTION_LEVEL: "insertion level",
  CLO: "clo",
  CLO_LOCATION: "clo location",
  INDICATION: "indication",
  FINDING: "finding",
  LESION: "lesion",
};