<template>
  <v-layout row wrap align-content-start fill-height class="pa-4">
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-text>
          <span class="headline">{{ $t('examination.add') }}</span>
          <v-container grid-list-md>
            <v-form ref="form" lazy-validation>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.accNo"
                    :label="this.$t('examination.number')"
                    :rules="required"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="patientSelected"
                    class="owl-carousel"
                    :label="this.$t('examination.patient')"
                    :items="getPatients"
                    item-text="name"
                    item-value="id"
                    return-object
                    :rules="required"
                    @change="setExaminationPatientID"
                  />
                  <div v-show="patientSelected.no != ''" class="hint">
                    <p>
                      {{ $t('patient.id') }}: <b>{{ patientSelected.no }}</b> {{ $t('patient.dob') }}:
                      <b>{{ patientSelected.birthday }}</b> {{ $t('patient.sex') }}:
                      <b>{{ genderFormated[patientSelected.gender] }}</b>
                    </p>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :label="this.$t('examination.date')"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      :value="editedItem.date"
                      :rules="required"
                    />
                    <v-date-picker
                      v-model="editedItem.date"
                      no-title
                    />
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="editedItem.orderDoctorID"
                    :label="this.$t('examination.doctor')"
                    :items="getDoctorsListFormat"
                    :rules="required"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-select
                    v-model="editedItem.examination_typeID"
                    :label="this.$t('examination.type')"
                    :items="getExaminationTypesListFormat"
                    :rules="required"
                  />
                </v-flex>
              </v-layout>
            </v-form>
            <v-chip
              class="err-msg"
              v-if="errorMessage"
              color="red"
              text-color="white"
            >
              <v-icon left>info</v-icon>
              {{ errorMessage }}
            </v-chip>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="close">{{ $t('common.cancel') }}</v-btn>
          <v-btn color="blue darken-1" flat @click="addExamination">{{ $t('common.add') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-flex xs12 class="page-head-container mb-3">
      <v-flex xs12 class="mb-4 page-head-container">
        <h1 class="headline font-weight-light">Examination List</h1>
      </v-flex>

      <v-flex xs12 class="search-container">
        <v-layout>
          <v-flex xs2>
            <h4>{{ $t('examination.number') }}</h4>
            <v-text-field
              v-model="condition.accNo"
              :rules="XssRules"
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <h4>{{ $t('examination.doctor') }}</h4>
            <search-doctor-input
              @selected="updateDoctor"
              :name="condition.orderDoctorID"
            ></search-doctor-input>
          </v-flex>
          <v-flex xs2>
            <h4>{{ $t('examination.patient') }}</h4>
            <search-patient-input
              @selected="updatePatient"
              :name="condition.patientID"
            ></search-patient-input>
          </v-flex>
          <v-flex xs2>
            <h4>Start Date</h4>
            <date-picker-input
              :date="condition.st"
              @selected="date => updateDate(date, 'st')"
            ></date-picker-input>
          </v-flex>
          <v-flex xs2>
            <h4>End Date</h4>
            <date-picker-input
              :date="condition.et"
              @selected="date => updateDate(date, 'et')"
            ></date-picker-input>
          </v-flex>
          <v-flex xs1>
            <v-btn @click="resetSearch()">{{ $t('common.reset') }}</v-btn>
          </v-flex>
          <v-flex xs1>
            <v-btn elevation="2" color="info" @click="search">{{ $t('common.search') }}</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12>
        <template>
          <v-data-table
            v-if="getExaminationSearchResult.length"
            :headers="headers"
            :items="getExaminationSearchResult"
            :pagination.sync="pagination"
            hide-actions
            class="my-4"
            disable-initial-sort
          >
            <template #headers>
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.text"
                  :class="[
                    'column sortable',
                    pagination.order ? 'desc' : 'asc',
                    header.value === pagination.title ? 'active' : '',
                  ]"
                  @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template #items="{ item, index }">
              <examination-table-row
                :item="item"
                :index="index"
                :date-menus="dateMenus"
                @update="updateExamination"
              />
            </template>
          </v-data-table>
        </template>

        <div class="text-xs-center">
          <v-pagination
            v-model="getExaminationSearchInfo.page"
            :length="getExaminationSearchInfo.total_page"
            :total-visible="7"
            @input="clickPage"
          ></v-pagination>
        </div>
      </v-flex>
    </v-flex>

    <v-btn
      icon
      fixed
      bottom
      right
      fab
      color="primary"
      @click="clickAddExamination"
    >
      <v-icon>add</v-icon>
    </v-btn>

    <v-snackbar v-model="snackbar" bottom :timeout="timeout" color="red">
      {{ message }}
    </v-snackbar>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import common from '../../utils/common'
import SearchDoctorInput from './components/SearchDoctorInput.vue'
import SearchPatientInput from './components/SearchPatientInput.vue'
import DatePickerInput from './components/DatePickerInput.vue'
import ExaminationTableRow from '@/components/examinations/ExaminationTableRow'

const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000)
  .toISOString()
  .slice(0, 10)
const twoWeeksAgo = common.getDateCompareToToday(-14)
const DEFAULT_PATIENT = { name: '', no: '', id: '', birthday: '', gender: '' }

function getDefaultPatient() {
  return DEFAULT_PATIENT
}

export default {
  components: {
    SearchDoctorInput,
    SearchPatientInput,
    DatePickerInput,
    ExaminationTableRow,
  },
  name: 'AdminExaminationList',
  watch: {
    dialog(v) {
      this.editedItem.accNo = ''
      this.editedItem.patientID = ''
      this.editedItem.date = today
      this.editedItem.orderDoctorID = ''
      this.editedItem.examination_typeID = ''
      this.$refs.form.resetValidation()
      this.errorMessage = null

      if (!v) {
        this.patientSelected = getDefaultPatient()
      }
    },
  },
  data() {
    return {
      genderFormated: {
        1: 'Male',
        0: 'Female',
      },
      patientSelected: getDefaultPatient(),
      pagination: {
        rowsPerPage: -1,
        title: '',
        order: false,
      },
      message: null,
      snackbar: false,
      timeout: 3000,
      tempItem: '',
      tempValue: '',
      dateMenus: [],
      errorMessage: null,
      editedItem: {
        accNo: '',
        patientID: '',
        date: today,
        orderDoctorID: '',
        examination_typeID: '',
      },
      dialog: false,
      condition: {
        page: 1,
        pgsz: 20,
        st: twoWeeksAgo, // startDate.. search last 2 weeks's result
        et: today, // endDate
        accNo: null,
        patientID: null,
        orderDoctorID: null,
      },
      XssRules: [
        value => {
          if (this.condition.accNo == null) {
            return (this.condition.accNo = '')
          } else {
            let xssFilter = new RegExp(
              /(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/gi,
            )
            this.condition.accNo = this.condition.accNo.replace(
              /[&><%^&"]/g,
              '',
            )
            this.condition.accNo = xssFilter.test(this.condition.accNo)
              ? (this.condition.accNo = '')
              : this.condition.accNo
            return true
          }
        },
      ],
      required: [v => !!v || '* Required']
    }
  },

  computed: {
    ...mapGetters('examinations', [
      'getExaminationSearchResult',
      'getExaminationSearchInfo',
      'getExaminationInfos',
    ]),
    ...mapGetters('patients', ['getPatients']),
    ...mapGetters('examinationTypes', ['getExaminationTypesListFormat']),
    ...mapGetters('doctors', ['getDoctorsListFormat']),
    headers() {
      return [
        {
          text: this.$t('examination.number'),
          align: 'left',
          value: 'accNo',
          // sortable: false
        },
        {
          text: this.$t('examination.patient'),
          align: 'left',
          value: 'patientID',
          sortable: false,
        },
        {
          text: this.$t('examination.date'),
          align: 'left',
          value: 'date',
          sortable: false,
        },
        {
          text: this.$t('examination.doctor'),
          align: 'left',
          value: 'orderDoctorID',
          sortable: false,
        },
        {
          text: this.$t('examination.type'),
          align: 'left',
          value: 'examination_typeID',
          sortable: false,
        },
      ]
    }
  },

  created() {
    this.search()
    this.fetchPatients()
    this.fetchExaminationTypes()
    this.fetchDoctors()
  },
  methods: {
    ...mapActions('examinations', ['searchExaminations']),
    ...mapActions('examinationTypes', ['fetchExaminationTypes']),
    ...mapActions('patients', ['fetchPatients']),
    ...mapActions('doctors', ['fetchDoctors']),
    setExaminationPatientID(item) {
      this.editedItem.patientID = item.id
    },
    search() {
      this.searchExaminations(this.condition).then(this.initDate)
    },
    clickPage(page) {
      this.condition.page = page
      this.search()
    },
    updatePatient(info) {
      this.condition.patientID = info ? info.id.toString() : null
    },
    updateDoctor(info) {
      this.condition.orderDoctorID = info ? info.id : null
    },
    updateDate(info, column) {
      this.condition[column] = info
    },
    resetSearch() {
      this.condition.st = twoWeeksAgo
      this.condition.et = today
      this.condition.accNo = null
      this.condition.patientID = null
      this.condition.orderDoctorID = null
    },
    changeSort(column) {
      if (column === '') {
        return
      }
      if (this.pagination.title === column) {
        this.pagination.order = !this.pagination.order
      } else {
        this.pagination.title = column
        this.pagination.order = false
      }
      this.$store.dispatch('examinations/sortExaminationSearchResult', {
        title: column,
        order: this.pagination.order,
      })
    },
    clickAddExamination() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    initDate() {
      if (!this.getExaminationInfos) {
        return
      }
      this.dateMenus = Array(this.getExaminationInfos.length).fill(false)
    },
    async addExamination() {
      var validateResult = true
      if (validateResult) {
        try {
          let obj = {
            accNo: this.editedItem.accNo,
            patientID: this.editedItem.patientID,
            date: this.editedItem.date,
            orderDoctorID: this.editedItem.orderDoctorID,
            examination_typeID: this.editedItem.examination_typeID,
          }

          await this.$store
            .dispatch('examinations/addExamination', obj)
            .then(res => {
              if (res && !res.success) {
                this.errorMessage = res.err.msg
              } else {
                this.errorMessage = null
                this.dialog = false
                this.search()
              }
            })
        } catch (e) {
          console.log(e)
          this.dialog = false
        }
      }
    },
    updateExamination(payload) {
      for (let i = 0; i < this.dateMenus.length; i++) {
        this.dateMenus[i] = false
      }

      this.$store
        .dispatch('examinations/updateColumnById', payload)
        .then(res => {
          if (res && !res.success) {
            this.message = res.err.msg
            this.snackbar = true
          } else {
            this.message = null
            this.snackbar = false
          }
          this.search()
        })
    },
  },
}
</script>

<style lang="scss">
.search-container {
  button {
    margin-top: 30px;
  }
}
.err-msg {
  padding-left: 3px;
}

// Solve: [Chrome] Unable to preventDefault inside passive event listener due to target being treated as passive.
.owl-carousel {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.hint {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  p {
    margin: -12px 0 0 0;
  }
  b {
    color: #007fff;
  }
}
</style>
