export const CLINIC_LEVEL = "2"

export const EXAMINATION_TYPE_OF_GASTROSCOPY = 1
export const EXAMINATION_TYPE_OF_COLONOSCOPY =  2
export const EXAMINATION_TYPE_OF_SIGMOIDOSCOPY = 3

export const PRINT_STATUS_NO = '0'
export const PRINT_STATUS_YES = '1'

export const EXAM_UNFINISHED = 'Unfinished'
export const EXAM_EXECUTING = 'Executing'
export const EXAM_FINISHED = 'Finished'

export const EXAMINATION_STATUS_MAP = {
  [EXAM_UNFINISHED]: "0",
  [EXAM_EXECUTING]: "1",
  [EXAM_FINISHED]: "2",
}