<template>
  <div class="header">
    <h2>{{ getUser.orgName }}</h2>
    <h3>{{ title }}</h3>
    <p class="small-print">
      {{ $t('poweredByFirst') }}<br/>{{ $t('poweredBySecond') }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
   computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
   }
}
</script>

<style lang="scss" scoped>
$headerBg: #3b587d;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $headerBg;
  margin-bottom: 10px;
  padding: 20px;
  color: white;

  h2 {
    font-size: 2.6rem;
  }
  h3 {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 5px;
  }
  .small-print {
    margin: 0;
    font-size: 14px;
    margin-top: auto;
    text-align: right;
    line-height: 1.2rem;
  }
}

@media print {
  .header {
    background:#3b587d  !important;
    -webkit-print-color-adjust: exact;
  }
}
</style>
