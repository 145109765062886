<template>
  <div>
    <v-menu
      class="date-picker-input"
      v-model="toggle"
      :nudge-right="40"
      :close-on-content-click="false"
      lazy
      transition="scale-transition"
      full-width
      max-width="290px"
      min-width="290px"
    >
      <div slot="activator">　
        {{ date || 'not specific'}}
      </div>
      <v-date-picker 
        v-model="dateSelect" 
        @change="select"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
  export default {
    name: 'DatePickerInput',
    props: {
      date: String
    },
    data() {
      return {
        toggle: false,
        dateSelect: this.date
      }
    },
    methods: {
      select(info) {
        this.$emit('selected', info)
      }
    }
  }
</script>
<style lang="scss" scope>
.date-picker-input {
  display: inline-block;
  margin-top: 20px;
}
</style>