import router from '@/router'
import { authServices } from '../services/auth'

export const actions = {
    setLoading ({commit}, payload) {
        commit('setLoading', payload)
    },
    setLoadingDescript ({commit}, payload) {
        commit('setLoadingDescript', payload)
    },
    setUser ({ commit }, user) {
        commit('setUser', user)
    },
    async login ({ commit, dispatch }, payload) {
        commit('setLoading', true)

        try {
            const res = await authServices.signIn(payload.username, payload.password)

            if (res.result === 'success') {
                dispatch('setUser', res)
                
                commit('setError', null)

                // send user to homepage
                router.push('/home')
            } else {
                commit('setError', 'auth')
            } 
        } catch (e) {
            console.log(e)
        } finally {
            commit('setLoading', false)
        }
    },
    logout ({ dispatch }) {
        dispatch('setUser', null)
        window.location.assign('/')
    },
    handleIsAutoRecognize ({ commit }, payload) {
        commit('setIsAutoRecognize', payload)
    },
    setSetting({commit}, setting) {
        commit('SET_SETTING', setting)
    }
}