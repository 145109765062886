import { dbServices } from '@/services/db'
import common from './common'

const forms = {
  formTypeDirective (type) {
    const inputTypeMapping = {
      s: 'select',
      c: 'checkbox',
      t: 'text-field',
      c2: 'select',
      n: 'text-field'
    }

    return `v-${inputTypeMapping[type]}`
  },

  prepareSelectQuestionProps (item) {
    const items = forms.prepareSelectItems(item.options)

    return {
      items
    }
  },

  prepareSelectItems (options) {
    let arr = []

    for (const key in options) {
      let obj = {
        value: key,
        text: options[key]
      }

      arr.push(obj)
    }

    return arr
  },

  findIndexByID (arr, target) {
    for (const key in arr) {
      if (arr[key].id === target) {
        return key
      }
    }

    return -1
  },

  findIndexByKey (arr, key, target) {
    for (const i in arr) {
      if (arr[i][key] === target) {
        return i
      }
    }

    return -1
  },

  createArrayWithSameValue: (size, value) => new Array(size).fill(value),

  getReportStatus (reportStatus) {
    const status = Number(reportStatus)

    if (!isNaN(status)) {
      switch (status) {
        case 0:
          return 'Unedited';
          break; // eslint-disable-line no-unreachable
        case 1:
          return 'Editing';
          break; // eslint-disable-line no-unreachable
        case 2:
          return 'Finalized';
          break; // eslint-disable-line no-unreachable
      }

    }
    return reportStatus
  },

  getLocationImage(data) {
    if (!data || !data.loca || data.loca.length === 0) return

    var orien = (!data.orien || data.orien.length === 0) ? 0 : data.orien.value;
    var loca = (!data.loca || data.loca.length === 0) ? 0 : data.loca.value;

    var locationImageFile = '';
    switch (Number(loca)) {
        case 296:
            locationImageFile = 'L_296_0.png';
            break;
        case 297:
            locationImageFile = 'L_297_0.png';
            break;
        case 298:
            locationImageFile = 'L_298_0.png';
            break;
        case 299:
            locationImageFile = 'L_299_0.png';
            break;
        case 300:
            locationImageFile = 'L_300_0.png';
            break;
        case 301:
            switch (Number(orien)) {
                case 361:
                    locationImageFile = 'L_301_361.png';
                    break;
                case 362:
                    locationImageFile = 'L_301_362.png';
                    break;
                default:
                    locationImageFile = 'L_301_0.png';
            }
            break;
        case 302:
            switch (Number(orien)) {
                case 361:
                    locationImageFile = 'L_302_361.png';
                    break;
                case 362:
                    locationImageFile = 'L_302_362.png';
                    break;
                default:
                    locationImageFile = 'L_302_0.png';
            }
            break;
        case 303:
            switch (Number(orien)) {
                case 361:
                    locationImageFile = 'L_303_361.png';
                    break;
                case 362:
                    locationImageFile = 'L_303_362.png';
                    break;
                default:
                    locationImageFile = 'L_303_0.png';
            }
            break;
        case 304:
            switch (Number(orien)) {
                case 361:
                    locationImageFile = 'L_304_361.png';
                    break;
                case 362:
                    locationImageFile = 'L_304_362.png';
                    break;
                default:
                    locationImageFile = 'L_304_0.png';
            }
            break;
        case 305:
            locationImageFile = 'L_305_0.png';
            break;
        case 306:
            locationImageFile = 'L_306_0.png';
            break;
        case 307:
            locationImageFile = 'L_307_0.png';
            break;
        case 308:
            locationImageFile = 'L_308_0.png';
            break;
        case 309:
            locationImageFile = 'L_309_0.png';
            break;
        case 310:
            locationImageFile = 'L_310_0.png';
            break;
        case 528:
            locationImageFile = 'L_528_0.png';
            break;
        case 312:
            locationImageFile = 'L_312_0.png';
            break;
        case 313:
            locationImageFile = 'L_313_0.png';
            break;
        case 314:
            locationImageFile = 'L_314_0.png';
            break;
        case 315:
            locationImageFile = 'L_315_0.png';
            break;
        case 316:
            switch (Number(orien)) {
                case 324:
                    locationImageFile = 'L_316_324.png';
                    break;
                case 325:
                    locationImageFile = 'L_316_325.png';
                    break;
                case 326:
                    locationImageFile = 'L_316_326.png';
                    break;
                default:
                    locationImageFile = 'L_316_325.png';
            }
            break;
        case 317:
            locationImageFile = 'L_317_0.png';
            break;
        case 318:
            switch (Number(orien)) {
                case 324:
                    locationImageFile = 'L_318_324.png';
                    break;
                case 325:
                    locationImageFile = 'L_318_325.png';
                    break;
                case 326:
                    locationImageFile = 'L_318_326.png';
                    break;
                default:
                    locationImageFile = 'L_318_325.png';
            }
            break;
        case 319:
            locationImageFile = 'L_319_0.png';
            break;
        case 320:
            switch (Number(orien)) {
                case 324:
                    locationImageFile = 'L_320_324.png';
                    break;
                case 325:
                    locationImageFile = 'L_320_325.png';
                    break;
                case 326:
                    locationImageFile = 'L_320_326.png';
                    break;
                default:
                    locationImageFile = 'L_320_325.png';
            }
            break;
        case 321:
            switch (Number(orien)) {
                case 324:
                    locationImageFile = 'L_321_324.png';
                    break;
                case 325:
                    locationImageFile = 'L_321_325.png';
                    break;
                case 326:
                    locationImageFile = 'L_321_326.png';
                    break;
                default:
                    locationImageFile = 'L_321_325.png';
            }
            break;
        case 322:
            locationImageFile = 'L_322_0.png';
            break;
        case 323:
            switch (Number(orien)) {
                case 325:
                    locationImageFile = 'L_323_325.png';
                    break;
                case 327:
                    locationImageFile = 'L_323_327.png';
                    break;
                case 328:
                    locationImageFile = 'L_323_328.png';
                    break;
                default:
                    locationImageFile = 'L_323_325.png';
            }
            break;
        case 502:
            locationImageFile = 'L_502_0.png';
            break;
        case 503:
            locationImageFile = 'L_503_0.png';
            break;
        case 504:
            locationImageFile = 'L_504_0.png';
            break;
        case 529:
            locationImageFile = 'L_529_0.png';
            break;
        case 590: // body == mid-body 303
            switch (Number(orien)) {
                case 361:
                    locationImageFile = 'L_303_361.png';
                    break;
                case 362:
                    locationImageFile = 'L_303_362.png';
                    break;
                default:
                    locationImageFile = 'L_303_0.png';
            }
            break
        case 591: // upper-body == high-body 304
            switch (Number(orien)) {
                case 361:
                    locationImageFile = 'L_304_361.png';
                    break;
                case 362:
                    locationImageFile = 'L_304_362.png';
                    break;
                default:
                    locationImageFile = 'L_304_0.png';
            }
            break
        case 624:
            locationImageFile = 'L_529_0.png';
            break;
    }
    return locationImageFile;
},

  /************************************************
   * fetch finding questions and records questions *
   * for ONE finding                               *
   ************************************************/
  async fetchFindingAllQuestionsJSON (finding) {
    let result = {
      finding: [],
      records: []
    }

    // fetch finding level one questions (finding)
    let level1Arr = []
    for (const findingQ in finding.questions) {
      const findingQID = finding.questions[findingQ].questionID
      level1Arr.push(dbServices.fetchQuestionJSON(findingQID))
    }
    const findingResult = await Promise.all(level1Arr)
    result.finding = findingResult


    // fetch finding level two questions (records)
    if (finding.records && finding.records.length !== 0) {
      result.records = await forms.fetchRecordsQuestionsJSON(finding.records)
    }

    return result
  },

  /************************************************
   * fetch records questions for ONE finding       *
   ************************************************/
  async fetchRecordsQuestionsJSON (records) {
    let promsieArr = []

    for (const record of records) {
      let level2RecordArr = []

      for (const q in record.questions) {
        const recordQID = record.questions[q].questionID
        level2RecordArr.push(dbServices.fetchQuestionJSON(recordQID))
      }

      const result = await Promise.all(level2RecordArr)
      promsieArr.push(result)
    }

    return promsieArr
  },

  /************************************************
   * fetch one record's questions for one finding  *
   ************************************************/
  async fetchRecordQuestionsJSON (record) {
    const questions = record.questions
    let promiseArr = []

    if (common.isEmptyObject(questions)) {
      return null
    }

    for (const field in questions) {
      const qID = questions[field].questionID
      promiseArr.push(dbServices.fetchQuestionJSON(qID))
    }

    const result = await Promise.all(promiseArr)
    return result
  },

  async fetchQuestionsJSON (questions) {
    let promiseArr = []

    for (const field in questions) {
      const qID = questions[field].questionID

      if (qID != null) {
        promiseArr.push(dbServices.fetchQuestionJSON(qID))
      }
    }

    const result = await Promise.all(promiseArr)
    return result
  },

  mapSedativesSelectItems (list) {
    return list.map(item => {
      return {
        value: item.id,
        text: item.sedative,
      }
    })
  },

  // returned a new object filtered
  // newQuestions: Object, stateQuestions: Object
  filterOutExistingQuestions (newQuestions, stateQuestions) {
    let obj = {}

    for (const key in newQuestions) {
      if (key == 'location' && newQuestions[key][0]) {
        for (const key2 in newQuestions[key][0].questions) {
          const qID = newQuestions[key][0].questions[key2].questionID
          if (!stateQuestions[qID]) {
            obj[key2] = newQuestions[key][0].questions[key2]
          }
        }
      } else {
        const qID = newQuestions[key].questionID
        if (!stateQuestions[qID]) {
          obj[key] = newQuestions[key]
        }
      }
    }

    return obj
  },

  // return an object that has unique values based on the keyField
  // the later value will replace the former value if they have the same key field
  getUniqueValuesBy (obj, keyField) {
    let uniqueQuestionsObj = {}

    for (const item of Object.values(obj)) {
      // recursively loop through
      if (Array.isArray(item)) {
        item.forEach(singleItem => {
          if (singleItem.questions) {
            uniqueQuestionsObj = Object.assign(uniqueQuestionsObj, this.getUniqueValuesBy(singleItem.questions, keyField))
          }
        })
      } else {
        const keyValue = item[keyField]
        uniqueQuestionsObj[keyValue] = item
      }
    }

    return uniqueQuestionsObj
  },

  getUniqueValuesFromAllFindings (findings) {
    let uniqueQuestionsObj = {}
    findings.forEach(finding => {
      // get unique questions from records
      if (finding.records && finding.records.length > 0) {
        finding.records.forEach(record => {
          const uniqueRecordQs = this.getUniqueValuesBy(record.questions, 'questionID')
          uniqueQuestionsObj = Object.assign(uniqueQuestionsObj, uniqueRecordQs)
        })
      }

      const uniqueFindingQs = this.getUniqueValuesBy(finding.questions, 'questionID')
      uniqueQuestionsObj = Object.assign(uniqueQuestionsObj, uniqueFindingQs)
    })

    return uniqueQuestionsObj
  }

}

export default forms