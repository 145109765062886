<template>
    <div class="sound-wave">
        <div class="r-1"></div>
        <div class="r-2"></div>
        <div class="r-3"></div>
        <div class="r-4"></div>
        <div class="r-5"></div>
        <div class="r-6"></div>
        <div class="r-5"></div>
        <div class="r-4"></div>
        <div class="r-3"></div>
        <div class="r-2"></div>
        <div class="r-1"></div>	
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: "SoundWaveAnim"
}
</script>

<style lang="scss" scoped>
.sound-wave {
	position: absolute;
	height: 8px;
	width: 60px;
	margin: 1px 0 0;
}

.sound-wave{

     > div {
        position: relative;
        display: inline-block;
        background: #DCB3FF;
        height: 100%;
        width: 4px;
        margin: 0;
        -webkit-animation: load 3s ease-in-out infinite;
	    animation: load 3s ease-in-out infinite;
    }

    .r-2 {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }

    .r-3 {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }

    .r-4 {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }

    .r-5 {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }

    .r-6 {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }
}

@-moz-keyframes load {
	0%,
	100% {
		-moz-transform: scaleY(1);
		background: #DCB3FF;
	}
	16.67% {
		-moz-transform: scaleY(3);
		background: #FFFFFF;
	}
	33.33% {
		-moz-transform: scaleY(1);
		background: #C5FFF8;
	}
	50% {
		-moz-transform: scaleY(3);
		background: #5DEBFF;
	}
	66.67% {
		-moz-transform: scaleY(1);
		background: #5D6FFF;
	}
	83.34% {
		-moz-transform: scaleY(3);
		background: #9A26FC;
	}
} 

@-webkit-keyframes load {
	0%,
	100% {
		-webkit-transform: scaleY(1);
		background: #DCB3FF;
	}
	16.67% {
		-webkit-transform: scaleY(3);
		background: #FFFFFF;
	}
	33.33% {
		-webkit-transform: scaleY(1);
		background: #C5FFF8;
	}
	50% {
		-webkit-transform: scaleY(3);
		background: #5DEBFF;
	}
	66.67% {
		-webkit-transform: scaleY(1);
		background: #5D6FFF;
	}
	83.34% {
		-webkit-transform: scaleY(3);
		background: #9A26FC;
	}
} 

@keyframes load {
	0%,
	100% {
		transform: scaleY(1);
		background: #DCB3FF;
	}
	16.67% {
		transform: scaleY(3);
		background: #FFFFFF;
	}
	33.33% {
		transform: scaleY(1);
		background: #C5FFF8;
	}
	50% {
		transform: scaleY(3);
		background: #5DEBFF;
	}
	66.67% {
		transform: scaleY(1);
		background: #5D6FFF;
	}
	83.34% {
		transform: scaleY(3);
		background: #9A26FC;
	}
} 
</style>