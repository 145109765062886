<template>
  <v-autocomplete
    v-model="nameParsed"
    :items="results"
    :loading="isLoading"
    :search-input.sync="watchSync"
    clearable
    filled
    hide-no-data
    hide-selected
    item-text="name"
    item-value="name"
    placeholder="Name"
    return-object
    @change="select"
    @keyup.enter="(e) => search(e.taget.value)"
  >
  </v-autocomplete>
</template>
<script>
import { patientServices } from '@/services/db'
export default {
  name: 'SearchPatientInput',
  props: {
    name: String
  },
  data() {
    return {
      results: [],
      isLoading: false,
      watchSync: null,
      nameParsed: null
    }
  },
  watch: {
    watchSync(value) {
      if (!value) return
      if (this.isLoading) return
      this.search()
    },
    name(value) {
      if (!value) {
        this.nameParsed = null
        this.result = []
      }
    }
  },
  methods: {
    search() {
      this.result = []
      this.isLoading = true
      try {
        clearTimeout(this._searchTimerId)
        this._searchTimerId = setTimeout(async () => {
          const keyword = this.watchSync
          const res = await patientServices.searchPatient({ keyword: keyword })
          this.results = res.data.suggestion
          this.isLoading = false
        }, 1000)
      } catch (e) {
        console.log(e)
      }
    },
    select(info) {
      this.$emit('selected', info)
    }
  }
}
</script>
