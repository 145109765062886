<template>
  <div
    class="findingName"
    :class="{ 'finding-over-cover': getStoreHiddenParameter.over }"
  >
    <v-layout
      justify-center
      align-items-center
      class="--full-width --shadow py-2"
      data-test="add-finding"
    >
      <v-flex class="flex-grow-0 align-self-center">
        <icon-button-with-tooltip
          flat
          small
          iconName="add"
          tooltipText="Add new finding"
          @click="dispatchAddFinding()"
        />
      </v-flex>
      <v-flex align-self-center class="flex-grow-0">
        <p class="mb-0 text-primary fsz-18 fsz-lg-20">ADD FINDING</p>
      </v-flex>
    </v-layout>

    <template v-if="findings.length > 0">
      <ul
        class="pl-0 --scroll-y pos-relative --hidden-x pb-3"
        ref="findingItems"
      >
        <v-layout
          data-test="finding-item"
          tag="li"
          v-for="(finding, idx) in sortFindings"
          :key="finding.id"
          class="list-style-none bg-dark-gray-2 text-white py-3 pl-3 pr-2 border-b border-white --cursor"
          :class="{
            'bg-light-gray-2': finding.id === activeId,
          }"
          align-content-center
          @click="$emit('clickFinding', finding.id)"
        >
          <v-flex
            data-test="finding-item-order"
            xs2
            align-self-center
            class="mr-2 font-weight-bold fsz-26"
            tag="h2"
            >{{ idx + 1 }}</v-flex
          >
          <v-flex
            xs8
            data-test="finding-item-name"
            mb-0
            tag="p"
            class="fsz-16 fsz-lg-18 text-uppercase"
            :class="
              !finding.name
                ? 'border-b  align-self-bottom border-white'
                : 'align-self-center '
            "
          >
            {{ finding.name }}
          </v-flex>

          <v-flex
            xs2
            align-self-center
            data-test="finding-item-delete"
            @click.stop="$emit('deleteItem', finding)"
          >
            <v-icon color="#999" class="hover--accent">delete</v-icon>
          </v-flex>
        </v-layout>
      </ul>
    </template>
  </div>
</template>

<script>
import IconButtonWithTooltip from "@/components/IconButtonWithTooltip.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BasicExamFindingList",
  components: { IconButtonWithTooltip },
  props: {
    examinationId: {
      required: true,
    },
    activeId: {
      type: Number,
    },
    findings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("examinations", ["getStoreHiddenParameter"]),
    sortFindings() {
      return this.findings;
    },
    findingIndex() {
      return this.findings.findIndex((f) => f.id === this.activeId);
    },
    getFindingsLength() {
      return this.findings ? this.findings.length : 0;
    },
    targetItem() {
      return (idx) =>
        document.querySelectorAll('[data-test="finding-item"]')[idx];
    },
  },
  methods: {
    ...mapActions("examinations", ["addFinding", "setStoreHiddenParameter"]),
    dispatchAddFinding() {
      this.addFinding(this.examinationId)
        .then(() => {
          this.setStoreHiddenParameter({
            target: "nowFindingIndex",
            value: this.getFindingsLength - 1,
          });
          this.setStoreHiddenParameter({
            target: "nowQuestionPart",
            value: "d",
          });
          this.setStoreHiddenParameter({
            target: "focus",
            value: "finding",
          });
          this.setStoreHiddenParameter({
            target: "over",
            value: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    scrollToTargetItem() {
      const target = this.targetItem(this.findingIndex);
      if (target) {
        this.$refs.findingItems.scrollTo({
          left: 0,
          top: target.offsetTop,
          behavior: "smooth",
        });
      }
    },
  },
  watch: {
    activeId() {
      this.scrollToTargetItem();
    },
  },
};
</script>

<style lang="scss">
.findingName {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 9fr;
}
</style>
