<template>
  <v-card class="examination-bowel-cleanse-forms">
    <v-card-text class="pa-0 forms-container">
      <v-layout row justify-center class="section-container">
        <v-flex xs8 class="pt-3 pb-1">
          <div class="title font-weight-light">Periprocedure Info</div>

          <template v-if="examinationType != 1">
            <v-layout row>
              <v-flex
                xs6
                :class="['pr-2', checkIfAddFocusClass('clean_medID')]"
              >
                <clean-med-input
                  :data="examination.clean_medID"
                  @change="handleExaminationChange('clean_medID', $event)"
                ></clean-med-input>
              </v-flex>
              <v-flex xs6 :class="['pl-2', checkIfAddFocusClass('cleanScale')]">
                <clean-scale-input
                  :data="examination.cleanScale"
                  @change="handleExaminationChange('cleanScale', $event)"
                ></clean-scale-input>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex
                xs6
                :class="['pr-2', checkIfAddFocusClass('insertionLevel')]"
              >
                <insertion-level-input
                  :data="examination.insertionLevel"
                  @change="handleExaminationChange('insertionLevel', $event)"
                ></insertion-level-input>
              </v-flex>
              <v-flex
                xs6
                :class="['pl-2', checkIfAddFocusClass('incompleteDue')]"
              >
                <incomplete-due-to-input
                  :data="examination.incompleteDue"
                  :examinationTypeID="examination.examination_typeID"
                  @change="handleExaminationChange('incompleteDue', $event)"
                ></incomplete-due-to-input>
              </v-flex>
            </v-layout>
          </template>

          <template v-if="examinationType === 1">
            <v-flex xs12>
              <incomplete-due-to-input
                :data="examination.incompleteDue"
                :examinationTypeID="examination.examination_typeID"
                @change="handleExaminationChange('incompleteDue', $event)"
              ></incomplete-due-to-input>
            </v-flex>
          </template>
        </v-flex>
      </v-layout>
      <v-layout row justify-center class="section-container">
        <v-flex xs8>
          <div class="title font-weight-light">
            Sedatives
            <v-btn
              icon
              absolute
              right
              @click="handleSedativeAction('add', $event)"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </div>

          <template v-for="(item, index) in examinationSedatives">
            <sedative-input
              :data="item"
              :medList="sedativesSelectItems"
              :focus="checkIfSedativeInFocus(index)"
              @removeSedative="handleSedativeAction('delete', $event)"
              @change="handleSedativeChange($event)"
            ></sedative-input>
          </template>
        </v-flex>
      </v-layout>

      <template v-if="examinationType != 1">
        <v-layout row justify-center class="section-container pt-4">
          <v-flex xs8>
            <time-inputs-group
              :data="examination"
              horizontal
              @change="handleExaminationChange($event.field, $event.event)"
            ></time-inputs-group>
          </v-flex>
        </v-layout>
      </template>

      <v-layout row justify-center class="py-4">
        <v-btn color="primary" @click="$emit('close')">Save</v-btn>
        <v-btn color="primary" @click="$emit('endOfExam')"
          >back to the list</v-btn
        >
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import forms from '@/utils/forms'
import TimeInputsGroup from '@/components/TimeInputsGroup.vue'
import SedativeInput from '@/components/SedativeInput.vue'
import CleanScaleInput from '@/components/bowelCleanseForms/CleanScaleInput.vue'
import CleanMedInput from '@/components/bowelCleanseForms/CleanMedInput.vue'
import IncompleteDueToInput from '@/components/bowelCleanseForms/IncompleteDueToInput.vue'
import InsertionLevelInput from '@/components/bowelCleanseForms/InsertionLevelInput.vue'

export default {
  name: 'ExaminationBowelCleanseForms',

  props: {
    examinationData: {
      type: Object,
      required: true,
    },
    hiddenParams: {
      type: Object,
      required: true,
    },
  },

  components: {
    TimeInputsGroup,
    SedativeInput,
    CleanScaleInput,
    CleanMedInput,
    IncompleteDueToInput,
    InsertionLevelInput,
  },

  data() {
    return {
      timeList: [
        {
          field: 'insertionTime',
          label: 'Insertion Time',
        },
        {
          field: 'proximalColonWithTime',
          label: 'Proximal Colon Withdrawal Time',
        },
        {
          field: 'withdrawTime',
          label: 'Withdrawal Time',
        },
        {
          field: 'totalExamTime',
          label: 'Total Exam',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('examinations', ['getSedativeList']),

    examination() {
      return this.examinationData.examination
    },

    examinationID() {
      return this.examinationData.examination.id
    },

    examinationType() {
      // 1 => Gastroscopy, 2=> Colonoscopy
      return this.examination.examination_typeID
    },

    examinationSedatives() {
      return this.examinationData.sedatives
    },

    sedativesSelectItems() {
      return forms.mapSedativesSelectItems(this.getSedativeList)
    },
  },

  methods: {
    ...mapActions('examinations', [
      'updateExaminationSedativeColumn',
      'addExaminationSedative',
      'deleteExaminationSedative',
    ]),

    handleSedativeChange({ column, event, id }) {
      this.updateExaminationSedativeColumn({
        column,
        value: Number(event),
        id,
      })
    },

    handleSedativeAction(action, event) {
      const examinationSedativeID = event ? event.id : null
      this[`${action}ExaminationSedative`]({
        examinationID: this.examinationID,
        examinationSedativeID,
      })
    },

    handleExaminationChange(column, event) {
      this.$emit('examinationChange', {
        column,
        event,
      })
    },
    checkIfSedativeInFocus(index) {
      const isCorrectIndex = index === this.hiddenParams.nowSedativesIndex
      const isInRange =
        ['sedative', 'switchSedative'].indexOf(this.hiddenParams.focus) >= 0
      return isCorrectIndex && isInRange == true
    },
    checkIfAddFocusClass(key) {
      return this.hiddenParams.focus == key ? 'focus' : ''
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/components/examinationBowelCleanseForms.scss';
</style>
