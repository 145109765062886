<template>
  <div class="exam-select">
    <h3 v-if="title" class="text-darken-1">{{title}}</h3>
    <v-select
      :chips="multiple"
      class="pt-0 mb-3"
      :multiple="multiple"
      :value="selected"
      :items="items"
      :placeholder="placeholder"
      @change="change" hide-details>
    </v-select>
     
    <v-layout wrap>
      <v-flex v-if="showOther" xs12>
        <v-text-field
          :value="otherValue"
          :label="$t('common.other')"
          hide-details
          @change="changeOther"
        />
      </v-flex>
      <v-flex xs12>
        <slot name="extra" />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'FormSelect',
  props: {
    placeholder: {
      type: String,
      default() {
        return this.$t('homepage.colon.please_select')
      },
    },
    title: {
      type: String,
      default: '',
    },
    selected: {
      type: [String, Number, Array],
    },
    items: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    column: {
      type: String,
      default: '',
    },
    otherColumn: {
      type: String,
      default: '',
    },
    otherValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    mapText() {
      return this.items.reduce((a, c) => ({ ...a, [c.value]: c.text }), {})
    },
    last() {
      if (this.items.length) {
        return this.items[this.items.length - 1].value
      }
      return ''
    },
    showOther() {
      return (
        !!this.otherColumn && !!this.last && this.selected.includes(this.last)
      )
    },
  },
  methods: {
    change(v) {
      if (this.multiple) {
        this.$emit('change', this.column, v)
      } else {
      }
    },
    changeOther(value) {
      this.$emit(this.otherColumn, value)
    },
  },
}
</script>

<style lang="scss">
.exam-select {
  .v-chip__content {
    padding: 4px 8px;
    font-size: 1rem;
  }
}

</style>