<style lang="scss">
.analytics-search {
  &__row {
    & + & {
      margin-top: 30px;
    }
  }

  &__conditions,
  &__fields {
    padding-left: 52px;
  }

  &__fields .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  &__field + &__field {
    margin-top: 10px;
  }
}
</style>

<template>
  <v-container>
    <div class="analytics-search">
      <!-- 篩選條件 -->
      <v-form
        v-model="valid"
        ref="form"
        lazy-validation
        @submit.prevent="handleSubmit"
      >
        <div class="analytics-search__row">
          <v-layout align-center>
            <v-btn icon @click="toggle('filtersPanel')">
              <v-icon :class="[{ '--rotate': filtersPanel.show }]">
                arrow_forward_ios
              </v-icon>
            </v-btn>
            <h2>{{ filtersPanel.text }}</h2>
            <v-btn
              :disabled="conditionLimit"
              icon
              small
              color="primary"
              @click="handleConditionAdd"
            >
              <v-icon small>add</v-icon>
            </v-btn>
          </v-layout>
          <v-expand-transition>
            <div v-show="filtersPanel.show">
              <div class="analytics-search__conditions">
                <v-slide-x-transition group hide-on-leave>
                  <search-filter
                    v-for="condition in conditions"
                    :key="condition.id"
                    v-model="condition.value"
                    :filter="condition.filter"
                    :filters="filters"
                    :items="condition.items"
                    @filter:change="handleFilterChange(condition.id, $event)"
                    @remove="handleFilterRemove(condition.id)"
                  />
                </v-slide-x-transition>
              </div>
            </div>
          </v-expand-transition>
        </div>
        <!-- 顯示欄位 -->
        <div class="analytics-search__row">
          <v-layout align-center>
            <v-btn icon @click="toggle('fieldsPanel')">
              <v-icon :class="[{ '--rotate': fieldsPanel.show }]">
                arrow_forward_ios
              </v-icon>
            </v-btn>
            <h2>{{ fieldsPanel.text }}</h2>
          </v-layout>
          <v-expand-transition>
            <div v-show="fieldsPanel.show">
              <v-layout class="analytics-search__fields">
                <v-flex
                  v-for="(cols, index) in fieldsPanel.items"
                  :key="`col-${index}`"
                  sm4
                  md3
                  lg2
                >
                  <div
                    v-for="item in cols"
                    :key="item.value"
                    class="analytics-search__field"
                  >
                    <v-checkbox
                      v-model="fields"
                      :label="item.text"
                      :value="item.text"
                      hide-details
                      :rules="[v => !!v.length || '']"
                    />
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-expand-transition>
        </div>
        <div class="analytics-search__row">
          <v-btn type="submit" outline>
            Apply
          </v-btn>
        </div>
        <!-- DATA -->
        <div class="analytics-search__row">
          <search-table :data="table" />
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import SearchFilter from '@/components/analytics/SearchFilter'
import SearchTable from '@/components/analytics/SearchTable'

import { FILTER, FILTER_MAP, SEARCH_FIELDS } from '@/utils/constants'
import helper from '@/utils/common'
import { analyticsServices } from '@/services/db'

const PANEL = {
  FILTER: 'filter',
  FIELD: 'field',
}
const FILTERS = [
  FILTER.FINDING,
  FILTER.LOCATION,
  FILTER.SIZE,
  FILTER.INTERVENTION,
]
const CONDITION = {
  filter: '',
  items: [],
  value: [],
}
const CONDITION_LIMIT = 4
const EXAMINATION_TYPE = [2]

export default {
  name: 'AnalyticsSearch',
  components: {
    SearchFilter,
    SearchTable,
  },
  data() {
    return {
      valid: false,
      filtersPanel: {
        type: PANEL.FILTER,
        text: 'Filter',
        show: true,
      },
      fieldsPanel: {
        type: PANEL.FIELD,
        text: 'Fields to display',
        show: true,
        items: SEARCH_FIELDS,
      },
      conditions: [],
      fields: [],
      filterMap: FILTER_MAP,
      table: { headers: [], items: [] },
    }
  },
  computed: {
    selectedFilters() {
      return this.conditions.map(({ filter }) => filter)
    },
    filters() {
      return FILTERS.filter(v => !this.selectedFilters.includes(v))
    },
    conditionLimit() {
      return this.conditions.length >= CONDITION_LIMIT
    },
  },
  created() {
    analyticsServices.fetchSearchFinding().then(this.setFilterMap('finding'))
    analyticsServices.fetchSearchLocation().then(this.setFilterMap('location'))
    analyticsServices
      .fetchSearchIntervention()
      .then(this.setFilterMap('intervention'))
  },
  methods: {
    setFilterMap(name) {
      return data => {
        this.filterMap[name] = this.filterMap[name].concat(data)
      }
    },
    toggle(key) {
      const panel = this[key]
      this[key] = { ...panel, show: !panel.show }
    },
    handleConditionAdd() {
      this.conditions = this.conditions.concat({
        ...CONDITION,
        id: helper.sid(),
      })
    },
    handleFilterChange(id, filter) {
      this.conditions = this.conditions.map(condition =>
        condition.id === id
          ? {
              ...CONDITION,
              id: condition.id,
              filter,
              items: this.filterMap[filter],
            }
          : condition,
      )
    },
    handleFilterRemove(id) {
      this.conditions = this.conditions.filter(condition => condition.id !== id)
    },
    getValues(conditions) {
      return conditions.map(condition => ({
        name: condition.filter,
        value: condition.value.reduce((acc, v) => {
          const index = condition.items.findIndex(({ value }) => value === v)

          if (index !== -1) {
            const found = condition.items[index]

            if (found.subItems) {
              return acc.concat(
                found.subItems.map(({ value }) => value),
                found.value,
              )
            }
          }
          return v === 'ALL' ? acc : acc.concat(v)
        }, []),
      }))
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        analyticsServices
          .search({
            examination_type: EXAMINATION_TYPE, // 目前僅搜尋大腸鏡
            conditions: this.getValues(this.conditions),
            fields: this.fields,
          })
          .then(table => {
            this.table = table
          })
      }
    },
  },
}
</script>
