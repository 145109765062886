export const getters = {
  getUser (state) {
    return state.user
  },
  getError (state) {
    return state.error
  },
  getLoading (state) {
    return state.loading
  },
  getLoadingDescript (state) {
    return state.loadingDescript
  },
  getIsAutoRecognize (state) {
    return state.isAutoRecognize
  },
  getSetting(state) {
    return state.setting
  }
}