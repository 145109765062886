<style lang="scss">
.v-menu__content {
  max-height: 30vh;
}
.multi-select {
  & + & {
    margin-top: 16px;
  }

  &__group {
    border-radius: 10px 10px 0 0;
  }
  &__title {
    border-bottom: 1px solid #efefef;
  }

  &__selection {
    height: 50px;

    &.--multiple {
      height: auto;
      max-height: 150px;
      overflow-y: auto;
    }

    .v-chip.v-chip,
    .v-chip__content {
      height: 2rem;
      font-size: 1.1rem;
    }
  }
}
</style>

<template>
  <v-card class="multi-select" flat>
    <v-menu
      class="multi-select__title"
      offset-y
      left
      attach
      :close-on-content-click="!multiple"
    >
      <template slot="activator" slot-scope="props">
        <v-card-title
          v-on="props.on"
          class="pa-2 grey--text text--darken-1 h3 font-weight-bold --cursor"
        >
          <div>
            <slot name="header-sub">
              <h3 class="mb-0">{{ label }}</h3>
            </slot>
          </div>
          <v-spacer />
          <v-btn icon color="grey--text text--darken-1" small>
            <v-icon>expand_more</v-icon>
          </v-btn>
        </v-card-title>
      </template>

      <v-list>
        <v-list-tile
          v-for="item in items"
          :key="`select-${item.value}`"
          :ripple="{ class: 'blue-grey--text' }"
          @click="toggle(item.value)"
        >
          <v-list-tile-content>
            <template v-if="multiple">
              <v-checkbox
                :input-value="selected"
                :value="item.value"
                multiple
                color="blue-grey"
              >
                <template #label>
                  <label class="header grey--text text--darken-2 --cursor">
                    {{ item.text }}
                  </label>
                </template>
              </v-checkbox>
            </template>
            <template v-else>
              <v-list-tile-title
                :class="[
                  'headine',
                  '--cursor',
                  item.value === selected
                    ? 'primary--text'
                    : 'grey--text text--darken-2',
                ]"
              >
                {{ item.text }}
              </v-list-tile-title>
            </template>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-menu>
    <v-card-text
      :class="['pa-2', 'multi-select__selection', { '--multiple': multiple }]"
    >
      <template v-if="multiple && selected.length">
        <v-chip
          v-for="value in selected"
          :key="`chip-${value}`"
          outline
          close
          @input="remove(value)"
        >
          {{ mapText[value] }}
        </v-chip>
      </template>
      <template v-else-if="!multiple && selected">
        <v-chip v-if="selected" outline close @input="remove(selected)">
          {{ mapText[selected] }}
        </v-chip>
      </template>
      <template v-else>
        <p class="text-defaultGreyText mb-0">{{ $t('common.nil') }}</p>
      </template>
    </v-card-text>

    <v-card-actions class="multi-select__extra">
      <v-layout wrap>
        <v-flex v-if="showOther" xs12>
          <v-text-field
            :value="otherValue"
            :label="$t('common.other')"
            hide-details
            @change="changeOther"
          />
        </v-flex>
        <v-flex xs12>
          <slot name="extra" />
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'FormSelect',
  props: {
    selected: {
      type: [String, Number, Array],
      // required: true, 在不改資料的前提，可能會有空值，故先comment
    },
    items: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    column: {
      type: String,
      default: '',
    },
    otherColumn: {
      type: String,
      default: '',
    },
    otherValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    mapText() {
      return this.items.reduce((a, c) => ({ ...a, [c.value]: c.text }), {})
    },
    last() {
      if (this.items.length) {
        return this.items[this.items.length - 1].value
      }
      return ''
    },
    showOther() {
      return (
        !!this.otherColumn && !!this.last && this.selected.includes(this.last)
      )
    },
  },
  methods: {
    change(column, value) {
      this.$emit('change', column, value)
    },
    changeOther(value) {
      this.change(this.otherColumn, value)
    },
    toggleMultiple(v) {
      if (this.selected.includes(v)) {
        this.remove(v)
      } else {
        this.change(this.column, this.selected.concat(v))
      }
    },
    toggleSingle(v) {
      this.change(this.column, v)
    },
    toggle(v) {
      if (this.multiple) {
        this.toggleMultiple(v)
      } else {
        this.toggleSingle(v)
      }
    },
    removeMultiple(v) {
      this.change(
        this.column,
        this.selected.filter((s) => s !== v),
      )

      if (v === this.last) {
        this.changeOther('')
      }
    },
    removeSingle() {
      this.change(this.column, '')
    },
    remove(v) {
      if (this.multiple) {
        this.removeMultiple(v)
      } else {
        this.removeSingle()
      }
    },
  },
}
</script>
