<template>
  <div class="time-info-group">
    <v-layout justify-space-around wrap align-items-center py-3>
      <v-flex v-for="(item, index) in timeList" :key="index" :grow="false">
        <div class="label">{{ $t(item) }}</div>
        <p>{{ data[item] }}</p>
      </v-flex>
    </v-layout>

  </div>
</template>

<script>
export default {
  name: 'TimeInputsGroup',

  props: {
    data: {
      type: Object
    }
  },

  data () {
    return {
      timeList: ['insertionTime', 'proximalColonWithTime', 'withdrawTime', 'totalExamTime'],
      showWihdrawTimeCheck () {
        const { withdrawTime } = this.data
        if (!withdrawTime) return false

        const timeArr = withdrawTime.split(":");
        const availableMinutes = 6
        return parseInt(timeArr[1]) > availableMinutes 
      }
    }
  }
    
}
</script>
<style lang="scss" scoped>
#print .quality-text{
  margin-bottom: 10px;
  color: grey;
  font-weight: bold;
}

</style>
