export const state = {
  user: null,
  error: null,
  loading: false,
  loadingDescript: '',
  isAutoRecognize: false, // 全時收音
  setting: {
    asr_domain: '',
    asr_ws_port: '',
    asr_ws_path: '',
    asr_ws_realtime_port: '',
    asr_ws_realtime_path: '',
    tts_port: '',
    tts_path: '',
    demo: false,
    screenshot: false,
    descriptive: false,
  },
}
