<template>
  <div class="gastro-bowel-cleanse-forms print my-3">
    <ul class="gastro-info ml-1">
      <!--  Scoped Id -->
      <PreviewExamItem
        title="scope_id"
        :value="[examinationInfo.inspection_instrumentID]"
        refKey="id"
        refValue="inspection_instrument"
        :refArr="getInspectionInstrumentList"
      />
      <!-- washingMachine -->
      <PreviewExamItem
        title="washing_machine"
        :value="[examinationInfo.wash_instrumentID]"
        refKey="id"
        refValue="wash_instrument"
        :refArr="getWashInstrumentList"
      />
      <!-- addition remark  -->
      <PreviewExamItem
        title="additional_remarks"
        :value="[examinationInfo.additionalRemarks]"
        :needMapRef="false"
      />
    </ul>
    <ul class="gastro-info ml-1">
      <!-- sedavtives -->
      <li>
        <h3>{{ $t('common.sedatives') }} :</h3>
        <div v-if="examination.sedatives.length" class="sedative-list">
          <div
            v-for="item in examination.sedatives"
            style="padding-right: 10px"
            :key="item.sedativeID"
          >
            {{ getSedativeNameFromID(item.sedativeID) }} {{ item.value }}mg
          </div>
          <div v-if="examination.examination.sedativeOther">
            {{ $t('common.other') }} : {{ examination.examination.sedativeOther }}
          </div>
        </div>
        <p v-else>{{ $t('common.nil') }}</p>
      </li>
      <!-- incompleteDue  -->
      <PreviewExamItem
        title="incomplete.title"
        :value="[examinationInfo.incompleteDue]"
        refKey="value"
        refValue="text"
        :refArr="getIncompleteReasonList[1]"
      />
      <!-- cloTest  -->
      <li
        v-if="
          examinationInfo.cloTest === '1' &&
          !examinationInfo.cloTestLocations.length
        "
      >
        <h3>CLO Test</h3>
        <p>是</p>
      </li>

      <PreviewExamItem
        v-else
        title="clo_test"
        :value="examinationInfo.cloTestLocations"
        refKey="value"
        refValue="text"
        :refArr="getCloTestLocations"
      />
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PreviewExamItem from './PreviewExamItem'

export default {
  name: 'GastroPreviewBowelCleanseForms',
  components: {
    PreviewExamItem,
  },
  props: {
    examination: {
      type: Object,
      required: true,
    },
    sedatives: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters('examinations', [
      'getInspectionInstrumentList',
      'getWashInstrumentList',
      'getIncompleteReasonList',
      'getIndicationList',
      'getCloTestLocations',
    ]),
    examinationInfo() {
      return this.examination.examination
    },
  },
  methods: {
    getSedativeNameFromID(id) {
      return this.sedatives.filter((item) => item.id === id)[0].sedative
    },
    isShow(data) {
      let result = true
      if (
        data == 'Choose' ||
        data == '' ||
        (Array.isArray(data) && data.length === 0)
      ) {
        result = false
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/components/bowelCleanseForms.scss';

.gastro-bowel-cleanse-forms {
  ul,
  li {
    margin-left: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.gastro-info {
  li {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 0.5rem;
  }
}
</style>
