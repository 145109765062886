<template>
  <v-layout row wrap align-content-start fill-height class="pa-4">
    <v-flex xs12 class="page-head-container mb-3">
      <v-flex xs12 class="mb-4 page-head-container">
        <h1 class="headline font-weight-light">Examination Type List</h1>
      </v-flex>

      <v-flex xs12 class="search-container">
        <v-text-field
          label="Search"
          solo
          flat
          hide-details
          v-model="search"
        ></v-text-field>
      </v-flex>

      <v-flex xs12>
        <template>
          <v-data-table
            v-if="getExaminationTypes.length > 0"
            :headers="headers"
            :items="getExaminationTypes"
            :pagination.sync="pagination"
            :search="search"
            hide-actions
            class="my-4"
          :disable-initial-sort=true >


            <template slot="headers" slot-scope="props">
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.text"
                  :class="['column sortable', pagination.order ? 'desc' : 'asc', header.value === pagination.title ? 'active' : '']"
                  @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>


            <template slot="items" slot-scope="props">
              <tr @click="clickItem(props.item)">
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.examination_type"
                    lazy
                    @save="updateExaminationType(props.item.id, 'examination_type', tempItem.examination_type)"
                  > {{ props.item.examination_type }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.examination_type"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-flex>
    </v-flex>

    <v-btn icon fixed bottom right fab color="primary" @click="addExaminationType">
      <v-icon>add</v-icon>
    </v-btn>

    <v-snackbar
      v-model="snackbar"
      bottom
      :timeout="timeout"
      color="green"
    >
      New ExaminationType Added
    </v-snackbar>
  </v-layout>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'AdminExaminationTypeList',
    data() {
      return {
        search: '',
        pagination: {
          rowsPerPage: -1,
          title:'',
          order:false
        },
        headers: [
          {
            text: 'ExaminationType',
            align: 'left',
            value: 'examination_type',
            // sortable: false
          }
        ],
        snackbar: false,
        timeout: 3000,
        tempItem:'',
        tempValue:''
      }
    },

    computed: {
      ...mapGetters('examinationTypes', [
        'getExaminationTypes'
      ])
    },

    created () {
      this.fetchExaminationTypes()
    },

    methods: {
      ...mapActions('examinationTypes', [
        'fetchExaminationTypes'
      ]),
      changeSort (column) {
        if(column===''){return;}
        if (this.pagination.title === column) {
          this.pagination.order = !this.pagination.order
        } else {
          this.pagination.title = column
          this.pagination.order = false
        }
        this.$store.dispatch('examinationTypes/sortExaminationType', {title:column,order:this.pagination.order})

      },

      updateExaminationType ( examinationTypeID, field, value ) {
        // console.log(examinationTypeID,field,value)
        const updateObj = {
          examinationTypeID: examinationTypeID,
          field: field,
          value: value
        }
        
        this.$store.dispatch('examinationTypes/updateColumnById', updateObj)
      },

      async addExaminationType () {
        try {
          await this.$store.dispatch('examinationTypes/addExaminationType')
          this.snackbar = true
        } catch (e) {
          this.snackbar = false
        }
      },
      clickItem(data){
        this.tempItem = Object.assign({}, data);
      }
    }
  }
</script>

<style lang="scss">
</style>