<template>
  <div>
    <ExamAudioVue ref="examAudio" />
    <Video :speechRecognition="speechRecognition" @audioOutput="changeAudioOutput" />
    <!-- <div>
      <input v-model="testText" name="ss" id="" cols="30" class="border" />
      <v-btn @click="() => speechRecognition(testText, 'websocket', 123456, 123456)">asdfsf</v-btn>
    </div> -->
    <v-dialog v-model="showEvasgoWarning" width="400">
      <v-card>
        <v-card-title class="justify-center headline">警告</v-card-title>
        <v-card-text class="text-xs-center"> 本檢查已記錄過時間，是否歸零重新計時? </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn dark color="green darken-1" @click="toggleEvasgoWarning(false)">NO</v-btn>
          <v-btn dark color="red darken-1" @click="handleEvasGo">YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { asrService, reportServices } from '@/services/db'
import COMMON from '@/utils/common'
import { INPUT_TYPES } from '../utils/constants'
import {
  EXAMINATION_TYPE_OF_GASTROSCOPY as GASTRO_TYPE,
  EXAMINATION_TYPE_OF_COLONOSCOPY as COLON_TYPE,
} from '../utils/examinations.config'
import examMixins from '../mixins/examMixins'
import ttsMixins from '@/mixins/ttsMixins'
import Video from '@/components/examinations/Video.vue'
import ExamAudioVue from './examinations/ExamAudio.vue'

import { STORE_ACTIONS, COMMON_COMMAND } from '@/utils/constants'

import { generateFindingID as GEN_FINDING_ID } from '@/settings/findingUIMap.js'
import { insertionLevelList, cleanScaleList } from '@/store/data/input-list'

const {
  DELETE_FINIDNG,
  FINDING_OVER,
  EVAS_GO,
  INSERTION_TO,
  PROXIMAL_TIME,
  FINISH,
  NEW_LOCATION,
  DELETE_LOCATION,
  CLEANSING_LEVEL,
  INSERTION_LEVEL,
  CLO,
  CLO_LOCATION,
  INDICATION,
  FINDING,
  LESION,
} = COMMON_COMMAND

const _toLowerCase = (str) => (typeof str === 'string' ? str.toLowerCase() : str)

const array2Object = (fn, arr) => arr.reduce(fn, {})
const combineResultWithItem = (result, item) => ({
  ...result,
  [_toLowerCase(item.text)]: item.value,
})
const genItemObj = (item) => {
  if(!item) return {}
  const qa =
    item.inputType !== INPUT_TYPES.CHECKBOX2
      ? _toLowerCase(item.question)
      : _toLowerCase(Object.values(item.options)[0])
  if (!qa) {
    return item
  }
  return {
    [qa]: {
      ...item,
      key: item.field,
      type: item.inputType,
      options: Object.entries(item.options).reduce(
        (acc, [key, val]) => ({
          ...acc,
          [_toLowerCase(val)]: item.inputType === INPUT_TYPES.CHECKBOX2 ? Number(key) : key,
        }),
        {}
      ),
    },
  }
}

const CLEAN_SCALE_LIST = array2Object(combineResultWithItem, cleanScaleList)
const INSERTION_LEVEL_LIST = array2Object(combineResultWithItem, insertionLevelList)

const EXAM_COUNT_STEPS = {
  [EVAS_GO]: 1,
  [INSERTION_TO]: 2,
  [PROXIMAL_TIME]: 3,
  [FINISH]: 4,
}

const QA_EXAM_TYPE = 'exam'

export default {
  name: 'VoiceActionControl',
  components: {
    Video,
    ExamAudioVue,
  },
  mixins: [examMixins, ttsMixins],
  data() {
    return {
      testText: "",
      showEvasgoWarning: false,
      isEvasGo: false, // Fix
      currentQuestion: '',
      intervenTemplate: {},
      hpaTemplate: {
        [INSERTION_LEVEL]: {
          category: QA_EXAM_TYPE,
          key: 'insertionLevel',
          type: INPUT_TYPES.SELECT,
          options: INSERTION_LEVEL_LIST,
        },
        [CLEANSING_LEVEL]: {
          category: QA_EXAM_TYPE,
          key: 'cleanScale',
          type: INPUT_TYPES.SELECT,
          options: CLEAN_SCALE_LIST,
        },
        [CLO_LOCATION]: {
          category: QA_EXAM_TYPE,
          key: 'cloLoca',
          type: INPUT_TYPES.CHECKBOX,
          options: {
            pylorus: 153,
            antrum: 154,
            'low-body': 155,
          },
        },
      },
      vueResourceOptions: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      commonCommand: [
        DELETE_FINIDNG,
        FINDING_OVER,
        EVAS_GO,
        FINISH,
        NEW_LOCATION,
        DELETE_LOCATION,
        FINDING,
        LESION,
      ],
      gastroCommand: [CLO, CLO_LOCATION],
      colonCommand: [INSERTION_TO, PROXIMAL_TIME, CLEANSING_LEVEL, INDICATION],
      // tts
      ttsActionList: [],
      // audio
      deviceId: '',
    }
  },
  computed: {
    ...mapGetters(['getUser']),
    ...mapGetters('examinations', ['getStoreHiddenParameter', 'getQuestionsList']),
    currentQaMap() {
      if (!this.getRecord_examMixins) return {}
      return Object.entries(this.getRecord_examMixins.questions).reduce((acc, [_, item]) => {
        return item.config ? { ...acc, ...genItemObj(item.config) } : acc
      }, {})
    },
    locationQaMap() {
      if (!this.getLocation__examMixins || !this.getLocation__examMixins.length) return {}
      return Object.entries(this.getLocation__examMixins[0].questions).reduce((acc, [_, item]) => {
        return {
          ...acc,
          ...genItemObj(this.getQuestionsList[item.questionID]),
        }
      }, {})
    },
    currentTemplate() {
      if (!this.getCurrentFinding_examMixins) return { ...this.hpaTemplate }
      return {
        ...this.currentQaMap,
        ...this.locationQaMap,
        ...this.hpaTemplate,
      }
    },
    currentInterven() {
      return this.getCurrentFinding_examMixins &&
        this.getRecord_examMixins &&
        this.getRecord_examMixins.questions.interven
        ? this.getRecord_examMixins.questions.interven.value
        : null
    },
    updateExamQParams() {
      const findingIndex = this.getStoreHiddenParameter.nowFindingIndex
      const recordIndex = this.getStoreHiddenParameter.nowRecordIndex
      const field = this.getStoreHiddenParameter.focus
      return {
        examination_typeID: this.examTypeID_examMixins,
        findingIndex,
        recordIndex,
        findingID: this.getCurrentFinding_examMixins?.id,
        field,
      }
    },
    currentCountStep() {
      return this.getStoreHiddenParameter.nowTimeCountingStep
    },
    examCommonCommand() {
      return this.examTypeID_examMixins == GASTRO_TYPE
        ? [...this.commonCommand, ...this.gastroCommand]
        : [...this.commonCommand, ...this.colonCommand]
    },
  },
  methods: {
    ...mapActions('examinations', [
      'updateExaminationColumn',
      'setCurrentCommand',
      'setStoreHiddenParameter',
      'setTimeCounting',
      'setTimeCountingStep',
      'setTimeFinish',
    ]),
    speechRecognitionParams(recorderData, mode, startTimestamp, endTimestamp, interven) {
      return {
        mode,
        file: recorderData,
        startTimestamp,
        endTimestamp,
        interven: interven,

        translationMode: 'o',
        focus: this.getStoreHiddenParameter.focus, // v
        part: this.getStoreHiddenParameter.nowQuestionPart,
        examinationID: this.exID_examMixins,
        examination_typeID: this.examTypeID_examMixins,
        examinationDoctorID: this.doctorID_examMixins,

        over: this.getStoreHiddenParameter.over,

        finding: this.getCurrentFinding_examMixins
          ? this.getCurrentFinding_examMixins.questions.finding.value
          : null,

        patient: this.getPatient,
        doctor: this.doctorID_examMixins,
        technician1: this.staffID_1_examMixins,
        technician2: this.staffID_2_examMixins,
      }
    },
    async speechRecognition(recorderData, mode, startTimestamp, endTimestamp) {
      // 呼叫後端 API
      const params = this.speechRecognitionParams(
        recorderData,
        mode,
        startTimestamp,
        endTimestamp,
        this.currentInterven
      )
      const data = await asrService.speechRecognition(params)
      const { voiceInput } = data
      this.addWindowVoiceInput(voiceInput)
    },
    // 呼叫後端 API - 轉換語音回傳文字
    async addWindowVoiceInput(voiceInputArray = []) {
      for (let i = 0; i < voiceInputArray.length; i++) {
        await this.voiceCommand(voiceInputArray[i].toLowerCase())
        await this.setCurrentCommand(voiceInputArray[i])
      }
      // PRINT LOG
      let frontEndLog = `# voiceCommand: ${new Date().getTime().toString()} => ${voiceInputArray}`
      await asrService.saveFrontendLog(frontEndLog)
      // Fetch Audio
      if (this.getUser.useGuiding && this.ttsActionList.length > 0) {
        await this.triggerFetchDataAudio()
        this.ttsActionList = []
      }
    },
    // MODIFY
    async voiceCommand(voiceInput) {
      const isCommonKeyword = this.examCommonCommand.includes(voiceInput)
      if (isCommonKeyword) {
        await this.handleCommonVoiceCommand(voiceInput)
      } else if (this.currentQuestion.category === QA_EXAM_TYPE) {
        await this.handleExamQaVoiceCommand(voiceInput)
      } else {
        const resultObj = await this.seperateCommandTypes(voiceInput)
        const { updateStatus, inputType, data: resultObjData, type: resultObjType } = resultObj
        if (updateStatus && !this.getStoreHiddenParameter.over) {
          const writeInputResult = await this.handleWriteRecordInput({
            inputType,
            data: resultObjData,
          })
          const questionIsAnswerType = inputType === INPUT_TYPES.CHECKBOX2
          const isCheckBox = inputType === INPUT_TYPES.CHECKBOX || INPUT_TYPES.CHECKBOX2
          const content = questionIsAnswerType
            ? this.currentQuestion.command
            : `${this.currentQuestion.command || resultObjType} ${voiceInput}`
          this.addTTSActionList({
            content,
            value: isCheckBox ? writeInputResult.length : writeInputResult,
          })
          this.currentQuestion = {}
          this.$refs.examAudio.audioPlay('success')
          return new Promise ((resolve) => {
            // 為了特效用 (滾動)
            setTimeout(() => {
              this.setStoreHiddenCtrl({ focus: '' })
              resolve()
            }, 400)
          })
        }
      }
    },
    async handleCommonVoiceCommand(voiceInput) {
      // 確定進來參數是在預期內
      const isCommonKeyword = this.examCommonCommand.includes(voiceInput)
      if (!isCommonKeyword) return

      switch (voiceInput) {
        case FINDING_OVER:
          this.storeHiddenActions(STORE_ACTIONS.OVER_FINIDNG)()
          break
        case EVAS_GO:
          const regexPattern = new RegExp(/[0|1]\d:[0-5]\d|[2]:[0-3]:[0-5]\d/)
          const checkHasTime = (time) => time && regexPattern.test(time) && time !== '00:00:00';
          const hasExamTimeRecord =
            checkHasTime(this.timeStart__examMixins) ||
            checkHasTime(this.timeInsertionLevel__examMixins) ||
            checkHasTime(this.timeProximal__examMixins) ||
            checkHasTime(this.timeFinished__examMixins)
          if (hasExamTimeRecord && this.examTypeID_examMixins !== GASTRO_TYPE) {
            this.toggleEvasgoWarning(true)
          } else {
            this.handleEvasGo()
          }
          break
        case INSERTION_TO:
          if (
            this.currentCountStep === EXAM_COUNT_STEPS[EVAS_GO] ||
            this.currentCountStep === EXAM_COUNT_STEPS[INSERTION_TO]
          ) {
            this.setStoreHiddenCtrl({ focus: 'insertionLevel' })
            this.handleUpdateTimer({
              step: INSERTION_TO,
              audio: 'insertion2cecum',
            })
            this.currentQuestion = {
              ...this.currentTemplate[INSERTION_LEVEL],
              command: INSERTION_LEVEL,
            }
          }
          break
        case PROXIMAL_TIME:
          if (this.currentCountStep === EXAM_COUNT_STEPS[INSERTION_TO]) {
            this.handleUpdateTimer({ step: PROXIMAL_TIME })
          }
          break
        case FINISH:
          const unfinishStatus = '0'
          if (this.examStatus_examMixins !== unfinishStatus) {
            this.isEvasGo = false
            this.setTimeFinish(true)
            this.handleUpdateTimer({ step: FINISH, audio: 'finish' })
            this.setExaminationColumn('status', 2)
            this.sendReport()
          }
          break
        case DELETE_FINIDNG:
          if (this.getCurrentFinding_examMixins && !this.getStoreHiddenParameter.over) {
            await this.handleDeleteFinding()
            this.$refs.examAudio.audioPlay('success')
            this.addTTSActionList({ content: DELETE_FINIDNG, isCommon: true })
          }
          break
        case NEW_LOCATION:
          if (this.getCurrentFinding_examMixins && !this.getStoreHiddenParameter.over) {
            await this.$store.dispatch('examinations/addExaminationLocation', {
              recordID: this.getRecord_examMixins.id,
              findingIndex: this.getStoreHiddenParameter.nowFindingIndex,
            })
            this.setStoreHiddenCtrl({
              nowLocationsIndex: this.getLocation__examMixins.length - 1,
              focus: 'loca',
            })
            this.addTTSActionList({ content: NEW_LOCATION, isCommon: true })
          }
          break
        case DELETE_LOCATION:
          if (!this.getStoreHiddenParameter.over) {
            this.$store.dispatch('examinations/deleteExaminationLocation', {
              locationID: this.getCurrentLocation__examMixins.id,
              recordIndex: this.getStoreHiddenParameter.nowRecordIndex,
              findingIndex: this.getStoreHiddenParameter.nowFindingIndex,
            })
            const locationIdx =
              this.getLocation__examMixins.length - 1 != -1
                ? this.getLocation__examMixins.length - 1
                : null
            this.setStoreHiddenCtrl({
              nowLocationsIndex: locationIdx,
            })
            this.$refs.examAudio.audioPlay('saved')
            this.addTTSActionList({ content: DELETE_LOCATION, isCommon: true })
          }
          break
        case CLEANSING_LEVEL:
          this.setStoreHiddenCtrl({ focus: 'cleanScale' })
          this.currentQuestion = {
            ...this.currentTemplate[CLEANSING_LEVEL],
            command: CLEANSING_LEVEL,
          }
          break
        case CLO:
          this.$emit('examCommand', { state: 'clo' })
          break
        case CLO_LOCATION:
          this.setStoreHiddenCtrl({ focus: 'cloLoca' })
          this.currentQuestion = {
            ...this.currentTemplate[CLO_LOCATION],
            command: CLO_LOCATION,
          }
          break
        case INDICATION:
          this.setStoreHiddenCtrl({ focus: INDICATION, over: true })
          this.$emit('examCommand', { state: INDICATION, value: 'open' })
          this.setCurrentQuestions({ key: INDICATION, category: 'exam', command: INDICATION })
          break
        case FINDING:
          this.setStoreHiddenCtrl({ focus: FINDING })
          this.currentQuestion = { category: 'exam', key: FINDING, command: FINDING }
          break
        case LESION:
          this.setStoreHiddenCtrl({ focus: 'switchLesion' })
          this.currentQuestion = { category: 'exam', key: 'switchLesion', command: LESION }
          break
        default:
          break
      }
    },
    async handleExamQaVoiceCommand(voiceInput) {
      const { key: currentKey } = this.currentQuestion
      let checkInt = /^[0-9]*[1-9][0-9]*$/
      switch (currentKey) {
        case 'cloLoca':
          this.$emit('examCommand', { state: currentKey, value: voiceInput })
          this.addTTSActionList({ content: `clo location ${voiceInput}`, value: voiceInput })
          break
        case INDICATION:
          if (Number(this.examTypeID_examMixins) === COLON_TYPE) {
            if (voiceInput === 'over' || voiceInput === 'cancel') {
              this.$emit('cancel')
              this.setStoreHiddenCtrl({ focus: '', over: false })
            } else {
              this.$emit('examCommand', { state: INDICATION, value: voiceInput })
              this.addTTSActionList({ content: `${INDICATION} ${voiceInput}`, value: voiceInput })
            }
          }
          break
        case FINDING:
          if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {
            if (this.getExaminationData.findings[Number(voiceInput) - 1]) {
              this.setStoreHiddenCtrl({
                nowFindingIndex: Number(voiceInput) - 1,
                nowRecordIndex: 0,
                over: false,
                nowLocationsIndex: 0,
              })
              this.addTTSActionList({ content: `finding ${voiceInput}`, value: voiceInput })
            }
          } else {
            this.currentQuestion = {}
            this.voiceCommand(voiceInput)
          }
          break
        case 'switchLesion':
          if (checkInt.test(voiceInput) && Number(voiceInput) > 0) {
            if (this.getCurrentFinding_examMixins.records[Number(voiceInput) - 1]) {
              this.setStoreHiddenCtrl({
                nowRecordIndex: Number(voiceInput) - 1,
                nowLocationsIndex: 0,
                focus: '',
              })
            }
          }
          break
        default:
          const resultObj = this.generateTemplateAnswer(voiceInput)
          this.setExaminationColumn(this.currentQuestion.key, resultObj.data)
          this.setStoreHiddenCtrl({ focus: '' })
          this.addTTSActionList({
            content: `${this.currentQuestion.command} ${voiceInput}`,
            value: voiceInput,
          })
          break
      }
      this.currentQuestion = {}
      this.$refs.examAudio.audioPlay('success')
    },
    async handleWriteRecordInput({ inputType, data }) {
      const finding_fields = ['finding', 'number', 'takeNote']
      const field = this.getStoreHiddenParameter.focus
      const recordIndex = this.getStoreHiddenParameter.nowRecordIndex
      let result = null

      if (finding_fields.includes(field)) {
        const originVal = this.getCurrentFinding_examMixins.questions[field].value
        result = this.genFieldsVal(originVal, data, inputType)
        const sendData = {
          ...this.updateExamQParams,
          recordIndex: null,
          value: result,
        }
        await this.$store.dispatch('examinations/updateFindingQuestions', sendData)
      } else if (field === 'loca' || field === 'dist' || field === 'orien') {
        const originVal = this.getCurrentLocation__examMixins.questions[field].value
        result = this.genFieldsVal(originVal, data, inputType)
        const { findingIndex, recordIndex } = this.updateExamQParams
        await this.$store.dispatch('examinations/updateExaminationLocationColumn', {
          findingIndex: findingIndex,
          recordIndex: recordIndex,
          obj: {
            id: this.getCurrentLocation__examMixins.id,
            column: field,
            value: result,
          },
        })
      } else {
        const question = this.getRecord_examMixins.questions[field]
        if (question) {
          const sendData = (value) => ({
            ...this.updateExamQParams,
            recordIndex,
            recordID: this.getCurrentFinding_examMixins.records[recordIndex].id,
            value,
          })
          const triggerUpdateApi = async (data) => {
            await this.$store.dispatch('examinations/updateFindingQuestions', data)
          }

          let maneuver = ['coldSnare', 'hotSnare', 'snare']
          if (maneuver.includes(field)) {
            maneuver.forEach(async (m) => {
              await triggerUpdateApi({ ...sendData([]), field: m })
            })
          }

          const originVal = question.value
          result = this.genFieldsVal(originVal, data, inputType)
          await triggerUpdateApi(sendData(result))
        }
      }
      return result
    },
    async handleFindingKeyword(text) {
      const findingNumberID = await GEN_FINDING_ID(text, this.examTypeID_examMixins)
      if (findingNumberID) {
        await this.$store.dispatch(
          'examinations/addFinding',
          this.getExaminationData.examination.id
        )
        this.storeHiddenActions(STORE_ACTIONS.ADD_FINIDNG)()
        return {
          table: 'finding',
          inputType: INPUT_TYPES.SELECT,
          data: findingNumberID,
        }
      }
      return null
    },
    async handleDeleteFinding() {
      const findingID = this.getCurrentFinding_examMixins.id
      await this.$store.dispatch('examinations/deleteFinding', {
        findingID,
        findingIndex: this.getStoreHiddenParameter.nowFindingIndex,
      })
      this.storeHiddenActions(STORE_ACTIONS.DEL_FINIDNG)()
    },
    // 回傳 voiceInput 的意思是 1. 問題 2. finding 3. 答案
    async seperateCommandTypes(voiceInput) {
      // 為perfor 特別處理的判斷式
      if (
        this.currentTemplate &&
        this.currentTemplate[voiceInput] &&
        this.getStoreHiddenParameter.focus === 'perfor'
      ) {
        return this.generateTemplateAnswer(voiceInput)
      } else if (this.currentTemplate && this.currentTemplate[voiceInput]) {
        this.currentQuestion = { ...this.currentTemplate[voiceInput], command: voiceInput }
        // TODO
        this.storeHiddenActions(STORE_ACTIONS.FOCUS_KEY)(this.currentQuestion.key)
        if (this.currentQuestion.type === INPUT_TYPES.CHECKBOX2) {
          return this.generateTemplateAnswer(voiceInput)
        }
        return {
          updateStatus: false,
          type: 'form',
          inputType: this.currentQuestion.type,
          data: null,
        }
      } else if (
        this.getStoreHiddenParameter.focus &&
        this.getStoreHiddenParameter.focus !== 'finding'
      ) {
        console.log(this.getStoreHiddenParameter.focus , voiceInput)
        const resultObj = this.generateTemplateAnswer(voiceInput)
        // if (this.getStoreHiddenParameter.focus === 'interven') {
        //   this.intervenTemplate = resultObj.data ? INTERVENTION_TEMPLATE[voiceInput] : {}
        // }
        return resultObj
      } else if (GEN_FINDING_ID(voiceInput, this.examTypeID_examMixins)) {
        const { inputType, data } = await this.handleFindingKeyword(voiceInput)
        return {
          updateStatus: true,
          type: 'finding',
          inputType,
          data,
        }
      }
      return {
        updateStatus: false,
      }
    },
    // 依據不同的題目回傳答案
    generateTemplateAnswer(voiceInput) {
      const CURRENT_QUESTION_TYPE = this.currentQuestion?.type
      const handleResult = (type) => {
        switch (type) {
          case INPUT_TYPES.NUMBER:
          case INPUT_TYPES.TEXT:
            return voiceInput
          case INPUT_TYPES.SELECT:
          case INPUT_TYPES.CHECKBOX:
          case INPUT_TYPES.CHECKBOX2:
            return this.currentQuestion.options[voiceInput]
          default:
            return ''
        }
      }
      return {
        updateStatus: true,
        type: 'answer',
        inputType: CURRENT_QUESTION_TYPE,
        data: handleResult(CURRENT_QUESTION_TYPE),
      }
    },
    storeHiddenActions(action) {
      switch (action) {
        case STORE_ACTIONS.ADD_FINIDNG:
          return () => {
            const nowFindingIndex = this.getExaminationData.findings.length - 1
            this.setStoreHiddenCtrl({
              nowFindingIndex,
              nowRecordIndex: 0,
              focus: 'finding',
              over: false,
            })
          }
        case STORE_ACTIONS.FOCUS_KEY:
          return (key) => {
            this.setStoreHiddenCtrl({ focus: key })
          }
        case STORE_ACTIONS.DEL_FINIDNG:
          return () => {
            const nowFindingIndex = this.getExaminationData.findings.length - 1
            this.setStoreHiddenCtrl({
              nowFindingIndex,
              nowRecordIndex: 0,
            })
          }
        case STORE_ACTIONS.OVER_FINIDNG:
          return () => {
            this.setStoreHiddenCtrl({
              focus: '',
              over: true,
            })
          }
        default:
          break
      }
    },
    /**
     * 改動當前5關鍵隱藏參數
     *
     * @param {Object} param
     *   - {boolean} focus
     *   - {number} nowFindingIndex
     *   - {number} nowRecordIndex
     *   - {number} nowLocationsIndex
     *   - {boolean} over - 啟用或關閉 Form
     */
    setStoreHiddenCtrl({ focus, nowFindingIndex, nowRecordIndex, nowLocationsIndex, over }) {
      const obj = {
        focus,
        nowFindingIndex,
        nowRecordIndex,
        nowLocationsIndex,
        over,
      }
      Object.entries(obj).forEach(([key, value]) => {
        if (value !== undefined) {
          this.setStoreHiddenParameter({
            target: key,
            value,
          })
        }
      })
    },
    setCurrentQuestions({ key, category, command, type, options, value }) {
      if (!key) return
      this.currentQuestion = {
        key, // focus
        category,
        command,
        type,
        options,
        value,
      }
    },
    genFieldsVal(oldVal, newValue, inputType) {
      const existTypes = (arr, inputType) => arr.indexOf(inputType) != -1
      return existTypes([INPUT_TYPES.CHECKBOX, INPUT_TYPES.CHECKBOX2], inputType)
        ? COMMON.toggleItemInArr(oldVal, newValue)
        : existTypes([INPUT_TYPES.SELECT], inputType)
        ? COMMON.toggleInputValue(newValue, oldVal)
        : newValue
    },
    handleUpdateTimer({ step, audio, unfinish = false }) {
      const timePoint = {
        TIME_START: 'time_start',
        TIME_INSERTION_LEVEL: 'time_insertion_level',
        TIME_LANDMARK_SPLENIC_FLEXURE: 'time_landmark_splenic_flexure',
        TIME_FINISHED: 'time_finished',
      }
      const timePeriod = {
        INSERTION_TIME: 'insertionTime',
        PROXIMAL_COLON_WITH_TIME: 'proximalColonWithTime',
        WITHDRAW_TIME: 'withdrawTime',
        TOTAL_EXAM_TIME: 'totalExamTime',
      }

      if (EXAM_COUNT_STEPS[step]) {
        this.setTimeCountingStep(EXAM_COUNT_STEPS[step])
        const now = () => moment().format('HH:mm:ss')
        const NO_TIME = '00:00:00'

        switch (step) {
          case EVAS_GO: // EVAS GO
            const startTime = unfinish ? this.timeStart__examMixins : now()
            // timeCode
            this.setExaminationColumn(timePoint.TIME_START, startTime)
            this.setExaminationColumn(timePoint.TIME_INSERTION_LEVEL, NO_TIME)
            this.setExaminationColumn(timePoint.TIME_LANDMARK_SPLENIC_FLEXURE, NO_TIME)
            this.setExaminationColumn(timePoint.TIME_FINISHED, NO_TIME)
            // timePeriod
            this.setExaminationColumn(timePeriod.INSERTION_TIME, NO_TIME)
            this.setExaminationColumn(timePeriod.PROXIMAL_COLON_WITH_TIME, NO_TIME)
            this.setExaminationColumn(timePeriod.WITHDRAW_TIME, NO_TIME)
            this.setExaminationColumn(timePeriod.TOTAL_EXAM_TIME, NO_TIME)
            break
          case INSERTION_TO: // INSERTION TO
            if (unfinish) {
              this.setExaminationColumn(
                timePoint.TIME_INSERTION_LEVEL,
                this.timeInsertionLevel__examMixins
              )
              this.setExaminationColumn(timePeriod.INSERTION_TIME, NO_TIME)
              this.setExaminationColumn(timePeriod.TOTAL_EXAM_TIME, NO_TIME)
            } else {
              this.setExaminationColumn(timePoint.TIME_INSERTION_LEVEL, now())
              this.setExaminationColumn(
                timePeriod.INSERTION_TIME,
                COMMON.calBetweenTimes(now(), this.timeStart__examMixins)
              )
            }
            break
          case PROXIMAL_TIME: // WITHDRAWAL PROXIMAL_TIME
            if (unfinish) {
              this.setExaminationColumn(
                timePoint.TIME_LANDMARK_SPLENIC_FLEXURE,
                this.timeProximal__examMixins
              )
              this.setExaminationColumn(timePeriod.WITHDRAW_TIME, NO_TIME)
              this.setExaminationColumn(timePeriod.TOTAL_EXAM_TIME, NO_TIME)
            } else {
              this.setExaminationColumn(timePoint.TIME_LANDMARK_SPLENIC_FLEXURE, now())
              if (this.timeInsertionLevel__examMixins !== NO_TIME) {
                this.setExaminationColumn(
                  timePeriod.PROXIMAL_COLON_WITH_TIME,
                  COMMON.calBetweenTimes(now(), this.timeInsertionLevel__examMixins)
                )
              }
            }
            break
          case FINISH: // WITHDRAWAL TIME
            this.setExaminationColumn(timePoint.TIME_FINISHED, now())
            this.setExaminationColumn(
              timePeriod.TOTAL_EXAM_TIME,
              COMMON.calBetweenTimes(now(), this.timeStart__examMixins)
            )
            if (this.timeInsertionLevel__examMixins !== NO_TIME) {
              this.setExaminationColumn(
                timePeriod.WITHDRAW_TIME,
                COMMON.calBetweenTimes(now(), this.timeInsertionLevel__examMixins)
              )
            }
          default:
            break
        }
      }

      if (audio) {
        setTimeout(() => this.$refs.examAudio.audioPlay(audio), 500)
      }
    },
    toggleEvasgoWarning(status) {
      this.showEvasgoWarning = status
    },
    handleEvasGo() {
      if (!this.isEvasGo) {
        this.isEvasGo = true
        this.setExaminationColumn('status', 1)
        this.toggleEvasgoWarning(false)
        this.setTimeCounting(true)
        this.handleUpdateTimer({ step: EVAS_GO, audio: 'evasgo' })
      }
    },
    setExaminationColumn(column, value) {
      var d = {
        id: this.exID_examMixins,
        column: column,
        value: value,
      }
      this.updateExaminationColumn(d)
    },
    sendReport() {
      reportServices.sendSRReport(Number(this.exID_examMixins))
    },
    async triggerFetchDataAudio() {
      let waitTime = 0
      this.ttsActionList.forEach(async (r) => {
        const audio = await this.fetchAudio_ttsMixins(r, this.deviceId)
        if (audio) {
          await this.playAudio_ttsMixins(audio, waitTime)
          waitTime = waitTime + audio.duration * 1000
        }
      })
    },
    addTTSActionList({ content, value, isCommon }) {
      const action = isCommon ? 'on' : value ? 'on' : 'off'
      if (this.ttsActionList.length == 0 || content.startsWith('finding')) {
        this.ttsActionList.push([])
      }
      this.ttsActionList[this.ttsActionList.length - 1].push([content, action])
    },
    // Audio Start
    onkeydown(evt) {
      if (evt.keyCode === 90) {
        this.stopAudio_ttsMixins()
        this.$refs.examAudio.audioPause()
      }
    },
    changeAudioOutput(id) {
      this.deviceId = id
      const audioElements = document.querySelectorAll('audio')
      audioElements.forEach((audio) => {
        if (audio.setSinkId) {
          audio.setSinkId(id)
        }
      })
    },
  },
  mounted() {
    window.voiceInput = this.addWindowVoiceInput
    window.addEventListener('keydown', this.onkeydown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onkeydown)

    if (this.isEvasGo && this.currentCountStep !== EXAM_COUNT_STEPS[FINISH]) {
      this.handleUpdateTimer({ step: FINISH })
    }
    this.setTimeCountingStep(0)
  },
}
</script>
