<template>
  <div>
    <p>{{ finding.name }}:</p>
    <div class="flex xs12 report-img-arrange">
      <div
        v-for="image in finding.images"
        :key="image.id"
        :class="`screenshot${imgArrange}`"
      >
        <img
          :src="prefixServerImgPath(`${image.examinationID}/${image.file}`)"
        />
        <p class="landmark" v-if="image.landmarkID">
          {{ getLandMarkInfoFromID(image.landmarkID) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FindingItemImages',
  props: {
    finding: {
      type: Object,
      required: true,
    },
    imgArrange: Number, // 圖片的排列方式 1 = 1*n , 2 = 2*n, 3*n
  },
  computed: {
    ...mapGetters('examinations', ['getLandmarkList']),
  },
  methods: {
    prefixServerImgPath(path) {
      return `/api/upload/image/${path}`
    },
    getLandMarkInfoFromID(id) {
      return this.getLandmarkList.filter((item) => item.id == id).length
        ? this.getLandmarkList.filter((item) => item.id == id)[0].landmark
        : null
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/components/reportImgArrange.scss';
.article {
  font-style: normal;
  font-weight: normal;
}
.findingAndNumber {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
</style>
