<style lang="scss">
.analytics {
  .v-navigation-drawer {
    z-index: unset;
  }
  
  .v-list__tile__action {
    min-width: 28px;
  }
}
</style>

<template>
  <v-layout row fill-height class="analytics pa-4">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="$vuetify.breakpoint.mdAndDown"
      clipped
      permanent
    >
      <v-list class="pt-0" dense>
        <v-list-tile
          v-for="item in menu"
          :key="item.category"
          :to="item.link"
          :disabled="item.disabled"
        >
          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-flex>
      <router-view />
    </v-flex>
  </v-layout>
</template>

<script>
const CATEGORY = {
  SEARCH: 'search',
  PDR: 'pdr',
  SIX_MIN: 'six-min',
  INTERVENTION: 'intervention',
  USAGE: 'usage',
}

export default {
  name: 'AdminAnalytics',
  data() {
    return {
      drawer: true,
      menu: [
        {
          title: 'Search',
          icon: 'pageview',
          link: '/admin/analytics/search',
          category: CATEGORY.SEARCH,
        },
        {
          title: 'Polyp Detection Rate',
          icon: 'assessment',
          link: '/admin/analytics/pdr',
          category: CATEGORY.PDR,
        },
        {
          title: 'Ratio of Withdrawal Time ≥ 6 min',
          icon: 'schedule',
          link: '/admin/analytics/six-min',
          category: CATEGORY.SIX_MIN,
        },
        {
          title: 'Correlation of Polyp Size and Intervention',
          icon: 'pan_tool',
          link: '/admin/analytics/intervention',
          category: CATEGORY.INTERVENTION,
          disabled: true,
        },
        {
          title: 'EVAS Usage',
          icon: 'history',
          link: '/admin/analytics/usage',
          category: CATEGORY.USAGE,
          disabled: true,
        },
      ],
    }
  },
}
</script>
