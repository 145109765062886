<template>
	<div class="wavemed-filters">
    <!-- ALL chip -->
    <v-chip
      :class="getColorClassName('all')"
      :text-color="getColorClassName('all')" 
      label
      outline
      @click="changeActive('all')"
    >All</v-chip>

    <!-- populate other chips based on images we have -->
    <!-- the item here starts with 1, not 0 -->
    <template v-if="getFilterItemsLength > 0">
      <v-chip
        v-for="item in getFilterItemsLength"
        label
        :key="item"
        :class="getColorClassName(item - 1)"
        :text-color="getColorClassName(item - 1)"
        outline
        @click="changeActive(item - 1)"
      >Finding {{ item }}</v-chip>

      <!-- landmark chip -->
      <v-chip
        v-if="landmark"
        :class="getColorClassName('landmark')"
        :text-color="getColorClassName('landmark')"
        label
        outline
        @click="changeActive('landmark')"
      >Landmark</v-chip>

      <!-- print chip -->
      <v-chip
        v-if="print"
        :class="getColorClassName('print')"
        :text-color="getColorClassName('print')"
        label
        outline
        @click="changeActive('print')"
      >Print</v-chip>
    </template>
    
  </div>
</template>

<script>
export default {
  name: 'FilterChips',

  props: {
    active: [String, Number],
    filterItems: Array,
    landmark: Boolean,
    print: Boolean,
  },

  data() {
    return {
      activeChip: this.active
    }
  },

  computed: {
    getFilterItemsLength() {
      return this.filterItems.length
    }
  },

  methods: {
    getColorClassName(target) {
      return this.activeChip === target ? 'primary' : 'grey darken-2'
    },

    changeActive(event) {
      this.activeChip = event
      this.$emit('activeChange', event)
    }
  }
}
</script>

<style lang="scss">
  @import '@/styles/pages/page_examinationDetail.scss';
</style>