<style lang="scss">
.donut-chart {
  max-width: 100%;

  @media only screen and (min-width: 960px) and (max-width: 1264px) {
    max-width: 85%;
  }

  @media only screen and (min-width: 1264px) and (max-width: 1904px) {
    max-width: 75%;
  }

  @media only screen and (min-width: 1904px) {
    max-width: 50%;
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    border: none;
  }
}
</style>

<template>
  <div class="donut-chart mx-auto">
    <h1
      class="my-4 grey--text text--darken-1  display-1 font-weight-bold  text-xs-center"
    >
      {{ title }}
    </h1>
    <apexchart type="donut" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
export default {
  name: 'DonutChart',
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    isSixMin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    series() {
      return this.data.map(e => e.count)
    },
    labels() {
      return this.data.map(e => e.name)
    },
    chartOptions() {
      return {
        width: '100%',
        intersect: false,
        legend: {
          position: 'top',
          fontSize: '24px',
          markers: {
            width: 20,
            height: 20,
            radius: 20,
          },
          formatter(seriesName, opts) {
            return [
              seriesName,
              ': ',
              opts.w.globals.series[opts.seriesIndex],
              'records',
            ]
          },
        },
        colors: ['#008ffb', '#bdbdbd'],
        labels: this.labels,
        dataLabels: {
          /* formatter(val) {
            return Number.parseInt(val.toFixed(1), 10) + '%'
          }, */
          style: { fontSize: '24px' },
        },
        tooltip: {
          custom: this.genTooltipContent,
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.35,
            },
          },
        },
        /* responsive: [
          {
            breakpoint: 769,
            options: {
              dataLabels: {
                style: { fontSize: '14px' },
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ], */
      }
    },
    sum() {
      return this.series.reduce((x, y) => x + y, 0)
    },
    prefix() {
      return this.isSixMin ? 'Examination Without Intervention' : 'Total examination'
    },
  },
  methods: {
    simple(data) {
      return `
        <div class="chart__tips">
          <h2 class="mt-2 headline">${this.prefix}:
            <span class="chart__percent">
              ${data.total || this.sum}
            </span>
            records
          </h2>
          <h2 class="mb-2 headline">${data.name}:
            <span class="chart__percent">
              ${data.count}
            </span>
            records
          </h2>
        </div>`
    },
    detail(data) {
      return `
        <div class="chart__tips">
          <h2 class="headline">Total examination:
            <span class="chart__percent">
              ${data.total || this.sum}
            </span>
            records
          </h2>
          <h2 class="mb-3 headline">${data.name}:
            <span class="chart__percent">
              ${data.count}
            </span>
            records
          </h2>
          <ul>
            ${data.subItems
              .map(
                e =>
                  `<li class="chart__tip">${e.name}: ${
                    e.definedPolypCount
                  } <span class="chart__percent">(${e.percent})</span></li>`,
              )
              .join('')}
          </ul>
        </div>`
    },
    genTooltipContent({ seriesIndex }) {
      const data = this.data[seriesIndex]
      return data.subItems && data.subItems.length ? this.detail(data) : this.simple(data)
    },
  },
}
</script>
