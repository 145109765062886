<template>
  <div>
    <div class="bowel-cleanse-forms print">
      <div v-if="isShow(getTextFromGetterAndKey([examination.examination.inspection_instrumentID], 'getInspectionInstrumentList', 'inspection_instrument'))" class="half">
        <h3>Scope ID : </h3>
        <p> {{ getTextFromGetterAndKey([examination.examination.inspection_instrumentID], 'getInspectionInstrumentList', 'inspection_instrument')  }} </p>
      </div>
      <div v-if="isShow(getTextFromGetterAndKey([examination.examination.wash_instrumentID], 'getWashInstrumentList', 'wash_instrument'))" class="half">
        <h3>Washing machine : </h3>
        <p>{{ getTextFromGetterAndKey([examination.examination.wash_instrumentID], 'getWashInstrumentList', 'wash_instrument') }}</p>
      </div>
      <div v-if="isShow(examination.sedatives)" class="half" style="display: flex;">
        <h3 style="flex: 1;">Sedatives : </h3>
        <div class="sedative-list" style="width:63%">
          <table>
            <template v-for="(item, index) in examination.sedatives">
              <tr v-if="item.id !== 1" :key="index">
                <td style="padding-right: 10px;">{{ getSedativeNameFromID(item.sedativeID) }}</td>
                <td>{{ item.value }}mg</td>
              </tr>
            </template>
            <tr v-if="examination.examination.sedativeOther"> 
              <td>Other :</td>
              <td>{{ examination.examination.sedativeOther }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="isShow(getTextFromIDsAndGetters([examination.examination.incompleteDue], 'getIncompleteReasonList'))" class="half">
        <h3>Incomplete study due to : </h3>
        <p> {{ getTextFromIDsAndGetters([examination.examination.incompleteDue], 'getIncompleteReasonList') }} </p>
      </div>
      <div v-if="examination.examination.additionalRemarks" class="half">
        <h3> Additional remarks : </h3>
        <p> {{ examination.examination.additionalRemarks }} </p>
      </div> 
  </div>
  <hr/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GastroPrintBowelCleanseForms',

  props: {
    examination: {
      type: Object,
      required: true
    },

    sedatives: {
      type: Array,
    }
  },
   computed: {
    ...mapGetters('examinations', [
      'getInspectionInstrumentList',
      'getWashInstrumentList',
      'getIncompleteReasonList'
    ]),
  },

  methods: {
    getSedativeNameFromID (id) {
      return this.sedatives.filter(item => item.id === id )[0].sedative
    },
    // generic list internally
    getTextFromIDsAndGetters(ids, getter) {  
      let text = ''
      let dataGroup = this[getter]
      if (getter == 'getIncompleteReasonList') {
        dataGroup = dataGroup[1]
      }

      dataGroup.map((item, index)=> {
        if (ids.indexOf(item.value) >= 0) {
          if (text.length <= 0) {
            text += item.text
          } else {
            text += (", " + item.text)
          }
        }
      })
      return text
    },
    getTextFromGetterAndKey(ids, getter, key) {
      let text = ''
      this[getter].map((item, index)=> {
        if (ids.indexOf(item.id) >= 0) {
          if (text.length <= 0) {
            text += item[key]
          } else {
            text += (", " + item[key])
          }
        }
      })
      return text
    },
    isShow(data) {
      let result = true
      if(data == 'Choose' || data == '' || (Array.isArray(data) && data.length === 0)) {
        result = false
      }
      return result
    }
  }
}

</script>

<style lang="scss">
  @import '@/styles/components/bowelCleanseForms.scss';
</style>