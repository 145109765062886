var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'findingLetter findingLetter--en': _vm.hasFindingLetter }},[_c('ol',{staticClass:"bg-white",class:{ 'pl-0': !_vm.hasFindingLetter }},[_vm._l((_vm.getFinding),function(finding,idx){return _c('li',{key:`${finding.id}findingItems`,class:[
        {
          'border-defaultGreyText border-b border-b--dashed border-1':
            idx !== _vm.getFinding.length - 1,
        },
        { 'list-style-none': !_vm.hasFindingLetter },
      ]},[_c('div',{class:_vm.hasFindingLetter
            ? 'findingLetter__li pt-3 pb-2 pr-3'
            : 'py-2 pl-1 pr-3'},[_c('FindingItemContent',{attrs:{"finding":finding},scopedSlots:_vm._u([{key:"findingAction",fn:function(){return [_c('div',{staticClass:"text-xs-right"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.$emit('delete', finding.id)}}},[_vm._v("delete")]),_c('v-icon',{staticClass:"--cursor",on:{"click":function($event){return _vm.$emit('edit', finding.id)}}},[_vm._v("edit")])],1)]},proxy:true}],null,true)})],1)])}),(!_vm.getFinding.length)?_c('li',{class:{ 'list-style-none py-2': !_vm.hasFindingLetter }},[_c('h3',{staticClass:"mb-0 ml-3"},[_c('p',[_vm._v(_vm._s(_vm.$t('common.nil')))])])]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }