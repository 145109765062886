<style lang="scss">
.report-detail-page {
  &__content {
    height: calc(100vh - 200px);
  }

  &__part {
    display: inline-block;
    width: 50%;
    height: 100%;

    &.--left {
      padding-right: 6px;
      padding-left: 12px;
    }

    &.--right {
      padding-right: 12px;
      padding-left: 6px;
      overflow-y: auto;
      vertical-align: top;
    }
  }
}

.fill-width {
  width: 100%;
}
</style>

<template>
  <div class="fill-width fill-height bg-light --scroll">
    <template v-if="getExaminationData">
      <examination-info
        :examination="getExaminationData.examination"
        :patient="getPatient"
        :examinationTypeList="getExaminationTypeList"
        showStatusButton
        @reportStatusChange="setReportStatus($event)"
      >
        <template #right>
          <v-btn dark color="teal" class="mr-0" @click="goToReportPreview">
            {{ $t('report_preview.title') }}
          </v-btn>
        </template>
      </examination-info>
    </template>
    <div class="report-detail-page__content">
      <div class="report-detail-page__part --left">
        <examination-form :id="examinationID" />
      </div>

      <div class="report-detail-page__part --right">
        <screenshots />
      </div>
    </div>
    <report-time :eid="examinationID" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ExaminationInfo from '@/components/ExaminationInfo.vue'
import Screenshots from '../components/report/screenshots'
import ExaminationForm from '../components/report/examination-form'
import ReportTime from '@/components/ReportTime'
import { reportServices } from '@/services/db'

export default {
  name: 'ReportDetail',
  components: {
    ExaminationInfo,
    Screenshots,
    ExaminationForm,
    ReportTime,
  },
  updated() {
    if (Number(this.getExaminationData.examination.reportStatus) == 0) {
      this.setReportStatus('Editing')
    }
  },
  async created() {
    this.$store.dispatch('examinations/resetStoreHiddenParameter')
    this.$store.dispatch('examinations/resetQuestionsList')

    this.$store.dispatch('examinations/fetchExaminationDetailAndOtherList', {
      examinationID: this.examinationID,
      accNo: this.accNo,
    }).then(r => {
      if (this.getSetting.descriptive) {
        this.fetchDescriptiveParagraph({
          eid: r.data.examination.id,
          etype: r.data.examination.examination_typeID,
        })
      }
    })
    this.$store.dispatch('examinations/fetchOrderArticle', {
      examinationID: this.examinationID,
    })
  },

  data() {
    return {
      dropdownItems: {
        reportStatus: ['Unedited', 'Editing', 'Finalized'],
        reportStatusColors: {
          Unedited: 'primary',
          Editing: 'orange',
          Finalized: 'green',
        },
      },
    }
  },

  computed: {
    ...mapGetters(['getSetting']),
    ...mapGetters('examinations', [
      'getExaminationData',
      'getExaminationTypeList',
      'getPatient',
    ]),

    examinationID() {
      if (Number(this.$route.params.examinationID) != 0) {
        return this.$route.params.examinationID
      } else {
        if (this.getExaminationData != null) {
          return this.getExaminationData.examination.id
        }
      }
    },

    accNo() {
      return Number(this.$route.params.accNo) != 0
        ? this.$route.params.accNo
        : 0
    },

    examType() {
      if (this.getExaminationData) {
        return this.getExaminationData.examination.examination_typeID
      }
      return 0
    },
  },

  methods: {
    ...mapActions('examinations', [
      'updateExaminationColumn',
      'addExaminationSedative',
      'deleteExaminationSedative',
      'fetchDescriptiveParagraph',
    ]),

    uploadScreenshot() {
      if (
        document
          .getElementById('uploadScreenshotButtonA')
          .innerHTML.split('Uploaded').length == 1
      ) {
        this.$store
          .dispatch('examinations/notificationUniGateUploadScreenshot', {
            examinationID: this.examinationID,
          })
          .then(() => {
            document.getElementById('uploadScreenshotButtonA').style =
              'display: none;'
            document.getElementById('uploadScreenshotButtonB').style =
              'background-color: green; color: #fff;'
            setTimeout(function () {
              document.getElementById('uploadScreenshotButtonA').style =
                'background-color: #EA7500; color: #ffffff;'
              document.getElementById('uploadScreenshotButtonB').style =
                'display: none;'
            }, 3000)
          })
      }
    },

    goToPrint() {
      this.$router.push({
        path: `/reports/${this.examinationID}/accNo/0/print`,
      })
    },

    goToHome() {
      this.$router.push({
        path: `/home`,
      })
    },

    setReportStatus(item) {
      const value = this.dropdownItems.reportStatus.indexOf(item)
      console.log(`setReportStatus: ${item}, value: ${value}`)
      this.setExaminationColumn('reportStatus', value)
      // Finalized
      /* if (
        value === 2 &&
        Number(this.getExaminationData.examination.examination_typeID) === 2
      ) {
        this.reportHPA()
      }
      if (
        value === 2 &&
        Number(this.getExaminationData.examination.examination_typeID) === 1
      ) {
        this.reportHIS()
      } */
      if (item === 'Finalized') {
        this.sendReport()
      }
    },

    sendReport() {
      reportServices.sendSRReport(Number(this.examinationID), this.examType);
    },

    reportHPA() {
      console.log(
        `====== report HPA ======> examinationId: ${Number(
          this.examinationID,
        )}, worklistId: ${this.getExaminationData.examination.accNo}`,
      )
      reportServices.uploadHPAReport({
        examinationId: Number(this.examinationID),
        worklistId: this.accNo,
      })
    },

    reportHIS() {
      console.log(
        `====== report HIS ======> examinationId: ${Number(
          this.examinationID,
        )}, worklistId: ${this.getExaminationData.examination.accNo}`,
      )
      reportServices.uploadHISReport({
        examinationId: Number(this.examinationID),
        worklistId: this.accNo,
      })
    },

    bowelCleanseFormChange(obj) {
      var value = obj.event
      const timeFiled = [
        'insertionTime',
        'proximalColonWithTime',
        'withdrawTime',
        'totalExamTime',
      ]
      const reg = '([0-1][0-9]|2[0-3])\:[0-5][0-9]\:[0-5][0-9]'
      const regExp = new RegExp(reg)

      if (obj.field == 'antiplateletStopDays') {
        value = parseInt(obj.event, 10)
      } else if (timeFiled.includes(obj.field) && !regExp.test(value)) {
        value = '00:00:00'
      }

      this.setExaminationColumn(obj.field, value)
    },

    setExaminationColumn(column, value) {
      let d = {
        id: this.examinationID,
        column: column,
        value: value,
      }
      this.updateExaminationColumn(d)
    },

    handleSedative(action, event) {
      const examinationSedativeID = event ? event.id : null
      this[`${action}ExaminationSedative`]({
        examinationID: this.examinationID,
        examinationSedativeID,
      })
    },
    goToReportPreview() {
      this.$router.push(
        `/reports/${this.examinationID}/accNo/${this.accNo}/preview`,
      )
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/pages/page_reportDetail.scss';
</style>