<template>
  <div class="border-animation">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BorderAnimation",
};
</script>

<style scoped lang="scss">
.border-animation {
  position: relative;
  display: grid;
  place-items: center;
  z-index: 0;
  border-radius: 1rem;
  overflow: hidden;
  padding: 2rem;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 0 100%, 100% 100%;
    background-image: linear-gradient(white, white),
      linear-gradient(#3b587d, #3b587d), linear-gradient(#3b587d, #3b587d),
      linear-gradient(white, white);
    animation: rotate 8s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: #fff;
    border-radius: 1rem;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>
