// set
const setLocalStorage = ({ key, val }) => localStorage.setItem(key, val)
// get
const getLocalStorage = (key) => localStorage.getItem(key)
// delete
const delLocalStorage = (key) => localStorage.removeItem(key)

export const STORAGE = {
  set: setLocalStorage,
  get: getLocalStorage,
  del: delLocalStorage
}