<template>
  <div>
    <v-checkbox
      class="text-primary mb-2 the-checkboxes"
      v-model="selected"
      v-for="{key, val, caption} in modifyOptions"
      :key="key"
      :value="+key"
      hide-details
      @change="handleChange"
      :color="color"
    >
      <template #label>
        <div class="chip-container">
          <div class="chip-item" :class="{active: value.includes(+key)}">
            <span :class="`text-${color}`">{{ val }}</span>
          </div> 
          <span class="fsz-16  ml-2">{{caption}}</span>
        </div>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: 'TheCheckboxes',
  props: {
    options: {
      required: true,
      type: Object,
    },
    value: {
      required: true,
      type: [Array, Number],
    },
    color: {
      type: String,
      default: 'blue',
    },
  },
  data() {
    return {
      selected: this.value,
      olds: [],
      prefixObj: {
        "Nil a": 'Use of anti-platelet or anti-coagulant',
        "Nil b": 'Unexpected trouble during management (cardiopulmonary event, other complication, mechanical trouble, etc.',
        "Nil c": 'Difficulty in management \n (will resect at another session of colonoscopy)',
        "Nil d": 'Difficulty in management (refer to other hospital)',
        "Nil e": 'Difficulty in management (refer for surgery)',
        "Nil f": 'Consent not obtained from the patient',
        "Nil z": 'Others',
      }
    }
  },
  computed: {
    modifyOptions () {
      return Object.entries(this.options).map(([key, value]) => {
        return {
          key,
          val: value,
          caption: this.prefixObj[value] || ''
        }
      })
    }
  },
  watch: {
    value(val) {
      this.selected = Array.isArray(val) ? val : [val]
    },
  },
  methods: {
    handleChange(news) {
      this.$emit('change', news)
    },
  },
}
</script>

<style lang="scss">
.the-checkboxes {
  .v-input--selection-controls__input {
    position: absolute !important;
    opacity: 0 !important;
  }
}
.chip-container {
  display: flex;
}
.chip-item {
  white-space: nowrap;
  border: 1px solid #555;
  height: 28px;
  font-size: 24px;
  border-radius: 2px;
  padding: 4px;
  &.active {
    background: #1976d2 !important;
    border-color: #1976d2 !important;
    color: #fff;
  }
}
@media screen and (max-width:600px){
  .chip-item  {
    font-size: 20px;
  }
}
</style>
