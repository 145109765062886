<template>
  <v-layout :tag="layoutTag" wrap class="list-style-none findingList mb-3">
    <v-flex wrap tag="h3" class="findingList__title text-uppercase">
      {{ name || $t(`common.${name}`) }} :
    </v-flex>
    <v-flex :tag="contentTag" class="findingList__content">
      <slot name="content" />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'FindingTypesLayout',
  props: {
    name: {
      type: String,
      required: true,
    },
    layoutTag: {
      type: String,
      default: 'div',
    },
    contentTag: {
      type: String,
      default: 'div',
    },
  },
}
</script>

<style lang="scss">
.findingList {
  &__title {
    flex-basis: 11%;
  }
  &__content {
    flex-basis: 89%;
  }
}
@media print {
  .findingList {
    &__title {
      flex-basis: 18%;
    }
    &__content {
      flex-basis: 82%;
    }
  }
}
</style>
