<template>
  <div class="laid-out-check-boxes">
    <template v-for="(option, key) in options">
      <v-chip
        :key="key"
        label
        :outline="!isSelected(key)"
        :dark="isSelected(key)"
        :color="isSelected(key) ? 'primary' : '#616161'"
        @click="submitData(Number(key))"
      >{{ option }}</v-chip>
    </template>
  </div>
</template>

<script>
	export default {
		name: 'LaidOutCheckBoxes',

		props: {
      options: {
        required: true,
        type: Object
      },

      value: {
        required: false,
        type: Array
      }
    },
    methods: {
      isSelected (optionValue) {
        return this.value.indexOf(Number(optionValue)) >= 0
      },
      submitData (key) {
        const newValue = this.isSelected(key) ? null : key
        this.$emit('change', newValue)
      }
    }
	}
</script>

<style lang="scss">
@import "@/styles/mixins";

.v-chip.v-chip.v-chip--outline,
.v-chip .v-chip__content,
.v-chip.v-chip {
  float: left;
  height: 24px;
  font-size: 20px;
  @include md {
    height: 30px;
    font-size: 22px;
  }
}
.laid-out-check-boxes {
  margin-left: -4px;
}
</style>