import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import { store } from './store/index'
// import './registerServiceWorker'
import VueResource from 'vue-resource'
import VueNativeSock from 'vue-native-websocket'
import Notifications from 'vue-notification'
import VueApexCharts from 'vue-apexcharts'

import './styles/common.scss'
import './styles/components/tables.scss'
import './plugins/date-range-picker'
import './filters/date-filter'
import common from './utils/common'
import { dbServices } from '@/services/db'
import { i18n } from './locale'

Vue.use(VueResource)
Vue.use(VueApexCharts)

Vue.use(Notifications)

Vue.use(VueNativeSock, 'ws://localhost:8081', {
  format: 'S16LE',
  connectManually: true,
})

Vue.config.productionTip = false

Vue.http.options.xhr = {
  withCredentials: true,
}
Vue.http.options.emulateJSON = true

Vue.component('apexchart', VueApexCharts)

Vue.config.errorHandler = function (err, vm, info) {
  console.log(err)
  console.log(vm)
  console.log(info)
}

/***** TODO *****/
/***** Need to maintain sign in state *****/

const vm = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
  created() {
    dbServices
      .get_setting()
      .then(res => {
        if (res.success) {
          this.$connect(common.getASRUrl(
            res.data.asr_domain,
            res.data.asr_ws_port,
            res.data.asr_ws_path,
          ))
          store.dispatch('setSetting', res.data)
        }
      })
      .catch(() => {
        console.error('CANNOT get client setting from server!!')
      })
  },
  methods: {
    set_locale(locale) {
      if (
        this.$i18n &&
        this.$i18n.availableLocales &&
        this.$i18n.availableLocales.includes(locale)
      ) {
        this.$i18n.locale = locale
      }
    }
  },
}).$mount('#app')
