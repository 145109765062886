import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('examinations', ['getExaminationData', 'getStoreHiddenParameter', 'getPatient']),
    exID_examMixins() {
      if (Number(this.$route.params.examinationID) != 0) {
        return this.$route.params.examinationID
      } else {
        if (this.getExaminationData != null) {
          return this.getExaminationData.examination.id
        }
      }
    },
    examStatus_examMixins() {
      return this.getExaminationData.examination.status
    },
    examTypeID_examMixins() {
      return this.getExaminationData.examination.examination_typeID
    },
    accNo_examMixins() {
      return Number(this.$route.params.accNo) != 0 ? this.$route.params.accNo : 0
    },
    doctorID_examMixins() {
      return this.getExaminationData.examination.examinationDoctorID
    },
    staffID_1_examMixins() {
      return this.getExaminationData.examination.technical_staffID_1
    },
    staffID_2_examMixins() {
      return this.getExaminationData.examination.technical_staffID_2
    },
    getCurrentFinding_examMixins() {
      return this.getExaminationData.findings[this.getStoreHiddenParameter.nowFindingIndex]
    },
    getRecord_examMixins() {
      return (
        this.getCurrentFinding_examMixins &&
        this.getCurrentFinding_examMixins.records &&
        this.getCurrentFinding_examMixins.records[this.getStoreHiddenParameter.nowRecordIndex]
      )
    },
    getLocation__examMixins() {
      return this.getRecord_examMixins && this.getRecord_examMixins.questions.location
    },
    getCurrentLocation__examMixins() {
      return (
        this.getRecord_examMixins &&
        this.getRecord_examMixins.questions &&
        this.getRecord_examMixins.questions.location[this.getStoreHiddenParameter.nowLocationsIndex]
      )
    },
    timeStart__examMixins() {
      return this.getExaminationData.examination.time_start
    },
    timeInsertionLevel__examMixins() {
      return this.getExaminationData.examination.time_insertion_level
    },
    timeProximal__examMixins() {
      return this.getExaminationData.examination.time_landmark_splenic_flexure
    },
    timeFinished__examMixins() {
      return this.getExaminationData.examination.time_finished
    },
  },
}
