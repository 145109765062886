<style lang="scss">
.examination-table-row {
  height: 69px;
}
</style>

<template>
  <tr ref="tr" class="examination-table-row">
    <td v-show="visible">
      <v-edit-dialog
        :return-value.sync="item.examination"
        lazy
        @save="emitUpdate('accNo', item.accNo)"
      >
        {{ item.accNo }}
        <v-text-field
          slot="input"
          v-model="item.accNo"
          label="Edit"
          single-line
        />
      </v-edit-dialog>
    </td>

    <td v-show="visible">
      <v-autocomplete
        v-model="item.patientID"
        :items="getPatientsListFormat"
        item-text="name"
        item-value="id"
        @change="emitUpdate('patientID', $event)"
      />
    </td>
    <td v-show="visible">
      <v-menu
        v-model="dateMenus[index]"
        :nudge-right="40"
        :close-on-content-click="false"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        max-width="290px"
        min-width="290px"
      >
        <div slot="activator">
          {{ item.date }}
        </div>
        <v-date-picker
          v-model="item.date"
          no-title
          @change="emitUpdate('date', item.date)"
        />
      </v-menu>
    </td>
    <td v-show="visible">
      <v-select
        label="Doctor"
        :items="getDoctorsListFormat"
        v-model="item.orderDoctorID"
        @change="emitUpdate('orderDoctorID', $event)"
      />
    </td>
    <td v-show="visible">
      <v-select
        :items="getExaminationTypesListFormat"
        v-model="item.examination_typeID"
        @change="emitUpdate('examination_typeID', $event)"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ExaminationTableRow',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    dateMenus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    ...mapGetters('patients', ['getPatientsListFormat']),
    ...mapGetters('examinationTypes', ['getExaminationTypesListFormat']),
    ...mapGetters('doctors', ['getDoctorsListFormat']),
    refTr() {
      return this.$refs.tr
    },
  },
  mounted() {
    this.createObserver()
  },
  methods: {
    emitUpdate(field, value) {
      this.$emit('update', {
        examinationID: this.item.id,
        field,
        value,
      })
    },
    createObserver() {
      if (this.refTr) {
        this.observer = new IntersectionObserver(this.onIntersect, {
          root: null,
          rootMargin: '0px',
          threshold: [0, 1],
        })
        this.observer.observe(this.refTr)
      }
    },
    onIntersect(entries) {
      if (entries.length) {
        this.visible = entries[0].isIntersecting
      }
    },
  },
}
</script>
