<template>
  <v-layout
    class="sedative-input-item"
    :class="[alignProp, focus == true ? 'focus' : '']"
  >
    <v-flex xs7 class="pr-2 pl-0">
      <v-select
        class="mb-1"
        v-bind:hide-details="shouldHideDetails"
        :items="medList"
        :value="data.sedativeID"
        @change="handleChange('sedativeID', $event)"
      ></v-select>
    </v-flex>
    <v-flex xs4 class="pl-2 pr-0">
      <v-text-field
        suffix="mg"
        class="mb-1"
        v-bind:hide-details="shouldHideDetails"
        :value="data.value"
        @change="handleChange('value', $event)"
      ></v-text-field>
    </v-flex>
    <v-flex xs1 class="pl-2 pr-0">
      <v-btn
        icon
        class="remove-sedatives"
        @click="$emit('removeSedative', data)"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'SedativeInput',

  props: {
    data: {
      type: Object,
      required: true,
    },

    medList: {
      type: Array,
    },

    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },

    align: {
      type: String,
      required: false,
      default: 'center',
    },
    focus: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {}
  },

  computed: {
    shouldHideDetails() {
      return this.hideDetails
    },

    alignProp() {
      return `align-${this.align}`
    },
  },

  methods: {
    handleChange(column, event) {
      this.$emit('change', {
        column,
        event,
        id: this.data.id,
      })
    },
  },
}
</script>

<style lang="scss">
// @import '@/styles/components/examinationBowelCleanseForms.scss';
</style>
