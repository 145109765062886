import { screenshotServices } from '@/services/db'

const state = {
  
}

// getters
const getters = {
  
}

// actions
const actions = {
  
  async setScreenshotGroup({commit}, payload){
    try {
      const res = await screenshotServices.setScreenshotGroup(payload)
      if(res.result === 'success'){
        return res
      } else {
        throw new Error(res.result)
      } 
    } catch (e) {
      throw new Error(e)
    }
  },
  
  async setScreenshotStatus({commit}, payload){
    try {
      const res = await screenshotServices.setScreenshotStatus(payload)
      if (res.result === 'success') {
        return res
      } else {
        throw new Error(res.result)
      }
    } catch (e) {
      throw new Error(e)
    }
  },
  
  async deleteScreenshot({commit}, payload){
    try {
      const res = await screenshotServices.deleteScreenshot(payload)
      if(res.result === 'success'){
        commit('examinations/screenshotDeletedFromExamination', payload, { root: true })    
      } else {
        throw new Error(res.result)
      }
    } catch (e) {
      throw new Error(e)
    }
  }
  
}

// mutations
const mutations = {
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}