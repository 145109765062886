<template>
    <v-layout>
        <p class="mt-0 ml-0 fsz-20 fsz-md-22">{{ data.config.question }}</p>
        <v-text-field xs-4
            :value="data.value"
            type="number"
            :min="float == true ? 0.1 : 0"
            :suffix="unit"
            :step="float == true ? 0.1 : 1"
            @change="submitData ($event)"
            hide-details
        ></v-text-field>
        <v-btn xs-1 icon
            @click="submitData (null, (float == true ? 0.1 : 1))"
        > <v-icon small>arrow_upward</v-icon> </v-btn>
        <v-btn xs-1 icon
            @click="submitData (null, (float == true ? -0.1 : -1))"
        > <v-icon small>arrow_downward</v-icon> </v-btn>
    </v-layout>
</template>
<script>
export default {
    name: 'NumberselectInput',

    props: {
        data: {
            required: true,
            type: Object
        },
        float: {
            required: false,
            type: Boolean,
            default: false
        },
        unit: {
            required: false,
            type: String,
            default: ''
        }
    },
    methods: {
        submitData (changedValue, calcuNumber) {
            let newValue = null

            if (changedValue !== null && changedValue >= 0) {
                newValue = Number(changedValue)
            } else if (calcuNumber < 0) {
                newValue = Number(this.data.value) + calcuNumber >= 0 ? Number(this.data.value) + calcuNumber : 0
            } else if(calcuNumber > 0) {
                newValue = Number(this.data.value) + calcuNumber
            }
            this.$emit('change', this.float == true ? newValue.toFixed(1) : newValue)
        }
    }
}
</script>
<style lang="scss" scoped>
.layout {
    margin: 0;
    width: 100%;
}
p {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;
    white-space: nowrap;
    margin-left: 8px;
    margin-top: 8px;
    color: #616161;
}
.v-input {
    font-size: 20px;
    padding-top: 0;
    margin-top: 0;
}
.v-btn {
    margin-top: 0;
}
</style>