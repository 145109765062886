<template>
  <div :class="{ 'findingLetter findingLetter--en': hasFindingLetter }">
    <ol class="bg-white" :class="{ 'pl-0': !hasFindingLetter }">
      <li
        :class="[
          {
            'border-defaultGreyText border-b border-b--dashed border-1':
              idx !== getFinding.length - 1,
          },
          { 'list-style-none': !hasFindingLetter },
        ]"
        v-for="(finding, idx) in getFinding"
        :key="`${finding.id}findingItems`"
      >
        <div
          :class="
            hasFindingLetter
              ? 'findingLetter__li pt-3 pb-2 pr-3'
              : 'py-2 pl-1 pr-3'
          "
        >
          <FindingItemContent :finding="finding">
            <template #findingAction>
              <div class="text-xs-right">
                <v-icon
                  class="mr-2"
                  color="red"
                  @click="$emit('delete', finding.id)"
                  >delete</v-icon
                >
                <v-icon class="--cursor" @click="$emit('edit', finding.id)"
                  >edit</v-icon
                >
              </div>
            </template>
          </FindingItemContent>
        </div>
      </li>
      <li
        v-if="!getFinding.length"
        :class="{ 'list-style-none py-2': !hasFindingLetter }"
      >
        <h3 class="mb-0 ml-3">
          <p>{{ $t('common.nil') }}</p>
        </h3>
      </li>
    </ol>
  </div>
</template>

<script>
import FindingItemContent from '@/components/commons/FindingItemContent.vue'

export default {
  name: 'FindingsItems',
  components: {
    FindingItemContent,
  },
  props: {
    hasFindingLetter: {
      type: Boolean,
      default: true,
    },
    findings: {
      type: Array,
      required: true,
    },
    hasActionTrigger: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getFinding() {
      return this.findings
    },
  },
}
</script>
