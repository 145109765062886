<template>
  <div class="horizontal-checkboxes">
    <v-checkbox
      class="text-primary mb-3"
      v-model="selected"
      v-for="(option, key) in options"
      :key="key"
      :value="+key"
      hide-details
      @change="handleChange"
      :color="color"
    >
      <template #label>
        <span :class="`text-${color}`">{{ option }}</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
const NEGATIVE = 428
const POSITIVE = [429, 430, 605]

export default {
  name: 'HorizontalCheckBoxes',

  props: {
    options: {
      required: true,
      type: Object,
    },
    value: {
      required: true,
      type: [Array, Number],
    },
    field: {
      required: true,
      type: String,
    },
    color: {
      type: String,
      default: 'blue',
    },
  },
  data() {
    return {
      selected: this.value,
      olds: [],
    }
  },
  computed: {
    isRedColorSign() {
      return this.field === 'rcs'
    },
  },
  watch: {
    value(val) {
      this.selected = Array.isArray(val) ? val : [val]
    },
  },
  methods: {
    handleChange(news) {
      if (this.isRedColorSign) {
        let value = [...news]
        if (!this.olds.includes(NEGATIVE) && news.includes(NEGATIVE)) {
          value = [NEGATIVE]
        } else if (
          !this.olds.some((o) => POSITIVE.includes(o)) &&
          news.some((o) => POSITIVE.includes(o))
        ) {
          value = news.filter((n) => n !== NEGATIVE)
        }
        this.olds = value
        this.$emit('change', value)
      } else {
        this.$emit('change', news)
      }
    },
  },
}
</script>

<style lang="scss">
.horizontal-checkboxes {
  margin-left: -4px;
  .v-input--selection-controls {
    margin-bottom: 20px;
  }
  .v-input--selection-controls__input + .v-label {
    font-size: 22px;
  }
  .v-input.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
    display: inline-block;
    float: left;
    padding-right: 20px;
  }
}


</style>
