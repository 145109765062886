<template>
  <v-container>
  <v-layout ref="capture" id="print" v-if="getExaminationData && getExaminationData.examination" row wrap align-content-start fill-height class="pa-4">
    <v-flex xs12 class="page-head-container mb-3">
      <h3 class="title">{{ getUser.orgName }}</h3>
      <hr/>
      <examination-info
        :examination="getExaminationData.examination"
        :patient="getPatient"
        :examinationTypeList="getExaminationTypeList"
      ></examination-info>
      <hr/>
      <colon-print-bowel-cleanse-forms
        id="colonPrintBowelCleanseForms" 
        v-if="getExaminationData.examination.examination_typeID != EXAMINATION_TYPE_OF_GASTROSCOPY"
        :examination="getExaminationData"
        :sedatives="getSedativeList"
      ></colon-print-bowel-cleanse-forms>

      <gastro-print-bowel-cleanse-forms
        id="gastroPrintBowelCleanseForms" 
        v-if="getExaminationData.examination.examination_typeID == EXAMINATION_TYPE_OF_GASTROSCOPY"
        :examination="getExaminationData"
        :sedatives="getSedativeList"
      ></gastro-print-bowel-cleanse-forms>
    
      <finding-info-group
        :hasAdditionalImage="true"
      ></finding-info-group>
      <hr/>
      <summary-info-group></summary-info-group>
      <hr/>
      <v-layout class="row">
        <v-flex xs4>
          <h6>Doctor</h6>
          <p>{{ getDoctor(getExaminationData.examination.examinationDoctorID) }}</p>
        </v-flex>
         <v-flex xs4>
          <h6>Technician</h6>
          <p>{{ getTechnicial(getExaminationData.examination.technical_staffID_1) }}</p>
        </v-flex>
         <v-flex xs4>
          <h6>Technician</h6>
          <p>{{ getTechnicial(getExaminationData.examination.technical_staffID_2) }}</p>
        </v-flex>
      </v-layout>
      <v-layout justify-center class="btn-wrap">
        <v-btn color="info" @click="goToReport()"> 回上一頁 </v-btn>
        <v-btn color="info" @click="print()"> Print </v-btn>
        <v-btn color="info" @click="download()"> Download </v-btn>
        <v-btn :color="uploadPDFButtonColor" id="uploadPDFButton" @click="uploadPDF()"> Upload PDF </v-btn>
        <v-btn color="info" @click="goToWorklist()"> Worklist </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import common from '@/utils/common'
  import ExaminationInfo from '@/components/ExaminationInfo.vue'
  import GastroPrintBowelCleanseForms from '@/components/GastroPrintBowelCleanseForms.vue'
  import ColonPrintBowelCleanseForms from '@/components/ColonPrintBowelCleanseForms.vue'
  import FindingInfoGroup from '@/components/ColonFindingInfoGroup.vue'
  import SummaryInfoGroup from '@/components/SummaryInfoGroup.vue'

  export default {
    name: 'Print',

    components: {
      ExaminationInfo,
      ColonPrintBowelCleanseForms,
      GastroPrintBowelCleanseForms,
      FindingInfoGroup,
      SummaryInfoGroup
    },
    data() {
      return {
        EXAMINATION_TYPE_OF_GASTROSCOPY: 1,
        EXAMINATION_TYPE_OF_COLONOSCOPY: 2,
        uploadPDFButtonColor: 'info'
      }
    },

    computed: {
      ...mapGetters('examinations', [
        'getExaminationData',
        'getExaminationTypeList',
        'getSedativeList',
        'getPatient',
        'getDoctorList',
        'getTechnicalStaffList'
      ]),

      examinationID () {
        if (Number(this.$route.params.examinationID) != 0) {
          return this.$route.params.examinationID;
        } else {
          if (this.getExaminationData != null) {
            return this.getExaminationData.examination.id;
          }
        }
      },

      accNo () {
        return (Number(this.$route.params.accNo) != 0) ? this.$route.params.accNo : 0;
      },

      getUser() {
        return this.$store.getters.getUser;
      }
    },

    created () {
      window.onkeydown = function(e) {};
      window.onkeyup = function(e) {};
      this.$store.dispatch('examinations/fetchExaminationDetailAndOtherList', { examinationID: this.examinationID, accNo: this.accNo })
    },

    methods: {
      print () {
        window.print();
      },
      getDoctor(id) {
        const doctor = this.getDoctorList.filter(item => item.id === id)

        if (doctor.length > 0) {
          return doctor[0].name
        }

        return ''
      },
      getTechnicial(id){
        const technician = this.getTechnicalStaffList.filter(item => item.id === id)

        if (technician.length > 0) {
          return technician[0].name
        }

        return ''
      },
      goToReport() {
        this.$router.push({ path: `/reports/${ this.$route.params.examinationID}/accNo/0` })
      },
      goToWorklist() {
        this.$router.push({ path: `/home` })
      },
      uploadPDF() {
        if (document.getElementById('uploadPDFButton').innerHTML.split('Uploaded').length == 1) {
          this.$store.dispatch('examinations/uploadPDF', { examinationID: this.examinationID }).then(() => {
            document.getElementById('uploadPDFButton').innerHTML = 'Uploaded';
            document.getElementById('uploadPDFButton').style = 'color: #fff;';
            this.uploadPDFButtonColor = 'green';
          });
        }
      },
      async download() {
        // html2canvas setting
        const options = {
          onclone: function(document) {
            document.querySelector('.btn-wrap').style.display = 'none';
          },
          allowTaint:true,
          useCORS:true,
          scrollY: -window.scrollY
        };

        // generate report to jpeg for user downloaded
        html2canvas(this.$refs.capture, options).then(canvas => { 
          let link = document.createElement('a');
          link.href = canvas.toDataURL('image/jpeg');
          link.setAttribute('download', common.fileRule(this.getExaminationData, this.getExaminationTypeList, this.getPatient));
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
        });    
      }
    }
  }
</script>

<style lang="scss">
  @import '@/styles/pages/page_print.scss';
</style>