export default {
  checkStatus(res) {
    if (res.status >= 200 && res.status < 300) {
      return res;
    } else {
      var error = new Error(res.statusText);
      error.res = res;
      throw error;
    }
  },

  parseJSON(res) {
    return res.json();
  },

  queryParams(params) {
    return Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
  },
};
