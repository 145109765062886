import { antiplateletServices } from '@/services/db'

const state = {
  antiplatelets: [],
  antiplateletsListFormat: []
}

// getters
const getters = {
  getAntiplatelets: state => state.antiplatelets,
  getAntiplateletsListFormat: state => state.antiplateletsListFormat,
}

// actions
const actions = {

  async fetchAntiplatelets ({commit}, payload) {
    try {
      commit('setLoading', true, { root: true })

      const res = await antiplateletServices.fetchAntiplatelets(payload)
      const list = []
      res.forEach((v,i) => {
        list.push({text:v.antiplatelet,value:v.id})  
      })
    
      commit('setAntiplateletsListFormat', list)
      commit('setAntiplatelets', res)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit}, payload) {
    /*************************************
    * ID: antiplatelet Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    
    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      const res = await antiplateletServices.updateAntiplateletColumn(payload)

      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addAntiplatelet ({commit}) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new antiplatelet */
      /* code here */

      let obj = {
        name: 'New Preparation Agent'
      }

      const res = await antiplateletServices.addAntiplatelet(obj)
      const data = await Promise.resolve([
        ...state.antiplatelets,
        res.data
      ])
      commit('setAntiplatelets', data)
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortAntiplatelet({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.antiplatelets.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.antiplatelets.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setAntiplatelets', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetAntiplatelets (state) {
    state.antiplatelets = []
  },

  setAntiplatelets (state, payload) {
    state.antiplatelets = payload
  },

  setAntiplateletsListFormat (state, payload) {
    state.antiplateletsListFormat = payload
  }

  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}