import { render, staticRenderFns } from "./BasicTimeGroup.vue?vue&type=template&id=ca35fad8&scoped=true&"
import script from "./BasicTimeGroup.vue?vue&type=script&lang=js&"
export * from "./BasicTimeGroup.vue?vue&type=script&lang=js&"
import style0 from "./BasicTimeGroup.vue?vue&type=style&index=0&id=ca35fad8&prod&scss=true&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca35fad8",
  null
  
)

export default component.exports