<template>
    <div class="hint">
        <sound-wave-anim v-if="getStoreHiddenParameter.isRecording"></sound-wave-anim>
        <img v-else src="@/assets/soundwave.svg" />
        <h3> {{ question }}
            <span>{{ answer }}</span>
        </h3>
        <h4> {{ hint }}</h4>
        <div class="clear"></div>
        <!-- {{ JSON.stringify(getStoreHiddenParameter) }} -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SoundWaveAnim from '@/components/examinations/SoundWaveAnim.vue'
export const idleQuestion = 'Waiting for voice input'
export const idleHint =  '[ press Z button 2 seconds to start recording ]'

export default {
    components:{
        SoundWaveAnim
    },
    props: {
        question: {
            type: String,
        },
        answer: {
            type: [String, Number],
        },
        hint: {
            type: String,
        }
    },
    computed: {
        ...mapGetters('examinations', [
            'getStoreHiddenParameter',  
        ])
    }
}
</script>

<style lang="scss" scoped>
    $pink:#FF5C5C;
    .hint {
        background: $pink;
        padding: 7px 10px;
        position: relative;
        img {
           height: 25px;
           position: absolute;
           top: 7px;
           left: 10px;
        }
        h3, h4 {
            padding: 0;
            color: white;
            display: inline-block;
        }
    }
    h3 {
        float: left;
        margin-left: 55px;
        text-transform: capitalize;
    }
    h4 {
        float: right;
        word-break: break-all;
    }
    p {
        word-break: break-word;
        width: 100%;
        margin-top: 25px;
    }
</style>