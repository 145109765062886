<style lang="scss">
.compare-item {
  .vue-daterange-picker {
    > .reportrange-text {
      padding: 0;
      border: none;
    }
  }

  .v-text-field--outline > .v-input__control > .v-input__slot {
    min-height: 60px;
  }

  &__append {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<template>
  <v-slide-x-transition leave-absolute>
    <v-layout wrap class="compare-item">
      <v-flex xs12 sm5 md5 lg4>
        <vue-daterange-picker
          :date-range="value.date"
          opens="right"
          show-dropdowns
          append-to-bodys
          auto-apply
          :max-date="maxDate"
          :ranges="ranges"
          class="d-block"
          @select="handleDateChange"
        >
          <template slot="input" slot-scope="props">
            <v-text-field
              :value="`${format(props.startDate)} - ${format(props.endDate)}`"
              label="time interval"
              outline
              readonly
              full-width
              hide-details
            />
          </template>
          <template slot="ranges"> </template>
        </vue-daterange-picker>
      </v-flex>
      <v-flex xs12 sm5 md5 lg4>
        <v-select
          :value="value.doctor"
          :items="doctors"
          label="Doctor"
          outline
          hide-details
          return-object
          :rules="[v => !!v || '']"
          @change="handleDoctorChange"
        />
      </v-flex>
      <v-flex xs12 sm2 md2 lg4 class="compare-item__append">
        <slot name="append" />
      </v-flex>
    </v-layout>
  </v-slide-x-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import common from '@/utils/common'

export default {
  name: 'CompareItem',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('doctors', ['getDoctors']),
    maxDate() {
      return common.dateFormat()
    },
    doctors() {
      return [{ text: 'All', value: 'all' }].concat(
        this.getDoctors.map(doctor => ({
          text: doctor.name,
          value: doctor.id,
        })),
      )
    },
    ranges() {
      let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000)
      today.setHours(0, 0, 0, 0)

      let todayEnd = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000)
      todayEnd.setHours(11, 59, 59, 999)

      let yesterdayStart = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000)
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0)

      let yesterdayEnd = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000)
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(11, 59, 59, 999)

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999)
      let thisYear = today.getFullYear()
      let lastYear = thisYear - 1

      return {
        Today: [today, todayEnd],
        Yesterday: [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'Last month': [
          new Date(thisYear, today.getMonth() - 1, 1),
          new Date(thisYear, today.getMonth(), 0),
        ],
        'This year': [new Date(thisYear, 0, 1), new Date(thisYear, 11, 31)],
        'Last year': [new Date(lastYear, 0, 1), new Date(lastYear, 11, 31)],
      }
    },
  },
  created() {
    if (!this.getDoctors.length) {
      this.fetchDoctors()
    }
  },
  methods: {
    ...mapActions('doctors', ['fetchDoctors']),
    format: common.dateFormat,
    handleDateChange({ startDate, endDate }) {
      // console.warn('startDate:', startDate)
      // console.warn('endDate:', endDate)
      const data = {
        ...this.value,
        date: {
          startDate: common.dateFormat(startDate),
          endDate: common.dateFormat(endDate),
        },
      }
      // console.warn(data)
      // this.$emit('input', data)
      this.$emit('change', data)
    },
    handleDoctorChange(doctor) {
      const data = {
        ...this.value,
        doctor: doctor.value,
        doctorName: doctor.text,
      }
      // this.$emit('input', data)
      this.$emit('change', data)
    },
  },
}
</script>
