import servicesUtils from "./servicesUtils";
import http from "./http-service";

export const authServices = {
  async signIn(username, password) {
    try {
      const url = `/signIn?t=` + new Date().getTime().toString();
      const res = await http.fetch(url, {
        method: "POST",
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      return res.data;
    } catch (e) {
      console.log(e);
    }
  },

  async signOut() {
    try {
      const url = `/signOut?t=` + new Date().getTime().toString();
      const res = await http.fetch(url);

      return servicesUtils.checkStatus(res);
    } catch (e) {
      console.log(e);
    }
  },
};
