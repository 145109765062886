import { userServices } from '@/services/db'

const state = {
  users: [],
  usernameList: []
}

// getters
const getters = {
  getUsers: state => state.users,
  getUsernameList: state => state.usernameList
}

// actions
const actions = {

  async fetchUsers ({commit}, payload) {
    try {
      commit('setLoading', true, { root: true })

      const res = await userServices.fetchUsers(payload)
      

      const list = []
      res.forEach((v,i) => {
        list.push(v.username)  
      })
    
      commit('setUsername', list)

      
      commit('setUsers', res)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({ commit, rootState }, payload) {
    /*************************************
    * ID: user Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    try {
      await userServices.updateUserColumn(payload)

      if (payload.field === 'useGuiding') {
        commit('setUser', { ...rootState.user, useGuiding: payload.value }, { root: true })
      }
    } catch (e) { 
      throw(e)
    }
  },

  async addUser ({commit}, payload) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new user */
      /* code here */

      const res = await userServices.addUser(payload)
      const data = await Promise.resolve([
        ...state.users,
        res.data
      ])


      commit('setUsers', data)
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortUser({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.users.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.users.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setUsers', data)
    commit('setLoading', false, { root: true })
  }
}

// mutations
const mutations = {
  resetUsers (state) {
    state.users = []
  },

  setUsers (state, payload) {
    state.users = payload
  },
  setUsername(state, payload){
    state.usernameList = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}