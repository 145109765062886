import { examinationTypeServices } from '@/services/db'

const state = {
  examinationTypes: [],
  examinationTypesListFormat: []
}

// getters
const getters = {
  getExaminationTypes: state => state.examinationTypes,
  getExaminationTypesListFormat: state => state.examinationTypesListFormat,
}

// actions
const actions = {

  async fetchExaminationTypes ({ commit, getters }, payload) {
    try {
      if (!getters.getExaminationTypes.length) {
        return examinationTypeServices.fetchExaminationTypes(payload).then(types => {
          commit('setExaminationTypes', types)
          commit('setExaminationTypesListFormat', types.map(x => ({ text: x.examination_type, value: x.id })))
        })
      }
      return Promise.resolve()
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit}, payload) {
    /*************************************
    * ID: examinationType Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    
    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      const res = await examinationTypeServices.updateExaminationTypeColumn(payload)

      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addExaminationType ({commit}) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new examinationType */
      /* code here */

      let obj = {
        name: 'New ExaminationType'
      }

      const res = await examinationTypeServices.addExaminationType(obj)
      const data = await Promise.resolve([
        ...state.examinationTypes,
        res.data
      ])


      commit('setExaminationTypes', data)
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortExaminationType({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.examinationTypes.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.examinationTypes.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setExaminationTypes', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetExaminationTypes (state) {
    state.examinationTypes = []
  },

  setExaminationTypes (state, payload) {
    state.examinationTypes = payload
  },

  setExaminationTypesListFormat (state, payload) {
    state.examinationTypesListFormat = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}