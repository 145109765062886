<template>
  <div class="flex xs12">
    <div v-for="list in data" :key="list.category">
      <div
        v-for="(item, index) in list.items"
        :key="item.id || item.questionID"
        :class="['input-wrapper', isFocus(list.category)]"
      >
        <locations
          v-if="list.category == 'loca'"
          v-show="index == 0"
          :data="list.items"
          :questions="questions"
          :hideLocationImage="hideLocationImage"
          :examination_typeID="examination_typeID"
          :focus="
            hiddenParams &&
            ['orien', 'loca', 'dist', 'switchLocation'].indexOf(
              hiddenParams.focus
            ) >= 0
          "
          :focusIndex="hiddenParams ? hiddenParams.nowLocationsIndex : null"
          :isExamination="isExamination"
          @addLocation="$emit('addLocation')"
          @deleteLocation="$emit('deleteLocation', $event)"
          @locationChange="$emit('locationChange', $event)"
        />
        <template v-else>
          <div class="header subtitle-border">{{ list.category }}</div>
          <laid-out-select-input
            v-if="item.config.inputType == 's'"
            :options="item.config.options"
            :value="item.value"
            :multiple="true"
            @change="
              $emit('change', { newValue: $event, qID: item.questionID })
            "
          />
          <textfield-landscape
            v-if="item.config.inputType == 't'"
            :class="`${list.category}-${id}`"
            :data="item"
            :isRequired="item.config.field === 'size' && isExamination"
            @change="
              $emit('change', { newValue: $event, qID: item.questionID })
            "
          />
          <v-textarea
            v-if="item.config.inputType == 'ta'"
            :label="item.config.question"
            :value="item.value"
            @change="
              $emit('change', { newValue: $event, qID: item.questionID })
            "
            no-resize
            outline
            hide-details
            height="88px"
            row-height="20px"
          />
          <horizontal-check-boxes
            v-if="item.config.inputType == 'c'"
            :field="item.config.field"
            :options="item.config.options"
            :value="item.value"
            @change="
              $emit('change', { newValue: $event, qID: item.questionID })
            "
          />
          <number-select-input
            v-if="
              item.config.field == 'prgC' ||
              item.config.field == 'prgM' ||
              item.config.field == 'dist'
            "
            :data="item"
            :float="false"
            unit="cm"
            @change="
              $emit('change', { newValue: $event, qID: item.questionID })
            "
          />
        </template>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>
<script>
import LaidOutSelectInput from "@/components/LaidOutSelectInput.vue";
import TextfieldLandscape from "@/components/TextfieldLandscape.vue";
import HorizontalCheckBoxes from "@/components/HorizontalCheckBoxes.vue";
import NumberSelectInput from "@/components/NumberSelectInput.vue";
import Locations from "@/components/Locations.vue";

export default {
  name: "DescriptionRecordsForm",
  props: {
    id: {
      required: true,
      type: [String, Number],
    },
    data: {
      required: true,
      type: Array,
    },
    questions: Object,
    hiddenParams: {
      required: false,
      type: Object,
    },
    hideLocationImage: {
      required: false,
      default: false,
      type: Boolean,
    },
    examination_typeID: Number,
    isExamination: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      timer: 0,
    };
  },
  components: {
    NumberSelectInput,
    LaidOutSelectInput,
    TextfieldLandscape,
    HorizontalCheckBoxes,
    Locations,
  },
  computed: {
    categories() {
      if (Array.isArray(this.data) && this.data.length) {
        return this.data.map(({ category }) => category);
      }
      return [];
    },
    focus() {
      if (
        this.hiddenParams &&
        this.hiddenParams.focus &&
        this.categories.length
      ) {
        if (this.categories.includes(this.hiddenParams.focus)) {
          return this.hiddenParams.focus;
        }

        const group = this.data.find(
          (e) =>
            e.items.some(
              (i) => i.questions && this.hiddenParams.focus in i.questions
            ) ||
            e.items.some(
              (i) => i.config && i.config.field === this.hiddenParams.focus
            )
        );

        if (group) {
          return group.category;
        }
      }
      return "";
    },
  },
  watch: {
    focus(nv, ov) {
      clearTimeout(this.timer);
      if (nv.trim() && nv !== ov) {
        this.timer = setTimeout(this.scrollToFocus, 300);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    isFocus(category) {
      if (this.focus === category) {
        return "focus";
      }
      return "";
    },
    scrollToFocus() {
      const focusdEls = document.querySelectorAll(".input-wrapper.focus");
      if (focusdEls.length) {
        const [focusd] = Array.from(focusdEls).filter(
          (el) => el.offsetParent !== null
        );
        if (focusd) {
          const [container] = document.querySelectorAll(".v-window");
          if (container) {
            container.scrollTo({
              left: 0,
              top: focusd.offsetTop,
              behavior: "smooth",
            });
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/variables";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital@0;1&display=swap");
.input-wrapper {
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  margin-bottom: 8px;
  .layout {
    margin: 0;
  }
  &.focus {
    color: $defaultPink;
    background: $formHighlight;
    .theme--light.v-label,
    p {
      color: $defaultPink !important;
    }
  }
}
.header {
  text-align: left;
  width: 100%;
  text-transform: capitalize;
  margin-bottom: 2px;
}
</style>