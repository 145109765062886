<template>
  <v-flex xs12>
    <v-btn
      block
      id="record-btn"
      :class="{ active: isRecording || isJudging }"
      @touchend="
        $event.stopPropagation();
        $emit('triggerRecordKey', 'keyup');
      "
      @touchstart="
        $event.stopPropagation();
        $emit('triggerRecordKey', 'keydown');
      "
      @mousedown="$emit('triggerRecordKey', 'keydown')"
      @mouseup="$emit('triggerRecordKey', 'keyup')"
    >
      <v-progress-circular
        :width="3"
        :size="20"
        v-if="isRecording"
        color="white"
        indeterminate
        class="icon-recoding"
      ></v-progress-circular>

      <div class="lds-ellipsis" v-else-if="isJudging">
        <div></div>

        <div></div>

        <div></div>

        <div></div>
      </div>

      <div class="blob" v-else></div>
      <!-- <v-icon v-else>keyboard_voice</v-icon> -->

      <!-- <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> -->
      {{ getStatusText() }}
    </v-btn>
  </v-flex>
</template>

<script>
export default {
  name: "MicrophoneStatus",
  props: { isRecording: Boolean, isJudging: Boolean },
  data() {
    return {
      RECORDING_TEXT: "RECORDING",
      RECORD_TEXT: "RECORD",
      JUDGING_TEXT: "ANALYZING",
    };
  },
  methods: {
    getStatusText() {
      if (this.isRecording) return this.RECORDING_TEXT;
      if (this.isJudging) return this.JUDGING_TEXT;
      return this.RECORD_TEXT;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/pages/page_examinationDetail.scss";
.icon-recoding {
  margin-right: 10px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 70px;

  div {
    position: absolute;
    top: 33px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 3px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 3px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 12px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 21px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(9px, 0);
  }
}

.blob {
  background: white;
  border-radius: 50%;
  margin: 10px;
  height: 15px;
  width: 15px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
</style>

