<template>
  <v-layout row wrap>
    <confirm-dialog-small
      :showDialog="deleteDialog"
      title="刪除"
      :message="deleteTarget.msg"
      :onConfirm="onDeleteConfirm.bind(this)"
      :onCancel="onDeleteCancel.bind(this)"
    />
    <v-flex
      xs2
      md2
      class="border max-vh-80"
      :class="{ 'border-textLightGrey': getStoreHiddenParameter.over }"
    >
      <BasicExamFindingList
        :examination-id="examinationId"
        :findings="getFindingsNameList"
        :activeId="nowFindingId"
        @clickFinding="handleClickFinding"
        @deleteItem="onOpenDeleteDialog"
      />
    </v-flex>
    <v-flex
      xs10
      md8
      class="bg-white max-vh-80 --scroll-y --hidden-x border-t"
      :class="{ 'border-textLightGrey': getStoreHiddenParameter.over }"
    >
      <template v-if="getFindings.length">
        <BasicExamFindingInfo
          :examType="examinationType"
          :examination-id="examinationId"
          :findings="getFindings"
          :activeIdx="getStoreHiddenParameter.nowFindingIndex"
          :questionList="generateQList"
        />
      </template>
      <div v-else class="d-flex align-center justify-center h-100">
        <BasicExamIndicationSteps
          v-if="!isExamFinished"
          :examType="examinationType"
          :current-step="currentStep"
        />
        <BorderAnimation v-else style="width: 60%" class="flex-grow-0">
          <p
            class="fsz-24 text-primary text-align-center font-weight-bold my-3"
          >
            The Examination is over
          </p>
        </BorderAnimation>
      </div>
    </v-flex>

    <v-flex xs12 md2 class="border pa-2 max-vh-80 --scroll-y --hidden-x">
      <BasicExamFindingImages
        :all-images="getAllImages"
        class="d-md-block d-none"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import {
  EXAMINATION_TYPE_OF_GASTROSCOPY,
  EXAMINATION_STATUS_MAP,
  EXAM_FINISHED,
} from "@/utils/examinations.config";
import { mapGetters, mapActions } from "vuex";
import BasicExamFindingList from "./BasicExamFindingList.vue";
import BasicExamFindingInfo from "./BasicExamFindingInfo.vue";
import BasicExamFindingImages from "./BasicExamFindingImages.vue";
import BasicExamIndicationSteps from "./BasicExamIndicationSteps.vue";
import BorderAnimation from "./BorderAnimation.vue";
import ConfirmDialogSmall from "@/components/ConfirmDialog.vue";

export default {
  name: "BasicExamFinding",
  components: {
    BasicExamFindingList,
    BasicExamFindingInfo,
    BasicExamFindingImages,
    BasicExamIndicationSteps,
    BorderAnimation,
    ConfirmDialogSmall,
  },
  data() {
    return {
      deleteDialog: false,
      deleteMessage: "",
      deleteTarget: {
        msg: "",
        item: {},
      },
    };
  },
  computed: {
    ...mapGetters("examinations", [
      "getQuestionsList",
      "getExaminationData",
      "getStoreHiddenParameter",
    ]),
    examinationId() {
      return this.getExaminationData && this.getExaminationData.examination.id;
    },
    examinationType() {
      return (
        this.getExaminationData &&
        this.getExaminationData.examination.examination_typeID
      );
    },
    getFindingQuestionOptions() {
      if (this.getQuestionsList === null) return [];
      // gastro 的 finding 使用 19.json
      // colon / Sigmoidoscopy 的 finding 使用 20.json
      const gastoJson = 19;
      const colonJson = 20;
      const nameOfFindinListJson =
        this.examinationType === EXAMINATION_TYPE_OF_GASTROSCOPY
          ? gastoJson
          : colonJson;
      return this.getQuestionsList[nameOfFindinListJson].options;
    },
    getFindings() {
      return this.getExaminationData ? this.getExaminationData.findings : [];
    },
    getAllImages() {
      return this.getExaminationData
        ? this.getExaminationData.all.sort((a, b) => b.id - a.id)
        : [];
    },
    // props
    getFindingsNameList() {
      return this.getFindings.reduce((acc, f) => {
        return f.questions && f.questions.finding && f.questions.finding.value
          ? [
              ...acc,
              {
                id: f.id,
                name: this.getFindingQuestionOptions[f.questions.finding.value],
              },
            ]
          : acc;
      }, []);
    },
    generateQList() {
      const OPTIONS = this.getFindingQuestionOptions || {};
      return Object.keys(OPTIONS)
        .map((o) => ({
          value: o,
          text: OPTIONS[o].charAt(0).toUpperCase() + OPTIONS[o].slice(1),
          tempOption: OPTIONS[o],
        }))
        .sort((a, b) => {
          return a.tempOption < b.tempOption ? -1 : a.option > b.option ? 1 : 0;
        });
    },
    currentStep() {
      return this.getStoreHiddenParameter.nowTimeCountingStep;
    },
    isExamFinished() {
      return (
        this.getStoreHiddenParameter.isTimeFinish ||
        (this.getExaminationData &&
          String(this.getExaminationData.examination.status) ===
            EXAMINATION_STATUS_MAP[EXAM_FINISHED])
      );
    },
    nowFindingId() {
      return (
        this.getFindings &&
        this.getFindings[this.getStoreHiddenParameter.nowFindingIndex] &&
        this.getFindings[this.getStoreHiddenParameter.nowFindingIndex].id
      );
    },
  },
  methods: {
    ...mapActions("examinations", ["setStoreHiddenParameter"]),
    openRecordIndex(recordIndex, findingIndex) {
      if (recordIndex !== undefined) {
        this.setStoreHiddenParameter({
          target: "nowRecordIndex",
          value: recordIndex,
        });
      }
      if (findingIndex !== undefined) {
        this.setStoreHiddenParameter({
          target: "nowFindingIndex",
          value: findingIndex,
        });
      }
      this.setStoreHiddenParameter({
        target: "nowQuestionPart",
        value: "d",
      });
      this.removeDisable();
    },
    handleClickFinding(id) {
      let idx = this.getFindings.findIndex((f) => f.id === id);
      this.openRecordIndex(0, idx);
    },
    removeDisable() {
      const isDisable = this.getStoreHiddenParameter.over;
      if (isDisable) {
        this.setStoreHiddenParameter({
          target: "over",
          value: false,
        });
      }
    },
    onOpenDeleteDialog(finding) {
      const idx = this.getFindings.findIndex((f) => f.id === finding.id);
      this.deleteTarget.item = { idx, ...finding };
      this.deleteTarget.msg = `確定要刪除 Finding ${idx + 1} - ${
        finding.name
      }嗎？`;
      this.deleteDialog = true;
    },
    onDeleteCancel() {
      this.deleteDialog = false;
    },
    onDeleteConfirm() {
      this.deleteDialog = false;
      this.deleteFinding(this.deleteTarget.item);
    },
    async deleteFinding(finding) {
      await this.$store.dispatch("examinations/deleteFinding", {
        findingID: finding.id,
        findingIndex: finding.idx,
      });
      this.openRecordIndex(0, this.getFindings.length - 1);
    },
  },
  created() {
    this.openRecordIndex(0, 0);
  },
};
</script>

<style lang="scss">
@import "@/styles/mixins";
.max-vh-80 {
  height: 80vh;
}

.d-md-block {
  @include md {
    display: block !important;
  }
}

.finding-over-cover {
  border-color: rgba(0, 0, 0, 0.7);
  &::before {
    content: "FINDING OVER";
    color: white;
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    letter-spacing: 2px;
    z-index: 10;
    font-style: italic;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
}
</style>
