import { cleanMedServices } from '@/services/db'

const state = {
  cleanMeds: [],
  cleanMedsListFormat: []
}

// getters
const getters = {
  getCleanMeds: state => state.cleanMeds,
  getCleanMedsListFormat: state => state.cleanMedsListFormat,
}

// actions
const actions = {

  async fetchCleanMeds ({commit}, payload) {
    try {
      commit('setLoading', true, { root: true })

      const res = await cleanMedServices.fetchCleanMeds(payload)
      const list = []
      res.forEach((v,i) => {
        list.push({text:v.clean_med,value:v.id})  
      })
    
      commit('setCleanMedsListFormat', list)
      commit('setCleanMeds', res)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit}, payload) {
    /*************************************
    * ID: cleanMed Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    
    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      const res = await cleanMedServices.updateCleanMedColumn(payload)

      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addCleanMed ({commit}) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new cleanMed */
      /* code here */

      let obj = {
        name: 'New Preparation Agent'
      }

      const res = await cleanMedServices.addCleanMed(obj)
      const data = await Promise.resolve([
        ...state.cleanMeds,
        res.data
      ])
      commit('setCleanMeds', data)
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortCleanMed({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.cleanMeds.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.cleanMeds.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setCleanMeds', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetCleanMeds (state) {
    state.cleanMeds = []
  },

  setCleanMeds (state, payload) {
    state.cleanMeds = payload
  },

  setCleanMedsListFormat (state, payload) {
    state.cleanMedsListFormat = payload
  }

  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}