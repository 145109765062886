export default {
  props: {
    findings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    gastroFindings() {
      const result = {
        ESOPHAGUS: [],
        STOMACH: [],
        DUODENUM: [],
        NONSPECIFIC: [],
      }
      this.findings.forEach((f) => {
        if (!Object.keys(result).includes(f.type)) {
          result[f.type] = []
        }
        result[f.type].push(f)
      })

      for (let r in result) {
        if (result[r].length === 0) {
          delete result[r]
        }
      }

      return result
    },
  },
}
