<style lang="scss">
.search-table {
  &__col {
    & + & {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  &__cell {
    padding-top: 9px;
    padding-bottom: 9px;

    & + & {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  table.v-table {
    thead th,
    tbody td {
      font-size: 24px;
    }

    thead th i {
      font-size: 24px !important;
    }
  }
}
</style>

<template>
  <v-data-table
    :headers="data.headers"
    :items="data.items"
    hide-actions
    class="search-table"
  >
    <template slot="items" slot-scope="props">
      <tr>
        <td class="search-table__col">{{ props.item.field }}</td>
        <td class="search-table__col">
          <div
            v-for="(value, index) in props.item.values"
            :key="`value-${index}-${value}`"
            class="search-table__cell"
          >
            {{ value }}
          </div>
        </td>
        <td class="search-table__col">
          <div
            v-for="(count, index) in props.item.counts"
            :key="`count-${index}-${count}`"
            class="search-table__cell"
          >
            {{ count }}
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'SearchTable',
  props: {
    data: {
      type: Object,
      default: () => ({ headers: [], items: [] }),
    },
  },
  data() {
    return {
      /* headers: [
        { text: '欄位', value: 'field' },
        { text: '值', value: 'values' },
        { text: '筆數', value: 'count' },
      ],
      items: [
        {
          field: 'Finding',
          values: ['Colitis', 'Stich', 'Inflammatory adenoma', 'No finding'],
          counts: [50, 4, 50, 696],
        },
        {
          field: 'Intervention',
          values: ['Biopsy', 'No intervention'],
          counts: [100, 700],
        },
      ], */
    }
  },
}
</script>
