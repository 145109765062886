<template>
	<v-layout row wrap class="examination-filtering-images mb-3">
    <v-flex xs12>
      <v-card flat>
        <v-card-text class="pb-2">
          <div class="filter-group-container">
            <!-- chip filters container -->
            <filter-chips
              v-if="!hideFilterChips"
              :active="imagesTabs"
              :filterItems="findings"
              landmark print
              @activeChange="imagesTabs = $event"
            ></filter-chips>

            <!-- images - horizontally scroll when overflow -->
            <filter-images-list
              :hideFocusItem="hideFocusItem"
              :active="imagesTabs"
              :filterItems="findings"
              :images="allImages"
              :landmarks="landmarksList"
              :horizontal="horizontal"
              @imgStatusChange="$emit('imgStatusChange', $event)"
              @findingChange="$emit('findingChange', $event)"
              @landmarkChange="$emit('landmarkChange', $event)"
              @deleteImg="$emit('deleteImg', $event)"
            ></filter-images-list>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  import FilterChips from '@/components/screenshots/FilterChips.vue'
  import FilterImagesList from '@/components/screenshots/FilterImagesList.vue'
  import ConfirmDialogSmall from '@/components/ConfirmDialogSmall.vue'

	export default {
		name: 'ExaminationFilteringImages',

    components: {
      FilterChips,
      FilterImagesList,
      ConfirmDialogSmall
    },

		props: {
      findings: {
        type: Array,
        required: true
      },

      allImages: {
        type: Array,
        required: true
      },

      landmarksList: {
        type: Array,
        required: true
      },

      horizontal: {
        type: Boolean,
        required: false,
        default: false
      },

      hideFocusItem: {
        type: Boolean,
        required: false,
        default: false
      },

      hideFilterChips: {
        type: Boolean,
        required: false,
        default: false
      }
    },

		data () {
			return {
        imagesTabs: 'all'
			}
    },
	}
</script>

<style lang="scss">
  @import '@/styles/components/filterImageGroup.scss';
</style>