import { doctorServices } from '@/services/db'

const state = {
  doctors: [],
  doctorsListFormat: [],
  doctorSearchResult: []
}

// getters
const getters = {
  getDoctors: state => state.doctors,
  getDoctorsListFormat: state => state.doctorsListFormat,
  getDoctorSearchResult: state => state.doctorSearchResult
}

// actions
const actions = {
  async searchDoctors ({commit}, { keyword }) {
    try {
      commit('setLoading', true, { root: true })
      const res = await doctorServices.searchDoctor({ keyword })
      commit('setDoctorSearchResult', res.data.suggestion)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  fetchDoctors ({ commit, getters }, payload) {
    try {
      if (!getters.getDoctors.length) {
        return doctorServices.fetchDoctors(payload).then(doctors => {
          commit('setDoctors', doctors)
          commit('setDoctorsListFormat', doctors.map(x => ({ text: x.name, value: x.id })))
        })
      }
      return Promise.resolve()
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit, state}, payload) {
    /*************************************
    * ID: doctor Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    
    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      await doctorServices.updateDoctorColumn(payload)
      commit('setDoctorsListFormat', state.doctors.map(x => ({ text: x.name, value: x.id })))
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addDoctor ({commit}) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new doctor */
      /* code here */

      let obj = {
        name: 'New Doctor'
      }

      const res = await doctorServices.addDoctor(obj)
      const data = await Promise.resolve([
        ...state.doctors,
        res.data
      ])


      commit('setDoctors', data)
      commit('setDoctorsListFormat', data.map(x => ({ text: x.name, value: x.id })))
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortDoctor({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.doctors.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.doctors.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setDoctors', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetDoctors (state) {
    state.doctors = []
  },

  setDoctors (state, payload) {
    state.doctors = payload
  },

  setDoctorsListFormat (state, payload) {
    state.doctorsListFormat = payload
  },

  setDoctorSearchResult (state, payload) {
    state.doctorSearchResult = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}