var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.getFindings?.length == 0),expression:"getFindings?.length == 0"}]},[_vm._v("Negative finding")]),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.getFindings?.length == 0),expression:"getFindings?.length == 0"}],staticClass:"mt-2 pt-2 findings-container",attrs:{"flat":""}},[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"mb-2",attrs:{"xs12":""}},[_c('div',{staticClass:"findings-tab-title title font-weight-light"},[_vm._v(" "+_vm._s(_vm.negativeFinding)+" ")])]),_c('div',{staticClass:"finding-bottom"},[_c('v-textarea',{attrs:{"label":"Negative Suggestion","value":_vm.getNegaSuggestion,"no-resize":"","outline":"","height":"120px"},on:{"change":function($event){return _vm.saveNegaSuggestion({
                event: { field: 'negaSuggestion', newValue: $event },
              })}}})],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2 pt-2",attrs:{"flat":""}},[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[(_vm.finding && _vm.getQuestionsList)?_c('v-flex',[_c('section',{staticClass:"pa-2 border-b border-b--dashed"},[_c('h3',[_vm._v("Finding "+_vm._s(_vm.findingIndex + 1))]),_c('examination-findings-form-level-one',{staticClass:"pa-0 mb-4",attrs:{"finding":_vm.finding,"findingIndex":_vm.findingIndex,"questions":_vm.getQuestionsList},on:{"change":function($event){return _vm.saveQuestionInput({
                  findingIndex: _vm.findingIndex,
                  event: $event,
                })}}})],1),_c('div',{staticClass:"wavemed-horizontal-stepper-container"},[_c('v-layout',{staticClass:"pa-2",attrs:{"row":"","align-items-start":""}},[_c('v-flex',{attrs:{"xs8":""}},[_c('v-tabs',{model:{value:(_vm.getNowRecordIndex),callback:function ($$v) {_vm.getNowRecordIndex=$$v},expression:"getNowRecordIndex"}},[_vm._l((_vm.finding.records),function(record,recordIndex){return _c('v-tab',{key:recordIndex,on:{"click":function($event){_vm.getNowRecordIndex = recordIndex}}},[(_vm.isMostSignificant(_vm.finding))?_c('div',[_vm._v(" The most significant ")]):(_vm.isOverall(_vm.finding))?_c('div',[_vm._v("Overall")]):_c('div',[_vm._v(" Lesion "),(_vm.finding.records.length > 1)?_c('span',[_vm._v(_vm._s(recordIndex + 1))]):_vm._e()])])}),_vm._l((_vm.finding.records),function(record,recordIndex){return _c('v-tab-item',{key:recordIndex,staticClass:"wavemed-horizontal-stepper-container px-2"},[_c('examination-findings-form-level-two',{attrs:{"findingIndex":_vm.findingIndex,"recordIndex":recordIndex,"record":record,"examination_typeID":_vm.getExaminationData.examination.examination_typeID,"questions":_vm.getQuestionsList,"hiddenParams":_vm.getStoreHiddenParameter,"hideLocationImage":"","isExamination":""},on:{"change":function($event){return _vm.saveQuestionInput({
                          findingIndex: _vm.findingIndex,
                          recordIndex: recordIndex,
                          event: $event,
                        })},"addLocation":function($event){return _vm.addExaminationLocation({
                          recordID: record.id,
                          findingIndex: _vm.findingIndex,
                        })},"deleteLocation":function($event){return _vm.deleteExaminationLocation({
                          locationID: $event,
                          recordIndex,
                          findingIndex: _vm.findingIndex,
                        })},"locationChange":function($event){return _vm.updateExaminationLocationColumn({
                          findingIndex: _vm.findingIndex,
                          recordIndex,
                          $event,
                        })}}})],1)})],2)],1),_c('v-flex',{attrs:{"xs4":""}},[_c('div',{attrs:{"data-descript":"產生文章"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                      !_vm.getEditArticleStatus[_vm.findingIndex][_vm.getNowRecordIndex]
                    ),expression:"\n                      !getEditArticleStatus[findingIndex][getNowRecordIndex]\n                    "}],attrs:{"color":"info","disabled":_vm.isFetchParagrah},on:{"click":function($event){return _vm.getSummaryAndDiagnosis(
                        _vm.findingIndex,
                        _vm.getNowRecordIndex,
                        _vm.finding.records[_vm.getNowRecordIndex]?.id,
                      )}}},[_vm._v(" 自動產生文章 ")])],1),(_vm.getOrderArticle && _vm.findingContent)?_c('section',{staticClass:"pa-2"},[_c('FindingItemContent',{attrs:{"hasActionTrigger":false,"finding":_vm.findingContent}})],1):_vm._e()])],1)],1)]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }