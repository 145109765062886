

   <template>
    <div>
      <div class="input-header">Number</div>
      <v-text-field
        type="text"
        min="0"
        :value="this.finding.questions.number.value"
        hide-details
        :disabled="isNumberReadonly"
        :readonly="isNumberReadonly"
        @change="handleNumberChange"
      ></v-text-field>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      finding: Object,
    },
    computed: {
      isNumberReadonly() {
        return this.finding.questions.finding.value === '651';
      },
    },
    methods: {
      handleNumberChange(newValue) {
        const isNumeric = /^[0-9]+$/.test(newValue);
        const isSeveral = newValue.toLowerCase() === 'several';
        const isMuptiple = newValue.toLowerCase() === 'muptiple';

        if (isNumeric || isSeveral || isMuptiple) {
          this.$emit('change', { field: 'number', newValue });
        } 
      }, 
      getFocusClass(key) {
        if (this.hiddenParams) {
          return this.hiddenParams.focus == key ? 'focus' : '';
        } else {
          return '';
        }
      },
      getFindingOneClass() {
        if (this.isExamination) return 'is-examination';
      },
    },
  };
  </script>
  