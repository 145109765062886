<template>
  <BorderAnimation style="height: 220px; width: 60%" class="flex-grow-0">
    <ul class="pl-0" data-test="step-view">
      <li
        class="list-style-none"
        v-for="(hint, idx) in stepsHint"
        :key="idx"
        :class="hint.className"
      >
        {{ hint.text }}
      </li>
    </ul>
  </BorderAnimation>
</template>

<script>
import BorderAnimation from "./BorderAnimation.vue";
import { EXAMINATION_TYPE_OF_COLONOSCOPY } from "@/utils/examinations.config.js";

export default {
  name: "BasicExamIndicationSteps",
  components: {
    BorderAnimation,
  },
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    examType: {
      type: Number,
    },
  },
  computed: {
    titleClass() {
      return "fsz-32 fsz-lg-50 text-accent";
    },
    contentClass() {
      return "mb-0 fsz-24 text-primary text-align-center font-weight-bold";
    },
    hintClass() {
      return "mb-0 fsz-28 text-primary text-align-center font-weight-bold";
    },
    stepsHint() {
      switch (this.currentStep) {
        case 0:
          return [
            { text: "Please Say", className: this.contentClass },
            { text: '"EVAS GO"', className: this.titleClass },
          ];
        case 1:
          return EXAMINATION_TYPE_OF_COLONOSCOPY === this.examType
            ? [
                { text: "Next", className: this.hintClass },
                { text: '"Insertion to Cecum"', className: this.titleClass },
                { text: "or Add Finding", className: this.contentClass },
              ]
            : [
                { text: "Next", className: this.hintClass },
                { text: "Add Finding", className: this.titleClass },
              ];
        default:
          return [
            { text: "Say the lesion you found", className: this.hintClass },
          ];
      }
    },
  },
};
</script>
