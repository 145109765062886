import { organizationServices } from '@/services/db'

const state = {
  organizations: [],
  organizationsListFormat: []
}

// getters
const getters = {
  getOrganizations: state => state.organizations,
  getOrganizationsListFormat: state => state.organizationsListFormat
}

// actions
const actions = {

  async fetchOrganizations ({commit}, payload) {
    try {
      commit('setLoading', true, { root: true })

      const res = await organizationServices.fetchOrganizations(payload)

      const list = []
      res.forEach((v,i) => {
        list.push({text:v.name,value:v.id})  
      })
    
      commit('setOrganizationsListFormat', list)

      commit('setOrganizations', res)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit}, payload) {
    /*************************************
    * ID: organization Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    
    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      const res = await organizationServices.updateOrganizationColumn(payload)

      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addOrganization ({commit}) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new organization */
      /* code here */

      let obj = {
        name: 'New Organization'
      }

      const res = await organizationServices.addOrganization(obj)
      const data = await Promise.resolve([
        ...state.organizations,
        res.data
      ])


      commit('setOrganizations', data)
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortOrganization({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.organizations.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.organizations.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setOrganizations', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetOrganizations (state) {
    state.organizations = []
  },

  setOrganizations (state, payload) {
    state.organizations = payload
  },

  setOrganizationsListFormat (state, payload) {
    state.organizationsListFormat = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}