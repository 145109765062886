<template>
  <v-layout row wrap fill-height justify-center align-center class="px-4 py-2">
    <v-flex xs6 class="text-xs-center">
      <div class="subheading font-weight-light">{{ $t('homepage.select_examination') }}</div>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    data() {
      return {
      }
    },

    computed: {
    },

    methods: {
    }
  }
</script>

<style>
  .subheading {
    line-height: 24px !important;
  }
</style>