import axios from "axios";
import { Observable } from "rxjs";
import { get, has } from "lodash";

let instance = null;

class HttpService {
  _http = null;
  constructor(baseURL = '/api') {
    if (!instance) {
      instance = this;
    }

    this._http = axios.create();
    this._http.defaults.baseURL = baseURL;
    this._http.defaults.headers.common["Accept"] = "*/*";

    this._http.interceptors.request.use((config) => {
      // config.data = JSON.stringify(config.data);
      return config;
    });
    this._http.interceptors.response.use(
      (res) => {
        return this._resFormat(res);
      },
      (err) => {
        return Promise.reject(this._errorFormat(err));
      }
    );
    return instance;
  }

  _resFormat(
    payload = {
      config: {},
      data: {},
      headers: {},
      request: {},
      status: 200,
      statusText: "",
    }
  ) {
    if (!has(payload, "status")) {
      console.log("Http Request failed, there is no status code");
    }
    const _data = get(payload, "data", {}) || {};
    const _status = get(payload, "status", 999) || 999;
    const _statusText =
      get(payload, "statusText", "RESPONSE_IS_EMPTY") || "RESPONSE_IS_EMPTY";
    const _success = _statusText === "OK";
    const res = {
      data: _data,
      status: _status,
      success: _success,
      statusText: _statusText,
      message: "",
    };
    return res;
  }

  _errorFormat(
    payload = {
      code: "",
      config: {},
      message: "",
      name: "",
      request: {},
      response: {},
    }
  ) {
    const _data = get(payload, "response.data", {}) || {};
    const _status = get(payload, "response.status", 999) || 999;
    const _message =
      get(payload, "message", "JavaScript Error") || "JavaScript Error";
    const _statusText =
      get(payload, "response.statusText", "RESPONSE_IS_EMPTY") ||
      "RESPONSE_IS_EMPTY";
    const _success = false;
    const res = {
      data: _data,
      status: _status,
      success: _success,
      statusText: _statusText,
      message: _message,
    };
    return res;
  }

  fetch(
    url,
    option = {
      method: "GET",
      headers: {},
      body: null,
      redirect: "follow",
      signal: null,
      params: {},
    }
  ) {
    const _method = get(option, "method", "") || "";
    if (
      !_method instanceof String ||
      !typeof this._http[_method.toLowerCase()] === "function"
    )
      return this._resFormat(new Error());
    return new Promise(async (resolve, reject) => {
      const args = [url];
      if (["post", "put", "delete"].indexOf(_method.toLowerCase()) > -1)
        args.push(get(option, "body", ""));
      args.push(option);
      await this._http[_method.toLowerCase()](...args)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get(
    url,
    option = {
      method: "GET",
      headers: {},
      body: null,
      redirect: "follow",
      signal: null,
      params: {},
    }
  ) {
    return new Observable((sub) => {});
    return this.getHttpBody(this._http.getObservable(`${url}`, options));
  }

  post(url, body, param) {
    const urlParam = this.setURLParams(param);
    const options = {
      observe: "response",
      params: urlParam,
    };
    return this.getHttpBody(this._http.postObservable(`${url}`, body, options));
  }

  put(url, body) {
    const options = {
      observe: "response",
    };
    return this.getHttpBody(this._http.putObservable(`${url}`, body, options));
  }

  delete(url, key) {
    const options = {
      observe: "response",
    };
    return this.getHttpBody(
      this._http.deleteObservable(`${url}/${key}`, options)
    );
  }

  handleTransObservable(httpFunc) {
    return new Observable((sub) => {
      httpFunc
        .then((res) => {
          sub.next(res);
          sub.complete();
        })
        .catch((err) => {
          sub.error(err);
          sub.complete();
        });
    });
  }
}

const http = new HttpService();

export default http;

// module.exports = (() => {
//   if (instance) {
//     return instance;
//   }

//   instance = new HttpService();
//   return instance;
// })();
