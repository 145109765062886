import Vue from 'vue'
import Vuex from 'vuex'

import { state } from './state'
import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

import examinations from './modules/examinations'
import screenshots from './modules/screenshots'
import organizations from './modules/organizations'
import doctors from './modules/doctors'
import patients from './modules/patients'
import employeeTypes from './modules/employeeTypes'
import examinationTypes from './modules/examinationTypes'
import landmarks from './modules/landmarks'
import antiplatelets from './modules/antiplatelets'
import antispasmodics from './modules/antispasmodics'
import cleanMeds from './modules/cleanMeds'
import inspectionInstruments from './modules/inspectionInstruments'
import sedatives from './modules/sedatives'
import washInstruments from './modules/washInstruments'
import technicalStaffs from './modules/technicalStaffs'
import users from './modules/users'
import report from './modules/report'


Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        examinations,
        screenshots,
        organizations,
        doctors,
        patients,
        employeeTypes,
        examinationTypes,
        landmarks,
        antiplatelets,
        antispasmodics,
        cleanMeds,
        inspectionInstruments,
        sedatives,
        washInstruments,
        technicalStaffs,
        users,
        report,
    },
  state,
  mutations,
  actions,
  getters
})