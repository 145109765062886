<template>
  <li class="previewList">
    <h3 class="previewList__title">{{ $t(`common.${title}`) }} :</h3>
    <slot class="previewList__content">
      <div v-if="isShow(content)">
        <slot name="extra-start" />
        <p>{{ prefix }}{{ content }}</p>
        <slot name="extra" />
      </div>
      <p v-else>{{ $t('common.nil') }}</p>
    </slot>
  </li>
</template>

<script>
export default {
  props: {
    title: String,
    value: Array,
    prefix: String,
    refKey: {
      type: String,
      default: 'id',
    },
    refValue: {
      type: String,
    },
    refArr: {
      type: Array,
      default: () => [],
    },
    needMapRef: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    content() {
      if (!this.needMapRef) return this.value.join(',')
      let text = ''
      this.refArr.map((item) => {
        if (this.value.indexOf(item[this.refKey]) >= 0) {
          if (text.length <= 0) {
            text += item[this.refValue]
          } else {
            text += ', ' + item[this.refValue]
          }
        }
      })
      return text
    },
  },
  methods: {
    isShow(data) {
      let result = true
      if (
        data == 'Choose' ||
        data == '' ||
        (Array.isArray(data) && data.length === 0)
      ) {
        result = false
      }
      return result
    },
  },
}
</script>
