<template>
  <section>
    <h2
      data-test="running-times"
      class="text-align-center text-dark-blue fsz-32 fsz-lg-40 l-space-2 timer d-block"
      :class="{ appendPadding: !showTimeSquare }"
    >
      {{ title }}
    </h2>

    <v-layout
      tag="ul"
      class="pl-0"
      data-test="time-square"
      v-if="showTimeSquare"
    >
      <v-flex
        tag="li"
        class="list-style-none px-2"
        v-for="(item, idx) in list"
        :key="item.field"
      >
        <p
          :class="textColor(idx + 1)"
          class="fsz-14 fsz-md-16 fsz-lg-18 fsz-xl-20 mb-0 text-align-center pa-2 font-weight-bold l-space-1"
        >
          {{ item.label }}: {{ (data && data[item.field]) || NO_TIME }}
        </p>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
const NO_TIME = "00:00:00";

export default {
  name: "BasicTimeGroup",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    showTimeSquare: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      NO_TIME,
      timer: "00:00:00",
      list: [
        {
          field: "insertionTime",
          label: "Insertion Time",
        },
        {
          field: "proximalColonWithTime",
          label: "Proximal Time",
        },
        {
          field: "withdrawTime",
          label: "Withdrawal Time",
        },
        {
          field: "totalExamTime",
          label: "Total Exam",
        },
      ],
      preTimeCountingStep: 0,
      colorValue: 0,
      timerInterval: null,
      colorInterval: null,
      colorIntervalTime: 0,
    };
  },
  computed: {
    ...mapGetters("examinations", [
      "getStoreHiddenParameter",
      "getExaminationData",
    ]),
    title() {
      return this.timer === NO_TIME ? this.totalExamTime : this.timer;
    },
    textColor() {
      return (step) => {
        const { nowTimeCountingStep } = this.getStoreHiddenParameter;
        return step === nowTimeCountingStep ? "text-accent-1" : "text-dark";
      };
    },
    time_start() {
      return this.getExaminationData.examination.time_start;
    },
    time_insertion_level() {
      return this.getExaminationData.examination.time_insertion_level;
    },

    totalExamTime() {
      return (
        this.getExaminationData &&
        this.getExaminationData.examination &&
        this.getExaminationData.examination.totalExamTime
      );
    },
  },
  methods: {
    autoTimer() {
      const _this = this;
      this.timerInterval = setInterval(() => {
        if (_this.getStoreHiddenParameter.nowTimeCountingStep > 0) {
          switch (_this.getStoreHiddenParameter.nowTimeCountingStep) {
            case 1:
              _this.timer = _this.calculatingTime(
                _this.getHHiiss(),
                _this.time_start
              );
              break;
            case 2:
              _this.timer =
                _this.time_insertion_level !== NO_TIME
                  ? _this.calculatingTime(
                      _this.getHHiiss(),
                      _this.time_insertion_level
                    )
                  : NO_TIME;
              break;
            case 3:
              _this.timer = _this.calculatingTime(
                _this.getHHiiss(),
                _this.time_insertion_level
              );
              break;
            case 4:
              _this.timer = _this.totalExamTime;
              break;
          }
          if (
            _this.preTimeCountingStep !=
            _this.getStoreHiddenParameter.nowTimeCountingStep
          ) {
            //rgb(226, 193, 221)
            _this.preTimeCountingStep =
              _this.getStoreHiddenParameter.nowTimeCountingStep;
            _this.colorInterval = setInterval(() => {
              if (document.getElementsByClassName("timer").length > 0) {
                document.getElementsByClassName("timer")[0].style =
                  "background-color: rgba(226, 193, 221, " +
                  _this.colorValue +
                  "); border-radius: 10px;";
              }

              if (_this.colorIntervalTime <= 800) {
                _this.colorValue += 0.01;
              } else {
                _this.colorValue -= 0.01;
              }
              _this.colorIntervalTime += 10;
            }, 10);
            setTimeout(function () {
              clearInterval(_this.colorInterval);
              _this.colorValue = 0;
              _this.colorIntervalTime = 0;
              if (document.getElementsByClassName("timer").length > 0) {
                document.getElementsByClassName("timer")[0].style =
                  "background-color: rgba(226, 193, 221, " +
                  _this.colorValue +
                  "); border-radius: 10px;";
              }
            }, 1600);
          }
          _this.$forceUpdate();
        }
      }, 100);
    },
    // 取得 HH:ii:ss
    getHHiiss() {
      var x = new Date();
      var h = x.getHours().toString();
      var i = x.getMinutes().toString();
      var s = x.getSeconds().toString();
      h.length == 1 && (h = "0" + h);
      i.length == 1 && (i = "0" + i);
      s.length == 1 && (s = "0" + s);
      var HHiiss = h + ":" + i + ":" + s;
      return HHiiss;
    },
    // 計算時間
    calculatingTime(a, b) {
      if (a && b) {
        var a = a.split(":");
        var b = b.split(":");
        a = Number(a[0]) * 3600 + Number(a[1]) * 60 + Number(a[2]);
        b = Number(b[0]) * 3600 + Number(b[1]) * 60 + Number(b[2]);
        var c = a - b;
        var HHiiss = "00:00:00";
        if (c > 0) {
          var h = parseInt(c / 3600);
          var i = parseInt((c - h * 3600) / 60);
          var s = c - h * 3600 - i * 60;
          String(h).length == 1 && (h = "0" + String(h));
          String(i).length == 1 && (i = "0" + String(i));
          String(s).length == 1 && (s = "0" + String(s));
          HHiiss = h + ":" + i + ":" + s;
        }
        return HHiiss;
      }
      return "";
    },
  },
  mounted() {
    this.autoTimer();
  },
};
</script>

<style scss scoped>
.appendPadding {
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
