<style lang="scss">
.analytics-compare {
  &__remove {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<template>
  <v-form v-model="valid" ref="form" @submit.prevent="handleSubmit">
    <v-container grid-list-md class="pa-0">
      <v-layout wrap>
        <v-flex sm12 md9>
          <!-- SINGLE -->
          <compare-item :value="single" @change="updateSingle">
            <template slot="append">
              <v-btn
                large
                depressed
                block
                flat
                class="my-0"
                @click="addCompare"
              >
                <v-icon>playlist_add</v-icon>
                <!-- $vuetify.breakpoint.smAndDown ||  -->
                <span v-show="$vuetify.breakpoint.lgAndUp" class="subheading">
                  Add Comparison
                </span>
              </v-btn>
            </template>
          </compare-item>
          <!-- MULTIPLE -->
          <compare-item
            v-for="item in items"
            :key="item.id"
            :value="item"
            @change="updateCompares"
          >
            <template slot="append">
              <v-btn large depressed block flat @click="removeCompare(item.id)">
                <v-icon>delete_forever</v-icon>
              </v-btn>
            </template>
          </compare-item>
        </v-flex>
        <!-- SUBMIT -->
        <v-flex sm12 md3 class="align-self-start">
          <v-btn
            type="submit"
            large
            depressed
            block
            color="primary"
            class="mt-2"
          >
            GENERATE
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import CompareItem from './CompareItem'

import common from '@/utils/common'

export default {
  name: 'AnalyticsCompare',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CompareItem,
  },
  data() {
    const now = common.dateFormat()
    return {
      valid: false,
      single: {
        date: {
          startDate: now,
          endDate: now,
        },
        doctor: 'all',
        doctorName: 'All',
      },
      items: [],
    }
  },
  computed: {
    compares() {
      return [this.single].concat(this.items)
    },
  },
  created() {
    this.sync()
  },
  methods: {
    sync() {
      this.$emit('input', this.compares)
      // this.$emit('change', this.compares)
    },
    genCompare() {
      const now = common.dateFormat()
      return {
        id: common.sid(),
        date: { startDate: now, endDate: now },
        doctor: 0,
        doctorName: '',
      }
    },
    addCompare() {
      this.items = this.items.concat(this.genCompare())
      this.sync()
    },
    updateSingle(data) {
      this.single = data
      this.sync()
    },
    updateCompares(data) {
      this.items = this.items.map(item => (item.id === data.id ? data : item))
      this.sync()
    },
    removeCompare(id) {
      this.items = this.items.filter(item => item.id !== id)
      this.sync()
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit('compare', this.compares)
      }
    },
  },
}
</script>
