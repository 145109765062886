<template>
  <v-layout row wrap align-content-start fill-height class="pa-4">
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-text>
          <span class="headline">Add User</span>
          <v-container grid-list-md>
            <v-form ref="form" lazy-validation>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.username"
                    label="Username"
                    :rules="[rules.min, rules.existed]"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.password"
                    :append-icon="show1 ? 'visibility' : 'visibility_off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    @click:append="show1 = !show1"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    label="Employee Type"
                    :items="getEmployeeTypesListFormat"
                    v-model="editedItem.employee_typeID"
                    @change="changeEmployeeType"
                  />
                </v-flex>
                <v-flex xs12 v-if="editedItem.employee_typeID === 1">
                  <v-select
                    label="Doctor"
                    :items="getDoctorsListFormat"
                    v-model="editedItem.employeeID"
                  />
                </v-flex>
                <v-flex xs12 v-if="editedItem.employee_typeID === 2">
                  <v-select
                    label="Nurse"
                    :items="getTechnicalStaffsListFormat"
                    v-model="editedItem.employeeID"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" flat @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" flat @click="addUser">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- @save="updateUser(props.item.id, 'password', new_password)" > -->
    <v-dialog v-model="dialog_change_password" max-width="500px">
      <v-card>
        <v-card-text>
          <span class="headline">Edit Password</span>
          <v-container grid-list-md>
            <v-form
              ref="form_change_password"
              lazy-validation
              @submit="doSubmit"
            >
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="new_password"
                    :append-icon="show2 ? 'visibility' : 'visibility_off'"
                    :rules="[rules.required, rules.min]"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    @click:append="show2 = !show2"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" flat @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" flat @click="changePassword">
            Change
          </v-btn>
        </v-card-actions>
        <v-snackbar
          v-model="snackbar"
          :bottom="y === 'bottom'"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="timeout"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ text }}
          <v-btn color="pink" flat @click="snackbar = false"> Close </v-btn>
        </v-snackbar>
      </v-card>
    </v-dialog>

    <v-flex xs12 class="page-head-container mb-3">
      <v-flex xs12 class="mb-4 page-head-container">
        <h1 class="headline font-weight-light">User List</h1>
      </v-flex>

      <v-flex xs12 class="search-container">
        <v-text-field label="Search" solo flat hide-details v-model="search">
        </v-text-field>
      </v-flex>

      <v-flex xs12>
        <template>
          <v-data-table
            v-if="getUsers.length > 0"
            :headers="headers"
            :items="getUsers"
            :pagination.sync="pagination"
            :search="search"
            hide-actions
            class="my-4"
            :disable-initial-sort="true"
          >
            <template slot="items" slot-scope="props">
              <tr @click="clickItem(props.item)">
                <td>{{ props.item.username }}</td>
                <td>
                  <v-select
                    :items="getEmployeeTypesListFormat"
                    v-model="props.item.employee_typeID"
                    @change="
                      updateUser(props.item.id, 'employee_typeID', $event)
                      changeItemEmployeeType(props.item)
                    "
                  >
                  </v-select>
                </td>
                <td>
                  <v-flex xs12 v-if="props.item.employee_typeID == 1">
                    <v-select
                      label="Doctor"
                      :items="getDoctorsListFormat"
                      v-model="props.item.employeeID"
                      @change="updateUser(props.item.id, 'employeeID', $event)"
                    />
                  </v-flex>
                  <v-flex xs12 v-if="props.item.employee_typeID == 2">
                    <v-select
                      label="Nurse"
                      :items="getTechnicalStaffsListFormat"
                      v-model="props.item.employeeID"
                      @change="updateUser(props.item.id, 'employeeID', $event)"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    v-if="
                      props.item.employee_typeID !== 1 &&
                      props.item.employee_typeID !== 2
                    "
                  >
                    --
                  </v-flex>
                </td>
                <td class="text-xs-center">
                  <v-btn @click="clickChangePassword()" color="warning">
                    Edit Password
                  </v-btn>
                </td>
                <td>
                  <v-switch
                    v-model="props.item.useGuiding"
                    color="teal"
                    hide-details
                    class="justify-center"
                    @change="updateUser(props.item.id, 'useGuiding', $event)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-flex>
    </v-flex>

    <v-btn icon fixed bottom right fab color="primary" @click="clickAddUser">
      <v-icon>add</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AdminUserList',
  watch: {
    dialog() {
      this.editedItem.username = ''
      this.editedItem.password = ''
      this.editedItem.passwordCheck = ''
      this.editedItem.employeeID = ''
      this.editedItem.employee_typeID = ''
      this.$refs.form.resetValidation()
    },
    dialog_change_password() {
      this.new_password = ''

      this.$refs.form_change_password.resetValidation()
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Username',
          align: 'center',
          value: 'username',
        },
        { text: 'Employee Type', value: 'employee_typeID', align: 'center' },
        { text: 'Employee', value: 'employeeID', align: 'center' },
        {
          text: 'Password',
          align: 'center',
          value: '',
          sortable: false,
        },
        {
          text: '語音覆誦',
          align: 'center',
          value: '',
          sortable: false,
        },
      ],
      pagination: {
        rowsPerPage: -1,
        title: '',
        order: false,
      },
      show1: false,
      show2: false,
      new_password: '',
      rules: {
        existed: (value) =>
          !this.getUsernameList.includes(value) ||
          'That username is taken. Try another.',
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
      },
      search: '',
      tempItem: '',
      dialog: false,
      dialog_change_password: false,

      editedItem: {
        username: '',
        password: '',
        passwordCheck: '',
        employee_typeID: '',
        employeeID: -1,
      },
      switch1: false,
      snackbar: true,
      y: 'top',
      x: null,
      mode: '',
      timeout: 6000,
      text: 'PERMISSION_DENIED',
    }
  },
  computed: {
    ...mapGetters('users', ['getUsers', 'getUsernameList']),
    ...mapGetters('employeeTypes', ['getEmployeeTypesListFormat']),
    ...mapGetters('doctors', ['getDoctorsListFormat']),
    ...mapGetters('technicalStaffs', ['getTechnicalStaffsListFormat']),
  },
  created() {
    this.fetchUsers()
    this.fetchEmployeeTypes()
    this.fetchDoctors()
    this.fetchTechnicalStaffs()
  },
  methods: {
    ...mapActions('users', ['fetchUsers']),
    ...mapActions('employeeTypes', ['fetchEmployeeTypes']),
    ...mapActions('doctors', ['fetchDoctors']),
    ...mapActions('technicalStaffs', ['fetchTechnicalStaffs']),
    changeSort(column) {
      if (column === '') {
        return
      }
      if (this.pagination.title === column) {
        this.pagination.order = !this.pagination.order
      } else {
        this.pagination.title = column
        this.pagination.order = false
      }
      this.$store.dispatch('users/sortUser', {
        title: column,
        order: this.pagination.order,
      })
    },
    doSubmit(e) {
      e.preventDefault()
      return false
    },
    clickChangePassword() {
      this.dialog_change_password = true
    },
    changePassword() {
      this.updateUser(this.tempItem.id, 'password', this.new_password)
      this.dialog_change_password = false
    },
    changeItemEmployeeType(item) {
      let employeeID = -1
      if (item.employee_typeID === 1 || item.employee_typeID === 2) {
        employeeID = 1
      }
      item.employeeID = employeeID

      this.updateUser(item.id, 'employeeID', item.employeeID)
    },

    changeEmployeeType() {
      this.editedItem.employeeID = -1
    },
    updateUser(userID, field, value) {
      this.new_password = ''
      this.$store
        .dispatch('users/updateColumnById', {
          userID,
          field,
          value: field === 'useGuiding' ? +value : value,
        })
        .catch((err) => {
          this.snackbar = true
        })
    },

    clickAddUser() {
      this.dialog = true
    },
    clickItem(data) {
      this.tempItem = Object.assign({}, data)
    },
    close() {
      this.dialog = false
      this.dialog_change_password = false
    },

    async addUser() {
      try {
        let obj = {
          username: this.editedItem.username,
          password: this.editedItem.password,
          employee_typeID: this.editedItem.employee_typeID,
          employeeID: this.editedItem.employeeID,
        }

        await this.$store.dispatch('users/addUser', obj)
        this.dialog = false
      } catch (e) {
        console.log(e)
        this.dialog = false
      }
    },
  },
}
</script>

<style lang="scss"></style>
