<template>
  <div
    class="commandDisplay pos-relative"
    :class="{ [animationCommand]: commandQueueText }"
    v-if="commandQueueText || isRecording || isJudging"
  >
    <div class="text-align-center">
      <div class="wave-container" v-show="isRecording">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>
      <v-icon v-show="!isRecording" class="queue">keyboard_voice</v-icon>
      <h3 class="queue fsz-28">
        {{ commandQueueText }}
      </h3>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BasicExamVoiceCommandDisplay",
  data() {
    return {
      animationCommand: "",
      commandQueueText: "",
    };
  },
  computed: {
    ...mapGetters("examinations", [
      "getCommandQueue",
      "getStoreHiddenParameter",
    ]),
    isRecording() {
      return this.getStoreHiddenParameter.isRecording;
    },
    isJudging() {
      return this.getStoreHiddenParameter.isJudging;
    },
  },
  watch: {
    getCommandQueue(val) {
      if (val) {
        this.toggleQueueText(true);
      }
      this.commandQueueText = val.length ? val.join(" ") : "";
    },
    isRecording(val) {
      this.toggleQueueText(val);
      this.commandQueueText = "";
    },
  },
  methods: {
    toggleQueueText(status) {
      if (!this.commandQueueText) return;
      if (this.isRecording || this.isJudging) {
        this.animationCommand = "show";
      } else {
        this.animationCommand = status ? "show" : "hidden";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.commandDisplay {
  display: grid;
  place-items: center;
  background: linear-gradient(45deg, #3b587daa, #3e4c60);
  &.show {
    position: absolute;
    right: 0;
    width: 100%;
    // animation: rToleft 1.5s linear;
  }
  &.hidden {
    position: absolute;
    right: 0;
    width: 0;
    // animation: lToRight 1.5s linear;
  }
  .queue {
    color: cyan;
    letter-spacing: 2px;
  }
}

.wave-container {
  display: flex;
  justify-content: center;
}

.wave {
  width: 5px;
  height: 30px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 0 0.5rem;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

@for $i from 2 through 11 {
  .wave:nth-child(#{$i}) {
    animation-delay: $i * 0.1s;
  }
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes rToleft {
  0% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}

@keyframes lToRight {
  0% {
    width: 100%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}
</style>
