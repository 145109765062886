<template>
  <div>
    <form-select
      :selected="selectedArr"
      :items="chooseList"
      :column="
        data.antispasmodicHave ? 'antispasmodicID' : 'antispasmodicReason'
      "
      other-column="antispasmodicOther"
      :other-value="data.antispasmodicOther"
      @change="handleChange"
    >
      <template #header-sub>
        <p class="mb-0">{{ $t('common.premedication.title') }}</p>
        <div>
          <v-switch
            :label="hasText"
            v-model="data.antispasmodicHave"
            @change.capture="selectUseOrNot($event)"
            hide-details
            class="py-0 mt-0"
          ></v-switch>
        </div>
      </template>
    </form-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formSelect from './form-select.vue'

export default {
  name: 'AntispasmodicInput',
  components: {
    formSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hasText: this.$t('common.antispasmodics.off'),
      selected: this.data.antispasmodicIDs,
      reasonSelected: this.data.antispasmodicReason,
      othersSelected: false,
    }
  },

  computed: {
    ...mapGetters('examinations', [
      'getAntispasmodicList',
      'getNoUseAntipasmodicReasonList',
    ]),
    getUseReasons() {
      return this.getAntispasmodicList
        .map((item) => ({ text: item.antispasmodic, value: item.id }))
        .concat({ text: this.$t('common.other'), value: this.getAntispasmodicList.length + 1 })
    },
    getNoUseReasons() {
      return this.getNoUseAntipasmodicReasonList.concat({
        text: this.$t('common.other'),
        value: this.getNoUseAntipasmodicReasonList.length + 1,
      })
    },
    selectedArr: {
      get() {
        return this.data.antispasmodicHave ? this.selected : this.reasonSelected
      },
      set(val) {
        if (this.data.antispasmodicHave) {
          this.selected = [...val]
        } else {
          this.reasonSelected = [...val]
        }
        console.log(val)
      },
    },
    chooseList() {
      return this.data.antispasmodicHave
        ? this.getUseReasons
        : this.getNoUseReasons
    },
  },
  methods: {
    selectOther(value) {
      this.$emit('change', { field: 'antispasmodicOther', event: value })
      this.othersSelected = value && value.length > 0
    },
    selectNotUse() {
      this.$emit('change', {
        field: 'antispasmodicReason',
        event: this.reasonSelected.join(','),
      })
    },
    selectUse() {
      this.$emit('change', {
        field: 'antispasmodicID',
        event: this.selected.join(','),
      })
    },
    selectUseOrNot(value) {
      this.hasText = value ? this.$t('common.antispasmodics.on') : this.$t('common.antispasmodics.off')
      this.$emit('change', { field: 'antispasmodicHave', event: value })
    },
    handleChange(column, value) {
      if (!column) return
      if (column === 'antispasmodicOther') {
        this.selectOther(value)
      } else {
        if (this.data.antispasmodicHave) {
          this.selectedArr = value
          this.selectUse()
        } else {
          this.selectedArr = value
          this.selectNotUse()
        }
      }
    },
  },
  mounted() {
    if (this.data.antispasmodicHave == null) {
      this.selectUseOrNot(false)
    } else {
      this.hasText = this.data.antispasmodicHave ? this.$t('common.antispasmodics.on') : this.$t('common.antispasmodics.off')
    }
    this.othersSelected =
      this.data.antispasmodicOther && this.data.antispasmodicOther.length > 0
  },
}
</script>
