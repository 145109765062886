<template>
  <v-select
    :label="label"
    :items="getSelectItems"
    :hide-details="shouldHideDetails"
    :value="data"
    @change="$emit('change', $event)"
  ></v-select>
</template>

<script>
  import { mapGetters } from 'vuex'

	export default {
		name: 'CleanMedInput',

		props: {
      data: {
        type: [Array],
        required: true
      },

      hideDetails: {
        type: Boolean,
        required: false,
        default: false
      }
    },

		data () {
			return {
        field: 'clean_medID',
        label: 'Preparation Agent'
			}
		},

    computed: {
      ...mapGetters('examinations', [
        'getCleanMedList'
      ]),

      getSelectItems () {
        return this.getCleanMedList.map(item => {
          return {
            text: item.clean_med,
            value: item.id
          }
        })
      },

      shouldHideDetails () {
        return this.hideDetails
      }
    }
	}
</script>