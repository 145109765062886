var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"basicExamFindingRecord"},[[(
        _vm.getFirstLocationItem ||
        _vm.getDescOptions('size') ||
        _vm.getGastroDescSelectInput.length
      )?_c('ul',{staticClass:"pl-0 basicExamFindingRecord__descript"},[_vm._l((_vm.getFirstLocationItem),function(item,key){return _c('BasicRecordFieldItem',_vm._b({key:key,class:[
          {
            'basicExamFindingRecord__descript--fullhalf':
              (_vm.getDescOptions('size') && key !== 'dist') ||
              (_vm.EXAMINATION_TYPE_OF_GASTROSCOPY === _vm.examType &&
                key === 'loca'),
          },
          {
            'basicExamFindingRecord__descript--fullfull':
              Object.keys(_vm.getFirstLocationItem).length === 1 &&
              _vm.getGastroDescSelectInput.length === 0 &&
              !_vm.getDescOptions('size'),
          },
          {
            'basicExamFindingRecord__descript--fullhalf':
              Object.keys(_vm.getFirstLocationItem).length === 2 &&
              _vm.getGastroDescSelectInput.length === 0 &&
              !_vm.getDescOptions('size'),
          },
        ],attrs:{"data-test":"desc-location","tag":"li","inputType":item.inputType,"title":item.title,"value":item.value,"options":item.questionID && _vm.getLocationQuestion(item.questionID),"disabled":!item.questionID},on:{"change":function($event){return _vm.$emit('locationChange', {
            value: $event,
            column: key,
            id: item.locationId,
          })}}},'BasicRecordFieldItem',item.option,false))}),(_vm.getDescOptions('size'))?_c('BasicRecordFieldItem',{class:{
          'basicExamFindingRecord__descript--fullhalf':
            _vm.getFirstLocationItem &&
            Object.keys(_vm.getFirstLocationItem).length === 1 &&
            !_vm.getGastroDescSelectInput.length,
        },attrs:{"tag":"li","inputType":"text","title":"Size","suffix":"cm","textType":"number","value":_vm.getDescOptions('size') && _vm.getDescOptions('size').value},on:{"change":function($event){return _vm.$emit('change', {
            newValue: $event,
            ..._vm.getSize.config,
          })}}}):_vm._e(),(_vm.EXAMINATION_TYPE_OF_GASTROSCOPY === _vm.examType)?_vm._l((_vm.getGastroDescSelectInput),function(desc){return _c('BasicRecordFieldItem',{key:desc.field,staticClass:"h-100",class:[
            {
              'basicExamFindingRecord__descript--fullhalf':
                !_vm.getFirstLocationItem ||
                (_vm.getFirstLocationItem &&
                  Object.keys(_vm.getFirstLocationItem).length === 1),
            },
            {
              'basicExamFindingRecord__descript--fullfull':
                !_vm.getFirstLocationItem &&
                _vm.getGastroDescSelectInput.length === 1,
            },
          ],attrs:{"data-test":"gastro-desc-specific","tag":"li","inputType":"selectInput","title":desc.title,"value":desc.items.value,"options":_vm.generateQList(desc.items.config.options)},on:{"change":function($event){return _vm.$emit('change', {
              newValue: $event,
              ...desc.items.config,
            })}}})}):_vm._e()],2):_c('section',{staticClass:"bg-light w-100 h-100",attrs:{"data-test":"descript--empty"}})],_c('ul',{staticClass:"pl-0 basicExamFindingRecord__intervention"},[(_vm.getIntervenOptions('intervention'))?_c('BasicRecordFieldItem',{attrs:{"title":"Intervention","tag":"li","bgColor":"bg-warning-1","inputType":"selectInput","value":_vm.getIntervenOptions('intervention').value,"options":_vm.generateQList(_vm.getIntervenOptions('intervention').config.options)},on:{"change":function($event){_vm.$emit('change', {
          newValue: $event,
          ..._vm.getIntervenOptions('intervention').config,
        })}}}):_vm._e(),_c('BasicRecordFieldItem',{attrs:{"tag":"li","inputType":"text","title":"Specimen Label","bgColor":"bg-warning-1","disabled":!_vm.getSpecimenLabel,"value":_vm.getSpecimenLabel && _vm.getSpecimenLabel.value},on:{"change":function($event){return _vm.$emit('change', {
          newValue: $event,
          ..._vm.getSpecimenLabel.config,
        })}}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }