<template>

  <v-layout v-if="examinationData" row wrap fill-height justify-center class="pa-0">
    <v-flex xs10 class="pt-0 mt-4">
      <h2 class="examination-info headline font-weight-light mb-4">{{ examinationData.patientName }}</h2>
      <div class="pa-4">
        <report-time :eid="examinationID" position="static" constant small />
        <div class="examinationInfoHeader text-uppercase"><span class="font-weight-light">{{ $t('homepage.date') }}</span> {{ examinationData.date }}</div>
        <div class="examinationInfoHeader text-uppercase"><span class="font-weight-light">{{ $t('report_preview.recordNo') }}</span> {{ examinationData.accNo }}</div>
        <div class="examinationInfoHeader text-uppercase"><span class="font-weight-light">{{ $t('homepage.medical_records') }}</span> {{ examinationData.medicalRecord }}</div>
        <div class="examinationInfoHeader text-uppercase"><span class="font-weight-light">{{ $t('homepage.gast.birth') }}</span> {{ examinationData.birthday }}</div>
        <div class="examinationInfoHeader text-uppercase"><span class="font-weight-light">{{ $t('homepage.gast.age') }}</span> {{ getAge(examinationData.birthday) }}</div>
        <div class="examinationInfoHeader text-uppercase"><span class="font-weight-light">{{ $t('homepage.gast.gender') }}</span> {{ getGender(examinationData.gender) }}</div>
      </div>

      <v-layout class="examination-info-form" v-if="examinationData.examination_typeID === 1">
        <v-flex xs12>
          <!-- <v-select v-model="examinationData.examination_typeID" 
                    label="Examination Type" 
                    :items="getExaminationTypeList" 
                    item-value="id" 
                    item-text="examination_type" 
                    @change="setExaminationColumn('examination_typeID', $event)" hide-details class="mb-3">
          </v-select> -->
          <v-select v-model="examinationData.inspection_instrumentID" 
                    :label="$t('homepage.gast.scope_id')"
                    :items="getInspectionInstrumentList" 
                    item-value="id" 
                    item-text="inspection_instrument" 
                    @change="setExaminationColumn('inspection_instrumentID', $event)" hide-details class="mb-3">
          </v-select>
          <v-select v-model="examinationData.wash_instrumentID" 
                    :label="$t('homepage.gast.washing_machine')"
                    :items="getWashInstrumentList" 
                    item-value="id" 
                    item-text="wash_instrument" 
                    @change="setExaminationColumn('wash_instrumentID', $event)" hide-details class="mb-3">
          </v-select>
          <v-select v-model="examinationData.examinationDoctorID" 
                    :label="$t('homepage.gast.doctor')"
                    :items="getDoctorList" 
                    item-value="id" 
                    item-text="name" 
                    @change="setExaminationColumn('examinationDoctorID', $event)" hide-details class="mb-4">
          </v-select>
          <v-select v-model="examinationData.technical_staffID_1" 
                    :label="$t('homepage.gast.nurse')"
                    :items="getTechnicalStaffList" 
                    item-value="id" 
                    item-text="name" 
                    @change="setExaminationColumn('technical_staffID_1', $event)" hide-details class="mb-4">
          </v-select>
          <v-select v-model="examinationData.technical_staffID_2" 
                    :label="$t('homepage.gast.nurse')"
                    :items="getTechnicalStaffList" 
                    item-value="id" 
                    item-text="name" 
                    @change="setExaminationColumn('technical_staffID_2', $event)" hide-details class="mb-3">
          </v-select>
        </v-flex>
      </v-layout>
      
      <!-- 藥品 -->
      <template v-if="examinationData.examination_typeID !== 1">
        <HpaInfo
          :examinationData="getExaminationData.examination"
          @change="setExaminationColumn"
        />
      </template>
      
    </v-flex>

    <v-flex id="home-examination-info-buttons" xs12 justify-center class="text-xs-center">
      <v-btn color="secondary" @click="goToPage('/reports', examinationID)">{{ $t('homepage.gast.go_to_report') }}</v-btn>
      <v-btn color="primary" @click="redirectDetail(examPageName)">{{ $t('homepage.gast.start_examination') }}</v-btn>
    </v-flex>
  </v-layout>
  
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import common from '@/utils/common'
  import ReportTime from '@/components/ReportTime'
  import AntispasmodicInput from '@/components/report/AntispasmodicInput.vue'
  import SedativeContent from '@/components/report/SedativeContent.vue'
  import FormSelect from '@/components/report/form-select'
  import { CLINIC_LEVEL } from '@/utils/examinations.config.js'
  import ExamSelect from '../components/home/ExamSelect.vue'
  import HpaInfo from '../components/home/HpaInfo.vue'
  
  export default {
    components: {
      ReportTime,
      AntispasmodicInput,
      SedativeContent,
      FormSelect,
      ExamSelect,
      HpaInfo
    },
    data() {
      return {
        examinationID: this.$route.params.examinationID,
        examinationData : this.$store.state.examinations.examinations.find(d => d.id == this.$route.params.examinationID)
      }
    },  
    computed: {
      ...mapGetters(['getUser']),
      ...mapGetters('examinations', [
        'getExaminationData',
        'getExaminationTypeList',
        'getDoctorList',
        'getTechnicalStaffList',
        'getInspectionInstrumentList',
        'getWashInstrumentList',
      ]),
      examPageName () {
        return this.getUser.level === CLINIC_LEVEL ? 'ClinicExaminationDetail' : 'ExaminationDetail'
      },
    },
    methods: {
      ...mapActions('examinations', ['updateExaminationColumn']),
      goToPage (url, param) {
        this.$router.push(`${url}/${param}/accNo/${this.examinationData.accNo}`)
      },
      redirectDetail (name) {
        this.$router.push({
          name,
          params: {
            examinationID: this.examinationID,
            accNo: this.examinationData.accNo
          }
        })
      },
      setExaminationColumn(column, value){
        let d = {
          id: this.examinationID,
          column: column,
          value: value
        }
        this.updateExaminationColumn(d)
      },
      getGender: common.getGender,
      getAge: common.calculageAge,
      fetchOtherList (id)  {
        this.$store.dispatch('examinations/fetchExamAndOther', {
          examinationID: id,
          accNo: 0
        })
      }
    },
    watch: {
      '$route' (to) {
        this.examinationID = to.params.examinationID
        this.examinationData = this.$store.state.examinations?.examinations.find(d => d.id == this.$route.params.examinationID)
        this.fetchOtherList(to.params.examinationID)
      },      
    },
    created () {
      if (this.examinationID && !this.examinationData) {
        this.$router.replace('/home')
      }
      this.examinationID && this.fetchOtherList(this.examinationID)
    },
  }
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 20px !important;
    text-transform: capitalize;
  }

  .examinationInfoHeader {
    padding: 4px 0 8px;

    span {
      display: inline-block;
      text-align: left;
      margin-right: 10px;
    }
  }

  .examinationInfoHeader div {
    line-height: 26px;
  }

  .examination-info-form {
    margin: 24px 0 8px;
    padding: 24px 0;
    border-top: 1px solid rgba(0,0,0,0.12);
  }

  #home-examination-info-buttons {
    margin: 24px 0 48px;
    .theme--light.v-btn {
      color: white;
    }
  }

  .headline {
    text-align: center;
  }
</style>