<style lang="scss">
.examination-form {
  &__window {
    height: calc(100% - 44px);
    border-radius: 0 4px 4px 4px;

    .v-window__container {
      height: 100%;
      overflow-y: auto;
    }
  }
}
</style>

<template>
  <v-form class="fill-height">
    <div :class="isMobile ? 'px-3' : 'px-4'">
      <v-item-group v-model="current" mandatory>
        <v-item v-for="window in windows" :key="window.value">
          <template #default="{ active, toggle }">
            <v-btn
              :input-value="active"
              large
              depressed
              class="ma-0 pa-4"
              @click="toggle"
            >
              {{ $t(`report.${window.text}`) }}
            </v-btn>
          </template>
        </v-item>
      </v-item-group>
    </div>
    <v-window v-model="current" class="examination-form__window">
      <v-window-item class="fill-height">
        <ExaminationInfo :id="id" />
      </v-window-item>
      <v-window-item class="fill-height">
        <FindingsFormDetails />
      </v-window-item>
    </v-window>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormSelect from './form-select'
import SedativeInputGroup from './SedativeInputGroup.vue'
import AntispasmodicInput from './AntispasmodicInput.vue'
import FindingsFormDetails from './FindingsFormDetails.vue'
import ExaminationInfo from './ExaminationInfo.vue'
import ExaminationTimeField from './ExaminationTimeField.vue'

export default {
  name: 'ExaminationForm',
  components: {
    FormSelect,
    SedativeInputGroup,
    AntispasmodicInput,
    FindingsFormDetails,
    ExaminationInfo,
    ExaminationTimeField,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      additionalRemarksValue: '',
      examViewMode: 'full',
      current: 0,
      windows: [
        { text: 'exam_info', value: 0 },
        { text: 'findings', value: 1 },
      ],
      dialog: true,
      day: '',
    }
  },
  computed: {
    ...mapGetters('examinations', [
      'getExaminationData',
      'getIndicationList',
      'getCleanMedList',
      'getAntiplateletList',
      'getCleanScaleList',
      'getIncompleteReasonList',
      'getInspectionInstrumentList',
      'getWashInstrumentList',
      'getInsertionLevelList',
      'getComplicationList',
      'getSedatives',
    ]),
    getAntispasmodicValues() {
      const {
        antispasmodicHave,
        antispasmodicID,
        antispasmodicOther,
        antispasmodicReason,
      } = this.getExaminationData.examination
      return {
        antispasmodicHave: Number(antispasmodicHave) || null,
        antispasmodicIDs: antispasmodicID,
        antispasmodicOther,
        antispasmodicReason,
      }
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.name === 'xs' ||
        this.$vuetify.breakpoint.name === 'sm'
      )
    },
  },
  methods: {
    ...mapActions('examinations', ['updateExaminationColumn']),
    formSelectDataFormat(arr, valueKey, textKey) {
      return arr.map((item) => ({ text: item[textKey], value: item[valueKey] }))
    },
    change(column, value) {
      this.updateExaminationColumn({ id: this.id, column, value })
    },
  },
}
</script>
