import { inspectionInstrumentsServices } from '@/services/db'

const state = {
  inspectionInstruments: [],
  inspectionInstrumentsListFormat: []
}

// getters
const getters = {
  getInspectionInstruments: state => state.inspectionInstruments,
  getInspectionInstrumentsListFormat: state => state.inspectionInstrumentsListFormat,
}

// actions
const actions = {

  async fetchInspectionInstruments ({commit}, payload) {
    try {
      commit('setLoading', true, { root: true })

      const res = await inspectionInstrumentsServices.fetchInspectionInstruments(payload)
      const list = []
      res.forEach((v,i) => {
        list.push({text:v.inspection_instrument,value:v.id})  
      })
    
      commit('setInspectionInstrumentsListFormat', list)
      commit('setInspectionInstruments', res)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit}, payload) {
    /*************************************
    * ID: inspectionInstrument Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    
    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      const res = await inspectionInstrumentsServices.updateInspectionInstrumentColumn(payload)

      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addInspectionInstrument ({commit}) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new inspectionInstrument */
      /* code here */

      let obj = {
        name: 'New Inspection Instrument'
      }

      const res = await inspectionInstrumentsServices.addInspectionInstrument(obj)
      const data = await Promise.resolve([
        ...state.inspectionInstruments,
        res.data
      ])
      commit('setInspectionInstruments', data)
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortInspectionInstrument({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.inspectionInstruments.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.inspectionInstruments.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setInspectionInstruments', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetInspectionInstruments (state) {
    state.inspectionInstruments = []
  },

  setInspectionInstruments (state, payload) {
    state.inspectionInstruments = payload
  },

  setInspectionInstrumentsListFormat (state, payload) {
    state.inspectionInstrumentsListFormat = payload
  }

  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}