<template>
  <v-container
    v-if="getExaminationData && getExaminationData.examination"
    id="print"
    class="report-preview"
    style="max-width: 1200px"
  >
    <div class="text-xs-right noprint">
      <v-btn color="primary" @click="() => $router.push(`/reports/${examinationID}/accNo/${accNo}`)">
        {{ $t('report_preview.back_to') }}
      </v-btn>
    </div>

    <v-flex>
      <preview-navigation
        :isColonReport="isColonReport"
        @previewCategory="getReportCategory"
        @getImgArrange="setImgArrange"
      />

      <preview-print-template>
        <template #header>
          <preview-header :title="reportTitle" />
          <!-- 檢驗者資料 -->
          <ul class="client-info" v-if="reportCategory != 'quality'">
            <li class="client-info__li" v-for="info in clientInfo" :key="info.key">
              <h3 class="client-info__title">{{ $t(`report_preview.${info.key}`) | appendColonSymbol }}</h3>
              <p class="client-info__text">{{ info.value }}</p>
            </li>
          </ul>
        </template>
        <template #content>
          <colon-quality-report
            v-if="isColonReport && reportCategory == 'quality'"
            :examinationID="examinationID"
            :examination="getExaminationData"
          />

          <SummaryEnReport
            v-if="reportCategory == 'summary' || reportCategory == 'en'"
            :isFullMode="reportCategory === 'en'"
            :isColonReport="isColonReport"
            :findings="getOrderArticle"
            :examination="getExaminationData"
            :sedatives="getSedativeList"
            :imgArrange="imgArrange"
          />

          <!-- 頁尾 -->
          <section class="printFooter border-t py-3">
            <v-layout class="bottom" wrap row px-0 justify-space-between>
              <v-flex class="flex-grow-0">
                <p>
                  <b>{{ $t('report_preview.report_date') | appendColonSymbol }} </b>
                  {{ reportTime }}
                </p>
              </v-flex>
              <v-flex class="flex-grow-0" v-if="isShow(getDoctor(getExaminationData.examination.examinationDoctorID))">
                <p>
                  <b>{{ $t('vs') | appendColonSymbol }}</b>
                  {{ getDoctor(getExaminationData.examination.examinationDoctorID) }}
                </p>
              </v-flex>
              <v-flex
                class="flex-grow-0"
                v-if="isShow(getTechnicial(getExaminationData.examination.technical_staffID_1))"
              >
                <p>
                  <b>{{ $t('report_preview.technician') | appendColonSymbol }} </b
                  >{{ getTechnicial(getExaminationData.examination.technical_staffID_1) }}
                </p>
              </v-flex>
              <v-flex
                class="flex-grow-0"
                v-if="isShow(getTechnicial(getExaminationData.examination.technical_staffID_2))"
              >
                <p>
                  <b>{{ $t('report_preview.technician') | appendColonSymbol }}</b
                  >{{ getTechnicial(getExaminationData.examination.technical_staffID_2) }}
                </p>
              </v-flex>
            </v-layout>
          </section>
        </template>
      </preview-print-template>
    </v-flex>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PreviewNavigation from '@/components/preview/PreviewNavigation.vue'
import PreviewHeader from '@/components/preview/PreviewHeader.vue'
import PreviewPrintTemplate from '@/components/preview/PreviewPrintTemplate.vue'
import ColonQualityReport from '@/components/preview/ColonQualityReport.vue'
import SummaryEnReport from '../components/preview/SummaryEnReport.vue'
import moment from 'moment'
import common from '@/utils/common'
import { EXAMINATION_TYPE_OF_GASTROSCOPY, EXAMINATION_TYPE_OF_SIGMOIDOSCOPY } from '../utils/examinations.config'

export default {
  name: 'ReportPreview',
  components: {
    ColonQualityReport,
    PreviewHeader,
    PreviewNavigation,
    PreviewPrintTemplate,
    SummaryEnReport
  },
  watch: {
    getExaminationData() {
      this.isColonReport =
        this.getExaminationData.examination.examination_typeID != EXAMINATION_TYPE_OF_GASTROSCOPY &&
        this.getExaminationData.examination.examination_typeID != EXAMINATION_TYPE_OF_SIGMOIDOSCOPY
    },
    examType(etype) {
      if (this.getSetting.descriptive) {
        this.fetchDescriptiveParagraph({
          eid: this.examinationID,
          etype,
        })
      }
    }
  },
  filters: {
    appendColonSymbol(str) {
      return str ? `${str} :` : ''
    },
  },
  data() {
    return {
      reportTime: moment().format('YYYY/MM/DD'),
      reportCategory: 'summary',
      imgArrange: 3, // 圖片的排列方式 = 1,2,3
      isColonReport: false,
    }
  },
  computed: {
    ...mapGetters(['getSetting']),
    ...mapGetters('examinations', [
      'getExaminationData',
      'getSedativeList',
      'getDoctorList',
      'getTechnicalStaffList',
      'getExaminationTypeList',
      'getPatient',
      'getOrderArticle',
    ]),
    examinationID() {
      if (Number(this.$route.params.examinationID) != 0) {
        return this.$route.params.examinationID
      } else {
        if (this.getExaminationData != null) {
          return this.getExaminationData.examination.id
        }
      }
    },
    getExamType() {
      return (id) => {
        let examType = this.getExaminationTypeList.find((item) => item.id === id)
        return examType.examination_type
      }
    },
    accNo() {
      return Number(this.$route.params.accNo) != 0 ? this.$route.params.accNo : 0
    },
    clientInfo() {
      return [
        { key: 'name', value: this.getPatient.name },
        { key: 'gender', value: this.getGender(this.getPatient.gender) },
        { key: 'age', value: this.getAge(this.getPatient.birthday, 'YYYY-MM-DD') },
        { key: 'recordNo', value: this.getPatient.no },
        {
          key: 'examDate',
          value: this.getExaminationData.examination.date.replace(/-/gi, '/'),
        },
        { key: 'examTime', value: this.getExaminationData.examination.time_start },
        {
          key: 'examItem',
          value: this.$t(`report_preview.${this.getExamType(this.getExaminationData.examination.examination_typeID)}CheckNo`),
        },
      ]
    },
    examType() {
      if (this.getExaminationData) {
        return this.getExaminationData.examination.examination_typeID
      }
      return 0
    },
    reportTitle() {
      return this.reportCategory !== 'quality'
        ? this.$t(`report_preview.${this.getTypeName()}`)
        : this.$t('report_preview.ai')
    },
  },
  methods: {
    ...mapActions('examinations', [
      'fetchExaminationDetailAndOtherList',
      'fetchOrderArticle',
      'fetchDescriptiveParagraph',
    ]),
    getGender: common.getGender,
    getAge: common.calculageAge,
    setImgArrange(n) {
      this.imgArrange = n
    },
    getReportCategory(category) {
      this.reportCategory = category
    },
    getDoctor(id) {
      const doctor = this.getDoctorList.filter((item) => item.id === id)
      if (doctor.length > 0) {
        return doctor[0].name
      }
      return ''
    },
    getTechnicial(id) {
      const technician = this.getTechnicalStaffList.filter((item) => item.id === id)
      if (technician.length > 0) {
        return technician[0].name
      }
      return ''
    },
    getTypeName() {
      const typeID = this.getExaminationData.examination.examination_typeID
      return this.getExaminationTypeList.filter((item) => item.id === typeID)[0].examination_type
    },
    isShow(data) {
      let result = true
      if (data == 'Choose' || data == '') {
        result = false
      }
      return result
    },
  },
  created() {
    this.fetchExaminationDetailAndOtherList({
      examinationID: this.examinationID,
      accNo: this.accNo,
    })
    this.fetchOrderArticle({ examinationID: this.examinationID })
    if (this.getSetting.descriptive) {
      this.fetchDescriptiveParagraph({
        eid: this.examinationID,
        etype: this.examType,
      })
    }
  },
}
</script>

<style lang="scss" scoped>
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.d-flex > * {
  flex-grow: 0 !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.theme--light.application {
  background: #ffffff;
}

.client-info {
  padding-bottom: 12px;
  border-bottom: 1px solid grey;
  display: grid;
  grid-template-columns: 25% 25% 24% 26%;
  &__li {
    display: flex;
    margin-bottom: 1px;
    :nth-child(2n + 1) {
      padding-left: 5px;
    }
  }
  &__title {
    white-space: nowrap;
  }
  &__text {
    padding-top: 2px;
    padding-left: 5px;
    align-self: flex-start;
  }
}

.report-preview .v-btn__content-btn, .report-preview .v-btn{
  text-transform: initial !important;
}

@media print {
  main.v-content {
    padding: 4.8rem 0 0 !important;
  }

  .noprint {
    display: none;
  }

  #nav,
  #gastroPreviewBowelCleanseForms {
    display: none;
  }

  .report-preview {
    padding: 0;
  }

  .layout {
    page-break-inside: avoid;
    break-inside: avoid;
  }
  .location-warp {
    break-inside: avoid;
  }
}
</style>
