<template>
	<v-tooltip bottom nudge-right="30">
    <v-btn 
      :class="className" 
      icon 
      :color="color" 
      slot="activator"
      @click="$emit('click')"
      :absolute="absolute"
      :flat="flat"
      :small="small"
    >
      <v-icon>{{ iconName }}</v-icon>
    </v-btn>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
	export default {
		name: 'ButtonWithTooltip',

		props: {
      tooltipText: String,
      className: String,
      iconName: String,
      color: String,
      absolute: Boolean,
      flat: Boolean,
      small: Boolean
    },

		data () {
			return {
				
			}
		},

		computed: {
      
		},

		methods: {
      
		}
	}
</script>