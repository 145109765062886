import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { zh } from './zh'
import { en } from './en'
import { ja } from './ja'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'ja',
  fallbackLocale: 'en',
  messages: {
    en,
    zh,
    ja,
  },
})
