import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import SignIn from './views/SignIn.vue'
import Home from './views/Home.vue'
import HomeExaminationInfo from './views/HomeExaminationInfo.vue'
import EmptyExaminationInfo from './views/EmptyExaminationInfo.vue'
import ExaminationDetail from './views/ExaminationDetailPage.vue'
import ClinicExaminationDetail from './views/ExaminationBasicDetailPage.vue'

import ReportPreview from './views/ReportPreview.vue'
import ReportDetail from './views/ReportDetailPage.vue'
import Print from './views/Print.vue'
const TestPage = () => import(/* webpackChunkName: "test" */ './views/TestPage')

import AdminOrganizationList from './views/admin/OrganizationList'
import AdminDoctorList from './views/admin/DoctorList'
import AdminEmployeeTypeList from './views/admin/EmployeeTypeList'
import AdminExaminationList from './views/admin/ExaminationList2'
import AdminExaminationTypeList from './views/admin/ExaminationTypeList'
import AdminLandmarkList from './views/admin/LandmarkList'
import AdminInspectionInstrumentList from './views/admin/InspectionInstrumentList'
import AdminWashInstrumentList from './views/admin/WashInstrumentList'
import AdminAntiplateletList from './views/admin/AntiplateletList'
import AdminAntispasmodicList from './views/admin/AntispasmodicList'
import AdminCleanMedList from './views/admin/CleanMedList'
import AdminSedativeList from './views/admin/SedativeList'
import AdminPatientList from './views/admin/PatientList2'
import AdminTechnicalStaffList from './views/admin/TechnicalStaffList'
import AdminUserList from './views/admin/UserList'
import AdminAnalytics from './views/admin/Analytics'
import AdminAnalyticsSearch from './views/admin/AnalyticsSearch'
import AdminAnalyticsPDR from './views/admin/AnalyticsPDR'
import AdminAnalyticsSixMin from './views/admin/AnalyticsSixMin'
import AdminAnalyticsIntervention from './views/admin/AnalyticsIntervention'
import AdminAnalyticsUsage from './views/admin/AnalyticsUsage'

import { store } from './store/index'
import routesUtils from '@/utils/routes'
import { CLINIC_LEVEL } from '@/utils/examinations.config.js'

Vue.use(Router)
Vue.use(Meta, {
  name: 'google-site-verification',
  content: '0-5MfY6ZO4XXkIE-y0mFs7vPcgmCFy7TJMskeNNqNCw'
})

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'SignIn',
      component: SignIn,
    },
    {
      path: '/test',
      name: 'test',
      component: TestPage,
      meta: { privilege: true },
    },
    {
      path: '/home',
      component: Home,
      children: [
        {
          path: '',
          component: EmptyExaminationInfo,
          meta: { privilege: true },
        },
        {
          path: ':examinationID',
          component: HomeExaminationInfo,
          meta: { privilege: true },
        },
      ],
    },
    {
      path: '/reports/:examinationID/accNo/:accNo',
      name: 'ReportDetail',
      component: ReportDetail,
      meta: { privilege: true },
    },
    {
      path: '/reports/:examinationID/accNo/:accNo/print',
      name: 'print',
      component: Print,
      meta: { privilege: true },
    },
    {
      path: '/reports/:examinationID/accNo/:accNo/preview',
      name: 'ReportPreview',
      component: ReportPreview,
      meta: { privilege: true },
    },
    {
      path: '/examinations/:examinationID/accNo/:accNo',
      name: 'ExaminationDetail',
      component: ExaminationDetail,
      meta: { privilege: true, examCheck: true },
    },
    {
      path: '/clinic/:examinationID/accNo/:accNo',
      name: 'ClinicExaminationDetail',
      component: ClinicExaminationDetail,
      meta: { privilege: true, examCheck: true },
    },

    ...routesUtils.withPrefix('/admin/', [
      {
        path: 'organization-list',
        component: AdminOrganizationList,
      },
      {
        path: 'doctor-list',
        component: AdminDoctorList
      },
      {
        path: 'employeeType-list',
        component: AdminEmployeeTypeList
      },
      {
        path: 'examination-list',
        component: AdminExaminationList
      },
      {
        path: 'examinationType-list',
        component: AdminExaminationTypeList
      },
      {
        path: 'landmark-list',
        component: AdminLandmarkList
      },
      {
        path: 'inspectionInstrument-list',
        component: AdminInspectionInstrumentList
      },
      {
        path: 'washInstrument-list',
        component: AdminWashInstrumentList
      },
      {
        path: 'antiplatelet-list',
        component: AdminAntiplateletList
      },
      {
        path: 'antispasmodic-list',
        component: AdminAntispasmodicList
      },
      {
        path: 'cleanMed-list',
        component: AdminCleanMedList
      },
      {
        path: 'sedative-list',
        component: AdminSedativeList
      },
      {
        path: 'patient-list',
        component: AdminPatientList
      },
      {
        path: 'technicalStaff-list',
        component: AdminTechnicalStaffList
      },
      {
        path: 'user-list',
        component: AdminUserList
      },
      {
        path: 'analytics',
        component: AdminAnalytics,
        children: [
          { path: 'search', component: AdminAnalyticsSearch },
          { path: 'pdr', component: AdminAnalyticsPDR },
          { path: 'six-min', component: AdminAnalyticsSixMin },
          { path: 'intervention', component: AdminAnalyticsIntervention },
          { path: 'usage', component: AdminAnalyticsUsage },
          { path: '', redirect: '/admin/analytics/search' }
        ]
      }
    ])
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.privilege) {
    if (!store.getters.getUser) {
      const sessionUser = sessionStorage.getItem('user')
      if (sessionUser) {
        store.dispatch('setUser', JSON.parse(sessionUser))
      } else {
        next('/')
      }
    }
    if (to.meta.examCheck) {
      const clinic= 'ClinicExaminationDetail'
      const normal = 'ExaminationDetail'
      const validExamName = store.getters.getUser.level === CLINIC_LEVEL ? clinic: normal

      if (validExamName !== to.name) {
        next({name: validExamName})
      }
    }
  } else if (to.name === 'SignIn') {
    store.dispatch('setUser', null)
  }
  next()
})

export default router
