<template>
  <div class="py-3">
    <h2>適應症</h2>
    <ul class="py-2">
      <li
        v-for="m in mainIndication"
        :key="m.value"
        class="d-flex align-center --cursor"
        @click="pickItem({ group: m.value })"
      >
        <v-chip
          label
          :outline="pick.group !== m.value"
          class="flex-grow-0"
          :color="pick.group === m.value ? 'success' : ''"
          >{{ m.value }}</v-chip
        >
        <p
          class="mb-0 fsz-18"
          :class="{ 'text-primary font-weight-bold': pick.group === m.value }"
        >
          {{ m.text }}
        </p>
      </li>
    </ul>
    <hr />
    <p class="px-4 py-2 mb-0">其他臨床目的施行之大腸鏡</p>
    <ul>
      <li
        class="d-flex align-center --cursor mb-2"
        v-for="o in otherIndication"
        :key="o.value"
        @click="pickItem({ group: o.group, item: o.value })"
      >
        <v-chip
          label
          :outline="!pick.item.includes(o.value)"
          class="flex-grow-0"
          :color="pick.item.includes(o.value) ? 'success' : ''"
          >{{ o.group }}{{ o.value }}</v-chip
        >
        <p
          class="mb-0 fsz-18"
          :class="{
            'text-primary font-weight-bold': pick.item.includes(o.value),
          }"
        >
          {{ o.text }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/utils/common'

export default {
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      pick: {
        group: null,
        item: [],
      },
      gOther: '9',
    }
  },
  computed: {
    ...mapGetters('examinations', ['getIndicationList']),
    mainIndication() {
      return this.getIndicationList.filter(
        (indication) => indication.group !== this.gOther
      )
    },
    otherIndication() {
      return this.getIndicationList.filter(
        (indication) => indication.group === this.gOther
      )
    },
  },
  methods: {
    ...mapActions('examinations', ['updateExaminationColumn']),
    pickItem({ group, item }) {
      this.handleIndication(`${group}${item||''}`)
    },
    handleIndication(arr) {
      this.$emit('change', arr)
    },
    setInit(item) {
      this.pick.group = item.filter((i) => !isNaN(i))[0]
      this.pick.item = item.filter((i) => isNaN(i))
    },
  },
  watch: {
    value(val) {
      this.setInit(val)
    },
  },
  created() {
    this.setInit(this.value)
  },
}
</script>
