<template>
  <div class="filter-list-image-item">
    <div
      class="filter-list-image-container"
      @mouseover="toggleImgOverlay(true)"
      @mouseleave="toggleImgOverlay(false)"
    >
      <img
        v-if="item.data != null"
        :src="item.data"
        class="filter-list-image-item__img"
      />
      <img
        v-else
        :src="prefixServerImgPath(`${item.examinationID}/${item.file}`)"
        class="filter-list-image-item__img"
      />

      <div class="filter-list-image-item-actions-overlay" v-if="showImgOverlay">
        <v-btn
          flat
          icon
          dark
          @click="downloadImg(`${item.examinationID}/${item.file}`)"
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <v-btn flat icon dark @click="$emit('deleteImg', item.id)">
          <v-icon>delete_outline</v-icon>
        </v-btn>
      </div>
    </div>

    <div :class="['filter-list-image-actions', statusIcon]">
      <div class="left pic-number">P{{ number }}</div>
      <select-icon
        :value="item.landmarkID"
        :items="landmarkSelectItems"
        @change="landmarkChange"
      >
        <template slot-scope="props">
          <v-btn icon flat small v-on="props.on">
            <v-icon>location_on</v-icon>
          </v-btn>
        </template>
      </select-icon>

      <font class="white--text">{{
        Number(item.cm) > 0 ? item.cm + ' cm' : ''
      }}</font>
      <v-spacer />
      <v-btn
        icon
        flat
        small
        class="action-btn print"
        @click="$emit('imgStatusChange')"
      >
        <v-icon>
          {{ statusIcon }}
        </v-icon>
      </v-btn>

      <select-icon
        :value="item.findingID"
        :items="findingSelectItems"
        @change="findingChange"
      >
        <template slot-scope="props">
          <v-btn
            icon
            flat
            small
            dark
            :outline="findingIndex !== 0"
            class="action-btn finding"
            v-on="props.on"
          >
            <v-icon v-if="findingIndex === 0">label</v-icon>
            <span v-else>{{ findingIndex }}</span>
          </v-btn>
        </template>
      </select-icon>
    </div>
    <div class="tag yellow accent-4" v-if="landmarkText">
      {{ landmarkText }}
    </div>
    <!-- <div class="tag yellow accent-4" v-if="item.tag && item.tag.length > 0">
      {{ item.tag.toUpperCase() }}
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import common from '@/utils/common'
import SelectIcon from './SelectIcon'

export default {
  name: 'ScreenshotImageItem',
  components: {
    SelectIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    findingSelectItems: {
      type: Array,
      required: true,
    },
    landmarkSelectItems: {
      type: Array,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showImgOverlay: false,
      showFindingSelectOptions: null,
      showLandmarkSelectOptions: null,
    }
  },
  computed: {
    ...mapGetters('examinations', [
      'getExaminationData',
      'getExaminationTypeList',
      'getPatient',
    ]),
    findingIndex() {
      if (this.item) {
        const findingID = Number.parseInt(this.item.findingID)
        const index = this.getExaminationData.findings.findIndex(({ id }) => id === findingID)
        return index + 1
      }
      return 0
    },
    landmarkText() {
      if (this.item.landmarkID != 0 && this.landmarkSelectItems.length) {
        const landmark = this.landmarkSelectItems.find(e => e.value === this.item.landmarkID)
        if (landmark) {
          return landmark.text.replace(/(\Wof\Wcolon|\Wcolon)$/ig, '')
        }
      }
      return ''
    },
    statusIcon() {
      return this.item.status == 0 ? 'print' : 'check'
    },
  },
  methods: {
    toggleImgOverlay(boolean) {
      this.showImgOverlay = boolean
    },
    toDataURL(url) {
      return fetch(url)
        .then(response => {
          return response.blob()
        })
        .then(blob => {
          return URL.createObjectURL(blob)
        })
    },
    async downloadImg(path) {
      const url = this.prefixServerImgPath(path)
      const filename = `${common.fileRule(
        this.getExaminationData,
        this.getExaminationTypeList,
        this.getPatient,
      )}_${url.replace(/^.*[\\\/]/, '')}`
      const a = document.createElement('a')
      a.href = await this.toDataURL(url)
      a.download = filename
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    prefixServerImgPath(path) {
      return `/api/upload/image/${path}`
    },
    findingChange(v) {
      this.$emit('findingChange', v)
    },
    landmarkChange(v) {
      this.$emit('landmarkChange', v)
    },
  },
}
</script>

<style lang="scss">
.filter-group-container .filter-list-image-actions {
  .left.pic-number {
    margin: 5px;
    max-width: 30px;
    height: calc(100% - 10px);
    text-align: center;
    padding-top: 3px;
    color: white;
    font-size: 14px;
    border: 1px solid white;
    width: 55px;
  }

  .v-btn--icon.v-btn--small {
    margin: 3px;
  }
}

.filter-list-image-item {
  position: relative;
  font-size: 0;

  .tag {
    color: #44362d;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding: 2px;
  }

  &__img {
    width: 100%;
  }
}
</style>
