<template>
    <div v-if="isShownFindingHint" class="finding-hint-background">
        <div class="finding-hint">
            <h3>Finding Hint </h3>
            <h4 v-for="n in list" :key="n">{{ n }}</h4>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { FindingList } from '@/settings/findingList.js'

export default {
    name: 'FindingHintPopup',
    props: {
        examinationType: Number,
        isShownFindingHint: Boolean
    },
    data () {
        return {
            list: []
        }
    },
    methods: {
        getFindingList(examinationType) {
            this.list = FindingList[examinationType]
        }
    },
    mounted() {
        this.getFindingList(this.examinationType);
    }
}
</script>

<style lang="scss" scoped>
@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.finding-hint-background {
    width: 100%;
    background: rgba(162, 162,  162, 0.3);
    height: 100vh;
    position: absolute;
    z-index: 203;
    opacity: 0;

    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
}
.finding-hint {
    padding: 20px;
    width: 500px;
    position: absolute;
    top: 0;
    background: white;
    box-shadow: 3px 1px 5px #cccccc;
    text-align: center;
    margin-left: calc(50% - 250px);
    margin-top: 10%;
    h4 {
        margin-right: 20px;
        display: inline-block;
    }
}

</style>