var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"multi-select",attrs:{"flat":""}},[_c('v-menu',{staticClass:"multi-select__title",attrs:{"offset-y":"","left":"","attach":"","close-on-content-click":!_vm.multiple},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_c('v-card-title',_vm._g({staticClass:"pa-2 grey--text text--darken-1 h3 font-weight-bold --cursor"},props.on),[_c('div',[_vm._t("header-sub",function(){return [_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.label))])]})],2),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"grey--text text--darken-1","small":""}},[_c('v-icon',[_vm._v("expand_more")])],1)],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(item){return _c('v-list-tile',{key:`select-${item.value}`,attrs:{"ripple":{ class: 'blue-grey--text' }},on:{"click":function($event){return _vm.toggle(item.value)}}},[_c('v-list-tile-content',[(_vm.multiple)?[_c('v-checkbox',{attrs:{"input-value":_vm.selected,"value":item.value,"multiple":"","color":"blue-grey"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"header grey--text text--darken-2 --cursor"},[_vm._v(" "+_vm._s(item.text)+" ")])]},proxy:true}],null,true)})]:[_c('v-list-tile-title',{class:[
                'headine',
                '--cursor',
                item.value === _vm.selected
                  ? 'primary--text'
                  : 'grey--text text--darken-2',
              ]},[_vm._v(" "+_vm._s(item.text)+" ")])]],2)],1)}),1)],1),_c('v-card-text',{class:['pa-2', 'multi-select__selection', { '--multiple': _vm.multiple }]},[(_vm.multiple && _vm.selected.length)?_vm._l((_vm.selected),function(value){return _c('v-chip',{key:`chip-${value}`,attrs:{"outline":"","close":""},on:{"input":function($event){return _vm.remove(value)}}},[_vm._v(" "+_vm._s(_vm.mapText[value])+" ")])}):(!_vm.multiple && _vm.selected)?[(_vm.selected)?_c('v-chip',{attrs:{"outline":"","close":""},on:{"input":function($event){return _vm.remove(_vm.selected)}}},[_vm._v(" "+_vm._s(_vm.mapText[_vm.selected])+" ")]):_vm._e()]:[_c('p',{staticClass:"text-defaultGreyText mb-0"},[_vm._v(_vm._s(_vm.$t('common.nil')))])]],2),_c('v-card-actions',{staticClass:"multi-select__extra"},[_c('v-layout',{attrs:{"wrap":""}},[(_vm.showOther)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"value":_vm.otherValue,"label":_vm.$t('common.other'),"hide-details":""},on:{"change":_vm.changeOther}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":""}},[_vm._t("extra")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }