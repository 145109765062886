import { technicalStaffsServices } from '@/services/db'

const state = {
  technicalStaffs: [],
  technicalStaffsListFormat: []

}

// getters
const getters = {
  getTechnicalStaffs: state => state.technicalStaffs,
  getTechnicalStaffsListFormat: state => state.technicalStaffsListFormat
}

// actions
const actions = {

  async fetchTechnicalStaffs ({commit}, payload) {
    try {
      commit('setLoading', true, { root: true })

      const res = await technicalStaffsServices.fetchTechnicalStaffs(payload)
      const list = []
      res.forEach((v,i) => {
        list.push({text:v.name,value:v.id})  
      })
    
      commit('setTechnicalStaffsListFormat', list)

      commit('setTechnicalStaffs', res)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit}, payload) {
    /*************************************
    * ID: technicalStaff Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    
    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      const res = await technicalStaffsServices.updateTechnicalStaffColumn(payload)

      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addTechnicalStaff ({commit}) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new technicalStaff */
      /* code here */

      let obj = {
        name: 'New TechnicalStaff'
      }

      const res = await technicalStaffsServices.addTechnicalStaff(obj)
      const data = await Promise.resolve([
        ...state.technicalStaffs,
        res.data
      ])


      commit('setTechnicalStaffs', data)
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortTechnicalStaffs({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.technicalStaffs.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.technicalStaffs.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setTechnicalStaffs', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetTechnicalStaffs (state) {
    state.technicalStaffs = []
  },

  setTechnicalStaffs (state, payload) {
    state.technicalStaffs = payload
  },


  setTechnicalStaffsListFormat (state, payload) {
    state.technicalStaffsListFormat = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}