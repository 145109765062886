<template>
  <div ref="container" class="filter-lists-container" :class="{ horizontal: isHorizontal }">
    <div v-if="images.length > 0">
      <screenshot-image-item
        v-for="(imgItem, imgIndex) in images"
        :key="imgItem.id"
        v-show="isAMatch(imgItem)"
        :number ="images.length - imgIndex"
        :class="{ 'selected-print': imgItem.status === '1', 'focus': (!hideFocusItem && imgIndex === 0) } "
        :item="imgItem"
        :findingSelectItems="findingSelectItems"
        :landmarkSelectItems="landmarkSelectItems"
        @imgStatusChange="$emit('imgStatusChange', imgIndex)"
        @findingChange="findingChange(imgItem.id, $event)"
        @landmarkChange="landmarkChange(imgItem.id, $event)"
        @deleteImg="$emit('deleteImg', $event)"
      />
    </div>

    <div v-else class="no-images">No Images</div>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ScreenshotImageItem from '@/components/screenshots/ScreenshotImageItem.vue'

  export default {
    name: 'FilterImagesList',

    props: {
      images: {
        type: Array,
        required: true
      },

      active: {
        type: [String, Number],
        required: false
      },

      filterItems: {
        type: Array,
        required: false
      },

      landmarks: {
        type: Array,
        required: true
      },

      horizontal: {
        type: Boolean,
        required: false,
        default: false
      },

      hideFocusItem: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    components: {
      ScreenshotImageItem
    },

    data() {
      return {
      }
    },

    computed: {
      ...mapGetters('examinations', [
        'getExaminationData'
      ]),
      isHorizontal () {
        return this.horizontal
      },

      activeTab() {
        return this.active
      },

      landmarkSelectItems() {
        let items = this.landmarks.map(elm => {
          return {
            value: elm.id,
            text: elm.landmark
          }
        })

        items.unshift({
          value: 0,
          text: "Choose"
        })

        return items
      },

      findingSelectItems() {
        let items = this.filterItems.map((elm, index) => {
          return {
            value: elm.id,
            text: `Finding ${index + 1}`
          }
        })

        items.unshift({
          value: 0,
          text: "Choose"
        })

        return items
      }
    },
    watch : {
      images: function (val) {
        this.assignFixWidthToContainer();
      }
    },
    methods: {
      isAMatch(img) {
        const active = this.activeTab

        switch (active) {
          case 'all':
            return true

          case 'landmark':
            return img.landmarkID && (img.landmarkID !== 0)

          case 'print':
            return img.status && (img.status === "1")

          default:
            {
              const activeID = this.filterItems[active] && this.filterItems[active].id
              return activeID === img.findingID
            }
        }
      },

      getLandmarkTextByID (id) {
        const item = this.landmarks.find(elm => elm.id === Number(id))
        if (item) {
          return item.landmark
        } else {
          return ''
        }
      },

      findingChange(id, event) {
        this.$emit('findingChange', {
          id,
          newValue: event
        })
      },

      landmarkChange(id, event) {
        console.log(id, event)
        this.$emit('landmarkChange', {
          id,
          newValue: event
        })
      },
      assignFixWidthToContainer () {
        if(this.horizontal == true) {
          const imageWidth = 190;        
          this.$refs.container.style.width = this.images.length * imageWidth + 'px';
        }
      }
    },
    mounted() {
      this.assignFixWidthToContainer();
    }
  }
</script>

<style lang="scss">
  // @import '@/styles/pages/page_examinationDetail.scss';
</style>