<template>
  <div class="textfield-landscape d-flex align-end">
    <p :class="['mr-3', 'my-0', 'fsz-20', 'fsz-md-22', color]">
      {{ data.config.question }}
    </p>
    <v-text-field
      :value="data.value"
      hide-details
      :suffix="getSuffixForSize(data.config)"
      class="textfield-landscape__input mt-0 mb-1 pt-0"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'TextfieldLandscape',
  props: {
    data: {
      required: true,
      type: Object,
    },
    isRequired: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.isRequired
        ? 'red--text text--accent-2'
        : 'grey--text text--darken-2'
    },
  },
  methods: {
    getSuffixForSize(config) {
      if (config.field == 'size') {
        return config.options[Object.keys(config.options)[0]]
      }
      return ''
    },
  },
}
</script>


<style lang="scss">
.textfield-landscape {
  width: 70%;

  &__input {
    font-size: 20px;
  }
}
</style>
