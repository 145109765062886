<template>
  <v-layout
    :class="[alignProp, focus == true ? 'focus' : '']"
    row
    align-items-center
  >
    <v-flex xs7>
      <v-select
        v-bind:hide-details="shouldHideDetails"
        :items="selectedList"
        :value="selectedId"
        small
        class="my-0 py-0"
        @change="$emit('changeSelect', $event)"
      ></v-select>
    </v-flex>
    <v-flex xs3>
      <v-text-field
        suffix="mg"
        small
        class="my-0 py-0"
        v-bind:hide-details="shouldHideDetails"
        :value="inputText"
        @change="$emit('changeFillInput', $event)"
      ></v-text-field>
    </v-flex>
    <v-flex>
      <v-btn icon small @click="$emit('deleteItem')">
        <v-icon class="mt-2">close</v-icon>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'InputSelectField',
  props: {
    selectedId: {
      type: Number,
    },
    inputText: {
      type: String,
    },
    selectedList: {
      type: Array,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    align: {
      type: String,
      required: false,
      default: 'center',
    },
    focus: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    shouldHideDetails() {
      return this.hideDetails
    },
    alignProp() {
      return `align-${this.align}`
    },
  },
}
</script>
