var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"sedative-input-group",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pa-2 grey--text text--darken-1 h3 font-weight-bol border-b border-formHighlight"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('common.sedatives')))]),_c('v-btn',{attrs:{"id":"add-sedatives","small":"","icon":""},on:{"click":function($event){return _vm.handleSedativeAction('add', $event)}}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('v-card-text',{staticClass:"pa-2"},[(_vm.sedativesList.length)?_c('div',{staticClass:"mb-2 px-1"},_vm._l((_vm.sedativesList),function(item,index){return _c('div',{key:index},[_c('InputSelectField',{attrs:{"selectedId":item.sedativeID,"selectedList":_vm.sedativesSelectItems,"inputText":item.value,"hideDetails":"","align":"end"},on:{"deleteItem":function($event){return _vm.handleSedativeAction('delete', item)},"changeSelect":function($event){return _vm.handleSedativeChange({
              column: 'sedativeID',
              value: $event,
              id: item.id,
            })},"changeFillInput":function($event){return _vm.handleSedativeChange({
              column: 'value',
              value: $event,
              id: item.id,
            })}}})],1)}),0):_vm._e(),(!_vm.sedativesList.length && !_vm.sedativeOtherText)?[_c('p',{staticClass:"mb-2 px-1 text-defaultGreyText"},[_vm._v(_vm._s(_vm.$t('common.nil')))])]:_vm._e(),_c('v-layout',{staticClass:"others"},[_c('v-text-field',{staticClass:"pt-3 pa-2",attrs:{"flex":"","xs4":"","label":_vm.$t('common.other'),"hide-details":""},on:{"change":function($event){return _vm.selectOther($event)}},model:{value:(_vm.sedativeOtherText),callback:function ($$v) {_vm.sedativeOtherText=$$v},expression:"sedativeOtherText"}})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }