<template>
    <v-card class="examination-ending-dialog">
        <div class="title pt-4 pb-4 pl-5">檢查完成</div>
        <insertion-level-input
            v-if="getExaminationData.examination.examination_typeID != EXAMINATION_TYPE_OF_GASTROSCOPY"
            class="pl-5 pr-5 pb-4"
            :data="insertionLevel"
            @change="$emit('change', { column: 'insertionLevel', event: $event })"
        ></insertion-level-input>
        <v-card-text class="pl-5">
        <v-layout row justify-center v-if="getExaminationData.examination.examination_typeID == EXAMINATION_TYPE_OF_GASTROSCOPY">
            <v-checkbox
                label="Negative finding"
                v-model="negaFinding"
                @change="$emit('change', { column: 'negaFinding', event: $event })"
                hide-details
            ></v-checkbox>
        </v-layout>
        <v-layout row justify-center v-if="getExaminationData.examination.examination_typeID != EXAMINATION_TYPE_OF_GASTROSCOPY">
            <v-checkbox
                v-if="insertionLevelNegativeValues.indexOf(examination.insertionLevel) >= 0"
                label="Negative finding"
                v-model="negaFinding"
                @change="$emit('change', { column: 'negaFinding', event: $event })"
                hide-details
            ></v-checkbox>
            <v-checkbox
                v-else
                label="Negative finding in the observable segments"
                v-model="negaFindingObSeg"
                @change="$emit('change', { column: 'negaFindingObSeg', event: $event })"
                hide-details
            ></v-checkbox>
        </v-layout>
        </v-card-text>
        <br>
        <preparation-input
            class="pl-5 pr-5 pb-4"
            :data="getExaminationData"
            @change="$emit('change', $event)"
        ></preparation-input>
        <v-layout row justify-center class="py-4">
            <v-btn color="primary" outline @click="$emit('close')">Continue</v-btn>
            <v-btn color="primary" @click="$emit('endOfExam')">Back to the list</v-btn>
        </v-layout>
    </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import InsertionLevelInput from '@/components/bowelCleanseForms/InsertionLevelInput.vue'
import PreparationInput from '@/components/bowelCleanseForms/PreparationInput.vue'

export default {
    name: 'ExaminationNoFindingDialog',
    props: {
        examination: {
            type: Object,
            required: true
        },
        hiddenParams: {
            required: false,
            type: Object
      },
    },
    components:{
        InsertionLevelInput,
        PreparationInput
    },
    data() {
        return {
            insertionLevelNegativeValues: ['0', '1', '4'],
            negaFinding: this.examination.negaFinding,
            negaFindingObSeg: this.examination.negaFindingObSeg,
            EXAMINATION_TYPE_OF_GASTROSCOPY: 1,
            EXAMINATION_TYPE_OF_COLONOSCOPY: 2
        }
    },
    computed: {
        ...mapGetters('examinations', [
            'getExaminationData'
        ]),

        insertionLevel() {
            return this.getExaminationData.examination.insertionLevel
        }
    }
}
</script>

<style>

</style>
