<template>
  <v-app>
    <v-toolbar v-if="user.orgName" color="white" app fixed class="elevation-1 pl-0" height="60" style="z-index: 10">
      <v-toolbar-title class="ml-0 pl-2 fsz-16 fsz-md-30">
        <span id="app-logo" @click="goToPage('/home')">{{ user.orgName }}</span>
      </v-toolbar-title>
      <v-toolbar-items class="v-toolbar-items">
        <text-with-label
          v-for="item in infoList"
          :key="item.text"
          :text="item.text"
          :label="item.label"
          :textClassList="item.textClassList || []"
          dense
        />
        <v-spacer />
  
        <v-layout align-center justify-end>
          <v-flex class="d-flex flex-grow-0">
            <div class="d-flex align-center" v-if="isExamPage">
              <v-switch
                label="啟用全時收音"
                :value="getIsAutoRecognize"
                data-test="switch-all-time"
                color="teal"
                hide-details
                class="mr-3"
                @change="handleIsAutoRecognize($event)"
              />
              <!-- <v-btn
                class="ma-2 black--text"
                :disabled="getExaminationData && getExaminationData.examination.status != 2 || getLoading"
                type="button"
                @click="() => goToPage(`/reports/${examId}/accNo/${accNo}/preview`)"
              >
                <label>GO TO REPORT</label>
                <v-icon color="info"> near_me </v-icon>
              </v-btn> -->
            </div>
            <v-menu offset-y left>
              <v-btn slot="activator" icon>
                <v-icon class="fsz-16 fsz-md-24">settings</v-icon>
              </v-btn>
              <v-list class="overflow-y-auto menu-height">
                <v-list-tile
                  class="menu-background"
                  v-for="(item, index) in adminPages"
                  :key="index"
                  @click="goToPage(`/admin/${item.href}`)"
                >
                  <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="menu-background" key="test" @click="goToPage('/test')">
                  <v-list-tile-title>{{ $t('enviroment_test.title') }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
            <v-menu offset-y left>
              <v-btn slot="activator" icon>
                <v-icon class="fsz-16 fsz-md-24">language</v-icon>
              </v-btn>
              <v-list class="overflow-y-auto menu-height">
                <v-list-tile
                  class="menu-background"
                  v-for="locale in locales"
                  :key="locale"
                  @click="on_change_locale(locale)"
                >
                  <v-list-tile-title>{{ $t(`i18n.${locale}`) }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-flex>
          <v-flex class="flex-grow-0 d-none d-md-block">
            <v-btn flat v-if="user">
              {{ user.username || user.name }}
              <v-icon :color="voiceRepeatColor" class="pl-1">
                {{ voiceRepeatIcon }}
              </v-icon>
            </v-btn>
          </v-flex>
          <v-flex class="flex-grow-0">
            <v-btn @click="logout" flat class="fsz-12 fsz-md-14">{{ $t('homepage.singout') }}</v-btn>
          </v-flex>
        </v-layout>
      </v-toolbar-items>
    </v-toolbar>
    <v-content class="app__content">
      <v-container fluid fill-height class="pa-0">
        <router-view></router-view>
      </v-container>
    </v-content>

    <div class="loading-overlay" v-show="getLoading">
      <div class="loading-overlay__wrap">
        <v-progress-circular indeterminate color="primary" id="loading-indicator" />
        <h2 class="loading-overlay__typing" :style="styled(getLoadingDescript.length)" v-if="getLoadingDescript">
          {{getLoadingDescript}}
        </h2>
      </div>
    </div>
    <v-dialog v-if="editDialog" v-model="editDialog" persistent max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <div>
              <div cols="6">
                <label>院內碼:</label><font>{{ examInfo.hospitalCode || '' }}</font>
              </div>
            </div>
            <div>
              <div cols="6">
                <label>日期:</label><font>{{ examInfo.examinationDate | Date }}</font>
              </div>
            </div>
            <div>
              <div cols="6">
                <label>病患姓名:</label><font>{{ examInfo.patientName }}</font>
              </div>
            </div>
            <div>
              <div cols="6">
                <label>病歷號碼:</label><font>{{ examInfo.medicalRecord }}</font>
              </div>
            </div>
            <div>
              <div cols="6">
                <label>檢查單號:</label><font>{{ examInfo.examinationRecord }}</font>
              </div>
            </div>
            <div align="center">
              <div cols="6">
                <v-text-field v-model="newAccNo" filled label="新檢查單號" clearable></v-text-field>
              </div>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="darken-2" text @click="editDialog = false"> Cancel </v-btn>

          <v-btn color="darken-2" text @click="reportShippingToNewAccNo()"> Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="transferLoading" v-model="transferLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          報告轉送中
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="notifyDialog" v-model="notifyDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h3"> 報告轉送結果 </v-card-title>

        <v-card-text>
          {{ transferResultMessage }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="darken-1" text @click="initialNotifyMessage()"> 知道了 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <notifications position="top center" group="topCenter">
      <template slot="body" slot-scope="{ item, close }">
        <div class="d-flex justify-between align-center pl-4 flex-grow-0 alert-container">
          <p class="alert-container__content text-sm-center">{{ item.text }}</p>
          <v-btn
            class="flex-grow-0 white--text"
            type="button"
            flat
            fab
            small
            @click="close"
          >
          <v-icon> close </v-icon>
        </v-btn>
        </div>
      </template>
    </notifications> 
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextWithLabel from '@/components/TextWithLabel.vue'
import { reportServices } from '@/services/db'
import axios from 'axios'
import { get } from 'lodash'

export default {
  components: {
    TextWithLabel,
  },

  computed: {
    ...mapGetters(['getLoading', 'getUser', 'getLoadingDescript', 'getIsAutoRecognize']),
    ...mapGetters('examinations', ['getExaminationData', 'getExaminationTypeList', 'getPatient']),
    user() {
      return (
        this.getUser || {
          username: '',
          name: '',
          employee_typeID: 1,
          employeeID: 0,
          orgNo: '',
          orgName: '',
          useGuiding: 0,
          level: '1',
        }
      )
    },
    getExaminationType() {
      if (!this.getExaminationTypeList) return []
      return this.getExaminationTypeList.filter(
        (type) => type.id === this.getExaminationData.examination.examination_typeID,
      )
    },
    accNo() {
      return Number(this.$route.params.accNo) != 0
        ? this.$route.params.accNo
        : 0
    },
    examId() {
      return this.$route.params.examinationID || -1
    },
    voiceRepeatIcon() {
      return Number(this.user.useGuiding) ? 'record_voice_over' : 'voice_over_off'
    },
    voiceRepeatColor() {
      return Number(this.user.useGuiding) ? 'success' : 'error'
    },
    adminPages() {
      return this.user ? this.pages.filter((p) => p.level <= this.user.level) : this.pages
    },
    isExamPage () {
      return this.$route.path.indexOf('/examinations') == 0 || this.$route.path.indexOf('/clinic') === 0
    },
    styled () {
      return (leng) => {
        return {
          width: `${leng * 1.7}ch`,
          animation: `typing 2s steps(${leng}), blink 0.5s step-end infinite alternate`
        }
      }
    },
    pages() {
      return [
        {
          name: this.$t('organization.title'),
          href: 'organization-list',
          level: 1,
        },
        {
          name: this.$t('doctor.title'),
          href: 'doctor-list',
          level: 1,
        },
        {
          name: 'Employee Types',
          href: 'employeeType-list',
          level: 9,
        },
        {
          name: this.$t('examination.title'),
          href: 'examination-list',
          level: 1,
        },
        {
          name: 'Examination Types',
          href: 'examinationType-list',
          level: 9,
        },
        {
          name: 'Landmarks',
          href: 'landmark-list',
          level: 9,
        },
        {
          name: this.$t('inspection_instrument.title'),
          href: 'inspectionInstrument-list',
          level: 1,
        },
        {
          name: this.$t('wash_Instrument.title'),
          href: 'washInstrument-list',
          level: 1,
        },
        {
          name: 'Antiplatelet',
          href: 'antiplatelet-list',
          level: 9,
        },
        {
          name: 'Antispasmodic',
          href: 'antispasmodic-list',
          level: 9,
        },
        {
          name: 'Preparation Agents',
          href: 'cleanMed-list',
          level: 9,
        },
        {
          name: 'Sedative',
          href: 'sedative-list',
          level: 9,
        },
        {
          name: this.$t('patient.title'),
          href: 'patient-list',
          level: 1,
        },
        {
          name: this.$t('nurse.title'),
          href: 'technicalStaff-list',
          level: 1,
        },
        {
          name: this.$t('user.title'),
          href: 'user-list',
          level: 1,
        },
        {
          name: this.$t('analytics.title'),
          href: 'analytics',
          level: 1,
        },
      ]
    },
    locales() {
      if (this.$i18n && this.$i18n.availableLocales) {
        return this.$i18n.availableLocales
      }
      return []
    }
  },
  data() {
    return {
      dialog: false,
      newAccNo: '',
      loading5: false,
      loading6: false,
      transferLoading: false,
      notifyDialog: false,
      drawer: null,
      showToolBar: true,
      editDialog: false,
      timerEnabled: false,
      isTranNewAccVisible: false,
      items: [
        { icon: 'list_alt', text: 'Examinations', href: '/examinations' },
        { icon: 'assignment', text: 'Reports', href: '/reports' },
        { icon: 'style', text: 'Admin', href: '/admin' },
      ],

      examInfo: {
        medicalRecord: '',
        examinationRecord: '',
        hospitalCode: '',
      },
      adminPagesByUser: [],
      infoList: [],
    }
  },

  methods: {
    ...mapActions(['logout', 'handleIsAutoRecognize']),

    goToPage(page) {
      this.$router.push(page)
    },
    setToolBar() {
      this.showToolBar = this.$router.currentRoute.path.indexOf('/examinations/') < 0
    },
    getPatientInfoList ({type, accNo, patientNo, name}) {
      const fwBold= 'font-weight-bold'
      const typeColor = type === 'Gastroscopy' ? 'text-defaultBlue' :'text-defaultOrange'
      return [
        { label: 'Exam. Type', text: type, textClassList: [typeColor, fwBold] },
        { label: 'Exam. Record', text: accNo, textClassList: [fwBold] },
        { label: 'Name', text: name, textClassList: [fwBold] },
        { label: 'Med. Record', text: patientNo, textClassList: [fwBold] },
      ]
    },
    setExaminationInfo() {
      // TODO: there is bug here
      const examination = this.getExaminationData.examination
      const patient = this.getPatient

      this.infoList = this.getPatientInfoList({
        type: this.getExaminationType[0].examination_type,
        accNo: examination.accNo,
        patientNo: patient.no,
        name: patient.name, 
      })

      this.examInfo.examinationRecord = examination.accNo
      this.examInfo.medicalRecord = patient.no
      this.examInfo.examinationDate = examination.date
      this.examInfo.patientName = patient.name
    },
    async reportShippingToNewAccNo() {
      this.editDialog = false
      this.transferResultMessage = ''
      if (this.newAccNo.trim() !== '') {
        this.transferLoading = true
        const res = await reportServices.transferToNewAccNo({
          patientId: this.examInfo.medicalRecord,
          accNo: this.examInfo.examinationRecord,
          newAccNo: this.newAccNo,
        })
        this.transferResultMessage = get(res, 'data.message', '') || ''
        this.transferLoading = false
        this.transferLoading = false
        this.notifyDialog = true
      }
    },
    initialNotifyMessage() {
      this.notifyDialog = false
      this.newAccNo = ''
    },
    async getExamination(id) {
      this.selectedExamination = id || ''
      const list = (await this.getExaminations) || []
      const row = list.find((item) => item.url === id)
      if (!row) return
      this.examInfo.examinationRecord = row.accNo
      this.examInfo.medicalRecord = row.medicalRecord
      this.examInfo.examinationDate = row.date
      this.examInfo.patientName = row.patientName
      this.examInfo.hospitalCode = row.hospitalCode
    },
    handleAddAccClick() {
      this.editDialog = true
    },
    on_change_locale(locale) {
      if (this.$root) {
        this.$root.set_locale(locale)
      }
    }
  },

  watch: {
    examId() {
      this.getExamination(this.examId)
    },
    $route(to) {
      this.isTranNewAccVisible = to.path.indexOf('examinations') > -1
    },
  },
  mounted() {
    this.setToolBar()
    this.getExamination(this.examId)
    this.isTranNewAccVisible = this.$route.path.indexOf('examinations') > -1
    document.oncontextmenu = function () {
      return false
    }
    window.axios = axios
  },
  beforeUpdate() {
    const hasExamination = this.getExaminationData && this.getExaminationData.examination.id
    const sameExaminationId =
      this.getExaminationData && this.getExaminationData.examination.id === parseInt(this.$route.params.examinationID)
    const inExaminationPage = this.$route.path.indexOf('/examinations') == 0 || this.$route.path.indexOf('/clinic') === 0

    if (hasExamination && inExaminationPage && sameExaminationId) {
      this.setExaminationInfo()
    } else if (!inExaminationPage) {
      this.infoList = []
    }
  },
}
</script>

<style lang="scss">
.v-toolbar__content {
  padding-left: 16px;
}
.v-toolbar__title {
  overflow: visible;
}

/***** TODO *****/
/***** Make overlay 100% height of the browser and do not allow scrolling *****/
/***** And make spinner align center *****/
.loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#e3e3e3, 0.9);
  z-index: 5;
  display: flex;
  padding-top: 200px;
  justify-content: center;
  overflow: hidden;
}
.loading-overlay__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loading-overlay__typing {
  color: #3b587d;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 20px;
  overflow: hidden;
  border-right: 3px solid;
  white-space: nowrap;
}
@keyframes typing {
  from {
    width: 0
  }
}
@keyframes blink {
  50% {
    border-color: transparent
  }
}

.v-toolbar--clipped {
  z-index: 6;
}

#app-logo {
  cursor: pointer;
}

.v-toolbar__content {
  .text-with-label-component {
    margin-left: 20px;
  }
}
.v-toolbar-items {
  width: 100%;
  align-items: center;
}

.menu-height {
  max-height: 280px;
  .menu-background {
    background: #fff;
  }
}

.alert-container {
  background-color: #404040 !important;
  border-radius: 8px;
  margin: auto;
  color: #fff;
  width: 100%;
  &__content {
    letter-spacing: 1px;
  }
}
</style>
