<template>
  <v-select
    :label="label"
    :items="items"
    :hide-details="shouldHideDetails"
    :value="data"
    @change="$emit('change', $event)"
  ></v-select>
</template>

<script>
export default {
    name: 'InsertionLevelInput',

    props: {
        data: {
            type: [String, Number],
            required: false
        },

        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            field: 'insertionLevel',
            label: 'Insertion Level'
        }
    },

    computed: {
        items() {
            return this.$store.getters['examinations/getInsertionLevelList']
        },
        shouldHideDetails() {
            return this.hideDetails
        }
    }
}
</script>