<template>
  <div>
    <v-radio-group v-model="selected" row class="mb-0 mr-0">
      <v-radio
        class="text-primary mb-2"
        v-for="(option, key) in options"
        :key="key"
        :value="+key"
        hide-details
        @change="handleChange"
        :color="color"
      >
        <template #label>
          <span :class="`text-${color}`">{{ option }}</span>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'HorizontalCheckBoxes',

  props: {
    options: {
      required: true,
      type: Object,
    },
    value: {
      required: true,
      type: Number,
    },
    field: {
      required: true,
      type: String,
    },
    color: {
      type: String,
      default: '#3b587d',
    },
  },
  data() {
    return {
      selected: this.value,
    }
  },
  watch: {
    value(val) {
      this.selected = val
    },
  },
  methods: {
    handleChange(news) {
      this.$emit('change', news)
    },
  },
}
</script>

<style lang="scss" scoped>
.horizontal-checkboxes {
  margin-left: -4px;
}
.v-input.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
  display: inline-block;
  float: left;
  padding-right: 20px;
}
</style>
