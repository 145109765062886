import { landmarkServices } from '@/services/db'

const state = {
  landmarks: []
}

// getters
const getters = {
  getLandmarks: state => state.landmarks
}

// actions
const actions = {

  async fetchLandmarks ({commit}, payload) {
    try {
      commit('setLoading', true, { root: true })

      const res = await landmarkServices.fetchLandmarks(payload)

      commit('setLandmarks', res)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit}, payload) {
    /*************************************
    * ID: landmark Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    
    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      const res = await landmarkServices.updateLandmarkColumn(payload)

      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addLandmark ({commit}) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new landmark */
      /* code here */

      let obj = {
        name: 'New Landmark'
      }

      const res = await landmarkServices.addLandmark(obj)
      const data = await Promise.resolve([
        ...state.landmarks,
        res.data
      ])


      commit('setLandmarks', data)
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortLandmark({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.landmarks.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.landmarks.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setLandmarks', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetLandmarks (state) {
    state.landmarks = []
  },

  setLandmarks (state, payload) {
    state.landmarks = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}