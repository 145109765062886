<template>
  <v-container>
    <!-- delete -->
    <confirm-dialog
      :showDialog="confirmDialog.show"
      :message="confirmDialog.message"
      :title="confirmDialog.title"
      :onConfirm="onConfirmHandler.bind(this)"
      :onCancel="cancelDialog.bind(this)"
    >
    </confirm-dialog>
    <!-- edit  -->
    <div class="text-xs-center">
      <v-bottom-sheet v-model="editDialog.show">
        <v-card height="80vh" style="overflow: scroll" v-if="editDialog.show">
          <FindingsItemsEdit
            :reporType="reportType"
            :findingIndex="findingItemsEditIndex"
            :findingId="findingItemsEditId"
          />
        </v-card>
      </v-bottom-sheet>
    </div>

    <article data-descript="report-findings-group">
      <section class="bg-white pa-3 mb-3">
        <v-layout>
          <v-flex>
            <v-select
              :value="examinationData?.examinationDoctorID"
              :label="$t('doctor.title')"
              :items="getDoctorList"
              item-value="id"
              item-text="name"
              @change="setExaminationColumn('examinationDoctorID', $event)"
              hide-details
              attach
            >
            </v-select>
          </v-flex>
          <v-flex>
            <v-select
              :value="examinationData?.technical_staffID_1"
              :label="$t('nurse.title') + '1'"
              :items="getTechnicalStaffList"
              item-value="id"
              item-text="name"
              @change="setExaminationColumn('technical_staffID_1', $event)"
              hide-details
              attach
            >
            </v-select>
          </v-flex>
          <v-flex>
            <v-select
              :value="examinationData?.technical_staffID_2"
              :label="$t('nurse.title') + '2'"
              :items="getTechnicalStaffList"
              item-value="id"
              item-text="name"
              @change="setExaminationColumn('technical_staffID_2', $event)"
              hide-details
              attach
            >
            </v-select>
          </v-flex>
        </v-layout>
      </section>

      <section class="bg-white mb-3 pt-2 pb-3" v-if="isColonReport">
        <div class="bg-white mb-0">
          <h3 class="print-color subtitle-border mb-3">{{ $t('common.procedure_note') }}</h3>
          <div class="px-2">
            <v-textarea
              :value="examinationData?.procedureNote"
              :rows="4"
              @change="setExaminationColumn('procedureNote', $event)"
              no-resize
              hide-details
              outline
              label="Fill Procedure Note"
            />
          </div>
        </div>
      </section>

      <section class="bg-white mb-3 py-2">
        <div style="display: flex; align-items: center" class="bg-white mb-0">
          <h3 class="print-color subtitle-border">{{ $t('report.diagnosis') }}</h3>
          <v-btn :disabled="isFetchParagraph" icon small outline color="blue-grey" @click="addFinding">
            <v-icon>add</v-icon>
          </v-btn>
        </div>
        <div v-if="getOrderArticle">
          <template v-if="isColonReport">
            <FindingsItems
              :del-btn-disabled="isFetchParagraph"
              :edit-btn-disabled="isFetchParagraph"
              :findings="getOrderArticle || []"
              @edit="editFinding"
              @delete="handleDeleteModal"
            />
            <div v-if="getColonDescriptiveParagraph" class="pa-3 border-defaultGreyText border-t border-t--dashed border-1">
              <div v-for="(text, idx) in getColonDescriptiveParagraph" :key="`detail-colon-des-${idx}`">
                <div>{{ text }}</div>
              </div>
            </div>
          </template>
          <template v-else>
            <FindingsItemsByOrgan
              :findings="getOrderArticle || []"
              @edit="editFinding"
              @delete="handleDeleteModal"
            />
            <div v-if="getGastroDescriptiveParagraph" class="pa-3">
              <div v-for="(text, pos) in getGastroDescriptiveParagraph" :key="pos">
                <p class="font-weight-bold mb-0">{{ pos }}:</p>
                <div>{{ text || '-'}}</div>
                <template v-if="pos !== 'DUODENUM'">
                  <br><br>
                </template>
              </div>
            </div>
          </template>
        </div>
      </section>

      <section class="mb-3 bg-white">
        <SummaryInfoGroup
          v-if="getOrderArticle"
          type="summary"
          :findings="getOrderArticle"
        />
        <div v-else class="py-2">
          <h3 class="print-color subtitle-border mb-2">Negative finding :</h3>
          <div class="px-2">
            <v-textarea
              :value="examinationData?.negaSuggestion"
              @change="setExaminationColumn('negaSuggestion', $event)"
              :rows="3"
              no-resize
              hide-details
              outline
              label="Negative Suggestion"
            />
          </div>
        </div>
      </section>

      <section class="bg-white mb-3 pt-2 pb-3">
        <div class="bg-white mb-0">
          <h3 class="print-color subtitle-border mb-3">Suggestions</h3>
          <div class="px-2">
            <v-textarea
              :value="examinationData?.suggestion"
              :rows="3"
              @change="setExaminationColumn('suggestion', $event)"
              no-resize
              hide-details
              outline
              label="Fill Suggestions of This Examination"
            />
          </div>
        </div>
      </section>
    </article>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FindingsItems from './FindingsItems.vue'
import SummaryInfoGroup from '../SummaryInfoGroup.vue'
import FindingsItemsEdit from './FindingsItemsEdit.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import FindingsItemsByOrgan from './FindingsItemsByOrgan.vue'
import {
  EXAMINATION_TYPE_OF_GASTROSCOPY,
  EXAMINATION_TYPE_OF_SIGMOIDOSCOPY,
} from '../../utils/examinations.config.js'

export default {
  name: 'FindingsFormDetails',
  components: {
    FindingsItems,
    SummaryInfoGroup,
    FindingsItemsEdit,
    ConfirmDialog,
    FindingsItemsByOrgan,
  },
  data() {
    return {
      editDialog: {
        show: false,
        mode: '',
      },
      confirmDialog: {
        show: false,
        message: '',
        title: '',
      },
      onConfirmHandler: () => {},
      findingItemsEditIndex: 0,
      findingItemsEditId: null,
      EXAMINATION_TYPE_OF_GASTROSCOPY,
      EXAMINATION_TYPE_OF_SIGMOIDOSCOPY,
      isFetchParagraph: false,
    }
  },
  computed: {
    ...mapGetters(['getSetting']),
    ...mapGetters('examinations', [
      'getOrderArticle',
      'getExaminationData',
      'getExaminationTypeList',
      'getDoctorList',
      'getTechnicalStaffList',
      'getOrderedGastricData',
      'getQuestionsList',
      'getColonDescriptiveParagraph',
      'getGastroDescriptiveParagraph',
    ]),
    examinationData() {
      return this.getExaminationData?.examination
    },
    isColonReport() {
      if (!this.examinationData) return
      return (
        this.examinationData.examination_typeID !=
          this.EXAMINATION_TYPE_OF_GASTROSCOPY &&
        this.examinationData.examination_typeID !=
          this.EXAMINATION_TYPE_OF_SIGMOIDOSCOPY
      )
    },
    reportType() {
      return this.getExaminationData?.examination?.examination_typeID
    },
    getFindings() {
      return this.getExaminationData ? this.getExaminationData.findings : []
    },
    examinationID() {
      return this.getExaminationData.examination.id
    },
    editDialogStatus() {
      return this.editDialog.show
    },
  },
  methods: {
    ...mapActions('examinations', [
      'setStoreHiddenParameter',
      'deleteFinding',
      'updateExaminationColumn',
      'fetchExaminationDetailAndOtherList',
      'fetchOrderArticle',
      'fetchDescriptiveParagraph',
    ]),
    addFinding() {
      this.isFetchParagraph = true
      this.$store
        .dispatch('examinations/addFinding', this.examinationID)
        .then(() => {
          this.setStoreHiddenParameter({
            target: 'focus',
            value: 'finding',
            part: 'd',
          })
          this.findingItemsEditIndex = this.getFindings.length - 1
          this.findingItemsEditId =
            this.getFindings[this.findingItemsEditIndex].id
          this.fetchOrderArticle({ examinationID: this.examinationData.id })
          if (this.getSetting.descriptive) {
            this.fetchDescriptiveParagraph({
              eid: this.examinationData.id,
              etype: this.examinationData.examination_typeID,
            })
          }
          this.editDialog.mode = 'create'
          this.editDialog.show = true
          this.isFetchParagraph = false
        })
    },
    getTypeName() {
      const typeID = this.getExaminationData.examination.examination_typeID
      return this.getExaminationTypeList.filter((item) => item.id === typeID)[0]
        .examination_type
    },
    editFinding(id) {
      const findingIndex = this.getFindings.findIndex((f) => f.id === id)
      this.findingItemsEditIndex = findingIndex
      this.findingItemsEditId = id
      this.editDialog.mode = 'edit'
      this.editDialog.show = true
    },
    deleteFinding({ findingIndex, id }) {
      this.$store
        .dispatch('examinations/deleteFinding', {
          findingID: id,
          findingIndex,
        })
        .then(() => {
          this.cancelDialog()
        })
    },
    async handleDeleteFinding({ findingIndex, id }) {
      this.isFetchParagraph = true
      await this.deleteFinding({ findingIndex, id })
      const { accNo, examination_typeID, examinationID } = this.examinationData
      if (examination_typeID === this.EXAMINATION_TYPE_OF_GASTROSCOPY) {
        this.fetchExaminationDetailAndOtherList({
          examinationID,
          accNo,
        })
      }
      setTimeout(() => {
        this.fetchOrderArticle({ examinationID: this.examinationData.id })
        if (this.getSetting.descriptive) {
          this.fetchDescriptiveParagraph({
            eid: this.examinationData.id,
            etype: this.examinationData.examination_typeID,
          })
        }
        this.isFetchParagraph = false
      }, 10)
    },
    handleDeleteModal(id) {
      try {
        const findingIndex = this.getFindings.findIndex((f) => f.id === id)
        const getFinding = this.getFindings[findingIndex]
        const { questionID, value } = getFinding.questions?.finding
        const otherMsg =
          questionID &&
          value &&
          this.getQuestionsList[questionID].options[value]
            ? `- ${this.getQuestionsList[questionID].options[value]}`
            : ''
        this.confirmDialog.title = this.$t('report.confirm.title')
        this.confirmDialog.message = this.$t('report.confirm.text', [findingIndex + 1])
        this.confirmDialog.show = true
        this.onConfirmHandler = () => {
          this.handleDeleteFinding({ findingIndex, id })
        }
      } catch (error) {
        // regarless
      }
    },
    cancelDialog() {
      this.confirmDialog.show = false
    },
    setExaminationColumn(column, value) {
      let d = {
        id: this.examinationID,
        column: column,
        value: value,
      }
      this.updateExaminationColumn(d)
    },
  },
  watch: {
    editDialogStatus(val) {
      if (!val && this.editDialog.mode === 'create') {
        const lastIdx = this.getFindings.length - 1
        const lastFindings = this.getFindings[lastIdx]
        const hasNoFindingValue = '0'
        if (lastFindings.questions.finding.value === hasNoFindingValue) {
          this.handleDeleteFinding({
            findingIndex: lastIdx,
            id: lastFindings.id,
          })
        }
      }
    },
  },
}
</script>