import { reportServices } from '@/services/db'

const state = {
  chineseReport: [],
}

// getters
const getters = {
  getChineseReport: state => state.chineseReport,
}

// actions
const actions = {
  async fetchChineseReport ({ commit }, payload) {
    try {
      commit('setLoading', true, { root: true })
      const res = await reportServices.fetchChineseReport(payload)
      commit('setChineseReport', res.data)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },
}

// mutations
const mutations = {
  setChineseReport (state, payload) {
    state.chineseReport = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}