import { employeeTypeServices } from '@/services/db'

const state = {
  employeeTypes: [],
  employeeTypesListFormat: []
}

// getters
const getters = {
  getEmployeeTypes: state => state.employeeTypes,
  getEmployeeTypesListFormat: state => state.employeeTypesListFormat,
}

// actions
const actions = {

  async fetchEmployeeTypes ({commit}, payload) {
    try {
      commit('setLoading', true, { root: true })

      const res = await employeeTypeServices.fetchEmployeeTypes(payload)
      const list = []
      res.forEach((v,i) => {
        list.push({text:v.employee_type,value:v.id})  
      })
    
      commit('setEmployeeTypesListFormat', list)
      commit('setEmployeeTypes', res)
      commit('setLoading', false, { root: true })
    } catch (e) {
      console.log(e)
    }
  },

  async updateColumnById ({commit}, payload) {
    /*************************************
    * ID: employeeType Id                      *
    * field: the field that changed      *
    * value: the new value of that field *
    *************************************/
    
    try {
      commit('setLoading', true, { root: true })

      /* API call here to save data to server */
      /* code here */

      const res = await employeeTypeServices.updateEmployeeTypeColumn(payload)

      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },

  async addEmployeeType ({commit}) {
    try {
      commit('setLoading', true, { root: true })

      /* API call here to server to create new employeeType */
      /* code here */

      let obj = {
        name: 'New EmployeeType'
      }

      const res = await employeeTypeServices.addEmployeeType(obj)
      const data = await Promise.resolve([
        ...state.employeeTypes,
        res.data
      ])
      commit('setEmployeeTypes', data)
      commit('setLoading', false, { root: true })
    } catch (e) { 
      console.log(e)
    }
  },
  async sortEmployeeType({commit}, payload){
    commit('setLoading', true, { root: true })
    let title = payload.title;
    let order = payload.order

    let data = []
    if (order){
      data= state.employeeTypes.sort((a,b) => a[title].toString().localeCompare(b[title].toString()))
    }else{
      data= state.employeeTypes.sort((b,a) => a[title].toString().localeCompare(b[title].toString()))
    }
    commit('setEmployeeTypes', data)
    commit('setLoading', false, { root: true })
  }

}

// mutations
const mutations = {
  resetEmployeeTypes (state) {
    state.employeeTypes = []
  },

  setEmployeeTypes (state, payload) {
    state.employeeTypes = payload
  },

  setEmployeeTypesListFormat (state, payload) {
    state.employeeTypesListFormat = payload
  }

  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}