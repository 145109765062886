<template>
  <v-layout row wrap tag="ul" class="pl-0 --scroll">
    <v-flex
      tag="li"
      xs4
      md12
      class="list-style-none d-flex bg-light-blue mb-2"
      v-for="(img, idx) in allImages"
      :key="`${img.file}${img.id}`"
    >
      <v-flex
        xs3
        align-self-center
        data-test="finding-image-number"
        tag="h3"
        class="fsz-28 text-align-center text-white"
        >{{ allImages.length - idx }}</v-flex
      >
      <v-flex xs9>
        <div class="py-2 pr-2">
          <v-img
            data-test="finding-image"
            :src="
              img.file
                ? prefixServerImgPath(`${img.examinationID}/${img.file}`)
                : img.data
            "
            aspect-ratio="1.4"
            contain
          />
        </div>
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "BasicExamFindingImages",
  props: {
    allImages: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    prefixServerImgPath(path) {
      return `/api/upload/image/${path}`;
    },
  },
};
</script>
